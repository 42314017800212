import {gql} from '@apollo/client';

export default gql`
  mutation submitAppraisalRecentTransactions(
    $taskId: ID!
    $appraisalRecentTransactions: AppraisalRecentTransactionsInputObject!
  ) {
    submitAppraisalRecentTransactions(
      taskId: $taskId
      appraisalRecentTransactions: $appraisalRecentTransactions
    ) {
      appraisalRecentTransactions {
        id
        createdAt
        date
        haveAppraisalFile
        occurredRecently
        updatedAt
        value
      }
    }
  }
`;
