import {useCallback, useEffect} from 'react';

import {propOr} from 'ramda';

export default (keyMap) => {
  const onKeyUp = useCallback(
    (event) => {
      const {key} = event;
      const keyCallback = propOr(null, key, keyMap);
      if (keyCallback) {
        keyCallback(event);
      }
    },
    [keyMap],
  );

  useEffect(() => {
    document.removeEventListener('keyup', onKeyUp);
    document.addEventListener('keyup', onKeyUp);

    return () => {
      document.removeEventListener('keyup', onKeyUp);
    };
  }, []);
};
