import * as React from 'react';

import PropTypes from 'prop-types';

const SvgHvac = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M12.37 17.405a5.572 5.572 0 0 0 5.565-5.566c0-1.38-.51-2.655-1.36-3.633l-.063-.063a5.524 5.524 0 0 0-4.143-1.849 5.509 5.509 0 0 0-4.143 1.87l-.02.021a5.505 5.505 0 0 0-1.382 3.676 5.549 5.549 0 0 0 5.545 5.544zm-4.887-7.074h9.815c.127.446.212.892.234 1.38H7.249c.021-.488.085-.955.234-1.38zm.998-1.806H16.3c.34.404.638.871.85 1.381H7.632a4.84 4.84 0 0 1 .85-1.38zm9.03 3.612a4.986 4.986 0 0 1-.277 1.38H7.525a5.898 5.898 0 0 1-.276-1.38H17.51zm-1.339 3.165H8.588a4.97 4.97 0 0 1-.893-1.38h9.39a5.207 5.207 0 0 1-.913 1.38zm-7.138.425h6.692a5.039 5.039 0 0 1-3.357 1.254 4.984 4.984 0 0 1-3.335-1.254zm6.84-7.627H8.886a5.136 5.136 0 0 1 3.505-1.38c1.36 0 2.571.53 3.484 1.38z"
        fill={color}
        stroke={color}
        strokeWidth={0.5}
      />
      <path
        d="M20.55 4H4.212C4.085 4 4 4.085 4 4.212v15.276c0 .127.085.212.212.212H20.55c.127 0 .212-.085.212-.212V4.212A.213.213 0 0 0 20.55 4zm-.213 15.275H4.425V4.425h15.912v14.85z"
        fill={color}
        stroke={color}
        strokeWidth={0.5}
      />
    </svg>
  );
};

SvgHvac.propTypes = {
  color: PropTypes.string,
};
export default SvgHvac;
