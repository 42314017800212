import React, {forwardRef, useEffect, useRef, useState} from 'react';

import PropTypes from 'prop-types';

import {useTheme} from '@renofi/theme/src';

import Portal from '../../../Portal';
import Box from '../../../Box';
import TextField from '../../../TextField';

import {Header} from './styled';

const CSS = {
  borderRadius: 4,
  transition: 'all 500ms ease',
  maxHeight: 360,
  overflow: 'hidden',
  overflowY: 'auto',
};

const OptionsContainer = forwardRef(
  (
    {
      children,
      containerCss,
      onClose,
      onKeyUp,
      onSearchBy,
      placeholder,
      position,
      optionsWidth,
      searchable,
      searchPlaceholder,
      tabIndex,
      value: selected,
      filterInput,
      searchIcon,
      help,
      small,
    },
    parentRef,
  ) => {
    const {styles} = useTheme('options');
    const [searchBy, setSearchBy] = useState(filterInput || '');
    const ref = useRef();

    const onSearchChange = (input) => {
      setSearchBy(input);
      onSearchBy(input);
    };

    useEffect(() => {
      if (ref?.current) {
        ref?.current?.focus();
      }
    }, []);

    return (
      <Portal
        position={searchable ? 'self' : position}
        forceWidth={optionsWidth}
        onClickOutside={onClose}
        ref={parentRef}>
        {searchable && (
          <Header searchable={searchable} p={0}>
            <TextField
              small={small}
              help={help}
              leftIcon={searchIcon ? 'glass' : null}
              mb={0}
              onChange={onSearchChange}
              onKeyUp={onKeyUp}
              placeholder={searchPlaceholder || placeholder || ''}
              forwardedRef={ref}
              tabIndex={tabIndex}
              value={searchBy}
            />
          </Header>
        )}
        <Box
          data-testid="options-container"
          as="div"
          css={{...styles, ...CSS, ...containerCss}}>
          {children}
        </Box>
      </Portal>
    );
  },
);

OptionsContainer.propTypes = {
  children: PropTypes.node,
  containerCss: PropTypes.object,
  help: PropTypes.node,
  label: PropTypes.node,
  position: PropTypes.string,
  optionsWidth: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onKeyUp: PropTypes.func.isRequired,
  onSearchBy: PropTypes.func.isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  searchable: PropTypes.bool,
  filterInput: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  tabIndex: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ),
  ]),
  searchIcon: PropTypes.bool,
};

OptionsContainer.defaultProps = {
  containerCss: {},
  position: 'bottom',
};

export default OptionsContainer;
