import React from 'react';

const SvgComponent = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 134 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m59.8 5.009-11.792 7.109c-.331.2-.533.558-.533.945V36.18c0 .306.247.553.552.553H53.9a.552.552 0 0 0 .553-.552V16.864c0-.394.21-.759.551-.956l4.797-2.772a1.103 1.103 0 0 1 1.103 0l4.797 2.772c.342.197.552.562.552.957V36.18c0 .305.247.552.552.552h5.85a.553.553 0 0 0 .553-.553V13.062c0-.386-.203-.744-.533-.944l-11.77-7.11a1.103 1.103 0 0 0-1.104 0Z"
      fill="#FF5253"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.41 23.575c0 7.44 5.76 13.47 12.866 13.47 5.224 0 9.718-3.26 11.733-7.941a.513.513 0 0 0-.465-.72h-5.518c-.34 0-.657.17-.844.455-.88 1.343-2.371 2.38-4.906 2.38-3.98 0-5.394-2.554-5.897-4.84a.51.51 0 0 1 .5-.616h17.19c.524 0 .968-.396 1.016-.917.038-.418.058-.842.058-1.27 0-7.44-5.76-13.471-12.867-13.471-7.105 0-12.866 6.03-12.866 13.47Zm7.163-3.538c.641-2.066 2.148-4.107 5.705-4.107s5.064 2.04 5.704 4.107a.51.51 0 0 1-.49.655H25.064a.51.51 0 0 1-.49-.655ZM77.545 23.553c0 7.452 6.035 13.494 13.48 13.494 7.444 0 13.479-6.042 13.479-13.494 0-7.453-6.035-13.494-13.479-13.494-7.445 0-13.48 6.041-13.48 13.494Zm13.477 7.262c-4.776-.006-6.495-4-6.495-7.267 0-3.269 1.722-7.267 6.505-7.267v.011c4.775.006 6.495 4 6.495 7.267 0 3.269-1.722 7.267-6.505 7.267v-.01ZM125.905 4.052a4.05 4.05 0 0 0 4.048 4.051A4.049 4.049 0 0 0 134 4.052 4.05 4.05 0 0 0 129.953 0a4.05 4.05 0 0 0-4.048 4.052ZM126.997 11.363a.51.51 0 0 0-.509.51v24.664c0 .281.228.51.509.51h5.907a.51.51 0 0 0 .509-.51V11.873a.51.51 0 0 0-.509-.51h-5.907ZM111.387 2.418c-1.529 1.562-2.263 3.505-2.263 5.884v2.47a.512.512 0 0 1-.512.512h-1.424a.508.508 0 0 0-.509.51v4.594c0 .281.227.51.508.51h1.425c.283 0 .512.23.512.512v19.126a.51.51 0 0 0 .516.51l5.809-.084a.51.51 0 0 0 .502-.51V17.415c0-.281.227-.51.508-.51h3.483a.51.51 0 0 0 .509-.509V11.8a.509.509 0 0 0-.509-.51h-3.475a.51.51 0 0 1-.51-.515l.029-2.672s-.055-1.226 1.036-1.871c.734-.434 3.134-.458 4.197-.447a.507.507 0 0 0 .513-.508V.596a.506.506 0 0 0-.459-.508A21.86 21.86 0 0 0 119.295 0c-2.376 0-5.914.382-7.908 2.418ZM7.96 13.097l-.011.01a.62.62 0 0 1-1.025-.468v-.767a.509.509 0 0 0-.508-.51H.509a.51.51 0 0 0-.509.51v24.664c0 .281.228.51.509.51h5.907c.28 0 .508-.229.508-.51V24.611c0-2.104.168-4.656 1.81-5.899 1.467-1.111 4.235-1.016 5.41-.917a.507.507 0 0 0 .548-.507v-5.456a.507.507 0 0 0-.478-.508c-.16-.009-.357-.015-.583-.015-1.383 0-3.841.246-5.67 1.788Z"
      fill="#212F49"
    />
    <mask
      id="a"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={11}
      width={15}
      height={27}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m7.96 13.097-.011.01a.62.62 0 0 1-1.025-.468v-.767a.509.509 0 0 0-.508-.51H.509a.51.51 0 0 0-.509.51v24.664c0 .281.228.51.509.51h5.907c.28 0 .508-.229.508-.51V24.611c0-2.104.168-4.656 1.81-5.899 1.467-1.111 4.235-1.016 5.41-.917a.507.507 0 0 0 .548-.507v-5.456a.507.507 0 0 0-.478-.508c-.16-.009-.357-.015-.583-.015-1.383 0-3.841.246-5.67 1.788Z"
        fill="#fff"
      />
    </mask>
  </svg>
);

export default SvgComponent;
