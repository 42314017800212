import React, {useState} from 'react';

import PropTypes from 'prop-types';

import {
  Alert,
  Box,
  Button,
  Card,
  Flex,
  MoneyField,
  SelectField,
  Text,
  Textarea,
  TextField,
  RadioGroup,
  RatioField,
} from '@renofi/components-internal';
import ThumbsUp from '@renofi/icons/src/ThumbsUp';

import {Help} from '../styled';

import DateField from './DateField';

const Approved = ({
  activeStatus,
  persistable,
  disabled,
  loading,
  status,
  appliedOn,
  approvedOn,
  closedOn,
  conditions,
  estimatedClosingOn,
  finalCreditScore,
  finalDtiRatio,
  finalLoanAmount,
  finalLoanRate,
  lenderProvidedIncome,
  lenderProvidedMonthlyDebt,
  lenderVerifiedReserves,
  loanNumber,
  loanProduct,
  loanProducts,
  onCancelChange,
  onChangeFieldValue,
  onChangeLoanProduct,
  onSaveLoanApplication,
}) => {
  const getChangeHandler = (field) => (value) => {
    return onChangeFieldValue(field, value);
  };
  const [showConditions, setShowConditions] = useState(
    conditions ? 'enabled' : 'disabled',
  );

  return (
    <Card
      title={
        <Flex width={1} alignItems="center">
          <ThumbsUp />
          <Text ml={16}>Approval details</Text>
        </Flex>
      }
      mt={24}
      p={0}>
      {persistable && (
        <Alert variant="info">
          <Flex width={1} alignItems="center" justifyContent="space-between">
            <div>
              {status === 'approved' &&
                `Enter as many details below before confirming the status change.`}
              {status === 'closed' &&
                `Enter the Actual closing date and any outstanding details before confirming the Closed status change.`}
            </div>
            <Box>
              <Button variant="link" onClick={() => onCancelChange()}>
                Cancel
              </Button>
              <Button
                loading={loading}
                disabled={disabled}
                onClick={onSaveLoanApplication}>
                {activeStatus !== status
                  ? 'Confirm status change'
                  : 'Update details'}
              </Button>
            </Box>
          </Flex>
        </Alert>
      )}

      {status === 'closed' && (
        <Flex mt={24} px={16}>
          <Help css={{fontStyle: 'italic'}}>All fields are required</Help>
        </Flex>
      )}

      <Flex width={1} mt={24}>
        <Box width={1 / 3} px={16}>
          <DateField
            label="Application date"
            value={appliedOn}
            onChange={getChangeHandler('appliedOn')}
          />
        </Box>
        <Box width={1 / 3} px={16}>
          <DateField
            label="Approval date"
            value={approvedOn}
            onChange={getChangeHandler('approvedOn')}
          />
        </Box>
        <Box width={1 / 3} px={16}>
          <DateField
            value={estimatedClosingOn}
            label="Estimated closing date"
            disabled={status === 'closed'}
            help={status === 'approved' && <Help>Required</Help>}
            onChange={getChangeHandler('estimatedClosingOn')}
          />
        </Box>
      </Flex>

      <Flex width={1}>
        <Box width={1 / 3} px={16}>
          <SelectField
            mb={0}
            label="Loan product"
            placeholder="Select a loan product"
            value={loanProduct?.id}
            options={loanProducts.map(({id, name}) => ({
              label: name,
              value: id,
            }))}
            onChange={onChangeLoanProduct}
          />
        </Box>
        <Box width={1 / 3} px={16}>
          <TextField
            value={loanNumber}
            label="Loan number"
            help={status === 'approved' && <Help>Required</Help>}
            onChange={getChangeHandler('loanNumber')}
          />
        </Box>
        <Box width={1 / 3} px={16}>
          <DateField
            disabled={status === 'approved'}
            value={closedOn}
            label="Actual closing date"
            onChange={getChangeHandler('closedOn')}
          />
        </Box>
      </Flex>

      <Flex width={1} px={16} mt="8px" mb={16}>
        <Text
          css={{
            color: '#737373',
            fontSize: 18,
            lineHeight: '24px',
          }}>
          Borrower underwriting details
        </Text>
      </Flex>

      <Flex width={1}>
        <Box width={1 / 3} px={16}>
          <MoneyField
            fractional={false}
            value={finalLoanAmount}
            label="Final loan amount"
            onChange={getChangeHandler('finalLoanAmount')}
          />
        </Box>
        <Box width={1 / 3} px={16}>
          <TextField
            value={finalCreditScore}
            label="Final FICO"
            onChange={getChangeHandler('finalCreditScore')}
          />
        </Box>
        <Box width={1 / 3} px={16}>
          <RatioField
            value={finalDtiRatio}
            label="Final DTI"
            onChange={getChangeHandler('finalDtiRatio')}
          />
        </Box>
      </Flex>

      <Flex width={1}>
        <Box width={1 / 3} px={16}>
          <TextField
            numeric
            value={finalLoanRate}
            leftIcon="percent"
            label="Final loan rate"
            onChange={getChangeHandler('finalLoanRate')}
          />
        </Box>
        <Box width={1 / 3} px={16}>
          <MoneyField
            fractional={false}
            value={lenderProvidedIncome}
            label="Lender calculated monthly income"
            onChange={getChangeHandler('lenderProvidedIncome')}
          />
        </Box>
        <Box width={1 / 3} px={16}>
          <MoneyField
            fractional={false}
            value={lenderProvidedMonthlyDebt}
            label="Lender calculated monthly debt"
            onChange={getChangeHandler('lenderProvidedMonthlyDebt')}
          />
        </Box>
      </Flex>

      <Flex width={1}>
        <Box width={1 / 3} px={16}>
          <MoneyField
            fractional={false}
            value={lenderVerifiedReserves}
            label="Lender verified reserves"
            onChange={getChangeHandler('lenderVerifiedReserves')}
          />
        </Box>
        <Box width={1 / 3} px={16}>
          <RadioGroup
            label="Conditions"
            onChange={setShowConditions}
            options={[
              {label: 'No', value: 'disabled'},
              {label: 'Yes, provide details', value: 'enabled'},
            ]}
            value={showConditions}
          />
        </Box>
        <Box width={1 / 3} px={16}>
          {showConditions === 'enabled' && (
            <Textarea
              value={conditions}
              rows={4}
              label="Details of conditions"
              onChange={getChangeHandler('conditions')}
            />
          )}
        </Box>
      </Flex>
    </Card>
  );
};

Approved.propTypes = {
  persistable: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  appliedOn: PropTypes.string,
  approvedOn: PropTypes.string,
  closedOn: PropTypes.string,
  conditions: PropTypes.string,
  estimatedClosingOn: PropTypes.string,
  finalCreditScore: PropTypes.string,
  finalDtiRatio: PropTypes.string,
  finalLoanAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  finalLoanRate: PropTypes.string,
  lenderProvidedIncome: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  lenderProvidedMonthlyDebt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  lenderVerifiedReserves: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  loanNumber: PropTypes.string,
  loanProduct: PropTypes.object,
  loanProducts: PropTypes.arrayOf(PropTypes.object).isRequired,
  status: PropTypes.string,
  activeStatus: PropTypes.string,
  onCancelChange: PropTypes.func.isRequired,
  onChangeFieldValue: PropTypes.func.isRequired,
  onChangeLoanProduct: PropTypes.func.isRequired,
  onSaveLoanApplication: PropTypes.func.isRequired,
};

export default Approved;
