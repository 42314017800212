import {gql} from '@apollo/client';

export default gql`
  query projectById($id: ID!) {
    projectById(id: $id) {
      id
      tasks {
        id
        additionalExplanation
        additionalRequestMessage
        allDocumentsAccepted
        anyDocumentRejected
        allDocumentsReviewed
        contractor {
          id
          email
          contactName
          contactTitle
          businessName
          phoneNumber
          city
          state
          streetAddress
          secondaryAddress
          zipCode
          website
        }
        dataSubmittedAt
        details
        documents {
          id
          fileName
          objectName
          pdfObjectName
          rejectionDetails
          rejectionReason
          status
          checksum
        }
        informationKind
        requirements
        status
        title
      }
    }
  }
`;
