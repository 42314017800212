import * as React from 'react';

import PropTypes from 'prop-types';

const SvgBasement = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M12.495 1.092a.25.25 0 0 0-.102.047L2.353 8.472a.254.254 0 0 0 .149.459h2.259v.53a.253.253 0 0 0 .124.221.25.25 0 0 0 .253 0 .253.253 0 0 0 .125-.222v-.782a.252.252 0 0 0-.251-.253H3.27l9.27-6.772 9.272 6.772H20.07a.252.252 0 0 0-.25.253v7.333h-2.26V9.69a.252.252 0 0 0-.25-.253h-3.514a.25.25 0 0 0-.047 0 .252.252 0 0 0-.204.253v6.322H5.263v-4.915a.253.253 0 0 0-.125-.222.25.25 0 0 0-.253 0 .253.253 0 0 0-.124.222v11.49c0 .14.112.253.25.253h15.06a.252.252 0 0 0 .251-.253V8.93h2.26a.254.254 0 0 0 .149-.458L12.69 1.138a.25.25 0 0 0-.196-.047zm-4.77 8.344a.253.253 0 0 0-.203.253v3.288c0 .14.112.253.25.253h3.515a.252.252 0 0 0 .25-.253V9.689a.252.252 0 0 0-.25-.253H7.773a.25.25 0 0 0-.024 0 .25.25 0 0 0-.023 0zm.299.506h3.012v2.782H8.024V9.942zm6.024 0h3.012v6.07h-3.012v-6.07zm-8.785 6.575h1.506v1.517c0 .14.112.253.25.253h2.009v1.264c0 .14.112.253.25.253h2.009v1.012c0 .14.112.253.25.253h2.009v1.264H5.263v-5.816zm2.008 0h12.55v5.816h-5.773v-1.517a.252.252 0 0 0-.251-.253h-2.008V19.55a.252.252 0 0 0-.251-.253H9.53v-1.264a.252.252 0 0 0-.251-.253H7.27v-1.264z"
        fill={color}
        stroke={color}
        strokeWidth={0.55}
      />
    </svg>
  );
};

SvgBasement.propTypes = {
  color: PropTypes.string,
};
export default SvgBasement;
