import {isNil, reject} from 'ramda';

export default (userObj, fallback = '') => {
  const {firstName, lastName, middleName, nameSuffix} = userObj || {};
  if (!firstName && !lastName) {
    return fallback;
  }
  return reject(isNil, [firstName, middleName, lastName, nameSuffix])
    .join(' ')
    .trim();
};
