import {makeVar, useReactiveVar} from '@apollo/client';

const hoverCancelVar = makeVar(false);

const useHoverCancel = () => {
  const hoverCancel = useReactiveVar(hoverCancelVar);

  const setHoverCancel = (value) => {
    hoverCancelVar(value);
  };

  return {hoverCancel, setHoverCancel};
};

export default useHoverCancel;
