import React from 'react';

import PropTypes from 'prop-types';

import {getFileType} from '@renofi/utilities';
import {
  Bmp,
  Csv,
  Doc,
  Gif,
  Jpg,
  Pdf,
  Png,
  Ppt,
  Rar,
  Txt,
  Xls,
  Zip,
} from '@renofi/icons/src';

const typesMap = {
  bmp: Bmp,
  csv: Csv,
  doc: Doc,
  gif: Gif,
  jpeg: Jpg,
  jpg: Jpg,
  pdf: Pdf,
  png: Png,
  ppt: Ppt,
  rar: Rar,
  txt: Txt,
  xls: Xls,
  zip: Zip,
};

const FileType = ({fileName, ...props}) => {
  const {extension: type} = getFileType(fileName);
  const Icon = typesMap[type];
  return Icon ? <Icon {...props} /> : null;
};

FileType.propTypes = {
  fileName: PropTypes.string.isRequired,
};

export default FileType;
