import {compose, withHandlers} from '@renofi/recompose/src';
import {RENOFI_GRAPHQL_ERROR} from '@renofi/utilities/src/analytics';
import logoutWithRedirect from '@renofi/utilities/src/logoutWithRedirect';
import {withStorage} from '@renofi/utilities/src/storage';

export default compose(
  withStorage,
  withHandlers({
    onGraphqlError:
      ({storage}) =>
      (error) => {
        if ([401].includes(error?.networkError?.statusCode)) {
          storage.clear();
          return logoutWithRedirect();
        }
        throw new Error(RENOFI_GRAPHQL_ERROR, {
          cause: error,
        });
      },
  }),
);
