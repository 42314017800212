import {ApolloClient, InMemoryCache} from '@apollo/client';

import {Storage, memoryEngine} from '@renofi/utilities/src/storage';

import writeInitialData from '../initialData';

import createLink from './createLink';

const getContext = ({storage, ...rest} = {}) =>
  storage ? {storage, ...rest} : {storage: new Storage(memoryEngine), ...rest};

export default async (graphqlUrl, context, clientCache) => {
  const cache = new InMemoryCache(clientCache);
  const link = createLink({context: getContext(context), uri: graphqlUrl});

  writeInitialData(cache);

  return new ApolloClient({
    cache,
    link,
    name: process.env.REACT_APP_SERVICE_NAME,
    version: process.env.REACT_APP_COMMIT_REF,
    connectToDevTools: process.env.NODE_ENV === 'development',
    resolvers: {
      Query: () => ({
        application: () => ({}),
        renovationUpdate: () => ({}),
        system: () => ({}),
      }),
    },
  });
};
