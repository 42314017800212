import {gql} from '@apollo/client';

export default gql`
  mutation markGcddReviewAsCompleted($id: ID!) {
    markGcddReviewAsCompleted(id: $id) {
      gcddReview {
        id
      }
    }
  }
`;
