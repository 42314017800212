import React from 'react';

import PropTypes from 'prop-types';

import File from '@renofi/icons/src/File';

import Link from './Link';

const Document = ({children, ...props}) => (
  <Link css={{display: 'inline-flex', alignItems: 'center'}} {...props}>
    <File css={{marginRight: 12}} />
    {children}
  </Link>
);

Document.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Document;
