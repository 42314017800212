import React from 'react';

import PropTypes from 'prop-types';

import Context from './context';

const Provider = ({storage, ...props}) => (
  <Context.Provider {...props} value={storage} />
);

Provider.propTypes = {
  storage: PropTypes.object.isRequired,
};

export default Provider;
