import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {Switch as RebassSwitch} from '@rebass/forms';

import {useTheme} from '@renofi/theme/src';
import noop from '@renofi/utilities/src/noop';

const Switch = ({checked, onClick = noop, ...props}) => {
  const [isChecked, setIsChecked] = useState(checked);
  const {styles} = useTheme('toggle');

  useEffect(() => setIsChecked(checked), [checked]);

  const onSwitch = (value) => {
    const newChecked = !isChecked;
    setIsChecked(newChecked);
    onClick(value, newChecked);
  };

  return (
    <RebassSwitch
      checked={isChecked}
      onClick={onSwitch}
      sx={styles}
      {...props}
    />
  );
};

Switch.propTypes = {
  checked: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Switch;
