import {getDomain, getDocument} from '../window';

export function setCookie(key, value, renofiShared = true, expireValue) {
  const suffix = `;path=/;domain=.${getDomain()}`;
  getDocument().cookie = `${key}=${value}${
    renofiShared ? suffix : ';path=/'
  }; expires=${expireValue ? handleExpire(expireValue) : ''};`;
}

export function getCookie(key) {
  const value = document.cookie.match(`(^|;) ?${key}=([^;]*)(;|$)`);
  return value ? value[2] : null;
}

export function deleteCookie(key) {
  setCookie(key, '');
}

export function deletePublicCookie(key) {
  setCookie(key, '', false);
}

export function deleteAllCookies() {
  document.cookie.split(';').forEach((c) => {
    document.cookie = c
      .replace(/^ +/, '')
      .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
  });
}

function handleExpire(expireValue) {
  //expire value should be in minutes
  const date = new Date();
  date.setTime(date.getTime() + expireValue * 60 * 1000);
  return date.toGMTString();
}
