import {gql} from '@apollo/client';

export default gql`
  query contractorById($id: ID!) {
    contractorById(id: $id) {
      businessName
      city
      id
      insurances {
        id
        source
      }
      licenses {
        id
        source
      }
      secondaryAddress
      state
      streetAddress
      website
      zipCode
    }
  }
`;
