import {gql} from '@apollo/client';

export default gql`
  query sentEmails(
    $filterBy: SentEmailsFilterInputObject!
    $limit: Int
    $page: Int
    $sortBy: SentEmailsSortInputObject
  ) {
    sentEmails(
      filterBy: $filterBy
      limit: $limit
      page: $page
      sortBy: $sortBy
    ) {
      collection {
        body
        campaign
        createdAt
        email
        from
        id
        subject
        to
        updatedAt
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;
