import {gql} from '@apollo/client';

export default gql`
  mutation submitQuestionnaireScore(
    $questionnaireId: ID!
    $score: ReviewResultEnum!
  ) {
    submitQuestionnaireScore(questionnaireId: $questionnaireId, score: $score) {
      questionnaire {
        id
        score
      }
    }
  }
`;
