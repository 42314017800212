import React, {forwardRef, memo, useContext} from 'react';

import PropTypes from 'prop-types';
import {always, omit} from 'ramda';

import Text from '@renofi/components-internal/src/Text';
import {dangerRegular, basic80} from '@renofi/theme/src/colors';
import {Context as ConfigContext} from '@renofi/utilities/src/config';

import {Circle, Container, Icon, SvgBox, Track, Wrapper} from './styled';

const DEFAULT_VALUE = 50;
const DEFAULT_LABEL_TEXT = 'Loading';
const OMITED_PROPS = [
  'loading',
  'onTaskComplete',
  'onAcceptFiles',
  'onRejectFiles',
  'onGcddReviewUpdate',
  'onCloseViewer',
  'onLenderSelected',
  'onSortComplete',
  'onViewDocument',
  'onLoop',
];

const Loader = forwardRef(
  (
    {innerColor, label, labelText, outerColor, width, queryName, ...props},
    ref,
  ) => {
    const config = useContext(ConfigContext);
    const halfWidth = width * 0.5;
    const viewBox = `${halfWidth} ${halfWidth} ${width} ${width}`;
    const rValue = width * 0.44;
    const strokeWidth = (width / DEFAULT_VALUE) * 5;
    const wrapperProps = omit(OMITED_PROPS, props);
    const isDevelopment = config?.DEVELOPMENT;

    return (
      <Wrapper {...wrapperProps} ref={ref}>
        <Container>
          <Icon width={width} height={width}>
            <SvgBox viewBox={viewBox}>
              <Track
                cx={width}
                cy={width}
                r={rValue}
                fill="none"
                stroke={outerColor}
                strokeWidth={strokeWidth}
              />
              <Circle
                cx={width}
                cy={width}
                r={rValue}
                fill="none"
                stroke={innerColor}
                strokeWidth={strokeWidth}
              />
            </SvgBox>
          </Icon>
          {label && (
            <Text color="#888A8E" fontSize={13} mt={20}>
              {isDevelopment && queryName
                ? `${labelText}: ${queryName}`
                : labelText}
            </Text>
          )}
        </Container>
      </Wrapper>
    );
  },
);

Loader.propTypes = {
  innerColor: PropTypes.string,
  label: PropTypes.bool,
  labelText: PropTypes.string,
  outerColor: PropTypes.string,
  queryName: PropTypes.string,
  width: PropTypes.number,
};

Loader.defaultProps = {
  innerColor: dangerRegular,
  label: true,
  labelText: DEFAULT_LABEL_TEXT,
  outerColor: basic80,
  width: DEFAULT_VALUE,
};

export default memo(Loader, always(true));
