import {gql} from '@apollo/client';

export default gql`
  mutation submitExceptionRequest(
    $projectId: ID!
    $lenderId: ID!
    $renovationScope: String!
    $title: String!
    $compensatingFactors: String!
    $renofiNotes: String
    $metrics: [ExceptionRequestMetricInputObject!]!
    $comments: [ExceptionRequestCommentInputObject!]
  ) {
    submitExceptionRequest(
      projectId: $projectId
      lenderId: $lenderId
      renovationScope: $renovationScope
      title: $title
      compensatingFactors: $compensatingFactors
      renofiNotes: $renofiNotes
      metrics: $metrics
      comments: $comments
    ) {
      exceptionRequest {
        id
        approvedAt
        approvedById
        approvedBy {
          id
          email
          firstName
          lastName
        }
        comments {
          id
          addedById
          addedBy {
            id
            email
            firstName
            lastName
          }
          createdAt
          message
          subject
          updatedAt
        }
        compensatingFactors
        createdAt
        deniedAt
        deniedById
        deniedBy {
          id
          email
          firstName
          lastName
        }
        lenderId
        lender {
          id
          name
        }
        metrics {
          id
          createdAt
          exceptional
          name
          notes
          updatedAt
          value
        }
        renofiNotes
        renovationScope
        status
        title
        updatedAt
      }
    }
  }
`;
