import React from 'react';

import {ENVIRONMENT} from 'config';
import withLender from 'lib/withLender';

import {
  branch,
  compose,
  renderComponent,
  lifecycle,
} from '@renofi/recompose/src';
import LogRocket from '@renofi/utilities/src/logrocket2';
import {withStorage} from '@renofi/utilities/src/storage';

import Component from './Component';
import {Forbidden} from './styled';

export default compose(
  withStorage,
  withLender,
  branch(
    ({errors, user}) =>
      !user &&
      errors &&
      Boolean(errors.length) &&
      ['FORBIDDEN'].includes(errors[0]?.message),
    renderComponent(() => <Forbidden>FORBIDDEN</Forbidden>),
  ),
  lifecycle({
    componentDidMount() {
      if (ENVIRONMENT === 'production') {
        const {id, email, firstName, lastName} = this.props.user;
        LogRocket.identify(id, {
          email,
          name: `${firstName} ${lastName}`,
        });
      }
    },
  }),
)(Component);
