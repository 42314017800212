import {gql} from '@apollo/client';

import {borrowersAndPropertyInformation} from '../../partials';

export default gql`
  mutation submitBorrowersAndPropertyInformation(
    $attributes: BorrowersAndPropertyInformationInputObject!
    $id: ID!
  ) {
    submitBorrowersAndPropertyInformation(
      attributes: $attributes
      id: $id
    ) {
      borrowersAndPropertyInformation {
        ${borrowersAndPropertyInformation}
      }
    }
  }
`;
