import {gql} from '@apollo/client';

import {documentWithAllProps} from '../../partials';

export default gql`
  mutation acceptAdditionalDocument($id: ID!) {
    acceptAdditionalDocument(id: $id) {
      document {
        ${documentWithAllProps}
      }
    }
  }
`;
