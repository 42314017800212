import styled from '@emotion/styled';

import {maxSmall} from '@renofi/theme/src/breakpoints';

export const Menu = styled.div({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px',
  marginTop: '32px',
  [maxSmall]: {
    overflow: 'auto',
    marginTop: 0,
    rowGap: 0,
  },
});

export const MenuItem = styled.div(
  {
    display: 'flex',
    alignItems: 'center',
    padding: '18px 16px',
    color: '#212f498c',
    fontSize: '18px',
    justifyContent: 'space-between',
    transition: 'all 150ms linear',

    svg: {
      color: '#d3d5db',
      transition: 'color 150ms linear',
    },
    '&:hover': {
      cursor: 'pointer',
      background: '#F5F6F7',
      borderRadius: '8px',
      color: '#212f49',
      transition: 'all 150ms linear',

      svg: {
        '.first': {
          transition: 'color 150ms linear',
          color: '#212F49',
        },
        '.second': {
          transition: 'color 150ms linear',
          color: '#FF5253',
        },
      },
    },
    [maxSmall]: {
      borderBottom: '1px solid rgba(0, 96, 153, 0.15)',
      color: '#212F49',
      fontSize: '24px',
      padding: '18px 20px',
      ':first-of-type': {
        borderTop: '1px solid rgba(0, 96, 153, 0.15)',
      },
      svg: {
        '.first': {
          color: '#212F49',
        },
        '.second': {
          color: '#FF5253',
        },
      },
      '&:hover': {
        borderRadius: 'unset',
      },
    },
  },
  ({selectedOption}) =>
    selectedOption
      ? {
          cursor: 'pointer',
          background: '#eff9ff',
          borderRadius: '8px',
          color: '#212f49',
          svg: {
            '.first': {
              color: '#212F49',
            },
            '.second': {
              color: '#FF5253',
            },
          },
        }
      : null,
);

export const StyledLabel = styled.div({
  display: 'flex',
  alignItems: 'center',
  columnGap: '14px',
});

export const ItemIcon = styled.span({
  display: 'flex',
  minWidth: '30px',
});

export const StyledBadge = styled.span({
  fontSize: '14px',
  borderRadius: '12px',
  color: '#fff',
  fontWeight: 700,
  background: '#FF5253',
  width: '28px',
  height: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
