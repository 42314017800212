import {propOr} from 'ramda';

import useToggledQuery from '../useToggledQuery';
import {GET_SIGNED_URLS} from '../../queries';

export default function useSignedUrls({
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_SIGNED_URLS,
    options: {
      fetchPolicy,
      ...options,
    },
  });

  const {data, error, loading, fetch, refetch} = response;
  const signedUrls = propOr([], 'signedUrls', data);

  return {error, fetch, refetch, signedUrls, loading};
}
