import * as React from 'react';

import PropTypes from 'prop-types';

const SvgLender = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M4 11v6h3v-6H4zm6.523 0v6h3v-6h-3zM2 22h20v-3H2v3zm15-11v6h3v-6h-3zm-5-9L2 7v2h20V7L12 2z"
          fill={color}
        />
      </g>
    </svg>
  );
};

SvgLender.propTypes = {
  color: PropTypes.string,
};
export default SvgLender;
