import styled from '@emotion/styled';

import {maxSmall, minSmall} from '@renofi/theme/src/breakpoints';

import Card from '../next/Card';
import Flex from '../Flex';
import Box from '../Box';

export const StyledCard = styled(Card)(
  {
    padding: 24,
    [maxSmall]: {
      padding: 16,
    },
  },
  ({statusCard, completed}) => ({
    '&:hover': {
      boxShadow: !statusCard ? '0px 4px 20px 0px #00000026' : 'unset',
      transition: 'all 200ms ease',
    },
    opacity: completed ? 0.8 : 1,
  }),
);

export const ButtonWraper = styled(Box)({
  [maxSmall]: {
    marginTop: 16,
    order: 99,
  },
});

export const Content = styled(Flex)({
  flexDirection: 'row',
  flexWrap: 'wrap',

  [maxSmall]: {
    flexDirection: 'column',
    flexWrap: 'unset',
  },
  [minSmall]: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export const Break = styled.div({
  flexBasis: '100%',
  height: 0,
  [maxSmall]: {
    display: 'none',
    visibility: 'hidden',
  },
});
