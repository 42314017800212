export const ASSET_TYPES = {
  BUSINESS: 'business',
  CHECKING: 'checking',
  MONEY_MARKET_FUND: 'money_market_fund',
  OTHER_LIQUID_ASSED: 'other_liquid_asset',
  RETIREMENT: 'retirement',
  SAVINGS: 'savings',
  STOCKS: 'stocks',
};

export const ASSET_TYPE_DOCUMENT_MAPPER = {
  business: 'business_asset_statement',
  checking: 'checking_asset_statement',
  money_market_fund: 'money_market_fund_asset_statement',
  other_liquid_asset: 'other_liquid_asset_statement',
  retirement: 'retirement_asset_statement',
  savings: 'savings_asset_statement',
  stocks: 'stocks_asset_statement',
};
