import styled from '@emotion/styled';

import Box from '../Box';
import Flex from '../Flex';
import Text from '../Text';

import arrowGrey from './arrow-grey.svg';

export const Head = styled(Flex)({
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  height: 60,
  padding: '12px 20px',
  boxShadow: '0 1px 0 0 rgba(0,0,0,0.1)',
  position: 'relative',
  flexGrow: 0,
  flexShrink: 0,
  cursor: 'pointer',
});

export const Line = styled(Text)({}, ({duration = 500, visible}) => ({
  transition: `all ${duration}ms ease`,
  opacity: visible ? 1 : 0,
}));

export const Change = styled.div(
  {
    width: 20,
    height: 20,
    backgroundImage: `url('${arrowGrey}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '16px 16px',
    backgroundPosition: 'center center',
    position: 'absolute',
    top: 22,
  },
  ({duration = 500, reverse}) => ({
    transition: `all ${duration}ms ease`,
    ...(reverse
      ? {left: 20, transform: 'rotate(180deg)', marginLeft: 0}
      : {left: '100%', marginLeft: -34}),
  }),
);

export const Content = styled(Box)(
  {
    flexGrow: 1,
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingBottom: 12,
  },
  ({top}) => ({
    height: `calc(100% - 60px - ${top}px)`,
  }),
);
