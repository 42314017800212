import {gql} from '@apollo/client';

export default gql`
  mutation shareRenovationUpdateWithLender(
    $id: ID!
    $shareWithLender: Boolean!
    $noteToLender: String
    $delaySeconds: Int
  ) {
    shareRenovationUpdateWithLender(
      id: $id
      shareWithLender: $shareWithLender
      noteToLender: $noteToLender
      delaySeconds: $delaySeconds
    ) {
      renovationUpdate {
        id
        createdAt
        date
        details
        source
        noteToLender
        photos {
          original {
            objectName
            url
          }
          thumbnail {
            objectName
            url
          }
        }
        shareWithLender
        sharedWithLenderAt
        kind
        updatedAt
        userId
        user {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`;
