//TODO: rename this in projectDocumentStatuses OR documentStatuses

export const taskDocumentStatuses = {
  accepted: 'accepted',
  hidden: 'hidden',
  inReview: 'in_review',
  processingFile: 'processing_file',
  rejected: 'rejected',

  // These two are only used by the FE to track upload progress/failure
  progress: 'progress',
  failed: 'failed',
};
