/* eslint-disable no-console */
import {useContext, useEffect} from 'react';

import {makeVar, useReactiveVar} from '@apollo/client';
import {isNil} from 'ramda';

import {Context as StorageContext} from '@renofi/utilities/src/storage';

import logger from '../logger';

export const THIRTY_MINUTES = 60000 * 30;

const ORIGIN = window.location.origin;
const IS_LOCAL = ORIGIN.includes('renofi.local');
const IS_TEST = process.env.NODE_ENV === 'test';

const hasVersionChangedVar = makeVar(null);

const fetchVersion = async () => {
  let body;
  try {
    const response = await fetch(`${ORIGIN}/version.json`, {
      method: 'GET',
      cache: 'no-cache',
    });
    body = await response.json();
  } catch (err) {
    console.group('🤔 There was a problem fetching the version');
    console.warn(err);
    console.groupEnd('🤔 There was a problem fetching the version');
  }
  return body || {};
};

const useLatestVersion = () => {
  const hasVersionChanged = useReactiveVar(hasVersionChangedVar);
  const storage = useContext(StorageContext);

  const checkForVersionChange = async () => {
    const {version: remoteVersion} = await fetchVersion();
    logger.debug('remoteVersion', remoteVersion);
    const savedVersion = storage.getItem('renofi:savedAppVersion');
    const hasNoSavedVersion = isNil(savedVersion);

    if (!remoteVersion) {
      return;
    }

    if (hasNoSavedVersion) {
      return storage.setItem('renofi:savedAppVersion', remoteVersion);
    }

    const hasVersionChanged = savedVersion !== remoteVersion;
    storage.setItem('renofi:savedAppVersion', remoteVersion);
    if (hasVersionChanged) {
      hasVersionChangedVar(true);
      console.log(`🤖 Found newer version of app (${remoteVersion})`);
    }
  };

  useEffect(() => {
    (async () => {
      if (IS_LOCAL || IS_TEST) {
        return;
      }
      checkForVersionChange();
      const intervalId = setInterval(checkForVersionChange, THIRTY_MINUTES);

      return () => {
        clearInterval(intervalId);
      };
    })();
  }, []);

  return {
    hasVersionChanged,
  };
};

export default useLatestVersion;
