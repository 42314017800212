import {gql} from '@apollo/client';

export default gql`
  query signedUrls($objectNames: [StorageObjectName!]!) {
    signedUrls(objectNames: $objectNames) {
      objectName
      url
    }
  }
`;
