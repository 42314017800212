import logger from '@renofi/utilities/src/logger';

export const ENVIRONMENT = process.env.REACT_APP_ENV || 'development';
const DEVELOPMENT = ENVIRONMENT === 'development';
export const PRODUCTION = ENVIRONMENT === 'production';
export const SERVICE_NAME = process.env.REACT_APP_SERVICE_NAME;

// temp code to compare env variables between this and monorepo
window._config = {
  NODE_ENV: process.env.NODE_ENV,
  ENVIRONMENT,
  DEVELOPMENT,
  PRODUCTION,
};

if (ENVIRONMENT === 'development') {
  logger.debug(process.env);
}
