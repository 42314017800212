import styled from '@emotion/styled';

import {Flex, Link, Dropdown} from '@renofi/components-internal';
import omitProps from '@renofi/utilities/src/omitProps';

export const Logo = styled(Flex)({
  justifyContent: 'center',
  alignItems: 'center',
  height: 70,
  width: 148,
  borderLeft: '1px solid rgba(255,255,255,0.1)',
  borderRight: '1px solid rgba(255,255,255,0.1)',
});

export const LogoLink = styled(Link)({
  textTransform: 'uppercase',
  color: 'white',
  '&:hover': {
    textDecoration: 'none',
    color: 'white',
  },
});

export const NavTab = styled(omitProps(['active'])(Link))(
  {
    display: 'flex',
    alignItems: 'center',
    padding: '0 24px',
    margin: '0 16px',
    height: 70,
    fontSize: 14,
    lineHeight: '17px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    ':hover': {
      textDecoration: 'none',
      color: '#FFFFFF',
    },
  },
  ({active}) => ({
    borderBottom: active ? 'solid 4px #FF5253' : 'solid 4px transparent',
    fontWeight: active ? 'bold' : 'normal',
  }),
);

export const Menu = styled(Dropdown)({
  zIndex: 1009,
});
