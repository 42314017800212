import {gql} from '@apollo/client';

export default gql`
  mutation requestFilesZip(
    $browserEventChannelId: String!
    $objectNames: [StorageObjectName!]!
    $outputFileName: String
  ) {
    requestFilesZip(
      browserEventChannelId: $browserEventChannelId
      objectNames: $objectNames
      outputFileName: $outputFileName
    ) {
      success
    }
  }
`;
