import {flatten, map, pathOr, pipe, prop, propOr} from 'ramda';
import LogRocket from 'logrocket';

import {
  RENOFI_GRAPHQL_ERROR,
  UNKNOWN_ERROR,
} from '@renofi/utilities/src/analytics';
import logger from '@renofi/utilities/src/logger';
import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';

import {AUTH_ERRORS} from './constants';
import parsePermissionsErrors from './parsePermissionsErrors';

const humanize = (msg = '') => humanizeSnakeCase(msg.toLowerCase());

const getMessage = propOr(UNKNOWN_ERROR, 'message');

export const getIsAuthError = (errors = []) =>
  errors.some((err) => AUTH_ERRORS.includes(getMessage(err)));

const getOperationTypes = pipe(
  pathOr([], ['query', 'definitions']),
  map(prop('operation')),
  flatten,
);

const parseGraphQLErrors = ({errors = [], operation}) => {
  const operationName = prop('operationName', operation);
  const variables = propOr({}, 'variables', operation);
  const operationType = getOperationTypes(operation);
  const gqlError = new Error(`${RENOFI_GRAPHQL_ERROR}:${operationName}`);
  const isAuthError = getIsAuthError(errors);

  parsePermissionsErrors({errors, operationName});

  if (isAuthError) {
    return;
  }

  const extra = errors.reduce(
    (obj, error) => {
      const extensions = propOr({}, 'extensions', error);
      const message = humanize(getMessage(error));
      return {
        ...obj,
        data: (obj?.data || []).concat({message, extensions}),
      };
    },
    {operationName, operationType, variables},
  );

  // Only surface for mutations; ignore queries...
  if (operationType.includes('mutation')) {
    global.dispatchEvent(
      new CustomEvent(RENOFI_GRAPHQL_ERROR, {
        detail: {
          error: gqlError,
          ...extra,
        },
      }),
    );
  }

  logger.error(gqlError);
  LogRocket.captureException(gqlError, {extra});
};

export default parseGraphQLErrors;
