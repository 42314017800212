import {createElement} from 'react';

import {identity} from 'ramda';

export default (test, left, right = identity) =>
  (BaseComponent) => {
    let leftBranch;
    let rightBranch;
    return (props) => {
      if (test(props)) {
        leftBranch = leftBranch || left(BaseComponent);
        return createElement(leftBranch, props);
      }
      rightBranch = rightBranch || right(BaseComponent);
      return createElement(rightBranch, props);
    };
  };
