import {gql} from '@apollo/client';

export default gql`
  mutation scheduleInsuranceCoverageProofRequest(
    $id: ID!
    $schedule: Boolean!
  ) {
    scheduleInsuranceCoverageProofRequest(id: $id, schedule: $schedule) {
      insuranceReview {
        id
        carrier
        completedAt
        completedById
        completedBy {
          id
          firstName
          lastName
        }
        coverageDuringRenovationPeriod
        coverageProofReceivedAt
        coverageProofRequestedAt
        coverageProofRequestScheduledAt
        createdAt
        dwellingCoverage
        expansiveRenovation
        largeAmountRenovation
        policyDateVisible
        policyEffectiveDate
        renovationIncludesRoofWorks
        renovationIncludesStructuralChanges
        updatedAt
      }
    }
  }
`;
