import {useEffect} from 'react';

import {isNil, pathOr} from 'ramda';

import {useLogout} from '@renofi/utilities';
import {
  JWT_KEY,
  REFRESH_TOKEN_KEY,
  setCookie,
} from '@renofi/utilities/src/cookies';
import getRandomInt from '@renofi/utilities/src/getRandomInt';
import jwtDecode from '@renofi/utilities/src/jwtDecode';
import logger from '@renofi/utilities/src/logger';
import {isDeployPreviewUrl} from '@renofi/utilities/src/netlify';
import {
  AUTH_TOKEN,
  REFRESH_TOKEN,
  getImpersonationStorage,
} from '@renofi/utilities/src/storage';
import {
  useGenerateAuthToken,
  useGetUser,
} from '@renofi/graphql/src/hooks/chainlink';

import getCurrentAuthTokens from '../utils/getCurrentAuthTokens';

const ONE_SECOND_MS = 1000;
const ONE_MINUTE = ONE_SECOND_MS * 60;

const debugRefreshAt = (interval) =>
  logger.debug('🔑 refresh token at', new Date(interval + Date.now()));

const getRefreshInterval = (jwt) => {
  const wobble = ONE_SECOND_MS * getRandomInt(10, false);
  const refreshAt = (jwt?.exp || 0) * ONE_SECOND_MS - ONE_MINUTE;
  return Math.max(0, refreshAt - Date.now()) + wobble;
};

const isJwtStale = () => {
  const {decodedJwt: jwt} = getCurrentAuthTokens();
  const refreshInterval = getRefreshInterval(jwt);
  return refreshInterval <= ONE_MINUTE;
};

const isLockAPIAvailable = navigator?.locks?.request;
let timeoutId;
let isImpersonation;

export default function useAuthentication({config}) {
  const isLoginApp = config.SERVICE_NAME === 'login-frontend';
  const isSharedCookie = !isDeployPreviewUrl();
  const storage = getImpersonationStorage();
  const {logout} = useLogout();

  const {generateAuthToken} = useGenerateAuthToken();
  const {getUser, loading, user} = useGetUser({
    lazy: true,
  });

  const refreshAuthToken = async () => {
    if (!isJwtStale() && isLockAPIAvailable) {
      const {decodedJwt: jwt} = getCurrentAuthTokens();
      const refreshInterval = getRefreshInterval(jwt);
      timeoutId = setTimeout(initRefresh, refreshInterval);
      return;
    }

    if (!isJwtStale()) {
      return;
    }

    const {refreshToken} = getCurrentAuthTokens();
    let rsp;
    try {
      rsp = await generateAuthToken({variables: {refreshToken}});
      if (Boolean(rsp?.errors)) {
        return logout({sessionExpired: true});
      }
    } catch (err) {
      return logout({sessionExpired: true});
    }

    const data = pathOr(null, ['data', 'generateAuthToken'], rsp);
    const newJwt = data?.jwt;
    const newRefreshToken = data?.refreshToken;
    const decodedJwt = jwtDecode(newJwt);

    const refreshInterval = getRefreshInterval(decodedJwt);
    isImpersonation = Boolean(decodedJwt?.original_user_id);
    debugRefreshAt(refreshInterval);

    // In theory the refresh token could change,
    // so use timeout, not interval to account for this
    timeoutId = setTimeout(initRefresh, refreshInterval);

    if (isImpersonation) {
      storage.setItem(AUTH_TOKEN, newJwt);
      storage.setItem(REFRESH_TOKEN, newRefreshToken);
    } else {
      setCookie(REFRESH_TOKEN_KEY, newRefreshToken, isSharedCookie);
      setCookie(JWT_KEY, newJwt, isSharedCookie);
    }
  };

  const initRefresh = () => {
    if (isLockAPIAvailable && !isImpersonation) {
      const serviceName = process.env.REACT_APP_SERVICE_NAME;
      navigator?.locks?.request(`${serviceName}-refresh-token`, async () => {
        // refresh is locked across all browser tabs
        await refreshAuthToken();
        // lock released with new token being saved in cookie
      });
    } else {
      refreshAuthToken();
    }
  };

  useEffect(() => {
    (async () => {
      if (isLoginApp) {
        return;
      }

      const {decodedJwt: jwt, refreshToken} = getCurrentAuthTokens();
      const refreshInterval = getRefreshInterval(jwt);
      debugRefreshAt(refreshInterval);

      if (isNil(refreshToken || jwt)) {
        return;
      }

      if (isJwtStale()) {
        await initRefresh();
      } else {
        timeoutId = setTimeout(initRefresh, refreshInterval);
      }
      getUser();

      return () => clearTimeout(timeoutId);
    })();
  }, []);

  return {
    loading,
    user,
  };
}
