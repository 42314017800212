import styled from '@emotion/styled';

import {actionRegular} from '@renofi/theme';

import Link from '../Link';

export const FakeTabIndex = styled.input({
  position: 'fixed',
  top: -99999,
  left: -99999,
});

export const SelectLinkItem = styled(Link)({
  fontSize: 14,
  lineHeight: '24px',
  color: actionRegular,
  ':hover': {
    textDecoration: 'none',
  },
});
