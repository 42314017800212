import styled from '@emotion/styled';
import {keyframes} from '@emotion/react';
import {Flex, Text} from 'rebass';

import {basic15} from '@renofi/theme';

const slide = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`;

export const CardWrapper = styled(Flex)({
  backgroundColor: '#fff',
  flexDirection: 'column',
  border: `1px solid ${basic15}`,
  borderRadius: 8,
  alignItems: 'center',
  maxWidth: 252,
  margin: '24px auto 24px auto',
  padding: '24px 0',
  animation: `${slide} 1s`,
  zIndex: 1000,
});

export const CardWrapperMobile = styled(CardWrapper)({
  borderRadius: '8px 8px 0 0',
  alignItems: 'center',
  maxWidth: '100%',
  padding: '17px 0 24px 0',
  zIndex: 1000,
  margin: '0 auto 0 auto',
  position: 'fixed',
  bottom: 0,
});

export const CardTitle = styled(Text)({
  textTransform: 'uppercase',
  fontSize: 12,
  color: '#9097a4',
  marginTop: 9,
});

export const InnerPaddedWrapper = styled(Flex)({
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 24px',
  textAlign: 'center',
});
