import appraisalProduct from './appraisalProduct';

export default `
apiEnabled
appraisalProducts {
  ${appraisalProduct}
}
code
enabledAppraisalProducts {
  ${appraisalProduct}
}
features
formTypes
hasApi
name
`;
