import {gql} from '@apollo/client';

export default gql`
  mutation createFileLinks(
    $email: String!
    $projectId: ID!
    $stripeFileIds: [ID!]!
    $stripeVerificationReportId: String
    $taskId: ID!
  ) {
    createFileLinks(
      email: $email
      projectId: $projectId
      stripeFileIds: $stripeFileIds
      stripeVerificationReportId: $stripeVerificationReportId
      taskId: $taskId
    ) {
      fileLinks {
        id
        stripeFileId
        url
      }
    }
  }
`;
