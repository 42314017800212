import * as React from 'react';

import PropTypes from 'prop-types';

const SvgAlert = ({color = '#FF5253', ...props}) => {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M14 .667C21.36.667 27.333 6.64 27.333 14S21.36 27.333 14 27.333.667 21.36.667 14 6.64.667 14 .667zM15.333 18h-2.666v2.667h2.666V18zm0-10.667h-2.666v8h2.666v-8z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

SvgAlert.propTypes = {
  color: PropTypes.string,
};
export default SvgAlert;
