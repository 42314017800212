import {gql} from '@apollo/client';

export default gql`
  mutation requestReferenceContactConfirmation(
    $messageContent: String!
    $referenceIds: [ID!]!
  ) {
    requestReferenceContactConfirmation(
      messageContent: $messageContent
      referenceIds: $referenceIds
    ) {
      success
    }
  }
`;
