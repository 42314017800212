import {gql} from '@apollo/client';

import {applicant} from '../../partials';

export default gql`
  query projectsSearch(
    $filter: ProjectsSearchFilterInputObject
    $limit: Int
    $page: Int
    $query: String
    $sort: ProjectsSearchSortInputObject
  ) {
    projectsSearch(
      filter: $filter
      limit: $limit
      page: $page
      query: $query
      sort: $sort
    ) {
      collection {
        id
        borrower {
          firstName
          email
          lastName
          id
        }
        primaryApplicant {
          ${applicant}
        }
        coborrower {
          ${applicant}
        }
        homeowners {
          id
          email
          firstName
          lastName
        }
        primaryApplicantId
        renovationReadyFile {
          id
        }
        city
        loanTypeEnum {
          crmName
          graphqlName
        }
        renofiId
        salesforceOpportunityId
        stageName
        state
        status
        streetAddressOne
        renovationUnderwritingRequestId
        wholesale
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;
