import {gql} from '@apollo/client';

export default gql`
  mutation submitSoftCreditLiabilityOverride(
    $adjustedMonthlyPayment: Float!
    $creditLiabilityId: String!
    $softCreditCheckId: ID!
    $adjustmentReason: String
  ) {
    submitSoftCreditLiabilityOverride(
      adjustedMonthlyPayment: $adjustedMonthlyPayment
      creditLiabilityId: $creditLiabilityId
      softCreditCheckId: $softCreditCheckId
      adjustmentReason: $adjustmentReason
    ) {
      softCreditCheck {
        id
      }
    }
  }
`;
