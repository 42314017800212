import styled from '@emotion/styled';

import {maxSmall, white} from '@renofi/theme';

import Flex from '../Flex';

export const StyledMenu = styled.div({
  width: '100%',
  position: 'relative',
  userSelect: 'none',
});

export const StyledContent = styled(Flex)(
  {
    position: 'absolute',
    top: '75px',
    width: '100%',
    background: white,
    boxShadow: '0px 1px 8px rgba(33, 47, 73, 0.2)',
    zIndex: '1000',
    flexDirection: 'column',
    border: '1px solid #00609926',
    borderRadius: 8,
    transition: 'all 0.3s ease-in-out 0s, visibility 0s linear 0.3s',
    transitionDelay: '0s, 0s',
    [maxSmall]: {
      boxShadow: 'unset',
      border: 'unset',
      position: 'unset',
      borderRadius: 'unset',
    },
  },
  ({collapsed}) => ({
    visibility: collapsed ? 'hidden' : 'visible',
    opacity: collapsed ? 0 : 1,
    transform: collapsed ? 'translateY(-2em)' : 'translateY(0%)',

    [maxSmall]: {
      transition: 'all 300ms ease',
      height: collapsed ? 0 : '100%',
      borderTop: collapsed ? 'unset' : '1px solid rgba(0, 96, 153, 0.15)',
    },
  }),
);

export const ContentItem = styled(Flex)(
  {
    padding: '16px 20px',
    borderRadius: '8px',
    '&:hover': {
      cursor: 'pointer',
      [maxSmall]: {
        borderRadius: 'unset',
      },
    },
  },
  ({selected}) => ({
    background: selected ? '#eff9ff' : 'unset',
    transition: 'background 150ms linear',
    '&:hover': {
      background: selected ? '#eff9ff' : '#F5F6F7',
      transition: 'background 150ms linear',
    },
  }),
);

export const LabelSection = styled(Flex)(({multiple}) => ({
  maxWidth: '280px',
  justifyContent: 'space-between',
  padding: '8px 16px',
  transition: 'background 150ms linear',

  ...(multiple
    ? {
        '&:hover': {
          cursor: 'pointer',
          borderRadius: 8,
          background: '#F5F6F7',
          transition: 'background 150ms linear',
        },
      }
    : {}),
  [maxSmall]: {
    maxWidth: '100%',
    padding: '16px 20px',
    '&:hover': {
      borderRadius: 'unset',
    },
  },
}));

export const StyledBackdrop = styled.div(
  {
    width: '100%',
    position: 'fixed',
    background: '#80808008',
    left: 0,
    top: 0,
    height: '100%',
    zIndex: '900',
    [maxSmall]: {
      display: 'none',
    },
  },
  ({collapsed}) => ({
    display: collapsed ? 'none' : 'block',
  }),
);
