import allProps from '../document/allProps';

export default `
id
businessName
city
contactName
contactTitle
contractorGeneralLiabilityInsuranceDocument {
  checksum
  createdAt
  fileName
  fileSizeBytes
  id
  objectName
  pdfObjectName
  rejectionReason
  shareWithLenderAllowed
  shareable
  source
  status
}
contractorLicenseDocument {
  checksum
  createdAt
  fileName
  fileSizeBytes
  id
  objectName
  pdfObjectName
  rejectionReason
  shareWithLenderAllowed
  shareable
  source
  status
}
contractRequests {
  contactEmail
  createdAt
  id
  updatedAt
}
createdAt
ddRequestedAt
deletedAt
documents {
  ${allProps}
}
email
estimatedCostOfWork
gcddFirstViewedByAdminAt
licenseNumber
phoneNumber
providedProofOfGeneralLiabilityInsurance
providedLicenseNumberOrDocument
questionnaireReturnedAt
rejectionDetails
rejectionReason
reviewedAt
scopeOfWork
secondaryAddress
state
status
streetAddress
submissionFinishedAt
updatedAt
website
zipCode
`;
