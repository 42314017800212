import React from 'react';

import PropTypes from 'prop-types';

const RenovationStartDelayed = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <mask
        id="mask0_11_2355"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_11_2355)">
        <path
          d="M18 23C16.6167 23 15.4377 22.5127 14.463 21.538C13.4877 20.5627 13 19.3833 13 18C13 16.6167 13.4877 15.4373 14.463 14.462C15.4377 13.4873 16.6167 13 18 13C19.3833 13 20.5627 13.4873 21.538 14.462C22.5127 15.4373 23 16.6167 23 18C23 19.3833 22.5127 20.5627 21.538 21.538C20.5627 22.5127 19.3833 23 18 23ZM19.65 20.35L20.35 19.65L18.5 17.8V15H17.5V18.2L19.65 20.35Z"
          fill={color}
        />
        <path
          d="M5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V6C3 5.45 3.19567 4.97933 3.587 4.588C3.979 4.196 4.45 4 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.021 4.196 20.413 4.588C20.8043 4.97933 21 5.45 21 6V12H19V10H5V20H12V22H5ZM5 8H19V6H5V8Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

RenovationStartDelayed.propTypes = {
  color: PropTypes.string,
};

export default RenovationStartDelayed;
