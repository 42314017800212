import {NO_INCOME_TYPE} from '../../../../constants';

export const filterNoIncome = (incomeSources) => {
  const hasNoIncomeType = incomeSources.some(
    ({incomeType}) => incomeType === NO_INCOME_TYPE,
  );

  // removes noIncome from collection if more than 1 item is present
  if (incomeSources.length > 1 && hasNoIncomeType) {
    return incomeSources.filter(
      ({incomeType}) => incomeType !== NO_INCOME_TYPE,
    );
  }

  return incomeSources;
};
