import {pathOr} from 'ramda';

import {compose, withHandlers} from '@renofi/recompose/src';

import {SET_RENOVATION_UPDATE_SHARING_SETTING} from '../mutations';
import {GET_PROJECT} from '../queries';

import withMutation from './withMutation';

export default () =>
  compose(
    withMutation(SET_RENOVATION_UPDATE_SHARING_SETTING, {
      name: 'setRenovationUpdateSharingSettingValue',
      options: ({projectId: id}) => ({
        update: (cache, response) => {
          const updatedProject = pathOr(
            null,
            ['data', 'setRenovationUpdateSharingSettingValue', 'project'],
            response,
          );
          if (updatedProject) {
            const {project} = cache.readQuery({
              query: GET_PROJECT,
              variables: {id},
            });
            cache.writeQuery({
              query: GET_PROJECT,
              variables: {id},
              data: {
                project: {...project, ...updatedProject},
              },
            });
          }
        },
      }),
    }),
    withHandlers({
      setRenovationUpdateSharingSetting:
        ({setRenovationUpdateSharingSettingValue}) =>
        (variables) => {
          return setRenovationUpdateSharingSettingValue({variables});
        },
    }),
  );
