import {gql} from '@apollo/client';

export default gql`
  query references(
    $contractorId: ID!
    $limit: Int!
    $page: Int!
    $questionnaireId: ID!
  ) {
    references(
      contractorId: $contractorId
      limit: $limit
      page: $page
      questionnaireId: $questionnaireId
    ) {
      collection {
        createdAt
        email
        id
        manualEntryDetails
        name
        phoneNumber
        questionnaireId
        score
        type
        updatedAt
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;
