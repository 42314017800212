import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {PatternFormat} from 'react-number-format';
import {format as dateFnsFormat, isValid, parse} from 'date-fns';
import {isNil} from 'ramda';

import {basic20} from '@renofi/theme';
import {isIsoDate} from '@renofi/utilities/src/dates';
import {formatIsoDate} from '@renofi/utilities/src/dates/dateTimeDiff';

import {Input} from '../FieldInput/styled';
import FieldFrame from '../FieldFrame';

const MASK = '_';

const SimpleDateField = ({
  format = 'MM/yyyy',
  leftIcon = 'calendar',
  mask = MASK,
  placeholder,
  value,
  onChange,
  ...props
}) => {
  const [displayValue, setDisplayValue] = useState(value);
  const [isDirty, setIsDirty] = useState(!isNil(value));

  function onValueChange({target}) {
    const newValue = target.value;
    setIsDirty(newValue !== placeholder);
    onChange(newValue, isValid(parse(newValue, format, new Date())));
  }

  useEffect(() => {
    const isIso = isIsoDate(value);

    // If the user input includes the "mask", we're still typing!
    if (!isIso || !value || (value && value.includes(MASK))) {
      return setDisplayValue(value);
    }
    setDisplayValue(formatIsoDate(value));
  }, [format, value]);

  return (
    <FieldFrame
      inputValue={displayValue}
      placeholder={placeholder}
      {...props}
      leftIcon={leftIcon}
      showIconDivider>
      <PatternFormat
        css={{color: `${isDirty ? 'inherit' : basic20}!important`}}
        showIconDivider
        allowEmptyFormatting
        leftIcon="calendar"
        value={displayValue}
        onChange={onValueChange}
        format={format.replace(/\w/gi, '#')}
        mask={mask}
        placeholder={placeholder}
        active
        customInput={Input}
        {...props}
      />
    </FieldFrame>
  );
};

SimpleDateField.propTypes = {
  format: PropTypes.string,
  leftIcon: PropTypes.string,
  mask: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default SimpleDateField;
