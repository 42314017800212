import {gql} from '@apollo/client';

import {underwritingCondition} from '../../partials/renovation';

export default gql`
  mutation updateUnderwritingCondition(
    $assignedToId: ID
    $condition: String
    $id: ID!
    $status: UnderwritingConditionStatus
    $notes: String
    $responsibleAgent: UnderwritingConditionResponsibleAgentEnum
    ) {
      updateUnderwritingCondition(
        assignedToId: $assignedToId
        condition: $condition
        id: $id
        status: $status
        notes: $notes
        responsibleAgent: $responsibleAgent
        ) {
          underwritingCondition {
            ${underwritingCondition}
          }
        }
    }
`;
