import * as React from 'react';

import PropTypes from 'prop-types';

const SvgDollar = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M12.46 10.778c-2.676-.656-3.537-1.334-3.537-2.39 0-1.21 1.19-2.055 3.183-2.055 2.098 0 2.876.945 2.947 2.334h2.605c-.082-1.911-1.32-3.667-3.784-4.234V2h-3.536v2.4c-2.287.467-4.126 1.867-4.126 4.011 0 2.567 2.252 3.845 5.54 4.589 2.947.667 3.537 1.644 3.537 2.678 0 .766-.578 1.989-3.183 1.989-2.428 0-3.383-1.023-3.513-2.334H6c.141 2.434 2.075 3.8 4.338 4.256V22h3.536v-2.389C16.173 19.2 18 17.944 18 15.667c0-3.156-2.864-4.234-5.54-4.89z"
          fill={color}
        />
      </g>
    </svg>
  );
};

SvgDollar.propTypes = {
  color: PropTypes.string,
};
export default SvgDollar;
