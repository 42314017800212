import {gql} from '@apollo/client';

export default gql`
  query taskAdditionalRequestBorrowerNotification(
    $task: EmailPreviewTaskInputObject!
    $taskAdditionalRequest: TaskAdditionalRequestInputObject!
  ) {
    taskAdditionalRequestBorrowerNotification(
      task: $task
      taskAdditionalRequest: $taskAdditionalRequest
    ) {
      subject
      content
    }
  }
`;
