import {v4} from 'uuid';

import noop from '@renofi/utilities/src/noop';
import {subscribe, unsubscribe} from '@renofi/utilities/src/pusher';
import {useNotifications} from '@renofi/utilities';
import {openTab} from '@renofi/utilities/src/window';

import {REQUEST_FILES_ZIP} from '../../mutations/storehouse';
import useMutation from '../useMutation';

export default function useRequestFilesZip({
  onCompleted = noop,
  refetchQueries = [],
  ...options
} = {}) {
  const [requestFilesZip, {data, loading, error}] = useMutation(
    REQUEST_FILES_ZIP,
    {
      onCompleted,
      refetchQueries,
      ...options,
    },
  );
  const {addNotification, removeById} = useNotifications();

  const onPusherUpdate = ({
    channelName,
    data = {},
    notificationId,
    timeoutId,
  }) => {
    const {download_url: downloadUrl} = data;
    removeById(notificationId, timeoutId);

    if (!downloadUrl) {
      return addNotification({
        variant: 'danger',
        content: 'There was a problem generating the ZIP; please try again',
      });
    }

    openTab(downloadUrl);
    unsubscribe(channelName);
  };

  const requestFilesZipAsync = async ({objectNames = [], outputFileName}) => {
    const browserEventChannelId = v4();
    const variables = {browserEventChannelId, objectNames, outputFileName};
    const channelName = `file_ready_for_download--file=${browserEventChannelId}`;

    const rsp = await requestFilesZip({variables});
    if (Boolean(rsp.errors || !rsp?.data?.requestFilesZip?.success)) {
      addNotification({
        variant: 'danger',
        content: 'There was a problem fetching the query; please try again',
      });
      return;
    }

    const {id: notificationId, timeoutId} = addNotification({
      delay: 240000, // 4 minutes
      variant: 'success',
      content:
        'Your download starting soon: depending on the number of files, this may take some time...',
      type: 'snackbar',
    });

    subscribe(channelName, (data) =>
      onPusherUpdate({channelName, data, notificationId, timeoutId}),
    );
  };

  return {
    requestFilesZip,
    requestFilesZipAsync,
    data,
    loading,
    error,
  };
}
