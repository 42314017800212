import {gql} from '@apollo/client';

import {
  advisorFinalCallSchedule,
  certificateAcknowledgement,
  productDetailsConfirmation,
} from '../../partials';

export default gql`
  mutation presentProductOptionsToBorrower(
    $projectId: ID!, $lenderId: ID!, $loanProductId: ID, 
    $applicationProcess: RenovationReadyFileApplicationProcess, 
    $autoTransmitToLender: Boolean, $interestRate: Float
  ) {
    presentProductOptionsToBorrower(
      projectId: $projectId, lenderId: $lenderId, loanProductId: $loanProductId,
      applicationProcess: $applicationProcess, autoTransmitToLender: $autoTransmitToLender, 
      interestRate: $interestRate
    ) {
      productConfirmationTasks {
        id
        additionalExplanation
        advisorFinalCallSchedule {
          ${advisorFinalCallSchedule}
        }
        allDocumentsAccepted
        anyDocumentRejected
        allDocumentsReviewed
        completedAt
        certificateAcknowledgement {
          ${certificateAcknowledgement}
        }
        contractor {
          businessName
          city
          contactName
          contactTitle
          email
          id
          phoneNumber
          rejectionDetails
          rejectionReason
          secondaryAddress
          state
          status
          streetAddress
          website
          zipCode
        }
        details
        documents {
          id
          checksum
          fileName
          objectName
          pdfObjectName
          rejectionDetails
          rejectionReason
          status
        }
        informationKind
        productDetailsConfirmation {
          ${productDetailsConfirmation}
        }
        requirements
        status
        submittedAt
        title
      }
    }
  }
`;
