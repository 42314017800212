import {gql} from '@apollo/client';

export default gql`
  query lenders($page: Int!, $limit: Int!) {
    lenders(page: $page, limit: $limit) {
      collection {
        id
        name
        fastTrackEnabled
        friendlyId
        mfaRequired
        notificationPreferences {
          id
          notificationType
          emailAddresses
        }
        users {
          id
        }
        raasEnabled
        tpoEnabled
        crmId
        wholesaleEnabled
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;
