import {gql} from '@apollo/client';

export default gql`
  query webReviews(
    $contractorId: ID
    $gcddReviewId: ID
    $limit: Int
    $page: Int
  ) {
    webReviews(
      contractorId: $contractorId
      gcddReviewId: $gcddReviewId
      limit: $limit
      page: $page
    ) {
      collection {
        comment
        createdAt
        gcddReviewId
        id
        rating
        updatedAt
        url
        website
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;
