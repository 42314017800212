import {gql} from '@apollo/client';

export default gql`
  mutation submitBbbGrade(
    $bbbGrade: BbbGradesEnum
    $bbbListed: Boolean!
    $contractorId: ID!
  ) {
    submitBbbGrade(
      bbbGrade: $bbbGrade
      bbbListed: $bbbListed
      contractorId: $contractorId
    ) {
      contractor {
        bbbCheckedAt
        bbbGrade
        bbbListed
        id
      }
    }
  }
`;
