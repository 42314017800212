import * as React from 'react';

import PropTypes from 'prop-types';

const SvgPercent = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="m9.732 20 6.684-15.092L14.33 4 7.636 19.092 9.732 20zm-3.446-7.338c2.574 0 4.297-1.93 4.297-4.279 0-2.546-1.93-4.237-4.297-4.237C3.723 4.146 2 6.056 2 8.383c0 2.568 1.93 4.28 4.286 4.28zm.021-1.993c-1.152 0-2.086-.908-2.086-2.265 0-1.242.84-2.233 2.086-2.233 1.163 0 2.097.887 2.097 2.233 0 1.253-.84 2.265-2.097 2.265zm11.396 9.007c2.584 0 4.297-1.92 4.297-4.258 0-2.547-1.93-4.248-4.297-4.248-2.563 0-4.276 1.91-4.276 4.248 0 2.546 1.93 4.258 4.276 4.258zm.021-1.993c-1.152 0-2.097-.908-2.097-2.255 0-1.231.841-2.244 2.097-2.244 1.162 0 2.097.908 2.097 2.244 0 1.242-.841 2.255-2.097 2.255z"
          fill={color}
        />
      </g>
    </svg>
  );
};

SvgPercent.propTypes = {
  color: PropTypes.string,
};
export default SvgPercent;
