import {gql} from '@apollo/client';

export default gql`
  mutation submitQuestionnaireReference(
    $attributes: ReferenceInputObject!
    $gcddReviewId: ID!
  ) {
    submitQuestionnaireReference(
      attributes: $attributes
      gcddReviewId: $gcddReviewId
    ) {
      reference {
        createdAt
        email
        id
        manualEntryDetails
        name
        phoneNumber
        questionnaireId
        score
        scoreSubmittedAt
        scoreSubmittedById
        type
        updatedAt
      }
    }
  }
`;
