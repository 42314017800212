import {gql} from '@apollo/client';

export default gql`
  mutation submitGcddDocument(
    $checksum: String
    $contentType: String!
    $documentType: DocumentTypeEnum!
    $expiryDate: Date
    $fileSizeBytes: Int!
    $gcddReviewId: ID!
    $namesMatch: Boolean
    $objectName: String!
    $number: String
    $insurance: InsuranceInputObject
    $state: String
  ) {
    submitGcddDocument(
      checksum: $checksum
      contentType: $contentType
      documentType: $documentType
      expiryDate: $expiryDate
      fileSizeBytes: $fileSizeBytes
      gcddReviewId: $gcddReviewId
      namesMatch: $namesMatch
      number: $number
      objectName: $objectName
      insurance: $insurance
      state: $state
    ) {
      gcddReview {
        id
      }
    }
  }
`;
