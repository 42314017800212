import styled from '@emotion/styled';

import {basic55} from '@renofi/theme/src/colors';

import CommonText from '../../../Text';

export const Text = styled(CommonText)({
  color: `${basic55} !important`,
  flexShrink: 0,
  flexGrow: 1,
  flex: 1,
  whiteSpace: 'nowrap',
  paddingLeft: '0 !important',
});
