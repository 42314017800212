import * as React from 'react';

import PropTypes from 'prop-types';

const SvgBoot = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="m18.048 14.526-3.509-1.024c-.82-.24-1.394-1.032-1.394-1.928V3.4c0-.22-.172-.4-.381-.4H4.38c-.209 0-.38.18-.38.4v17.2c0 .22.171.4.381.4h5.714c.21 0 .381-.18.381-.4V19h1.143v1.6c0 .22.171.4.381.4h7.619c.21 0 .381-.18.381-.4v-3.376c0-1.252-.804-2.362-1.952-2.698zm-3.715-.252 3.509 1.024c.82.24 1.394 1.032 1.394 1.928v.974h-8.413c-.183-1.134-1.122-2-2.252-2h-3.81V12h6.096c.21 0 .381-.18.381-.4v-2c0-.744.488-1.37 1.143-1.548v3.524c0 1.252.804 2.362 1.952 2.698zM8.19 18.2H4.762V17h3.81c.708 0 1.304.512 1.474 1.2H8.19zm4.191-14.4v3.436c-1.08.192-1.905 1.178-1.905 2.364v1.6H4.762V3.8h7.619zM4.761 19h1.144v1.2H4.762V19zm1.906 0H7.81v1.2H6.667V19zm3.047 1.2H8.571V19h1.143v1.2zm2.667-1.2h1.143v1.2H12.38V19zm1.905 0h1.143v1.2h-1.143V19zm1.905 0h1.142v1.2h-1.142V19zm3.047 1.2h-1.143V19h1.143v1.2z"
        fill={color}
      />
    </svg>
  );
};

SvgBoot.propTypes = {
  color: PropTypes.string,
};
export default SvgBoot;
