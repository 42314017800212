import React from 'react';

import {useParams} from 'react-router-dom';

import {Box, DateFormat, Flex, Loader, Text} from '@renofi/components-internal';
import {Alarm} from '@renofi/icons';
import {useProjectTimeline} from '@renofi/graphql';

const CompletionDate = () => {
  const {projectId} = useParams();
  const {loading, timeline} = useProjectTimeline({variables: {id: projectId}});
  const completedOn = timeline?.expectedRenovationCompletionOn;

  return (
    <Flex width={1} alignItems="center">
      <Alarm />
      <Text ml={3}>Expected completion date:</Text>
      <Box pl={2}>
        {loading ? (
          <Loader label={false} width={25} />
        ) : Boolean(completedOn) ? (
          <DateFormat value={completedOn} time={false} />
        ) : (
          'Not yet available'
        )}
      </Box>
    </Flex>
  );
};

export default CompletionDate;
