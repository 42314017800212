import {gql} from '@apollo/client';

import {underwritingCondition} from '../../partials/renovation';

export default gql`
  mutation submitUnderwritingCondition(
    $assignedToId: ID
    $condition: String!
    $type: UnderwritingConditionTypeEnum
    $projectId: ID!
    $completionStage: UnderwritingConditionCompletionStageEnum
    $responsibleAgent: UnderwritingConditionResponsibleAgentEnum
    $external: Boolean
    $completableByAssigned: Boolean
  ) {
    submitUnderwritingCondition(
      assignedToId: $assignedToId
      condition: $condition
      projectId: $projectId
      type: $type
      completionStage: $completionStage
      responsibleAgent: $responsibleAgent
      external: $external
      completableByAssigned: $completableByAssigned
    ) {
      underwritingCondition {
        ${underwritingCondition}
      }
    }
  }
`;
