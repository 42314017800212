import React from 'react';

import {Text, Box} from 'rebass';
import PropTypes from 'prop-types';
import {isNil} from 'lodash';
import Skeleton from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonText = ({
  children,
  count = 1,
  animate = false,
  fontSize,
  style = {},
  skeletonHeight,
  skeletonWidth,
  ...props
}) => {
  if (isNil(children))
    return (
      <Box {...props}>
        <Skeleton
          style={style}
          width={skeletonWidth || '100%'}
          height={skeletonHeight || 16}
          count={count}
          enableAnimation={animate}
        />
      </Box>
    );
  return (
    <Text fontSize={fontSize} {...props}>
      {children}
    </Text>
  );
};

SkeletonText.propTypes = {
  children: PropTypes.node,
  count: PropTypes.number,
  style: PropTypes.object,
  animate: PropTypes.bool,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  skeletonHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  skeletonWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default SkeletonText;
