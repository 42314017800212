import * as React from 'react';

import PropTypes from 'prop-types';

import {basic20} from '@renofi/theme/src/colors';

const SvgContract = ({color, width, ...props}) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g id="description">
        <mask
          id="mask0_532_101285"
          style={{maskType: 'alpha'}}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width={width}
          height={width}>
          <rect id="Bounding box" width={width} height={width} fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_532_101285)">
          <path
            id="description_2"
            d="M8 18H16V16H8V18ZM8 14H16V12H8V14ZM6 22C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V4C4 3.45 4.19583 2.97917 4.5875 2.5875C4.97917 2.19583 5.45 2 6 2H14L20 8V20C20 20.55 19.8042 21.0208 19.4125 21.4125C19.0208 21.8042 18.55 22 18 22H6ZM13 9V4H6V20H18V9H13Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

SvgContract.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
};

SvgContract.defaultProps = {
  color: basic20,
  width: 24,
};

export default SvgContract;
