import isPlainObject from '@renofi/utilities/src/isPlainObject';

export const generateKey = ({value, keyProp, label}) => {
  if (isPlainObject(value)) {
    return JSON.stringify(value);
  }

  if (value?.[keyProp]) {
    return value?.[keyProp];
  }

  if (value && !Array.isArray(value)) {
    return value;
  }

  return label;
};
