/* eslint-disable max-lines */
import {gql} from '@apollo/client';

import {
  projectUser,
  productDetailsConfirmation,
  advisorFinalCallSchedule,
  borrowersAndPropertyInformation,
  applicant,
} from '../../partials';

export default gql`
  query projectsSearch(
    $filter: ProjectsSearchFilterInputObject
    $limit: Int
    $page: Int
    $query: String
    $sort: ProjectsSearchSortInputObject
  ) {
    projectsSearch(
      filter: $filter
      limit: $limit
      page: $page
      query: $query
      sort: $sort
    ) {
      collection {
        appraisalStatus
        actualCloseDate
        primaryApplicant {
          ${applicant}
        }
        coborrower {
          ${applicant}
        }
        city
        id
        processor {
          id
          firstName
          lastName
          email
        }
        seniorProcessor {
          id
          firstName
          lastName
          email
        }
        borrower {
          id
          email
          firstName
          lastName
        }
        borrowersAndPropertyInformation {
          ${borrowersAndPropertyInformation}
        }
        homeowners {
          id
          email
          firstName
          lastName
        }
        primaryApplicantId
        contractReviewStatus
        createdAt
        estimatedCloseDate
        feasibilityReviews {
          completedAt
          requestedAt
          returnedAt
          status
          uncompletedDetails
          uncompletedReason
        }
        owner {
          id
          name
        }
        renovationReadyFile {
          id
          lenderId
          lender {
            id
            name
          }
          loanApplicationStatus
          transferredAt
        }
        renovationUnderwritingRequestId
        renovationUnderwritingRequest {
          id
          intendedStartDate
          lenderId
          lender {
            id
            name
          }
          ruStartedAt
        }
        lastAction {
          createdAt
          document {
            id
            fileName
          }
          id
          task {
            details
            id
            status
            title
          }
          type
          updatedAt
        }
        lenderId
        lender {
          id
          name
        }
        raas
        fastTracked
        notes {
          authorId
          body
          createdAt
          editable
          id
          mentions {
              label
              userEmail
              userId
              userName
          }
          projectId
          salesforceAuthorEmail
          salesforceAuthorId
          salesforceContentDocumentId
          salesforceOpportunityId
          title
          updatedAt
        }
        renofiId
        ruStatus
        loanType
        salesforceOpportunityId
        stageName
        productDetailsConfirmationStatus
        tasks {
          id
          borrowerFinishedAt
          status
          taskType
          title
          productDetailsConfirmation {
            ${productDetailsConfirmation}
          }
          advisorFinalCallSchedule {
            ${advisorFinalCallSchedule}
          }
        }
        projectAssociate {
          ${projectUser}
        }
        state
        status
        streetAddressOne
        streetAddressTwo
        tasksOverview {
          outstandingNoDocuments
          withRejectedDocuments
          withDocumentsInReview
          documentsUploaded
          lastDocumentUploadedAt
        }
        timeline {
          appraisalCompletedAt
          appraisalCorrectedReportReceivedAt
          appraisalFeeConsentOn
          appraisalInspectionAppointmentAt
          appraisalInspectionCompletedAt
          appraisalReportReceivedAt
          appraisalRequestedAt
          appraisalSubmittedAt

          contractReviewCompletedAt
          contractReviewRequestedAt

          feasibilityCompletedAt
          feasibilityRequestedAt

          contractorDueDiligenceCompletedAt
          contractorDueDiligenceRequestedAt
          gcddReviewReturnedAt
          
          readyFor1003PrepOn
          stageLastChangedAt
          x1003CompleteOn
          x1003PrepCompleteOn
        }
      }
      metadata {
        currentPage
        facets {
          appraisalStatus {
            count
            value
          }
          contractReviewStatus {
            count
            value
          }
          estimatedCloseMonth {
            count
            month {
              month
              year
            }
          }
          estimatedCloseQuarter {
            count
            quarter {
              quarter
              year
            }
          }
          feasibilityReviewStatus {
            count
            value
          }
          feasibilityReviewUncompleted {
            count
            value
          }
          gcddReviewStatus {
            count
            value
          }
          hasActualRenovationStartDate {
            count
            value
          }
          hasExpectedRenovationStartDate {
            count
            value
          }
          loanType {
            count
            value
          }
          lastActionType {
            count
            type
          }
          loanClosedMonth {
            count
            month {
              month
              year
            }
          }
          loanClosedQuarter {
            count
            quarter {
              quarter
              year
            }
          }
          outstandingTasks {
            count
            value
          }
          pendingReviewTasks {
            count
            value
          }
          projectOwner {
            count
            projectOwner {
              id
              name
            }
          }
          processor {
            count
            processor {
              id
              firstName
              lastName
              email
            }
          }
          seniorProcessor {
            count
            processor {
              id
              firstName
              lastName
              email
            }
          }
          rejectedTasks {
            count
            value
          }
          ruStatus {
            count
            value
          }
          stage {
            count
            stageId
            stageName
          }
        }
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;
