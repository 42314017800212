import {useEffect} from 'react';

import {createElement, getElementById} from '../domTools';

function resizeIframe(iframe, counter = 0) {
  if (counter > 10) {
    // probably it's too much, let's break it
    return;
  }
  const {scrollHeight} = iframe.contentDocument.body;
  iframe.style.height = `${scrollHeight}px`;
  if (iframe.contentDocument.body.scrollHeight !== scrollHeight) {
    resizeIframe(iframe, counter + 1);
  }
}

function defaultTransform(iframe) {
  iframe.contentWindow.document.body.style.backgroundColor = '#FFFFFF';
  iframe.contentWindow.document.body.style.margin = '0';
  iframe.contentWindow.document.body.style.padding = '0';
  iframe.contentWindow.document.body.style.fontFamily =
    'Averta, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif';

  const dataCell = iframe.contentWindow.document.querySelector(
    'body > table > tbody > tr > td',
  );

  if (dataCell) {
    dataCell.style.padding = '0';
  }

  const table = iframe.contentWindow.document.querySelector(
    'body > table > tbody > tr > td > table',
  );

  if (table) {
    table.style.border = 'none';
  }
}

function usePreview(html, id, transform = defaultTransform) {
  useEffect(() => {
    if (!html) return;

    const container = getElementById(id);

    if (!container || container.children.length) return;

    const iframe = createElement('iframe');
    iframe.style.border = 'none';
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.scrolling = 'no';
    iframe.id = `iframe-${id}`;

    container.appendChild(iframe);

    const observer = new MutationObserver(() => {
      resizeIframe(iframe);
    });

    observer.observe(iframe.contentWindow.document, {
      childList: true,
      attributes: true,
      subtree: true,
    });

    iframe.contentWindow.document.open();
    iframe.contentWindow.document.write(html.content);
    iframe.contentWindow.document.close();

    transform(iframe);

    try {
      iframe.contentWindow.document
        .querySelector('body')
        .addEventListener('click', (event) => {
          event.preventDefault();
        });
    } catch (error) {
      // ignore
    }

    return () => {
      observer.disconnect();
      iframe.remove();
    };
  }, [html]);
}

export default usePreview;
