import {isDeployPreviewUrl} from '@renofi/utilities/src/netlify';
import {getLocation} from '@renofi/utilities/src/window';

const EMOJI_DEV = '🚧';
const EMOJI_STAG = '👩‍🔬';

const TITLE = 'Renofi';
const STAGING = /^https:\/\/[a-z0-9\-\_]+\.(renofistaging|renofitesting)?\.com/;

export default () => {
  const {NODE_ENV, REACT_APP_SERVICE_NAME: APP = ''} = process.env;
  const appName = APP.split('-')[0] || '';
  const suffix = appName ? `(${appName})` : '';
  const {host, hostname, origin} = getLocation() || {};

  switch (true) {
    case isDeployPreviewUrl():
      const [match, previewId] =
        (host || hostname).match(/deploy-preview-([0-9]+)/) || [];
      return [`[preview #${previewId}]`, suffix].join(' ').trim();
    case NODE_ENV === 'development':
      return [EMOJI_DEV, TITLE, suffix].join(' ').trim();
    case Boolean(origin?.match(STAGING)):
      return [EMOJI_STAG, TITLE, suffix].join(' ').trim();
    default:
      return TITLE;
  }
};
