import React from 'react';

import Flex from '@renofi/components-internal/src/Flex';
import {white} from '@renofi/theme/src/colors';

function Container(props) {
  return (
    <Flex
      my={24}
      width="100%"
      justifyContent="center"
      alignItems="center"
      flexGrow={0}
      css={{backgroundColor: white, height: '100%', minHeight: 400}}
      {...props}
    />
  );
}

export default Container;
