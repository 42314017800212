import alternativeNames from './alternativeNames';

export default `
id
taskId
firstName
middleName
lastName
maritalStatus
dateOfBirth
citizenshipType
dependentsNumber
dependentsAges
borrowerAlternativeNames {
  firstName
  lastName
  middleName
  nameSuffix
}
coborrowerAlternativeNames {
  firstName
  lastName
  middleName
  nameSuffix
}
borrowerResidentialAddresses {
  city
  county
  country
  housingType
  id
  monthlyExpenses
  residenceFrom
  residenceTo
  borrowerRole
  state
  streetAddressOne
  streetAddressTwo
  zipCode
  currentlyLiveHere
  mailingAddress
  currentAddress
}
borrowerMailingAddress {
  city
  county
  country
  id
  borrowerRole
  state
  streetAddressOne
  streetAddressTwo
  zipCode
  mailingAddress
}
dependentsNumber
dependentsAges
nameSuffix
coborrowerNameSuffix
coborrowerDateOfBirth
coborrowerDependentsAges
coborrowerDependentsNumber
coborrowerFirstName
coborrowerMiddleName
coborrowerLastName
coborrowerMaritalStatus
coborrowerCitizenshipType
coborrowerSharesSameDependents
coborrowerPhoneNumber
coborrowerResidentialAddresses {
  city
  county
  country
  housingType
  id
  monthlyExpenses
  residenceFrom
  residenceTo
  borrowerRole
  state
  streetAddressOne
  streetAddressTwo
  zipCode
  currentlyLiveHere
  mailingAddress
  coborrowerSharesSameAddressAndExpenses
  currentAddress
}
coborrowerMailingAddress {
  city
  county
  country
  id
  borrowerRole
  state
  streetAddressOne
  streetAddressTwo
  zipCode
  mailingAddress
}
borrowerAlternativeNames {
  ${alternativeNames}
}
coborrowerAlternativeNames {
  ${alternativeNames}
}
`;
