import {withRouter} from 'react-router-dom';
import {pathOr} from 'ramda';
import withLender from 'lib/withLender';

import {compose, withProps} from '@renofi/recompose/src';
import {withConfig} from '@renofi/utilities/src/config';
import withImpersonation from '@renofi/utilities/src/withImpersonation';
import spreadProps from '@renofi/utilities/src/spreadProps';

import Component from './Component';

export default compose(
  withConfig,
  withRouter,
  withImpersonation,
  withLender,
  spreadProps(['user']),
  withProps((props) => {
    return {
      path: pathOr('/', ['location', 'pathname'])(props),
      ruEnabled: props?.config?.DEVELOPMENT || props?.lender?.raasEnabled,
    };
  }),
)(Component);
