import React from 'react';

import PropTypes from 'prop-types';
import {propEq} from 'ramda';

import {
  compose,
  withState,
  branch,
  withHandlers,
  defaultProps,
} from '@renofi/recompose/src';
import {actionRegular, basic10} from '@renofi/theme/src/colors';
import noop from '@renofi/utilities/src/noop';

const DURATION = 2000;

const SvgProgress = ({
  className,
  radius = 11,
  showCenter,
  stroke = 2,
  strokeActiveColor = actionRegular,
  strokeBgColor = basic10,
  strokeOpacity = 1,
  progress = 100,
  reverse = false,
}) => {
  const normalizedRadius = radius - stroke / 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset =
    circumference -
    ((reverse ? 100 - progress - 100 : progress) / 100) * circumference;
  return (
    <svg className={className} height={radius * 2} width={radius * 2}>
      <circle
        stroke={strokeBgColor}
        fill="none"
        opacity={strokeOpacity}
        strokeWidth={stroke}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <circle
        stroke={strokeActiveColor}
        fill="none"
        opacity={strokeOpacity}
        strokeWidth={stroke}
        strokeDasharray={circumference + ' ' + circumference}
        style={{strokeDashoffset}}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      {showCenter && (
        <rect
          fill={strokeActiveColor}
          x={radius - 3}
          y={radius - 3}
          width={6}
          height={6}
        />
      )}
    </svg>
  );
};

SvgProgress.propTypes = {
  className: PropTypes.string,
  progress: PropTypes.number.isRequired,
  radius: PropTypes.number,
  showCenter: PropTypes.bool,
  stroke: PropTypes.number,
  strokeActiveColor: PropTypes.string,
  strokeBgColor: PropTypes.string,
  strokeOpacity: PropTypes.number,
  reverse: PropTypes.bool,
};

class Progress extends React.Component {
  static propTypes = {
    duration: PropTypes.number.isRequired,
    onLoopComplete: PropTypes.func,
    paused: PropTypes.bool,
    radius: PropTypes.number,
    showCenter: PropTypes.bool,
    stroke: PropTypes.number,
    strokeOpacity: PropTypes.number,
    strokeActiveColor: PropTypes.string,
    strokeBgColor: PropTypes.string,
    updateProgress: PropTypes.func,
  };

  static defaultProps = {
    duration: DURATION,
    onLoopComplete: noop,
    paused: false,
    showCenter: true,
    updateProgress: noop,
  };

  frameId = null;

  state = {
    elapsed: 0,
    lastTick: null,
  };

  startLoop() {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.loop);
    }
  }

  stopLoop() {
    if (this.frameId) {
      window.cancelAnimationFrame(this.frameId);
      this.frameId = null;
    }
  }

  loop = (timestamp) => {
    const {duration, paused, onLoopComplete, updateProgress} = this.props;
    const {elapsed: prevElapsed} = this.state;
    const lastTick = this.state.lastTick || timestamp;

    const elapseBy = timestamp - lastTick;
    const elapsed = paused ? prevElapsed : prevElapsed + elapseBy;

    this.setState({elapsed, lastTick: timestamp}, () => {
      const hasLooped =
        prevElapsed && Math.floor(elapsed % duration) <= elapseBy;
      if (hasLooped) {
        onLoopComplete(timestamp);
      }

      updateProgress(elapsed);
      requestAnimationFrame(this.loop);
    });
  };

  componentDidMount() {
    this.startLoop();
  }

  componentWillUnmount() {
    this.stopLoop();
  }

  render() {
    return <SvgProgress {...this.props} />;
  }
}

export default compose(
  branch(
    propEq('animate', true),
    compose(
      defaultProps({
        duration: DURATION,
      }),
      withState('reverse', 'setReverse', false),
      withState('progress', 'setProgress', 0),
      withHandlers({
        updateProgress:
          ({duration, reverse, progress, setReverse, setProgress}) =>
          (elapsed) => {
            let isReverse = reverse;
            const newProgress = (elapsed / duration) * 100;

            if (newProgress < progress && !reverse) {
              isReverse = true;
              setReverse(isReverse);
            }
            if (newProgress < 100 - progress && reverse) {
              isReverse = false;
              setReverse(isReverse);
            }
            setProgress(isReverse ? 100 - newProgress : newProgress);
          },
      }),
    ),
  ),
)(Progress);
