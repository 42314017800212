import {either, is, keys, map, addIndex} from 'ramda';

import isPlainObject from '@renofi/utilities/src/isPlainObject';

const mapIndexed = addIndex(map);

const convertOptionsArray = (options = []) => {
  return options.reduce((arr, option) => {
    const isBasicType = either(is(Number), is(String))(option);
    if (isBasicType) {
      return arr.concat({label: String(option), value: option});
    }

    return arr.concat(option);
  }, []);
};

const convertOptionsObject = (options = {}) => {
  return keys(options).reduce((arr, value) => {
    if (Array.isArray(options[value])) {
      return arr.concat({
        label: value,
        options: convertOptionsArray(options[value]),
      });
    }

    return arr.concat({
      label: options[value],
      value,
    });
  }, []);
};

export const convertOptions = (options) => {
  switch (true) {
    case !options:
      return [];
    case isPlainObject(options):
      return convertOptionsObject(options);
    case Array.isArray(options):
      return convertOptionsArray(options);
    default:
      return [];
  }
};

export const getFlattenedOptions = (options = []) => {
  // Ignores plain objects w. Array values; if use-case ever occurs, we can fix then
  if (isPlainObject(options)) {
    return options;
  }

  const flattenedOptions = [];

  const parse = ({items = [], depth = 1, prefix}) => {
    mapIndexed((item, index) => {
      const isFirstItem = index === 0;
      if (item.options) {
        return parse({
          items: item.options,
          depth: depth + 1,
          prefix: item.label,
        });
      }
      flattenedOptions.push({
        ...item,
        depth,
        prefix: isFirstItem ? prefix : null,
      });
    })(items);
  };

  parse({items: options});
  return flattenedOptions;
};

export const filterOptionsByInput = (options = [], input) => {
  return options.filter((opt) => {
    const label = opt.label || opt;

    if (typeof label === 'string') {
      return label.toLowerCase().match(input.toLowerCase());
    }

    return true;
  });
};
