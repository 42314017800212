import {gql} from '@apollo/client';

export default gql`
  mutation submitPutOrderOnHold($appraisalOrderId: ID!, $comments: String!) {
    submitPutOrderOnHold(
      appraisalOrderId: $appraisalOrderId
      comments: $comments
    ) {
      success
    }
  }
`;
