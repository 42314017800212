import React, {useMemo} from 'react';

import PropTypes from 'prop-types';

import {Switch, Tooltip} from '@renofi/components-internal';
import {formatDateTime} from '@renofi/utilities/src/dates';

const ShareToggle = ({onToggle, sharedWithLenderAt, shareWithLender}) => {
  const isDisabled = !!sharedWithLenderAt;

  const switchCmp = useMemo(() => {
    const pointerEvents = sharedWithLenderAt ? 'none' : 'initial';
    return (
      <Switch
        css={{pointerEvents}}
        disabled={isDisabled}
        checked={shareWithLender}
        onClick={onToggle}
      />
    );
  }, [onToggle, sharedWithLenderAt, shareWithLender]);

  if (!isDisabled) {
    return switchCmp;
  }
  const date = formatDateTime(new Date(sharedWithLenderAt));
  const message = `This update was already shared on ${date}`;

  return (
    <Tooltip place="top" content={message}>
      <div>{switchCmp}</div>
    </Tooltip>
  );
};

ShareToggle.propTypes = {
  onToggle: PropTypes.func.isRequired,
  sharedWithLenderAt: PropTypes.string,
  shareWithLender: PropTypes.bool,
};

export default ShareToggle;
