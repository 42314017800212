import styled from '@emotion/styled';

import Button from '@renofi/components-internal/src/Button';
import {CONTENT_OFFSET} from '@renofi/components-internal/src/Modal';
import {border, white} from '@renofi/theme/src/colors';
import {minSmall, maxSmall} from '@renofi/theme/src/breakpoints';

import Flex from '../Flex';

import landscape from './landscape.svg';

const PANEL_WIDTH = 335;
export const DEFAULT_MODAL_WIDTH = 800;

export const footerCss = {
  padding: 0,
  backgroundColor: '#eee',
  borderTopColor: '#eee',
};

export const Container = styled.div({
  display: 'flex',
  width: '100%',
  alignItems: 'stretch',
  height: 'auto',

  backgroundImage: `url('${landscape}')`,
  backgroundRepeat: 'no-repeat',

  [minSmall]: {
    backgroundPosition: `${PANEL_WIDTH}px bottom`,
    backgroundSize: `calc(100% - ${PANEL_WIDTH}px) auto`,
  },

  [maxSmall]: {
    position: 'relative',
    height: '100%',
    backgroundPosition: 'left bottom',
    backgroundSize: '100% auto',
  },
});

export const Panel = styled.div(({force, panelWidth}) => ({
  maxHeight: '100%',
  overflowX: 'hidden',
  overflowY: 'auto',
  width: panelWidth ? panelWidth : PANEL_WIDTH,
  flexShrink: 0,
  padding: '32px 24px',
  borderRight: `1px solid ${border}`,

  [maxSmall]: {
    display: !force ? 'none' : 'unset',
    ...(force
      ? {
          width: '100%',
          maxHeight: '90%',
          position: 'absolute',
          zIndex: 1,
          bottom: 0,
          left: 0,
          background: white,
          boxShadow: '0px -6px 10px rgba(0, 0, 0, 0.1)',
        }
      : {}),
  },
}));

export const Content = styled.div({
  overflow: 'auto',
  flexGrow: 1,
  backgroundColor: 'rgba(229,229,229,0.1)',
});

export const ToggleProgress = styled(Button)({
  display: 'none',
  width: 30,
  height: 30,
  padding: 0,
  marginRight: 'auto',
  borderRadius: 30,
  lineHeight: '50px',

  [maxSmall]: {
    display: 'unset',
  },
});

export const FooterContainer = styled(Flex)({
  height: '100%',
  paddingLeft: '8px',
  paddingRight: '8px',
  justifyContent: 'flex-end',
  alignItems: 'center',

  [maxSmall]: {
    '.wizardBtn': {
      minWidth: 86,
      height: 44,
      borderRadius: 100,
    },
  },
});
