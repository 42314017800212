import {gql} from '@apollo/client';

export default gql`
  query userLender {
    userLender {
      id
      applyUrl
      contactInformation1
      contactInformation2
      friendlyId
      joinUrl
      raasEnabled
      logoObjectName
      logoUrl
      loanProducts {
        id
        name
      }
      name
      notificationPreferences {
        id
        emailAddresses
        notificationType
      }
    }
  }
`;
