import {gql} from '@apollo/client';

export default gql`
  mutation submitAppraisalPropertyPhotos(
    $taskId: ID!
    $propertyPhotos: [AppraisalPropertyPhotoInputObject!]!
  ) {
    submitAppraisalPropertyPhotos(
      taskId: $taskId
      appraisalPropertyPhotos: $propertyPhotos
    ) {
      appraisalPropertyPhotos {
        id
        createdAt
        homeownerDescription
        objectName
        shareableWithAmc
        updatedAt
      }
      task {
        id
      }
    }
  }
`;
