import {gql} from '@apollo/client';

export default gql`
  query allowedIpAddresses($lenderId: ID!) {
    allowedIpAddresses(lenderId: $lenderId) {
      id
      cidrBlock
      createdAt
      lenderId
      name
      updatedAt
    }
  }
`;
