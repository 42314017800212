import {gql} from '@apollo/client';

import {contractor} from '../../partials';

export default gql`
  mutation requestContractorDueDilligence($contractorInformationId: ID!, $projectId: ID!) {
    requestContractorDueDilligence(contractorInformationId: $contractorInformationId, projectId: $projectId) {
      contractorInformation {
        ${contractor}
      }
    }
  }
`;
