import React from 'react';

import PropTypes from 'prop-types';

import Flex from '@renofi/components-internal/src/Flex';
import LenderLogo from '@renofi/components-internal/src/LenderLogo';
import LogoIcon from '@renofi/icons/src/Logo';

import {AndSymbol, Wrapper} from './styled';

function Logo({lender}) {
  return (
    <Wrapper hasLenderLogo={!!lender} alignItems="center">
      <LogoIcon />
      {!!lender && (
        <Flex alignItems="center">
          <AndSymbol px={2}>&</AndSymbol>

          <LenderLogo lender={lender} />
        </Flex>
      )}
    </Wrapper>
  );
}

Logo.propTypes = {
  lender: PropTypes.shape({}),
};

export default Logo;
