import React, {useEffect, useState} from 'react';

import {ThemeProvider} from '@emotion/react';
import PropTypes from 'prop-types';

import {mobile} from './breakpoints';
import {ALL_THEME_KEYS} from './constants';
import Context from './context';

const emotionTheme = {
  breakpoints: [`${mobile}px`],
};

const getInitialTheme = ({theme}) =>
  ALL_THEME_KEYS.includes(theme) ? theme : 'light';

const Provider = (props) => {
  const [theme, setTheme] = useState(getInitialTheme(props));

  useEffect(() => {
    if (theme !== props?.theme) {
      setTheme(getInitialTheme(props));
    }
  }, [props, theme]);

  return (
    <ThemeProvider theme={emotionTheme}>
      <Context.Provider
        {...props}
        value={{theme, changeTheme: (value) => setTheme(value)}}
      />
    </ThemeProvider>
  );
};

Provider.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default Provider;
