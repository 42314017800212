import React from 'react';

import PropTypes from 'prop-types';
import {useDropzone} from 'react-dropzone';

import getMimeTypes from '@renofi/utilities/src/mimeTypes';

import Button from './Button';

const defaultAcceptList = getMimeTypes();

const FileButton = ({
  name,
  onClick,
  onAcceptFiles,
  onRejectFiles = () => {},
  accept = defaultAcceptList,
  children,
  variant,
  ...props
}) => {
  const {getRootProps, getInputProps} = useDropzone({
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles && acceptedFiles.length) {
        onAcceptFiles(acceptedFiles);
      }
      if (rejectedFiles && rejectedFiles.length) {
        onRejectFiles(rejectedFiles);
      }
    },
    noDrag: true,
    accept: accept.join(', '),
    ...props,
  });
  return (
    <div {...getRootProps()} css={{display: 'flex', flexGrow: 1}}>
      <input {...getInputProps()} />
      <Button block name={name} variant={variant} onClick={onClick}>
        {children}
      </Button>
    </div>
  );
};

FileButton.propTypes = {
  accept: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  onAcceptFiles: PropTypes.func.isRequired,
  onRejectFiles: PropTypes.func.isRequired,
};

export default FileButton;
