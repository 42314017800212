import jwtDecode from './jwtDecode';
import {AUTH_TOKEN} from './storage';

export default (storage) => {
  const jwt = storage.getItem(AUTH_TOKEN);

  if (!jwt) return false;
  const {original_user_id: userId} = jwtDecode(jwt);
  return Boolean(userId);
};
