import * as React from 'react';

import PropTypes from 'prop-types';

const SvgWasher = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M19.6 2H4.396c-.846 0-1.534.688-1.534 1.534v16.933c0 .845.688 1.533 1.534 1.533H19.6c.845 0 1.533-.688 1.533-1.533V3.534C21.133 2.688 20.445 2 19.6 2zM3.544 3.534c0-.47.382-.852.852-.852H19.6c.47 0 .851.382.851.852v2.103H3.544V3.534zm16.907 16.933c0 .47-.382.851-.851.851H4.396a.853.853 0 0 1-.852-.851V6.319h16.907v14.148z"
        fill={color}
        stroke={color}
        strokeWidth={0.3}
      />
      <path
        d="M7.597 3.381A.779.779 0 1 0 7.6 4.94a.779.779 0 0 0-.002-1.558z"
        fill={color}
      />
      <path
        d="M11.997 19.52a5.708 5.708 0 0 0 5.701-5.701 5.708 5.708 0 0 0-5.7-5.702 5.708 5.708 0 0 0-5.702 5.702 5.708 5.708 0 0 0 5.701 5.7zm0-10.721a5.025 5.025 0 0 1 5.02 5.02 5.025 5.025 0 0 1-5.02 5.02 5.025 5.025 0 0 1-5.02-5.02 5.025 5.025 0 0 1 5.02-5.02z"
        fill={color}
        stroke={color}
        strokeWidth={0.3}
      />
      <path
        d="M12 17.702a3.887 3.887 0 0 0 3.882-3.883A3.887 3.887 0 0 0 12 9.936a3.888 3.888 0 0 0-3.883 3.883A3.888 3.888 0 0 0 12 17.702zm0-7.085a3.205 3.205 0 0 1 3.2 3.202A3.205 3.205 0 0 1 12 17.02a3.205 3.205 0 0 1-3.202-3.201 3.205 3.205 0 0 1 3.201-3.202z"
        fill={color}
        stroke={color}
        strokeWidth={0.3}
      />
      <path
        d="M10.983 15.643c.635 0 1.151-.516 1.151-1.151a1.153 1.153 0 0 0-2.303 0c0 .635.517 1.151 1.152 1.151zm0-1.621a.47.47 0 1 1-.001.94.47.47 0 0 1 0-.94zm1.305.116a.943.943 0 0 0 1.885 0 .943.943 0 0 0-1.885 0zm.942-.26a.26.26 0 1 1 0 .521.26.26 0 0 1 0-.521zm-1.207-.461a.713.713 0 1 0-.001-1.425.713.713 0 0 0 .001 1.425zM5.808 3.381a.78.78 0 0 0 0 1.557.779.779 0 0 0 0-1.557zm13.124.438H16.64a.34.34 0 1 0 0 .682h2.29a.34.34 0 1 0 0-.682z"
        fill={color}
      />
    </svg>
  );
};

SvgWasher.propTypes = {
  color: PropTypes.string,
};
export default SvgWasher;
