import React from 'react';

import PropTypes from 'prop-types';
import {pathOr} from 'ramda';

import {projectStatus} from '@renofi/utilities/src/enums';
import statuses from '@renofi/utilities/src/statuses';
import {diffDates} from '@renofi/utilities/src/dates';

import TimeAgo from './TimeAgo';

const ruPath = ['renovationUnderwritingRequest', 'ruStartedAt'];

const ProjectStatus = ({project}) => {
  const {createdAt, status} = project;
  const label = statuses[status] || 'Unknown status';
  const ruStartedAt = pathOr(null, ruPath)(project);

  if (status === projectStatus.inProgress && ruStartedAt) {
    const diff = diffDates(new Date(ruStartedAt));
    const end = diff === 1 ? '1 day' : `${diff} days`;
    return (
      <>
        {label} {end}
      </>
    );
  }
  if (status === projectStatus.preparing) {
    return (
      <>
        {label} <TimeAgo date={new Date(createdAt)} />
      </>
    );
  }
  return <>{label}</>;
};

ProjectStatus.propTypes = {
  project: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    renovationUnderwritingRequest: PropTypes.shape({
      ruStartedAt: PropTypes.string,
    }),
    status: PropTypes.oneOf(Object.values(projectStatus)).isRequired,
  }).isRequired,
};

export default ProjectStatus;
