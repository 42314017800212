import {propOr} from 'ramda';

import useToggledQuery from '../useToggledQuery';
import {GET_SIGNED_URL} from '../../queries';

export default function useSignedUrl({
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_SIGNED_URL,
    options: {
      fetchPolicy,
      ...options,
    },
  });

  const {data, error, loading, fetch} = response;
  const signedUrl = propOr(null, 'signedUrl', data);

  return {error, fetch, signedUrl, loading};
}
