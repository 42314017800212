import React from 'react';

import PropTypes from 'prop-types';

import {basic15} from '@renofi/theme/src/colors';

const DEFAULT_WIDTH = 24;

function SvgWarning({color, width}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={width}
      viewBox={`0 0 ${width} ${width}`}
      width={width}
      fill={color}>
      <path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z" />
    </svg>
  );
}

SvgWarning.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
};

SvgWarning.defaultProps = {
  color: basic15,
  width: DEFAULT_WIDTH,
};

export default SvgWarning;
