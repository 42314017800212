import {useEffect, useRef, useState} from 'react';

import {useMutation} from '@apollo/client';
import {mergeDeepRight} from 'ramda';

import useSystemContext from '@renofi/utilities/src/hooks/useSystemContext';
import useNotifications from '@renofi/utilities/src/hooks/useNotifications';
import logger from '@renofi/utilities/src/logger';

import getQueryName from '../utils/getQueryName';

import useSystemState from './system/useSystemState';
import onClientError from './onClientError';

export default (mutation, {canAbort = true, ...props} = {}) => {
  const abortController = useRef();
  const [loading, setLoading] = useState(false);
  const {config, storage} = useSystemContext();
  const {addNotification} = useNotifications();
  const {toggleSystemMutation} = useSystemState();
  const operationName = getQueryName(mutation);

  const [mutate, data] = useMutation(mutation, {
    ...props,
    onError: (error) => {
      if (props.onError) {
        props.onError({config, error, storage});
      }
      onClientError({addNotification, config, error, storage});
    },
  });

  useEffect(() => {
    setLoading(data.loading);
    toggleSystemMutation(operationName, data.loading);
  }, [data.loading]);

  const abort = () => {
    setLoading(false);
    toggleSystemMutation(operationName, false);
    if (canAbort && abortController.current) {
      logger.info(`[🟧 OPERATION (${operationName})] ABORTED`);
      abortController.current.abort();
    }
  };

  const mutateWithSignal = async (params = {}, ...rest) => {
    const context = {};
    if (canAbort) {
      const controller = new window.AbortController();
      abortController.current = controller;
      context.fetchOptions = {
        signal: controller.signal,
      };
    }

    const paramsWithSignal = mergeDeepRight(params, {
      context,
    });

    const response = await mutate(paramsWithSignal, ...rest);
    abortController.current = null;
    return response;
  };

  return [mutateWithSignal, {...data, loading, abort}];
};
