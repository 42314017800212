import jwtDecode from 'jwt-decode';
import {identity, memoizeWith} from 'ramda';

export default memoizeWith(identity, (jwt) => {
  let decoded = null;
  if (!jwt) {
    return decoded;
  }

  try {
    decoded = jwtDecode(jwt);
  } catch (err) {
    console.error('[renofi] invalid JWT token given of:', jwt);
    console.error(err);
  }
  return decoded;
});
