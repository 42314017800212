import {gql} from '@apollo/client';

export default gql`
  mutation allowIpAddresses(
    $lenderId: ID!
    $cidrBlocks: [String!]!
    $name: String
  ) {
    allowIpAddresses(
      lenderId: $lenderId
      cidrBlocks: $cidrBlocks
      name: $name
    ) {
      allowedIpAddresses {
        id
        cidrBlock
        createdAt
        lenderId
        name
        updatedAt
      }
    }
  }
`;
