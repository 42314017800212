import {propOr} from 'ramda';

import getJwt from './getJwt';

const IS_TEST_RUN = process.env.NODE_ENV === 'test';

export default (storage) => {
  if (IS_TEST_RUN) {
    return '00000000-0000-0000-0000-000000000000';
  }

  return propOr(null, 'user_id', getJwt({storage, decode: true}));
};
