export default (borrowersInfo = {}) => {
  const {
    borrowerIsOnLoan,
    firstName,
    lastName,
    coborrowerIsPresent,
    coborrowerFirstName,
    coborrowerLastName,
  } = borrowersInfo || {};

  const result = [];

  if (borrowerIsOnLoan) {
    result.push(`${firstName} ${lastName}`);
  }

  if (coborrowerIsPresent) {
    result.push(`${coborrowerFirstName} ${coborrowerLastName}`);
  }

  return result.join(', ');
};
