import {setDataDogUser} from '@renofi/analytics';

import logger from '../logger';
import execute from '../safeExecute';
import LogRocket from '../logrocket2';
import {getCookie} from '../cookies';
import {getFullName} from '../data';

export const clearBorrower = () => {
  execute(() => {
    logger.debug('analytics.reset');
    window.analytics && window.analytics.reset();
  });
};

export const analyticsIdentify = (id, traits = {}) => {
  const data = {
    ...traits,
    ...gaParams(),
  };
  window?.analytics?.identify(id, data);
};

export const setAnalyticsUser = ({id, ...params} = {}) => {
  const {email, loanType} = params;
  const name = getFullName(params);

  execute(() => {
    logger.debug('analytics.identify', id, params);
    analyticsIdentify(id, params);
    setDataDogUser({id, ...params});
    LogRocket.identify(id, {
      name,
      email,
      loanType,
    });
  });
};

export const sendEvent = (event, options = {}) => {
  execute(() => {
    const data = {
      ...options,
      ...gaParams(),
    };

    logger.log('analytics.track', event, data);
    window.analytics?.track(event, data);
    LogRocket.track(event, data);
    window.gtag &&
      window.gtag('event', 'page_view', {
        send_to: 'AW-802113946',
        dynx_pagetype: `{{${event}}}`,
      });
  });
};

export const pageView = (name, options) => {
  execute(() => {
    logger.debug('analytics.page', name, options);
    window.analytics &&
      window.analytics.page(name, {
        path: window.location.pathname,
        url: window.location.href,
        ...options,
      });
  });
};

export const logRocketSessionURL = () => {
  let url = null;
  execute(() => {
    url = LogRocket.sessionURL;
  });
  return url;
};

function gaClientId() {
  const cookie = getCookie('_ga');
  if (typeof cookie === 'string') {
    const split = cookie.split('.');
    return split?.length > 1 ? split.slice(-2).join('.') : '';
  }
  return '';
}

function gaSessionId() {
  const cookie = getCookie('_ga_RTCZTCBR91');
  if (typeof cookie === 'string') {
    const split = cookie.split('.');
    return split?.length > 2 ? split[2] : '';
  }
  return '';
}

export function gaParams(event) {
  const clientId = gaClientId();
  const sessionId = gaSessionId();

  return {
    ...(clientId ? {gaClientId: clientId} : {}),
    ...(sessionId ? {gaSessionId: sessionId} : {}),
    ...(event ? {gaName: event?.replace(/[/.-]/g, '_')} : {}),
  };
}
