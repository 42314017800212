import styled from '@emotion/styled';

import Flex from '../Flex';

import arrow from './arrow.svg';

export const HeaderWrapper = styled(Flex)({
  display: 'flex',
  width: '100%',

  button: {
    width: 24,
    height: 24,
    background: 'transparent',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '12px 7px',
    backgroundPosition: 'center',
    border: 'none',
    cursor: 'pointer',

    '&.expand': {
      transform: 'rotate(270deg)',
      backgroundImage: `url('${arrow}')`,
    },

    '&.collapse': {
      backgroundImage: `url('${arrow}')`,
    },
  },
});

export const Title = styled.div({
  fontSize: 12,
  color: '#737373',
  width: '100%',
  marginTop: 1,
  display: 'flex',
  alignItems: 'center',
});
