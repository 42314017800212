import * as React from 'react';

import PropTypes from 'prop-types';

const SvgPatio = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="m20.849 21.995-1.292-2.788h.435c.323 0 .596-.24.637-.56l.643-4.926a.642.642 0 1 0-1.274-.166L19.679 16l-.008-.001h-3.855a.322.322 0 0 0-.322.321c0 .177.145.321.322.321h1.662l.57 1.282h-2.554a.643.643 0 0 0 0 1.285h.493l-1.135 2.788h.919l1.135-2.788h1.713l1.292 2.788h.938zm-1.864-4.073-.57-1.282h1.18l-.167 1.282h-.443z"
        fill={color}
      />
      <path
        d="M21.188 9.457c-1.418-2.407-4.872-4.004-8.907-4.159v-.655a.642.642 0 1 0-1.285 0v.655c-4.035.155-7.489 1.752-8.907 4.159a.642.642 0 0 0 .553.969h8.354v3.64H7.141a.643.643 0 1 0 0 1.286h3.855v6.643h1.285v-6.643h3.856a.643.643 0 1 0 0-1.286H12.28v-3.64h8.354a.642.642 0 0 0 .553-.969zM3.962 9.141C5.55 7.57 8.45 6.57 11.639 6.57c3.188 0 6.088 1 7.677 2.57H3.962z"
        fill={color}
      />
      <path
        d="M7.29 19.207h.493a.643.643 0 1 0 0-1.285H5.23l.57-1.282h1.663a.322.322 0 0 0 .321-.32.322.322 0 0 0-.321-.322H3.598l-.318-2.443a.642.642 0 1 0-1.274.166l.642 4.927c.042.32.315.56.637.56h.436l-1.293 2.787h.938l1.292-2.788h1.714l1.14 2.788h.918l-1.14-2.788zm-2.998-1.285H3.85l-.168-1.282h1.18l-.57 1.282z"
        fill={color}
      />
    </svg>
  );
};

SvgPatio.propTypes = {
  color: PropTypes.string,
};
export default SvgPatio;
