import {gql} from '@apollo/client';

export default gql`
  mutation submitProjectDocument(
    $borrowerRole: BorrowerRole
    $checksum: String
    $documentId: ID!
    $documentType: DocumentType!
    $filePassword: String
    $projectId: ID!
    $objectName: String!
    $passwordProtectedFile: Boolean
  ) {
    submitProjectDocument(
      borrowerRole: $borrowerRole
      checksum: $checksum
      documentId: $documentId
      documentType: $documentType
      filePassword: $filePassword
      projectId: $projectId
      objectName: $objectName
      passwordProtectedFile: $passwordProtectedFile
    ) {
      document {
        id
        checksum
        createdAt
        documentType
        fileName
        fileSizeBytes
        objectName
        pdfObjectName
        requestIpAddress
        requestIpLocation
        shareWithLenderAllowed
        shareable
        source
        status
        updatedAt
      }
    }
  }
`;
