import React, {useCallback} from 'react';

import PropTypes from 'prop-types';

import SimpleField from './SimpleField';
import ActiveField from './ActiveField';

const NumberField = ({defaultValue, value, active, onChange, ...props}) => {
  const FieldComponent = active ? ActiveField : SimpleField;
  const changeHandler = useCallback(
    (val) => {
      const newValue = val ? Number(val) : null;
      onChange(newValue);
    },
    [onChange],
  );
  return (
    <FieldComponent
      defaultValue={defaultValue ? defaultValue.toString() : null}
      value={value ? value.toString() : value === 0 ? '0' : ''}
      onChange={changeHandler}
      {...props}
    />
  );
};

NumberField.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  active: PropTypes.bool,
};

export default NumberField;
