export default `
authorId
bodyText
createdAt
entityId
entityType
id
readAt
recipientEmailAddress
source
subject
`;
