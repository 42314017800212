import {gql} from '@apollo/client';

export default gql`
  mutation transmitConfirmedProductToLender(
    $productDetailsConfirmationId: ID!
  ) {
    transmitConfirmedProductToLender(
      productDetailsConfirmationId: $productDetailsConfirmationId
    ) {
      renovationReadyFile {
        id
        createdAt
        lenderId
        project {
          id
          status
        }
      }
    }
  }
`;
