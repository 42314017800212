import {gql} from '@apollo/client';

import {correspondenceMessages, gcddDocument, references} from '../../partials';

export default gql`
  query gcddReviewsByProjectId($projectId: ID!) {
    gcddReviewsByProjectId(projectId: $projectId) {
      assignedToId
      assignedTo {
        email
        firstName
        id
        lastName
        phoneNumber
      }
      correspondenceMessages {
        ${correspondenceMessages}
      }
      projectId
      project {
        city
        id
        renovationCost
        renovationCostToCurrentPropertyValueRatio
        renovationScope
        state
        status
        streetAddressOne
        streetAddressTwo
        tasks {
          id
          contractorInformations {
            email
            id
            removedAt
          }
          taskType
        }
        timeline {
          id
          expectedRenovationCompletionOn
          expectedRenovationStart
        }
        zipCode
      }
      priority
      overallScore
      overallScoreNotes
      insuranceNotes
      referencesNotes
      webReviewsNotes
      licenseNotes
      estimatedCostOfWork
      scopeOfWork
      cancelledAt
      cancellationMessage
      cancellationReason
      completedAt
      complexityLevel
      contractorInformationId
      contractor {
        bbbCheckedAt
        bbbGrade
        bbbListed
        businessName
        city
        id
        insurances {
          ${gcddDocument}
          limitAggregate
          limitEachOccurrence
          limitWorkersCompensation
          workersCompDetailsAvailableInGeneralLiability
        }
        licenses {
          ${gcddDocument}
          number
          state
        }
        secondaryAddress
        state
        streetAddress
        website
        zipCode
      }
      id
      returnedQuestionnaireDocuments {
        contentType
        contractorId
        createdAt
        documentType
        expiryDate
        fileName
        fileSizeBytes
        id
        namesMatch
        number
        objectName
        pdfObjectName
        processedAt
        rejectedAt
        rejectedBySystem
        rejectionDetails
        rejectionReason
        reviewResult
        reviewedAt
        reviewedById
        source
        submittedById
        updatedAt
      }
      questionnaire {
        avgNumberOfRenovationsPerYear
        avgPriceOfProjects
        bankruptcyDetails
        borrowerReminderSentAt
        businessName
        businessType
        businessTypeOtherDetails
        city
        contactFirstName
        contactLastName
        contactTitle
        contractorGeneralLiabilityInsurance {
          ${gcddDocument}
          limitAggregate
          limitEachOccurrence
          limitWorkersCompensation
          workersCompDetailsAvailableInGeneralLiability
        }
        contractorLicense {
          ${gcddDocument}
          number
          state
        }
        contractorReminderSentAt
        contractorWorkersCompInsurance {
          ${gcddDocument}
          limitAggregate
          limitEachOccurrence
          limitWorkersCompensation
        }
        createdAt
        email
        firstOpenedAt
        hasBeenInvolvedInLawsuit
        hasEverFiledForBankruptcy
        hasLicense
        hasTaxLiens
        hasW2Employees
        hasWorkersCompInsurance
        id
        involvedInLawsuitDetails
        lastSentAt
        licenseNumber
        noInsuranceReasons
        noInsuranceReasonsOther
        noLicenseReasons
        noLicenseReasonsOther
        numberOfCompletedRenovations
        officePhoneNumber
        percentageBusinessWithCommercialInLast2y
        percentageBusinessWithResidentialInLast2y
        percentageCompletedRenovationsAmountRanges
        percentageLeadsNeedingExpertDesign
        percentageOfCompletedRenovationsIn30PercentAmountRange
        percentageSalesSpentOnMarketing
        phoneNumber
        propertyState
        references {
          ${references}
        }
        residentialHomeRemodelBlueprintingOption
        residentialHomeRemodelBlueprintingOptionOther
        residentialHomeRemodelRole
        residentialHomeRemodelRoleOther
        returnedAt
        reviewedAt
        score
        secondaryAddress
        signatoryBusinessName
        signatoryName
        signatoryTitle
        signatureDate
        state
        streetAddress
        submittedStep
        subsidaries
        taxLiensDetails
        teamSize
        typicalRenovationProjects
        typicalRenovationProjectsOtherDetails
        updatedAt
        website
        workersCompIncludedInGlid
        yearsAsAContractor
        zipCode
      }
      returnedAt
      webReviewsNotFound
      webReviewsScore
      webReviewsScoreNotes
      webReviewsScoreSubmittedAt
      webReviewsScoreSubmittedById
    }
}
`;
