import {gql} from '@apollo/client';

import {personalInfoAndResidency} from '../../partials';

export default gql`
  mutation submitResidentialAddress(
    $taskId: ID!
    $residentialAddressAttributes: ResidentialAddressInputObject!
    $mailingAddress: Boolean
    $mailingAddressAttributes: MailingAddressInputObject
  ) {
    submitResidentialAddress(
      taskId: $taskId
      residentialAddressAttributes: $residentialAddressAttributes
      mailingAddress: $mailingAddress
      mailingAddressAttributes: $mailingAddressAttributes
    ) {
      personalInfoAndResidency {
        ${personalInfoAndResidency}
      }
    }
  }
`;
