import * as React from 'react';

import PropTypes from 'prop-types';

const SvgBullet = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={15}
      height={12}
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.223 1.38c-.27-.382-.72-.63-1.223-.63L1.75.757c-.825 0-1.5.668-1.5 1.493v7.5c0 .825.675 1.492 1.5 1.492l8.25.008c.502 0 .953-.248 1.223-.63L14.5 6l-3.277-4.62z"
        fill={color}
      />
    </svg>
  );
};

SvgBullet.propTypes = {
  color: PropTypes.string,
};
export default SvgBullet;
