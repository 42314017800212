export default (borrowersInfo = {}) => {
  const {
    borrowerIsOnTitle,
    firstName,
    lastName,
    coborrowerIsOnTitle,
    coborrowerFirstName,
    coborrowerLastName,
  } = borrowersInfo || {};

  const result = [];

  if (borrowerIsOnTitle) {
    result.push(`${firstName} ${lastName}`);
  }

  if (coborrowerIsOnTitle) {
    result.push(`${coborrowerFirstName} ${coborrowerLastName}`);
  }

  return result.join(', ');
};
