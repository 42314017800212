/* global AdobeDC */
import React, {useCallback, useEffect, useMemo, useState} from 'react';

import PropTypes from 'prop-types';
import {pathOr, propOr} from 'ramda';

import Loader from '@renofi/components-internal/src/Loader';
import {useRotateDocument, useSignedUrl} from '@renofi/graphql/src/hooks';
import {basic80} from '@renofi/theme/src/colors';
import {useNotifications} from '@renofi/utilities/src/hooks';
import {useChannel} from '@renofi/utilities/src/pusher';
import {openTab} from '@renofi/utilities/src/window';
import {getObjectName} from '@renofi/utilities';

import {injectAdobeScript} from './utils';
import {
  ANTI_CLOCKWISE,
  DIV_ID,
  PREVIEW_CONFIG,
  VIEWER_CONFIG,
} from './constants';
import {CustomIcons, ExternalIcon, RotateIcon, Wrapper} from './styled';

const getRotateSuccess = pathOr(false, ['data', 'rotateDocument', 'success']);

const AdobePdfViewer = ({pdf, projectId, width = '100%'}) => {
  const {fileName, objectName, pdfObjectName} = pdf;
  const {addNotification} = useNotifications();
  const {loading: isRotating, rotateDocument} = useRotateDocument();
  const {
    fetch: fetchSignedUrl,
    loading,
    signedUrl,
  } = useSignedUrl({lazy: true});

  const [preview, setPreview] = useState(null);
  const isLoading = isRotating || loading;

  useEffect(() => {
    if (!pdfObjectName && !objectName) {
      return;
    }

    const objName = getObjectName(pdf);
    fetchSignedUrl({variables: {objectName: objName}});
  }, [fileName, pdfObjectName, objectName]);

  const fileUrl = useMemo(() => signedUrl?.url, [signedUrl]);

  const onSdkReady = useCallback(async () => {
    if (!window.AdobeDC) {
      return;
    }

    const adobeDCView = new AdobeDC.View(VIEWER_CONFIG);
    const instance = await adobeDCView.previewFile(
      {
        content: {location: {url: fileUrl}},
        metaData: {fileName},
      },
      PREVIEW_CONFIG,
    );
    setPreview(instance);
  }, [fileName, fileUrl]);

  const onDocumentPdfObjectNameUpdated = useCallback((event) => {
    const newObjectName = propOr(null, 'pdf_object_name', event);
    if (!newObjectName) {
      return;
    }

    fetchSignedUrl({variables: {objectName: newObjectName}});
  }, []);

  const onClickRotate = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();

      const apis = await preview.getAPIs();
      const page = (await apis.getCurrentPage()) || 1;
      const result = await rotateDocument({
        variables: {degrees: ANTI_CLOCKWISE, objectName, page},
      });
      const success = getRotateSuccess(result);
      if (success) {
        return addNotification({
          variant: 'success',
          delay: 10000,
          content: `Page ${page} of "${fileName}" is being rotated; this may take a few moments`,
        });
      }
      addNotification({
        variant: 'danger',
        content: `There were problems rotating page ${page} of this document`,
      });
    },
    [objectName, preview],
  );

  useEffect(() => {
    if (!fileUrl) {
      return;
    }

    injectAdobeScript(onSdkReady);
    document.removeEventListener('adobe_dc_view_sdk.ready', onSdkReady);
    document.addEventListener('adobe_dc_view_sdk.ready', onSdkReady);

    return () => {
      document.removeEventListener('adobe_dc_view_sdk.ready', onSdkReady);
    };
  }, [fileUrl]);

  useChannel({
    channelName:
      projectId && `document_pdf_object_name_updated--project=${projectId}`,
    onUpdate: onDocumentPdfObjectNameUpdated,
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <Wrapper maxWidth={width}>
      <div id={DIV_ID} data-private />
      {Boolean(projectId) && (
        <CustomIcons>
          <ExternalIcon
            color={basic80}
            disabled={isLoading}
            onClick={() => openTab(fileUrl)}
            title="Open file in new tab"
          />

          <RotateIcon
            color={basic80}
            disabled={isLoading}
            onClick={onClickRotate}
            title="Rotate file counter-clockwise by 90°"
          />
        </CustomIcons>
      )}
    </Wrapper>
  );
};

AdobePdfViewer.propTypes = {
  pdf: PropTypes.shape({
    fileName: PropTypes.string.isRequired,
    objectName: PropTypes.string.isRequired,
    pdfObjectName: PropTypes.string,
  }).isRequired,
  projectId: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default AdobePdfViewer;
