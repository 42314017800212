import {gql} from '@apollo/client';

export default gql`
  mutation updateNotificationPreference($id: ID!, $emailAddresses: [String!]!) {
    updateNotificationPreference(id: $id, emailAddresses: $emailAddresses) {
      notificationPreference {
        id
        emailAddresses
        notificationType
      }
    }
  }
`;
