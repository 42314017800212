import * as React from 'react';

import PropTypes from 'prop-types';

const SvgUpload = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}>
      <defs>
        <path
          d="M11 0c6.075 0 11 4.925 11 11s-4.925 11-11 11S0 17.075 0 11 4.925 0 11 0zm0 2a9 9 0 1 0 0 18 9 9 0 0 0 0-18zm0 3 5 5-1.41 1.41L12 8.83v8.224h-2V8.83l-2.59 2.58L6 10l5-5z"
          id="upload_svg__a"
        />
      </defs>
      <use
        fill={color}
        xlinkHref="#upload_svg__a"
        transform="translate(1 1)"
        fillRule="evenodd"
      />
    </svg>
  );
};

SvgUpload.propTypes = {
  color: PropTypes.string,
};
export default SvgUpload;
