import {gql} from '@apollo/client';

export default gql`
  query renovationReadyFileTransmittedLenderNotification(
    $renovationReadyFile: RenovationReadyFileInputObject!
  ) {
    renovationReadyFileTransmittedLenderNotification(
      renovationReadyFile: $renovationReadyFile
    ) {
      content
      subject
    }
  }
`;
