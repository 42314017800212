import styled from '@emotion/styled';

import {Flex} from '@renofi/components-internal';

export const Forbidden = styled(Flex)({
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  fontSize: 16,
  lineHeight: '20px',
  fontWeight: '600',
});
