import * as React from 'react';

import PropTypes from 'prop-types';

const SvgJpg = ({color = '#13C427', ...props}) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <mask id="jpg_svg__a" fill="#fff">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.56 0A3.56 3.56 0 0 0 8 3.56v24.88A3.56 3.56 0 0 0 11.56 32h16.88A3.56 3.56 0 0 0 32 28.44V3.539 8l-8-8H11.56z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.56 0A3.56 3.56 0 0 0 8 3.56v24.88A3.56 3.56 0 0 0 11.56 32h16.88A3.56 3.56 0 0 0 32 28.44V3.539 8l-8-8H11.56z"
        fill="#fff"
      />
      <path
        d="m32 3.539 1-.006-2 .006h1zM32 8l-.707.707L33 10.414V8h-1zm-8-8 .707-.707L24.414-1H24v1zM9 3.56A2.56 2.56 0 0 1 11.56 1v-2A4.56 4.56 0 0 0 7 3.56h2zm0 24.88V3.56H7v24.88h2zM11.56 31A2.56 2.56 0 0 1 9 28.44H7A4.56 4.56 0 0 0 11.56 33v-2zm16.88 0H11.56v2h16.88v-2zM31 28.44A2.56 2.56 0 0 1 28.44 31v2A4.56 4.56 0 0 0 33 28.44h-2zm0-24.88v24.88h2V3.56h-2zm0-.015v.015h2v-.027l-2 .012zm0-.006V8h2V3.539h-2zm1.707 3.754-8-8L23.293.707l8 8 1.414-1.414zM11.56 1H24v-2H11.56v2z"
        fill="#EBEBEB"
        mask="url(#jpg_svg__a)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m24 0 8 8h-6a2 2 0 0 1-2-2V0z"
        fill="#A6ADBC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 8h5v5l-5-5z"
        fill="#D2D6DE"
      />
      <rect y={3} width={21} height={10} rx={2} fill={color} />
      <path
        d="M5.871 9.293c0 .255-.045.495-.137.719a1.46 1.46 0 0 1-.406.562c-.19.164-.42.292-.687.383-.269.089-.6.133-.996.133-.269 0-.521-.01-.758-.031a5.178 5.178 0 0 1-.625-.082v-1.18h.14c.125.047.256.091.391.133.135.039.309.058.52.058.273 0 .483-.036.628-.11a.656.656 0 0 0 .317-.304c.06-.127.094-.267.101-.418.008-.153.012-.349.012-.586V6.254H3.04v-1.07h2.832v4.109zm6.215-2.273c0 .26-.046.515-.137.765a1.7 1.7 0 0 1-.39.625c-.232.23-.491.402-.778.52-.284.117-.638.175-1.062.175h-.934V11h-1.5V5.184h2.469c.37 0 .68.032.934.097.255.063.48.158.675.285.235.154.413.35.535.59.125.24.188.528.188.864zm-1.55.035a.68.68 0 0 0-.134-.422.717.717 0 0 0-.308-.25 1.357 1.357 0 0 0-.457-.102 11.858 11.858 0 0 0-.594-.011h-.258v1.742h.43c.255 0 .465-.016.629-.047a.895.895 0 0 0 .418-.188.708.708 0 0 0 .207-.297 1.21 1.21 0 0 0 .066-.425zm7.76 3.613c-.26.102-.605.203-1.034.305-.43.099-.858.148-1.285.148-.99 0-1.765-.268-2.325-.805-.56-.539-.84-1.282-.84-2.23 0-.904.283-1.632.848-2.184.565-.554 1.353-.832 2.363-.832.383 0 .748.035 1.094.106a4.82 4.82 0 0 1 1.156.41v1.363h-.168c-.072-.054-.18-.131-.32-.23a3.716 3.716 0 0 0-.406-.258 2.653 2.653 0 0 0-.531-.215c-.2-.06-.414-.09-.641-.09-.266 0-.507.04-.723.117-.216.079-.41.198-.582.36a1.657 1.657 0 0 0-.39.597 2.27 2.27 0 0 0-.14.832c0 .644.17 1.136.51 1.477.342.341.846.512 1.513.512a4.911 4.911 0 0 0 .378-.016v-1.14h-1.16V7.797h2.684v2.871z"
        fill="#fff"
      />
      <path
        d="M15.32 23.496a.922.922 0 0 1 1.274-.03l1.155 1.127a.355.355 0 0 0 .534-.06l3.2-5.274a.9.9 0 0 1 1.51-.03l4.86 7.527c.385.592-.03 1.392-.74 1.392H12.978c-.8 0-1.185-.948-.652-1.511l2.993-3.14z"
        fill={color}
      />
      <circle
        r={2.281}
        transform="matrix(-1 0 0 1 15.32 18.282)"
        fill={color}
      />
    </svg>
  );
};

SvgJpg.propTypes = {
  color: PropTypes.string,
};
export default SvgJpg;
