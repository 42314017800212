import styled from '@emotion/styled';
import {Text} from 'rebass';

import {maxSmall} from '@renofi/theme/src/breakpoints';

import Flex from '../Flex';
import Link from '../Link';

export const Wrapper = styled(Flex)(
  {
    flexDirection: 'column',
    alignItems: 'center',
    [maxSmall]: {
      width: '100%',
      minWidth: 'auto',
      marginBottom: 30,
      '> div': {
        textAlign: 'center',
        marginBottom: 20,
      },
    },
  },
  ({maxWidth}) => ({
    maxWidth: maxWidth || 640,
    [maxSmall]: {
      maxWidth: '100%',
    },
  }),
);

export const LegalMessage = styled(Text)(
  {
    margin: '10px 0',
    textAlign: 'center',
    fontSize: 14,
    lineHeight: '18px',
    opacity: 0.7,
  },
  ({maxWidth}) => ({
    maxWidth: maxWidth || 600,
    [maxSmall]: {
      width: '100%',
    },
  }),
);

export const LicensingLink = styled(Link)({
  [maxSmall]: {
    display: 'block',
  },
});
