import {gql} from '@apollo/client';

export default gql`
  query userActions($userId: ID!) {
    userActions(userId: $userId) {
      actionType
      payload
      performedAt
      performer {
        firstName
        lastName
      }
    }
  }
`;
