import React from 'react';

import PropTypes from 'prop-types';

import PhoneIcon from '@renofi/icons/src/Phone';
import EmailIcon from '@renofi/icons/src/Email';
import {dangerRegular} from '@renofi/theme/src/colors';
import RenofiIcon from '@renofi/icons/src/Renofi';

import {
  StyledCard,
  StyledTitle,
  StyledContent,
  StyledContainer,
  StyledInfo,
  ButtonsSection,
  IconContainer,
  StyledName,
  StyledDescription,
  StyledButton,
} from './ContactCard.styles';

const ContactCard = ({
  description,
  name,
  onEmailClick,
  onPhoneClick,
  isScheduleACallVisible,
}) => {
  return (
    <StyledCard styledTitle={false} radius={16}>
      <StyledTitle>Got questions?</StyledTitle>
      <StyledContent>
        <StyledContainer>
          <IconContainer>
            <RenofiIcon color={dangerRegular} width={32} />
          </IconContainer>
          <StyledInfo>
            <StyledDescription>{description}</StyledDescription>
            <StyledName>{name}</StyledName>
          </StyledInfo>
        </StyledContainer>
        <ButtonsSection>
          <StyledButton onClick={onEmailClick}>
            <EmailIcon color="#FF5253" />
            <span>Email</span>
          </StyledButton>
          {isScheduleACallVisible && (
            <StyledButton onClick={onPhoneClick}>
              <PhoneIcon color="#FF5253" />
              <span>Schedule a call</span>
            </StyledButton>
          )}
        </ButtonsSection>
      </StyledContent>
    </StyledCard>
  );
};

ContactCard.propTypes = {
  description: PropTypes.string,
  name: PropTypes.string,
  onEmailClick: PropTypes.func,
  onPhoneClick: PropTypes.func,
  isScheduleACallVisible: PropTypes.bool,
};

ContactCard.defaultProps = {
  description: 'Contact your dedicated RenoFi Advisor today.',
  isScheduleACallVisible: true,
};

export default ContactCard;
