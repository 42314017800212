import {always, propOr, range, update} from 'ramda';

import {
  compose,
  withHandlers,
  withProps,
  withState,
} from '@renofi/recompose/src';
import omitProps from '@renofi/utilities/src/omitProps';

import withAppraisalComparableSales from './withAppraisalComparableSales';

const emptyUrls = range(0, 5).map(always(''));

export default () =>
  compose(
    withAppraisalComparableSales(),
    withProps(({appraisalComparableSales}) => ({
      urls: propOr([], 'urls')(appraisalComparableSales),
    })),
    withState(
      'comparableSales',
      'setComparableSales',
      ({appraisalComparableSales, urls}) => {
        return appraisalComparableSales
          ? emptyUrls.map((url, i) => urls[i] || url)
          : null;
      },
    ),
    withHandlers({
      clearUrls:
        ({setComparableSales}) =>
        () => {
          setComparableSales([]);
        },
      initializeUrls:
        ({setComparableSales}) =>
        () => {
          setComparableSales(emptyUrls);
        },
      updateUrls:
        ({setComparableSales}) =>
        (comparableSales) => {
          setComparableSales(comparableSales);
        },
      updateUrl:
        ({comparableSales, setComparableSales}) =>
        (url, idx) => {
          setComparableSales(update(idx, url, comparableSales));
        },
    }),
    omitProps(['appraisalComparableSales']),
  );
