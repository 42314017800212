import {ApolloLink, Observable} from '@apollo/client';
import {RetryLink} from '@apollo/client/link/retry';
import {find, pipe, propEq, propOr} from 'ramda';

export default ({mockQueries = []} = {}) => {
  if (!mockQueries.length) {
    return [];
  }

  return [
    new RetryLink().split(
      (operation) => {
        return mockQueries.some(({operationName, validator}) => {
          const isOpName = operationName === operation.operationName;
          return !!validator ? isOpName && validator(operation) : isOpName;
        });
      },
      new ApolloLink((operation, forward) => {
        return new Observable((observer) => {
          const data = pipe(
            find(propEq('operationName', operation.operationName)),
            propOr({}, 'data'),
          )(mockQueries);
          const body = {data};
          const response = new Response(JSON.stringify(body), {status: 200});
          operation.setContext({response});
          observer.next(body);
          observer.complete();
        });
      }),
      new ApolloLink((operation, forward) => forward(operation)),
    ),
  ];
};
