import React from 'react';

import PropTypes from 'prop-types';
import {DayPicker} from 'react-day-picker';

import {getUtcDate} from '@renofi/utilities/src/dates';

import Wrapper from './components/Wrapper';

import 'react-day-picker/dist/style.css';

const DateFieldPopup = ({
  popupCss,
  position,
  onClose,
  disabledDays,
  onSelect,
  modifiersStyles,
  rect,
  local,
  fixed,
  value,
  datePickerProps,
}) => {
  const activeDate = getUtcDate(value);
  const {x, height, top, bottom} = rect;

  const popupProps = local
    ? {local}
    : {fixed, left: x, topRect: top + height, bottomRect: bottom - height};

  return (
    <Wrapper
      {...popupProps}
      {...position}
      popupCss={popupCss}
      onClickOutside={() => onClose(false)}>
      <DayPicker
        mode="single"
        required
        disabled={disabledDays}
        onSelect={onSelect}
        selected={activeDate}
        defaultMonth={activeDate}
        modifiersStyles={modifiersStyles}
        {...datePickerProps}
      />
    </Wrapper>
  );
};

DateFieldPopup.propTypes = {
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  rect: PropTypes.shape({
    x: PropTypes.number,
    top: PropTypes.number,
    height: PropTypes.number,
    bottom: PropTypes.number,
  }),
  local: PropTypes.bool,
  fixed: PropTypes.bool,
  onSelect: PropTypes.func,
  position: PropTypes.shape({
    top: PropTypes.bool,
    bottom: PropTypes.bool,
  }),
  onClose: PropTypes.func,
  popupCss: PropTypes.object,
  disabledDays: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        after: PropTypes.instanceOf(Date),
        before: PropTypes.instanceOf(Date),
      }),
    ),
    PropTypes.shape({
      after: PropTypes.instanceOf(Date),
      before: PropTypes.instanceOf(Date),
    }),
  ]),
  modifiersStyles: PropTypes.shape({
    disabled: PropTypes.shape({
      color: PropTypes.string,
    }),
  }),
  datePickerProps: PropTypes.object,
};

export default DateFieldPopup;
