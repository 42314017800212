import * as React from 'react';

import PropTypes from 'prop-types';

const SvgCsv = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <mask id="csv_svg__a" fill="#fff">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.56 0A3.56 3.56 0 0 0 8 3.56v24.88A3.56 3.56 0 0 0 11.56 32h16.88A3.56 3.56 0 0 0 32 28.44V3.539 8l-8-8H11.56z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.56 0A3.56 3.56 0 0 0 8 3.56v24.88A3.56 3.56 0 0 0 11.56 32h16.88A3.56 3.56 0 0 0 32 28.44V3.539 8l-8-8H11.56z"
        fill="#fff"
      />
      <path
        d="m32 3.539 1-.006-2 .006h1zM32 8l-.707.707L33 10.414V8h-1zm-8-8 .707-.707L24.414-1H24v1zM9 3.56A2.56 2.56 0 0 1 11.56 1v-2A4.56 4.56 0 0 0 7 3.56h2zm0 24.88V3.56H7v24.88h2zM11.56 31A2.56 2.56 0 0 1 9 28.44H7A4.56 4.56 0 0 0 11.56 33v-2zm16.88 0H11.56v2h16.88v-2zM31 28.44A2.56 2.56 0 0 1 28.44 31v2A4.56 4.56 0 0 0 33 28.44h-2zm0-24.88v24.88h2V3.56h-2zm0-.015v.015h2v-.027l-2 .012zm0-.006V8h2V3.539h-2zm1.707 3.754-8-8L23.293.707l8 8 1.414-1.414zM11.56 1H24v-2H11.56v2z"
        fill="#EBEBEB"
        mask="url(#csv_svg__a)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m24 0 8 8h-6a2 2 0 0 1-2-2V0z"
        fill="#A6ADBC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 8h5v5l-5-5z"
        fill="#D2D6DE"
      />
      <rect y={3} width={21} height={10} rx={2} fill="#7A0B0B" />
      <path
        d="M5.078 11.113a3.63 3.63 0 0 1-1.2-.191 2.56 2.56 0 0 1-.94-.57 2.574 2.574 0 0 1-.614-.946 3.677 3.677 0 0 1-.215-1.308c0-.461.07-.88.207-1.254a2.68 2.68 0 0 1 .602-.965c.253-.258.565-.457.937-.598.375-.14.784-.21 1.227-.21.245 0 .465.014.66.042a3.552 3.552 0 0 1 1.02.273c.143.06.268.117.375.169v1.41h-.172a6.753 6.753 0 0 0-.277-.223 3.473 3.473 0 0 0-.375-.254 2.607 2.607 0 0 0-.465-.21 1.78 1.78 0 0 0-1.137.016 1.44 1.44 0 0 0-.527.327 1.642 1.642 0 0 0-.375.59 2.492 2.492 0 0 0-.141.89c0 .365.05.67.152.915.104.244.235.437.39.578.16.143.337.246.532.308.196.06.388.09.578.09.183 0 .362-.027.54-.082a2.09 2.09 0 0 0 .495-.223 3.27 3.27 0 0 0 .356-.242c.11-.086.2-.16.27-.222h.156v1.39c-.146.065-.285.127-.418.184a3.444 3.444 0 0 1-.418.148c-.19.055-.369.097-.535.125a4.269 4.269 0 0 1-.688.043zm7.817-1.941c0 .573-.244 1.039-.73 1.398-.485.357-1.144.535-1.977.535-.482 0-.903-.041-1.262-.125a6.66 6.66 0 0 1-1.004-.324V9.262h.164c.31.247.656.437 1.039.57.385.133.755.2 1.11.2.09 0 .21-.009.359-.024.148-.016.27-.042.363-.078a.88.88 0 0 0 .281-.176c.076-.07.114-.175.114-.313a.414.414 0 0 0-.165-.328c-.106-.093-.264-.165-.472-.215-.219-.052-.45-.1-.695-.144a5.57 5.57 0 0 1-.684-.176c-.49-.159-.843-.374-1.059-.644-.213-.274-.32-.612-.32-1.016 0-.542.242-.983.727-1.324.487-.344 1.112-.516 1.875-.516.382 0 .76.038 1.132.113.375.073.7.166.973.278v1.34h-.16a3.05 3.05 0 0 0-.863-.47 2.923 2.923 0 0 0-1.04-.19c-.124 0-.25.009-.374.027a1.376 1.376 0 0 0-.356.094.674.674 0 0 0-.262.18.383.383 0 0 0-.109.269.41.41 0 0 0 .176.355c.117.081.338.155.664.223l.613.129c.198.041.41.099.637.172.445.145.773.345.984.597.214.25.32.576.32.977zm6.304-3.988L17.08 11H15.4l-2.122-5.816h1.575l1.406 4.086 1.406-4.086H19.2z"
        fill="#fff"
      />
      <rect x={12} y={17} width={6} height={2} rx={0.5} fill="#C4C4C4" />
      <rect x={12} y={21} width={6} height={2} rx={0.5} fill="#C4C4C4" />
      <rect x={12} y={25} width={6} height={2} rx={0.5} fill="#C4C4C4" />
      <rect x={22} y={17} width={6} height={2} rx={0.5} fill="#C4C4C4" />
      <rect x={22} y={21} width={6} height={2} rx={0.5} fill="#C4C4C4" />
      <rect x={22} y={25} width={6} height={2} rx={0.5} fill="#C4C4C4" />
      <rect x={19} y={17} width={2} height={2} rx={0.5} fill="#C4C4C4" />
      <rect x={19} y={21} width={2} height={2} rx={0.5} fill="#C4C4C4" />
      <rect x={19} y={25} width={2} height={2} rx={0.5} fill="#C4C4C4" />
    </svg>
  );
};

SvgCsv.propTypes = {
  color: PropTypes.string,
};
export default SvgCsv;
