import React, {useCallback, useState} from 'react';

import PropTypes from 'prop-types';
import {useDropzone} from 'react-dropzone';
import bytes from 'bytes';

import {useTheme} from '@renofi/theme/src';
import getMimeTypes from '@renofi/utilities/src/mimeTypes';
import noop from '@renofi/utilities/src/noop';
import Paperclip from '@renofi/icons/src/Paperclip';

import Link from '../../Link';
import Text from '../../Text';

import {Container} from './styled';

const defaultFileSize = '256MB';
const defaultAcceptList = getMimeTypes();

const DropZone = ({
  accept,
  disabled,
  fileName,
  maxFiles,
  maxFileSize,
  multiple,
  onAcceptFiles,
  onRejectFiles,
}) => {
  const {styles} = useTheme('dropZone');
  const [hover, setHover] = useState(false);
  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles?.length && !disabled) {
      onAcceptFiles(acceptedFiles);
    }
    if (rejectedFiles?.length && !disabled) {
      onRejectFiles(rejectedFiles);
    }
  });
  const acceptList = accept?.length ? accept : defaultAcceptList;
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    disabled: disabled,
    accept: acceptList.join(', '),
    maxFiles,
    maxSize: bytes(maxFileSize),
    multiple,
  });
  const handlers = getRootProps();

  return (
    <Container
      px={24}
      py={16}
      disabled={disabled}
      active={isDragActive}
      hover={hover}
      {...handlers}
      css={styles}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}>
      <input {...getInputProps()} />
      <Paperclip width={16} height={16} />
      <Text pl="5px" fontSize={14} color="#8C8C8C">
        <Link
          hover={hover}
          disabled={disabled}
          onClick={(e) => {
            e.preventDefault();
          }}>
          Add {fileName}
        </Link>{' '}
        or drop file here
      </Text>
    </Container>
  );
};

DropZone.propTypes = {
  accept: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  fileName: PropTypes.string,
  maxFiles: PropTypes.number,
  maxFileSize: PropTypes.string,
  multiple: PropTypes.bool,
  onAcceptFiles: PropTypes.func.isRequired,
  onRejectFiles: PropTypes.func.isRequired,
};

DropZone.defaultProps = {
  accept: defaultAcceptList,
  multiple: true,
  disabled: false,
  fileName: 'file',
  maxFiles: 0,
  maxFileSize: defaultFileSize,
  onRejectFiles: noop,
};

export default DropZone;
