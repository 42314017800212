import * as React from 'react';

import PropTypes from 'prop-types';

import {basic20} from '@renofi/theme/src/colors';

const SvgCrossCircle = ({color, width, ...props}) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M12 1C5.928 1 1 5.928 1 12s4.928 11 11 11 11-4.928 11-11S18.072 1 12 1zm3.111 15.667L12 13.556l-3.111 3.11-1.556-1.555L10.444 12l-3.11-3.111 1.555-1.556L12 10.444l3.111-3.11 1.556 1.555L13.556 12l3.11 3.111-1.555 1.556z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

SvgCrossCircle.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
};

SvgCrossCircle.defaultProps = {
  color: basic20,
  width: 24,
};

export default SvgCrossCircle;
