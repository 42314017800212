import React from 'react';

import PropTypes from 'prop-types';

import {isMobile} from '@renofi/theme/src/breakpoints';

import Box from './Box';

const getMaxWidth = ({narrow, wide}) => {
  if (isMobile()) {
    return '100%';
  }
  if (narrow) {
    return 1140;
  }
  if (wide) {
    return 1740;
  }
  return '95%';
};

const Container = ({
  fluid = false,
  narrow = false,
  wide = false,
  width = 1,
  maxWidth = getMaxWidth({narrow, wide}),
  ...props
}) => (
  <Box
    px={[2, 0]}
    width={width}
    css={{
      ...(fluid ? {} : {maxWidth}),
    }}
    {...props}
  />
);

Container.propTypes = {
  width: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.number,
  ]),
  maxWidth: PropTypes.number,
  fluid: PropTypes.bool,
  narrow: PropTypes.bool,
  wide: PropTypes.bool,
};

export default Container;
