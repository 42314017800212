import {gql} from '@apollo/client';

export default gql`
  mutation markTaskAsOutstanding($taskId: ID!) {
    markTaskAsOutstanding(taskId: $taskId) {
      task {
        id
        borrowerFinishedAt
        status
      }
    }
  }
`;
