import {gql} from '@apollo/client';

import {task, softCreditCheck} from '../../partials';

export default gql`
  mutation softCreditCheckFinishedByPrimaryApplicant(
    $softCreditCheckId: ID!
    $selfReportedCreditScore: CreditScoreEnum
  ) {
    softCreditCheckFinishedByPrimaryApplicant(
      softCreditCheckId: $softCreditCheckId
      selfReportedCreditScore: $selfReportedCreditScore
    ) {
        softCreditCheck {
          ${softCreditCheck}
        }
        task {
          ${task}
        }
    }
  }
`;
