import {gql} from '@apollo/client';

import {documentWithAllProps} from '../../partials';

export default gql`
  mutation submitDocument(
    $borrowerRole: BorrowerRole
    $taskId: ID!
    $documentId: ID!
    $documentType: DocumentType
    $filePassword: String
    $checksum: String
    $objectName: String!
    $passwordProtectedFile: Boolean
  ) {
    submitDocument(
      borrowerRole: $borrowerRole
      taskId: $taskId
      documentId: $documentId
      documentType: $documentType
      checksum: $checksum
      filePassword: $filePassword
      objectName: $objectName
      passwordProtectedFile: $passwordProtectedFile
    ) {
      document {
        ${documentWithAllProps}
        rejectedById
        rejectedBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
