import React from 'react';

import PropTypes from 'prop-types';
import {propOr} from 'ramda';

import SignedUrlImage from '@renofi/components-internal/src/SignedUrlImage';

function LenderLogo({lender, ...props}) {
  const hardcodedUrl = propOr(null, 'logoUrl', lender);
  const objectName = propOr(null, 'logoObjectName', lender);
  const alt = propOr('The lender', 'name', lender);
  const shared = {alt, title: alt};

  return objectName ? (
    <SignedUrlImage {...shared} objectName={objectName} {...props} />
  ) : (
    <img {...shared} src={hardcodedUrl} {...props} />
  );
}

LenderLogo.propTypes = {
  lender: PropTypes.shape({
    logoObjectName: PropTypes.string,
    logoUrl: PropTypes.string,
  }),
};

export default LenderLogo;
