import {gql} from '@apollo/client';

export default gql`
  query propertyDetails {
    propertyDetails @client {
      id
      additionalInformation
      additionalInformationPresent
      basementSquareFootage
      basementState
      bedroomsInBasement
      bedroomsTotal
      city
      coolingTypes
      county
      fireplaceTypes
      fullBathroomsInBasement
      fullBathroomsTotal
      garagesAttached
      garagesDetached
      halfBathroomsInBasement
      halfBathroomsTotal
      heatingTypes
      lat
      lng
      mapPhotoUrl
      noteForAppraiser
      noteForAppraiserPresent
      previousRenovationsDescription
      previousRenovationsDescriptionPresent
      propertyType
      propertyTypeOther
      rentalIncome
      rentalIncomeCollectedOnPrimaryResidence
      state
      streetAddressOne
      streetAddressTwo
      streetViewPhotoUrl
      totalSquareFootage
      yearBuilt
      yearPurchased
      zipCode
    }
  }
`;
