import {gql} from '@apollo/client';

export default gql`
  query underwritingRequest {
    underwritingRequest @client {
      requirements {
        condition1
        condition2
        condition3
        condition4
      }
      personalDetails {
        firstName
        lastName
        phoneNumber
        email
        streetAddressOne
        streetAddressTwo
        zipCode
        state
        city
      }
      eligibilityStats {
        creditScore
        assets
        reserves
        dtiRatio
      }
      loanDetails {
        currentHomeValue
        currentMortgageBalance
        lenderLoanNumber
        loanValue
        renovationCost
        postRenovationHomeValue
        secondMortgageBalance
        intendedStartDate
        returnDeadline
      }
      lenderAccountExecutiveDetails {
        lenderAccountExecutiveEmail
        lenderAccountExecutiveFirstName
        lenderAccountExecutiveLastName
        lenderAccountExecutivePhone
      }
      mloDetails {
        mloFirstName
        mloLastName
        mloEmail
        mloPhone
      }
    }
  }
`;
