export default `
additionalProperties {
  annualInsurancePremium
  annualMortgageTaxes
  annualPrivateMortgageInsurance
  city
  coborrowerOwned
  county
  currentOccupancy
  currentOccupancyOther
  documentIds
  existingMortgageLoan
  homeownersAssociationFee
  homeownersAssociationFeesIncluded
  homeownersAssociationFeesExist
  id
  intendedOccupancy
  marketValue
  mortgageAccountNumber
  mortgageBalance
  mortgageBalanceSecondLien
  mortgageBalanceThirdLien
  mortgageCompany
  mortgageCompanyOther
  mortgageInterestRate
  mortgageMonthlyPayment
  mortgageStatementDate
  primaryBorrowerOwned
  propertyStatus
  propertyType
  rentalIncome
  state
  streetAddressOne
  streetAddressTwo
  taskId
  taxAndInsuranceIncluded
  yearPurchased
  zipCode
}
additionalPropertyNumber
additionalPropertyPresent
id
soldProperties {
  city
  coborrowerOwned
  county
  id
  owner
  primaryBorrowerOwned
  propertyType
  state
  streetAddressOne
  streetAddressTwo
  taskId
  zipCode
  soldDate
}
soldPropertyNumber
soldPropertyPresent
subjectProperty {
  annualInsurancePremium
  annualMortgageTaxes
  coborrowerOwned
  documentIds
  existingMortgageLoan
  homeownersAssociationFee
  homeownersAssociationFeesIncluded
  homeownersAssociationFeesExist
  id
  mortgageAccountNumber
  mortgageBalance
  mortgageCompany
  mortgageCompanyOther
  mortgageInterestRate
  mortgageMonthlyPayment
  mortgageStatementDate
  primaryBorrowerOwned
  propertyType
  taskId
  taxAndInsuranceIncluded
  yearPurchased
}
  taskId
`;
