import * as React from 'react';

import PropTypes from 'prop-types';

const SvgPng = ({color = '#00A0FF', ...props}) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <mask id="png_svg__a" fill="#fff">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.56 0A3.56 3.56 0 0 0 8 3.56v24.88A3.56 3.56 0 0 0 11.56 32h16.88A3.56 3.56 0 0 0 32 28.44V3.539 8l-8-8H11.56z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.56 0A3.56 3.56 0 0 0 8 3.56v24.88A3.56 3.56 0 0 0 11.56 32h16.88A3.56 3.56 0 0 0 32 28.44V3.539 8l-8-8H11.56z"
        fill="#fff"
      />
      <path
        d="m32 3.539 1-.006-2 .006h1zM32 8l-.707.707L33 10.414V8h-1zm-8-8 .707-.707L24.414-1H24v1zM9 3.56A2.56 2.56 0 0 1 11.56 1v-2A4.56 4.56 0 0 0 7 3.56h2zm0 24.88V3.56H7v24.88h2zM11.56 31A2.56 2.56 0 0 1 9 28.44H7A4.56 4.56 0 0 0 11.56 33v-2zm16.88 0H11.56v2h16.88v-2zM31 28.44A2.56 2.56 0 0 1 28.44 31v2A4.56 4.56 0 0 0 33 28.44h-2zm0-24.88v24.88h2V3.56h-2zm0-.015v.015h2v-.027l-2 .012zm0-.006V8h2V3.539h-2zm1.707 3.754-8-8L23.293.707l8 8 1.414-1.414zM11.56 1H24v-2H11.56v2z"
        fill="#EBEBEB"
        mask="url(#png_svg__a)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m24 0 8 8h-6a2 2 0 0 1-2-2V0z"
        fill="#A6ADBC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 8h5v5l-5-5z"
        fill="#D2D6DE"
      />
      <rect y={3} width={21} height={10} rx={2} fill={color} />
      <path
        d="M6.477 7.02c0 .26-.046.515-.137.765a1.701 1.701 0 0 1-.39.625c-.233.23-.492.402-.778.52-.284.117-.638.175-1.063.175h-.933V11h-1.5V5.184h2.469c.37 0 .68.032.933.097.255.063.48.158.676.285.234.154.413.35.535.59.125.24.188.528.188.864zm-1.551.035a.68.68 0 0 0-.133-.422.717.717 0 0 0-.309-.25 1.356 1.356 0 0 0-.457-.102 11.857 11.857 0 0 0-.593-.011h-.258v1.742h.43c.255 0 .464-.016.628-.047a.894.894 0 0 0 .418-.188.708.708 0 0 0 .207-.297c.045-.117.067-.259.067-.425zM12.832 11h-1.445L8.918 7.008V11H7.543V5.184h1.793l2.121 3.332V5.184h1.375V11zm6.629-.332a8.04 8.04 0 0 1-1.035.305c-.43.099-.858.148-1.285.148-.99 0-1.765-.268-2.325-.805-.56-.539-.84-1.282-.84-2.23 0-.904.283-1.632.848-2.184.565-.554 1.353-.832 2.364-.832.382 0 .747.035 1.093.106a4.82 4.82 0 0 1 1.157.41v1.363h-.168c-.073-.054-.18-.131-.32-.23a3.705 3.705 0 0 0-.407-.258 2.656 2.656 0 0 0-.531-.215 2.22 2.22 0 0 0-.64-.09c-.267 0-.507.04-.724.117-.216.079-.41.198-.582.36a1.655 1.655 0 0 0-.39.597 2.27 2.27 0 0 0-.14.832c0 .644.17 1.136.51 1.477.342.341.846.512 1.513.512a4.911 4.911 0 0 0 .378-.016v-1.14h-1.16V7.797h2.684v2.871z"
        fill="#fff"
      />
      <path
        d="M15.32 23.496a.922.922 0 0 1 1.274-.03l1.155 1.127a.355.355 0 0 0 .534-.06l3.2-5.274a.9.9 0 0 1 1.51-.03l4.86 7.527c.385.592-.03 1.392-.74 1.392H12.978c-.8 0-1.185-.948-.652-1.511l2.993-3.14z"
        fill={color}
      />
      <circle
        r={2.281}
        transform="matrix(-1 0 0 1 15.32 18.282)"
        fill={color}
      />
    </svg>
  );
};

SvgPng.propTypes = {
  color: PropTypes.string,
};
export default SvgPng;
