import {gql} from '@apollo/client';

export default gql`
  mutation requestAdditionalDocuments(
    $projectId: ID!
    $additionalDocumentRequests: [AdditionalDocumentRequestInputObject!]!
  ) {
    requestAdditionalDocuments(
      projectId: $projectId
      additionalDocumentRequests: $additionalDocumentRequests
    ) {
      tasks {
        id
        project {
          id
        }
        title
        details
        informationKind
        facet
        taskType
        canHaveDocument
        documents {
          id
        }
        status
      }
    }
  }
`;
