import {gql} from '@apollo/client';

export default gql`
  mutation markAppraisalReviewAsFinished($appraisalOrderId: ID, $id: ID!) {
    markAppraisalReviewAsFinished(
      appraisalOrderId: $appraisalOrderId
      id: $id
    ) {
      projectTimestamps {
        id
        appraisalReportReviewFinishedAt
      }
    }
  }
`;
