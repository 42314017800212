import styled from '@emotion/styled';

import {basic55, renofiBlue} from '@renofi/theme/src/colors';

import CommonBadge from '../Badge';
import Box from '../Box';
import Flex from '../Flex';

export const Wrapper = styled(Flex)({
  overflow: 'hidden',
  alignItems: 'center',
  justifyContent: 'space-around',
});

export const Item = styled(Box)(({active, activeColor}) => ({
  color: active ? renofiBlue : basic55,
  fontSize: 14,
  lineHeight: '17px',
  textAlign: 'center',
  textTransform: 'uppercase',
  cursor: 'pointer',
  fontWeight: active ? 700 : 'normal',
  borderBottom: active ? `4px solid ${activeColor}` : 'none',
  paddingLeft: 8,
  paddingRight: 8,
  paddingBottom: active ? 12 : 16,
}));

export const Badge = styled(CommonBadge)({
  border: 'none',
  fontSize: 10,
  position: 'relative',
  bottom: 4,
  left: 10,
});
