import {propOr} from 'ramda';

import useToggledQuery from '../useToggledQuery';
import {GET_RENOVATION_UNDERWRITING_REQUEST} from '../../queries';

export default function useRenovationUnderwritingRequestById({
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
}) {
  const response = useToggledQuery({
    lazy,
    query: GET_RENOVATION_UNDERWRITING_REQUEST,
    options: {
      fetchPolicy,
      ...options,
    },
  });

  const {data, error, loading, fetch, startPolling, stopPolling} = response;
  const renovationUnderwritingRequest = propOr(
    null,
    'renovationUnderwritingRequestById',
    data,
  );

  return {
    error,
    fetch,
    renovationUnderwritingRequest,
    loading,
    startPolling,
    stopPolling,
  };
}
