import {gql} from '@apollo/client';

export default gql`
  mutation requestSecondFeasibilityReview(
    $sourceFeasibilityReviewId: ID!
    $feasibilityReviewerId: ID
    $documentIds: [ID!]
    $propertyPhotoIds: [ID!]
  ) {
    requestSecondFeasibilityReview(
      sourceFeasibilityReviewId: $sourceFeasibilityReviewId
      feasibilityReviewerId: $feasibilityReviewerId
      documentIds: $documentIds
      propertyPhotoIds: $propertyPhotoIds
    ) {
      feasibilityReview {
        id
        changeOrderProcedureApproved
        changeOrderProcedureApprovedSource
        changeOrderProcedureNotes
        clearDescriptionOfWorkApproved
        clearDescriptionOfWorkApprovedSource
        clearDescriptionOfWorkNotes
        clearGuaranteeApproved
        clearGuaranteeApprovedSource
        clearGuaranteeNotes
        clearGuaranteeRating
        clearGuaranteeRatingSource
        completedAt
        complexityGrading
        contactInfoApproved
        contactInfoApprovedSource
        contactInfoNotes
        contractTimelineApproved
        contractTimelineApprovedSource
        contractTimelineNotes
        contractTimelineRating
        contractTimelineRatingSource
        costEstimateSuppliedApproved
        costEstimateSuppliedApprovedSource
        costEstimateSuppliedNotes
        createdAt
        documents {
          id
          checksum
          createdAt
          documentType
          fileName
          objectName
          pdfObjectName
          requestIpAddress
          requestIpLocation
          shareWithLenderAllowed
          shareable
          updatedAt
        }
        estimatedDuration
        estimatedDurationSource
        feasibilityStudyItems {
          id
          addedById
          addedBy {
            id
          }
          adjustedCost
          cost
          descriptionOfWork
          reviewerNotes
          reviewerRating
          source
          type
        }
        generalCommentaryAndNotes
        openedAt
        propertyPhotos {
          id
          createdAt
          homeownerDescription
          objectName
          updatedAt
        }
        requestedAt
        returnedAt
        reviewerId
        reviewer {
          id
        }
        termsOfPaymentApproved
        termsOfPaymentApprovedSource
        termsOfPaymentNotes
        termsOfPayments {
          amount
          comment
          specialMaterialCosts
        }
        uncompletedDetails
        uncompletedReason
        updatedAt
      }
    }
  }
`;
