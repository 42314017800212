import {propOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import {GET_PROJECT_TIMELINE} from '../../queries';
import useToggledQuery from '../useToggledQuery';

export default function useProjectTimeline({
  fetchPolicy = 'cache-first',
  lazy = false,
  onCompleted = noop,
  ...options
} = {}) {
  const {data, fetch, loading, error, refetch, refetching} = useToggledQuery({
    lazy,
    query: GET_PROJECT_TIMELINE,
    options: {
      fetchPolicy,
      onCompleted,
      ...options,
    },
  });

  const timeline = propOr(null, 'projectTimeline', data);

  return {fetch, timeline, data, loading, error, refetch, refetching};
}
