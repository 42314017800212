import {identity} from 'ramda';

import {compose, mapProps} from '@renofi/recompose/src';

import {GET_LENDER} from '../queries';

import withQuery from './withQuery';

export default ({mapper = identity, options} = {}) =>
  compose(
    withQuery({
      query: GET_LENDER,
      props: ({data: {lender}, ...props}) => ({
        lender,
        ...props,
      }),
      options:
        options ||
        ((props) => ({variables: {lenderId: props.lenderId || props.id}})),
    }),
    mapProps(mapper),
  );
