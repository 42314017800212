import {gql} from '@apollo/client';

export default gql`
  mutation assignGcddReview($assignedToId: ID, $id: ID!) {
    assignGcddReview(assignedToId: $assignedToId, id: $id) {
      gcddReview {
        assignedTo {
          email
          firstName
          id
          lastName
          phoneNumber
        }
      }
    }
  }
`;
