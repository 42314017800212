import {mergeDeepRight, propOr} from 'ramda';

import capitalize from '@renofi/utilities/src/capitalize';

import {
  DEFAULT_VARIANT,
  DISABLED_VARIANTS,
  MUTATION_VARIANTS,
  OVERRIDE_VARIANTS,
} from './constants';

export const getNestedStyles = (objOrFunc, executable, variant, props) => {
  const result = executable ? objOrFunc(props) : objOrFunc;
  return mergeDeepRight(
    propOr({}, 'common', result),
    propOr({}, variant, result),
  );
};

export const getVariantFromProps = (props = {}) => {
  const getTruthyProp = (k) => Boolean(props[k]);
  const {defaultVariant, variant} = props;

  switch (true) {
    case MUTATION_VARIANTS.some(getTruthyProp):
      const finalVariant = variant || defaultVariant;

      return `${finalVariant}${capitalize(
        MUTATION_VARIANTS.find(getTruthyProp),
      )}`;

    case DISABLED_VARIANTS.some(getTruthyProp):
      return 'disabled';

    case OVERRIDE_VARIANTS.some(getTruthyProp):
      return OVERRIDE_VARIANTS.find(getTruthyProp);

    default:
      return variant || defaultVariant || DEFAULT_VARIANT;
  }
};
