import styled from '@emotion/styled';
import {Text} from '@rebass/emotion';

export const Badge = styled(Text)(({bold, pill, small, theme, ...props}) => {
  return {
    ...theme,
    display: 'inline-block',
    fontSize: small ? 11 : 14,
    lineHeight: `${small ? 11 : 14}px`,
    fontWeight: bold ? 'bold' : 'normal',
    borderRadius: pill ? 50 : 4,
  };
});
