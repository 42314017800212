import * as React from 'react';

import PropTypes from 'prop-types';

const SvgPainting = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M20.95 10.67a.648.648 0 0 0-.138-.209L13.54 3.19a.642.642 0 0 0-.91 0L6.754 9.065l-2.007 2.007a.642.642 0 0 0 0 .909l2.227 2.226-3.08 2.537a2.371 2.371 0 0 0-.89 1.729 2.37 2.37 0 0 0 .714 1.808A2.386 2.386 0 0 0 5.411 21a2.38 2.38 0 0 0 1.842-.893l2.539-3.08 2.227 2.227a.643.643 0 0 0 .91 0l2.006-2.007.922-.921v1.466a1.608 1.608 0 0 0 3.215 0v-.353A1.609 1.609 0 0 0 21 15.864v-4.948a.63.63 0 0 0-.05-.246zm-7.865-6.118 1.781 1.78c-.035.024-.074.04-.105.07L13.153 8.01a.642.642 0 1 0 .91.909l1.606-1.607c.031-.031.047-.07.07-.106l2.002 2.002-1.695 1.694a.642.642 0 1 0 .909.91l1.694-1.695.8.8-3.403 3.401v.002l-1.566 1.565L8.117 9.52l4.968-4.969zM6.26 19.292c-.42.513-1.165.55-1.633.082a1.101 1.101 0 0 1-.34-.835c.015-.305.165-.589.423-.8l3.176-2.618.993.992-2.619 3.179zm6.213-1.402-2.272-2.273L8.382 13.8 6.11 11.526l1.098-1.098 6.364 6.364-1.098 1.098zm6.92-1.705a.322.322 0 0 1-.322-.321.643.643 0 0 0-1.285 0v1.928a.322.322 0 0 1-.643 0V15.04l2.571-2.571v3.395a.322.322 0 0 1-.321.321zm-4.367-2.445-.643.643a.641.641 0 0 1-.909 0 .642.642 0 0 1 0-.909l.643-.643a.642.642 0 1 1 .909.91zm-2.571-4.123a.642.642 0 0 1 0 .909l-.965.964a.641.641 0 0 1-.909 0 .642.642 0 0 1 0-.909l.965-.964a.642.642 0 0 1 .909 0z"
        fill={color}
      />
    </svg>
  );
};

SvgPainting.propTypes = {
  color: PropTypes.string,
};
export default SvgPainting;
