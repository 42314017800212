import * as React from 'react';

import PropTypes from 'prop-types';

const SvgCertificate = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M13 2H5c-1.1 0-1.99.9-1.99 2L3 20c0 1.1.89 2 1.99 2H17c1.1 0 2-.9 2-2V8l-6-6zm2 16H7v-2h8v2zm0-4H7v-2h8v2zm-3-5V3.5L17.5 9H12z"
          fill={color}
        />
        <circle fill="#C62223" cx={17} cy={15} r={5} />
        <path
          d="M14 15h6v9c-1.714-1.147-2.667-1.721-2.86-1.721-.191 0-1.238.574-3.14 1.721v-9z"
          fill="#C62223"
        />
        <circle fill="#FFF" cx={17} cy={15} r={3} />
      </g>
    </svg>
  );
};

SvgCertificate.propTypes = {
  color: PropTypes.string,
};
export default SvgCertificate;
