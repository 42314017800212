import * as React from 'react';

import PropTypes from 'prop-types';

const SvgInfo = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}>
      <defs>
        <path
          d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11zm0-20.35a9.35 9.35 0 1 1 0 18.7 9.35 9.35 0 0 1 0-18.7zm1.1 8.25h-2.2v6.6h2.2v-6.6zm0-4.4h-2.2v2.2h2.2V6.5z"
          id="info_svg__a"
        />
      </defs>
      <use
        fill={color}
        xlinkHref="#info_svg__a"
        transform="matrix(1 0 0 -1 0 24)"
        fillRule="evenodd"
      />
    </svg>
  );
};

SvgInfo.propTypes = {
  color: PropTypes.string,
};
export default SvgInfo;
