import {gql} from '@apollo/client';

export default gql`
  mutation mark1003PrepAsCompleted($id: ID!) {
    mark1003PrepAsCompleted(id: $id) {
      projectTimestamps {
        id
        readyFor1003PrepOn
        x1003CompleteOn
        x1003PrepCompleteOn
      }
    }
  }
`;
