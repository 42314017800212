import {gql} from '@apollo/client';

import {task} from '../../partials';

export default gql`
  mutation requestSoftCreditCheckForProject(
    $taskId: ID!
    $dateOfBirth: Date!
    $authorizedRenofiToVerifyCredit: Boolean!
  ) {
    requestSoftCreditCheckForProject(
      taskId: $taskId,
      dateOfBirth: $dateOfBirth,
      authorizedRenofiToVerifyCredit: $authorizedRenofiToVerifyCredit) {
      task {
        ${task}
      }
    }
  }
`;
