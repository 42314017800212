import styled from '@emotion/styled';

export const Container = styled.div(
  {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    height: 56,
  },
  ({active, disabled, hover}) => ({
    ...(disabled
      ? {
          opacity: 0.5,
        }
      : {
          ':hover': {
            cursor: 'pointer',
          },
        }),
    ...(!disabled && (active || hover)
      ? {
          cursor: 'pointer',
        }
      : {}),
  }),
);
