import {gql} from '@apollo/client';

export default gql`
  mutation cancelGcddReview(
    $cancellationMessage: String
    $cancellationReason: String
    $emailNotification: Boolean
    $id: ID!
  ) {
    cancelGcddReview(
      cancellationMessage: $cancellationMessage
      cancellationReason: $cancellationReason
      emailNotification: $emailNotification
      id: $id
    ) {
      gcddReview {
        id
        cancelledAt
        cancellationMessage
        cancellationReason
      }
    }
  }
`;
