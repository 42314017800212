import React from 'react';

const UploadId = (props) => (
  <svg
    width={87}
    height={177}
    viewBox="60 25 100 177"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      opacity={0.05}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M107.138 46.652c0 .522-.363.946-.811.946-.448 0-.811-.424-.811-.946 0-.523.363-.947.811-.947.448 0 .811.424.811.947Zm-5.532 4.695c.001.209.325.376.722.372.398-.003.718-.175.717-.384-.001-.114-.068-.088-.193-.04-.105.04-.25.096-.432.098a1.872 1.872 0 0 1-.477-.08c-.197-.051-.338-.088-.337.034Zm.722 7.675c-.397.003-.721-.164-.722-.373-.001-.122.14-.085.337-.034.141.037.311.081.477.08.182-.002.327-.058.432-.098.125-.048.192-.074.193.04.001.209-.319.381-.717.385Zm-.909 23.703 2.099-1.258a.197.197 0 0 1 .197 0l2.095 1.258a.195.195 0 0 1 .095.167v4.088a.098.098 0 0 1-.099.098h-1.041a.098.098 0 0 1-.098-.098v-3.416a.195.195 0 0 0-.099-.17l-.853-.49a.198.198 0 0 0-.197 0l-.854.49a.195.195 0 0 0-.098.17v3.416a.098.098 0 0 1-.098.098h-1.046a.098.098 0 0 1-.098-.098v-4.088c0-.069.036-.132.095-.167Zm-3.578-29.13a.65.65 0 0 1-.025-.13c-.03-.342.267-.528.62-.5l-.086-.23s1.893.677 3.785.677c1.893 0 4.378-.947 4.378-.947l-.019.11c.236-.083.452-.161.64-.233.277-.106.685.138.708.424a.298.298 0 0 1-.004.084c-.485 2.584-3.168 4.934-5.726 4.956-2.163.018-3.78-2.556-4.271-4.212Zm1.996-5.726c.448 0 .811-.424.811-.947 0-.522-.363-.946-.811-.946-.448 0-.812.424-.812.947 0 .522.364.946.812.946Z"
      fill="#202020"
    />
    <ellipse
      rx={33.58}
      ry={1.612}
      transform="matrix(-1 0 0 1 101.698 181.928)"
      fill="#000"
      fillOpacity={0.1}
    />
    <path
      d="M110.544 113.594c5.143 26.57 3.036 53.032 1.339 62.942h-2.678s2.679-39.104-2.41-49.014c-4.286-2.947-9.375-4.822-14.196 0-3.214 7.928-3.125 35.444-2.678 49.014H87.51c-4.071-17.356-1.696-49.193 0-62.942h23.034Z"
      fill="#404040"
    />
    <path
      d="m107.488 179.866 1.616-3.53c.696.164-.076.324 3.793.504 3.901.182 9.139 2.654 12.237 4.644.629.404.283 1.225-.458 1.127l-12.742-1.695-4.446-1.05ZM92.112 180.286l-1.616-3.53c-.696.164.076.324-3.793.504-3.901.182-9.139 2.654-12.237 4.644-.629.404-.282 1.225.459 1.127l12.74-1.695 4.447-1.05Z"
      fill="#333"
    />
    <path
      opacity={0.3}
      d="M102.88 123.684h-7.131c1.663.693 5.419 1.478 7.131 0Z"
      fill="#737373"
    />
    <path
      d="M99.445 117.821c-4.52 0-9.536-1.831-12.205-3.076a1.458 1.458 0 0 1-.815-1.522c.38-2.914 1.5-11.292 2.62-17.763.588-3.394 1.913-7.22 2.298-12.36.258-3.443.259-7.149.195-10.004-.06-2.654 2.053-4.897 4.707-4.897h5.768a4.636 4.636 0 0 1 4.625 4.482c.22 6.403.735 17.929 1.705 22.78a3338.543 3338.543 0 0 1 3.452 17.657 1.515 1.515 0 0 1-.842 1.664c-2.674 1.243-7.032 3.039-11.508 3.039Z"
      fill="#FF5253"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m98.729 80.528-2.378 1.424a.221.221 0 0 0-.108.19v4.63c0 .061.05.11.112.11h1.184c.062 0 .111-.049.111-.11v-3.87c0-.078.043-.151.112-.191l.967-.555a.224.224 0 0 1 .222 0l.968.555c.069.04.111.113.111.192v3.87c0 .06.05.11.111.11h1.18c.061 0 .111-.05.111-.11V82.14a.222.222 0 0 0-.107-.19l-2.374-1.423a.223.223 0 0 0-.222 0Z"
      fill="#fff"
    />
    <path
      d="M95.2 50.283c8.612 0 13.848-8.728 12.917-15.361-.931-6.633-4.422-10.59-14.314-10.125-7.331.345-11.52 7.68-11.055 12.685.233 3.956.985 5.228 3.142 8.379 2.157 3.15 3.89 4.422 9.31 4.422Z"
      fill="#313131"
    />
    <path
      d="M102.972 30.902c-4.024-.73-9.846.29-11.487 1.225-3.19 1.82-3.081 8.975-3.118 10.682-.036 1.706-3.09.09-3.94 2.905-.849 2.814 3.223 2.672 3.689 4.485.795 3.096 2.241 5.234 4.135 7.046 1.894 1.811 1.604 1.41 3.063 3.675 1.459 2.267-.497 7.944 0 10.193.48 2.175 4.697 5.703 7.658.815.5-.827-.759-7.673 0-9.936s2.881-3.942 4.441-5.82c2.258-2.717 1.547-7.504 1.225-9.342-.321-1.838-.591-6.335-.591-6.335-.328-2.395.165-8.642-5.075-9.593Z"
      fill="#D8D8D8"
    />
    <path
      opacity={0.6}
      d="M101.582 54.446c-.002.237-.369.426-.819.422-.45-.004-.813-.199-.811-.436.002-.237.258.062.708.066.45.004.924-.29.922-.052ZM101.582 46.175c-.002.238-.369.427-.819.423-.45-.004-.813-.2-.811-.436.002-.237.258.062.708.066.45.003.924-.29.922-.053Z"
      fill="#3C3C3C"
    />
    <path
      d="M104.827 48.096c.575-.243 1.196.027 1.019.625-.558 1.876-2.389 4.79-4.838 4.77-2.899-.024-5.937-2.686-6.487-5.614-.067-.361.454-.705.797-.574 1.425.545 4.237 1.439 6.029 1.436 1.46-.003 2.65-.293 3.48-.643Z"
      fill="#3C3C3C"
    />
    <ellipse
      rx={0.919}
      ry={1.072}
      transform="matrix(-1 0 0 1 96.233 41.09)"
      fill="#3B3B3B"
    />
    <ellipse
      rx={0.919}
      ry={1.072}
      transform="matrix(-1 0 0 1 103.817 41.163)"
      fill="#3B3B3B"
    />
    <path
      d="M104.949 48.037s-1.424.653-3.668.687c-2.144 0-5.309-1.19-5.309-1.19l.157.682c.067.292.237.55.495.701.734.434 2.418 1.283 4.356 1.283 1.846 0 2.999-.615 3.514-.974.215-.15.336-.391.375-.651l.08-.538Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90.312 38.392c0-.074.032-.227.162-.25l.061-.013c.358-.072 1.984-.4 4.366-.414 2.06-.012 3.604.358 4.119.544.142.044.709.127 1.191.103v1.324c-.157.005-.485.059-.544.235l-.044.139c-.178.562-.79 2.49-1.236 3.185-.5.78-.956 1.354-2.794 1.442-1.471.07-2.437-.147-2.736-.265-.324-.044-.942-.294-1.265-.868a39.099 39.099 0 0 0-.049-.102c-.255-.533-.451-.942-.687-2.59l-.147-.897c-.024-.122-.094-.38-.176-.426-.028-.016-.056-.03-.083-.042-.075-.036-.138-.066-.138-.12v-.986Zm4.665-.207c.632.005 2.12.077 3.015.324.894.247.904.927.882 1.427a7.328 7.328 0 0 1-1 3.265c-.117.23-.488.718-1.03.824l-.17.033c-.585.116-1.02.202-1.77.202-.93 0-1.972-.235-2.516-.5-.435-.212-.73-1.216-.824-1.692-.127-.607-.335-2.012-.147-2.765.189-.753.971-.903 1.53-.985a12.864 12.864 0 0 1 2.03-.133Z"
      fill="#333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M110.095 38.392c0-.074-.032-.227-.162-.25l-.061-.013c-.357-.072-1.984-.4-4.366-.414-2.059-.012-3.603.358-4.118.544-.142.044-.709.127-1.192.103v1.324c.157.005.486.059.545.235l.044.139c.178.562.789 2.49 1.235 3.185.5.78.956 1.354 2.795 1.442 1.471.07 2.437-.147 2.736-.265.323-.044.941-.294 1.265-.868l.048-.102c.256-.533.452-.942.687-2.59l.147-.897c.025-.122.094-.38.177-.426.027-.016.056-.03.083-.042.074-.036.137-.066.137-.12v-.986Zm-4.664-.207c-.633.005-2.121.077-3.016.324-.894.247-.903.927-.882 1.427.03.706.259 2.03 1 3.265.118.23.488.718 1.03.824l.17.033c.585.116 1.02.202 1.771.202.929 0 1.971-.235 2.515-.5.436-.212.731-1.216.824-1.692.128-.607.335-2.012.147-2.765-.188-.753-.971-.903-1.529-.985a12.876 12.876 0 0 0-2.03-.133Z"
      fill="#333"
    />
    <path
      d="M108.001 41.671c-.161-1.513-1.11-3.258-1.157-6.4 0-.815-1.047-3.375-2.56-3.375-2.327 0-1.753.815-4.43.815-2.068 0-5.003-1.28-7.214-.815-2.212.465-2.677 1.28-3.142 3.026-.466 1.745 1.04 1.861 0 5.353-.52 1.745-.466.814-1.28 3.142-1.8-1.394-3.86-6.145-1.63-10.706 2.56-5.237 6.755-5.936 11.405-6.517 4.74-.192 6.129.25 7.447 2.56 1.016 1.78 1.54 3.824 1.979 6.284.582 3.258.582 4.422.582 6.633Z"
      fill="#313131"
    />
    <path
      d="M109.048 29.685c-1.117.745-1.124 3.414-1.047 4.19l-4.189-4.771-4.655-1.164h-4.655l-5.353 3.025-5.47 11.172s-3.374-6.517-3.374-11.172c0-4.654 2.094-7.913 4.654-8.262 2.56-.35 2.91.582 4.19-.35 1.28-.93 3.84-5.352 8.379-5.352 4.655-.466 4.422 2.793 4.422 2.793s4.538-1.048 6.051.349c1.513 1.396 1.397 2.56.349 3.724-1.164.232-2.237 3.343-.349 2.676 1.978-.698 2.444 2.211 1.047 3.142Z"
      fill="#313131"
    />
    <path
      opacity={0.3}
      d="M105.326 95.852c-8.313 4.546-12.585 5.84-17.34 5.75l.349-4.655c6.33 2.235 15.379-4.423 21.974-8.38.698-.31 2.117-.279 3.607 2.328a331.14 331.14 0 0 1-5.766 3.377l.317 1.744c-1.351.676-2.458.31-3.141-.164Z"
      fill="#993131"
    />
    <path
      d="M91.127 85.077c.582-5.819-.174-10.415-3.607-8.262-10.997 5.993-9.24 16.618-2.91 18.852 6.331 2.234 19.822-4.655 26.417-8.612.698-.31 2.118-.279 3.607 2.328-25.508 15.826-31.537 11.171-35.493 7.913-3.259-2.793-5.72-11.713-.233-18.387 4.306-5.236 10.894-9.132 15.71-10.473 1.746-.582-4.073 22.46-3.49 16.641Z"
      fill="#FF5253"
    />
    <path
      d="M121.617 77.397c-8.081-2.819-11.169-6.037-17.805-8.845 0 0 2.56 14.78 3.025 10.241.466-4.538 6.284 1.746 16.409 2.793 13.964 1.047 8.165-25.734 6.536-31.786-.815-.349-2.444.466-2.444.466.795 5.137 6.265 30.971-5.721 27.13Z"
      fill="#FF5253"
    />
    <path
      d="M106.034 93.931a2.529 2.529 0 0 1 .526-3.821.665.665 0 0 0 .083-.064l3.395-3.055a.717.717 0 0 1 .124-.09l2.03-1.142a.698.698 0 0 1 .94.247l1.041 1.724a3.258 3.258 0 0 1-1.385 4.624l-4.775 2.28a1.397 1.397 0 0 1-1.642-.327l-.337-.376Z"
      fill="#C4C4C4"
    />
    <path
      d="M111.259 86.009c0 .465-.659 1.086-.931 1.163l.116 1.164-3.723 1.746 3.771-4.689c.231-.287.675-.2.73.165.022.152.037.307.037.45Z"
      fill="#BDBDBD"
    />
    <path
      d="m106.488 90.198 5.434-4.21 4.916-2.968a.706.706 0 0 1 .758 1.19l-10.132 6.794-.976-.806Z"
      fill="#C4C4C4"
    />
    <path
      opacity={0.2}
      d="m112.888 87.289.699-.466c1.047 1.397 1.978 3.724-.699 5.47.233-.466.35-.815.233-1.164.009-.166.466-.466.466-1.047-.011-.25-.077-.443-.117-.699-.059-.378.12-.628 0-.93-.245-.621-.349-.815-.582-1.164Z"
      fill="#7A7A7A"
    />
    <path
      d="M122.147 42.754a1.08 1.08 0 0 1 .073-1.577 1.057 1.057 0 0 1 1.434.037c.634.624 1.518 1.551 1.86 2.177.32.585.5 1.097.595 1.427a.692.692 0 0 1-.188.677c-.403.408-1.107.267-1.403-.224-.204-.339-.456-.686-.74-.93-.41-.354-1.093-1.038-1.631-1.587Z"
      fill="#D8D8D8"
    />
    <path
      opacity={0.2}
      d="M88.612 25.612c0 1.21 2.406 4.926 3.608 6.633l-.931.233c-1.047-1.823-3.049-5.749-2.677-6.866Z"
      fill="#C4C4C4"
    />
    <path
      opacity={0.3}
      d="M122.664 43.3s.815-.35 1.397-.35c.582 0 1.28.35 1.28.35l.232.232.699 1.164-2.56-.349-1.048-1.047Z"
      fill="#AFAFAF"
    />
    <rect
      x={144.237}
      y={42.959}
      width={21.526}
      height={31.392}
      rx={1.164}
      transform="rotate(90 144.237 42.959)"
      fill="#969696"
    />
    <path fill="#fff" d="M142.443 44.753V62.69h-27.804V44.753z" />
    <path
      d="M125.224 43.998h7.564l.349 1.862h-7.099l-.814-1.862Z"
      fill="#AFAFAF"
    />
    <path
      d="m125.689 43.532 1.164-.465.931.232 1.513-.232h1.164l.698.232h1.745v.699h-7.215v-.466Z"
      fill="#C4C4C4"
    />
    <rect
      opacity={0.4}
      x={126.299}
      y={50.135}
      width={13.454}
      height={1.794}
      rx={0.897}
      fill="#C4C4C4"
    />
    <rect
      opacity={0.4}
      x={126.299}
      y={53.722}
      width={13.454}
      height={1.794}
      rx={0.897}
      fill="#C4C4C4"
    />
    <rect
      opacity={0.4}
      x={117.33}
      y={58.207}
      width={21.526}
      height={1.794}
      rx={0.897}
      fill="#C4C4C4"
    />
    <path opacity={0.4} fill="#C4C4C4" d="M117.33 47.443h8.072v9.866h-8.072z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M121.541 52.583a1.673 1.673 0 1 0 0-3.345 1.673 1.673 0 0 0 0 3.345Zm-.001.837c-1.117 0-3.346.56-3.346 1.674v.836h6.693v-.836c0-1.113-2.23-1.674-3.347-1.674Z"
      fill="#737373"
    />
    <path
      d="M129.185 42.298a.863.863 0 1 1 1.677-.406l1.088 5.35a.662.662 0 0 1-1.286.312l-1.479-5.256ZM127.155 42.18a.864.864 0 1 1 1.64-.538l1.509 5.247a.662.662 0 0 1-1.257.412l-1.892-5.122ZM125.339 42.488a.864.864 0 1 1 1.607-.63l1.804 5.154a.662.662 0 0 1-1.232.482l-2.179-5.006ZM131.233 42.986a.786.786 0 1 1 1.554-.238l.504 4.258a.642.642 0 0 1-1.268.194l-.79-4.215Z"
      fill="#D8D8D8"
    />
  </svg>
);

export default UploadId;
