import {gql} from '@apollo/client';

export default gql`
  query exceptionRequest {
    exceptionRequest @client {
      projectId
      lenderId
      renovationScope
      exceptionTitle
      compensatingFactors
      renofiNotes
      comments {
        id
        createdAt
        createdBy {
          id
          email
          firstName
          lastName
        }
        message
        subject
      }
      metrics {
        name
        exception
        label
        notes
        value
      }
    }
  }
`;
