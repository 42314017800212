import {gql} from '@apollo/client';

export default gql`
  mutation submitRenovationUnderwritingRequest(
    $contractors: [ContractorDetailsInputObject!]
    $eligibilityStats: EligibilityStatsInputObject!
    $homeownerDetails: HomeownerDetailsInputObject!
    $lenderAccountExecutiveDetails: LenderAccountExecutiveDetailsInputObject
    $lenderAccountExecutiveId: String
    $lenderProcessorId: String
    $loanDetails: LoanDetailsInputObject!
    $mloDetails: MloDetailsInputObject
    $mloLoanProcessorDetails: MloLoanProcessorDetailsInputObject
  ) {
    submitRenovationUnderwritingRequest(
      contractors: $contractors
      eligibilityStats: $eligibilityStats
      homeownerDetails: $homeownerDetails
      lenderAccountExecutiveDetails: $lenderAccountExecutiveDetails
      lenderAccountExecutiveId: $lenderAccountExecutiveId
      lenderProcessorId: $lenderProcessorId
      loanDetails: $loanDetails
      mloDetails: $mloDetails
      mloLoanProcessorDetails: $mloLoanProcessorDetails
    ) {
      success
      renovationUnderwritingRequest {
        id
        project {
          id
        }
      }
    }
  }
`;
