import {GET_SYSTEM_STATE} from './queries';

export default (cache) => {
  cache.writeQuery({
    query: GET_SYSTEM_STATE,
    data: {
      system: {
        mutations: [],
        queries: [],
      },
    },
  });
};
