import {gql} from '@apollo/client';

export default gql`
  mutation submitAppraisalComparableSales($taskId: ID!, $urls: [String!]!) {
    submitAppraisalComparableSales(taskId: $taskId, urls: $urls) {
      appraisalComparableSaleUrls {
        id
        url
        createdAt
        updatedAt
      }
    }
  }
`;
