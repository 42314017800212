import {gql} from '@apollo/client';

export default gql`
  mutation autoTransmitToLender(
    $productDetailsConfirmationId: ID!
    $autoTransmitToLender: Boolean!
  ) {
    autoTransmitToLender(
      productDetailsConfirmationId: $productDetailsConfirmationId
      autoTransmitToLender: $autoTransmitToLender
    ) {
      success
    }
  }
`;
