import {gql} from '@apollo/client';

export default gql`
  mutation denyExceptionRequest($id: ID!, $message: String!) {
    denyExceptionRequest(id: $id, lenderNotes: $message) {
      exceptionRequest {
        id
        approvedAt
        approvedById
        approvedBy {
          id
          email
          firstName
          lastName
        }
        borrower {
          id
          email
          firstName
          lastName
        }
        comments {
          id
          addedById
          addedBy {
            id
            email
            firstName
            lastName
          }
          createdAt
          message
          source
          subject
          updatedAt
        }
        compensatingFactors
        createdAt
        deniedAt
        deniedById
        deniedBy {
          id
          email
          firstName
          lastName
        }
        lenderId
        lender {
          id
          name
        }
        lenderNotes
        metrics {
          id
          createdAt
          exceptional
          name
          notes
          updatedAt
          value
        }
        renofiNotes
        renovationScope
        status
        title
        updatedAt
      }
    }
  }
`;
