import React, {Fragment, useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {propOr} from 'ramda';

import {useSignedUrl} from '@renofi/graphql/src/hooks';
import noop from '@renofi/utilities/src/noop';

import Loader from './Loader';

function LazyImage({objectName, onLoad = noop, ...props}) {
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const {loading, signedUrl} = useSignedUrl({variables: {objectName}});
  const src = propOr(null, 'url', signedUrl);

  useEffect(() => {
    (async () => {
      if (!src) {
        return;
      }

      const image = new Image();
      image.src = src;
      image.onerror = () => setError(true);
      image.onload = () => {
        setError(false);
        setDone(true);
        onLoad(image);
      };
    })();
  }, [src]);

  return (
    <Fragment>
      {done && !loading && <img src={src} {...props} />}
      {error && 'Failed to load image'}
      {loading || (!(done || error) && <Loader />)}
    </Fragment>
  );
}

LazyImage.propTypes = {
  objectName: PropTypes.string.isRequired,
  onLoad: PropTypes.func,
};

export default LazyImage;
