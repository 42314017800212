import * as React from 'react';

import PropTypes from 'prop-types';

const SvgRestroom = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#restroom_svg__a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.224 10.724h-2.21c1.42-3.151 1.41-7.616 1.41-8.124V.524a.511.511 0 0 0-.52-.525H4.955a.52.52 0 0 0-.52.512v2.074c-.001.508-.01 4.96 1.41 8.159H3.567a.52.52 0 0 0-.52.518.511.511 0 0 0 .52.512h16.657a.514.514 0 0 0 .52-.512.518.518 0 0 0-.52-.539zM5.47 1.03h12.92v1.017H5.47V1.03zm.007 2.06c.028 1.358.26 5.158 1.513 7.634h9.882c1.286-2.476 1.512-6.27 1.512-7.634H5.476zm14.2 8.867H4.204a.506.506 0 0 0-.506.416.462.462 0 0 0 0 .102.469.469 0 0 0 0 .164 8.248 8.248 0 0 0 4.62 6.713v3.35h-.993a.52.52 0 0 0-.5.79.52.52 0 0 0 .5.24h9.273a.52.52 0 0 0 .5-.792.52.52 0 0 0-.5-.239h-1.033v-3.363a8.187 8.187 0 0 0 4.599-6.699.47.47 0 0 0 .034-.164.46.46 0 0 0 0-.102.52.52 0 0 0-.52-.416zM14.53 22.7H9.363v-2.933c.83.272 1.7.41 2.574.41a8.132 8.132 0 0 0 2.593-.417v2.94zm-5.46-4.147a7.22 7.22 0 0 0 10.012-5.567H4.811a7.255 7.255 0 0 0 4.257 5.567zM8.685 4.632h3.244c.273.004.495.22.506.491a.505.505 0 0 1-.513.512H8.679a1.035 1.035 0 0 1-1.914-.694 1.032 1.032 0 0 1 1.92-.309z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="restroom_svg__a">
          <path fill="#fff" transform="translate(3)" d="M0 0h19v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

SvgRestroom.propTypes = {
  color: PropTypes.string,
};
export default SvgRestroom;
