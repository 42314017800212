import {gql} from '@apollo/client';

export default gql`
  mutation submitWebReviewsScore(
    $gcddReviewId: ID!
    $webReviewsNotFound: Boolean
    $webReviewsScore: ScoreEnum
    $webReviewsScoreNotes: String
  ) {
    submitWebReviewsScore(
      gcddReviewId: $gcddReviewId
      webReviewsNotFound: $webReviewsNotFound
      webReviewsScore: $webReviewsScore
      webReviewsScoreNotes: $webReviewsScoreNotes
    ) {
      gcddReview {
        id
        webReviewsNotFound
        webReviewsScore
        webReviewsScoreNotes
        webReviewsScoreSubmittedAt
        webReviewsScoreSubmittedById
      }
    }
  }
`;
