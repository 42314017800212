import {gql} from '@apollo/client';

export default gql`
  mutation assignFeasibilityReview($feasibilityReviewerId: ID, $id: ID!) {
    assignFeasibilityReview(
      feasibilityReviewerId: $feasibilityReviewerId
      id: $id
    ) {
      feasibilityReview {
        id
        assignedAt
        requestedAt
        reviewerId
        status
      }
    }
  }
`;
