import {gql} from '@apollo/client';

export default gql`
  mutation unassignGcddReview($id: ID!) {
    unassignGcddReview(id: $id) {
      gcddReview {
        assignedTo {
          email
          firstName
          id
          lastName
          phoneNumber
        }
      }
    }
  }
`;
