import {pathOr, propEq, reject} from 'ramda';

import {compose, withHandlers} from '@renofi/recompose/src';

import {REMOVE_COMMENT} from '../mutations';
import {GET_PROJECT, GET_RENOVATION_READY_FILE} from '../queries';

import withMutation from './withMutation';

export default () =>
  compose(
    withMutation(REMOVE_COMMENT, {
      name: 'removeComment',
    }),
    withHandlers({
      removeComment:
        ({client, removeComment}) =>
        async ({commentId, projectId, rrfId}) => {
          const response = await removeComment({
            variables: {id: commentId},
          });
          const success = pathOr(
            false,
            ['data', 'removeComment', 'success'],
            response,
          );

          if (success) {
            if (rrfId) {
              const {renovationReadyFileById} = client.readQuery({
                query: GET_RENOVATION_READY_FILE,
                variables: {id: rrfId},
              });
              client.writeQuery({
                query: GET_RENOVATION_READY_FILE,
                variables: {id: rrfId},
                data: {
                  renovationReadyFileById: {
                    ...renovationReadyFileById,
                    project: {
                      ...renovationReadyFileById.project,
                      comments: reject(
                        propEq('id', commentId),
                        renovationReadyFileById.project.comments,
                      ),
                    },
                  },
                },
              });
            } else {
              const {projectById: project} = client.readQuery({
                query: GET_PROJECT,
                variables: {
                  id: projectId,
                },
              });
              client.writeQuery({
                query: GET_PROJECT,
                variables: {
                  id: projectId,
                },
                data: {
                  projectById: {
                    ...project,
                    comments: reject(propEq('id', commentId), project.comments),
                  },
                },
              });
            }
          }

          return success;
        },
    }),
  );
