import {gql} from '@apollo/client';

export default gql`
  mutation unmarkContractorInformationAsDuplicate(
    $contractorId: ID!
    $contractorInformationId: ID!
  ) {
    unmarkContractorInformationAsDuplicate(
      contractorId: $contractorId
      contractorInformationId: $contractorInformationId
    ) {
      success
    }
  }
`;
