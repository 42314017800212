import {gql} from '@apollo/client';

export default gql`
  mutation updateLenderUser(
    $id: ID!
    $active: Boolean
    $email: String
    $firstName: String
    $lastName: String
    $manageUsers: Boolean!
    $notificationPreferences: [NotificationPreferenceInputObject!]
  ) {
    updateLenderUser(
      id: $id
      active: $active
      email: $email
      firstName: $firstName
      lastName: $lastName
      manageUsers: $manageUsers
      notificationPreferences: $notificationPreferences
    ) {
      user {
        id
        active
        email
        invitationSentAt
        firstName
        lastName
        notificationPreferences {
          id
          active
          notificationType
        }
        permissions {
          id
          key
        }
      }
    }
  }
`;
