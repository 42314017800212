import React from 'react';

import PropTypes from 'prop-types';
import {either, identity, ifElse, is, keys, map} from 'ramda';

import Label from '../Label';
import MouseHover from '../MouseHover';

import Button from './components/Button';
import {Container, Wrapper} from './styled';

const noop = () => {};

const handleOptions = ifElse(
  Array.isArray,
  map(
    ifElse(
      either(is(Number), is(String)),
      (state) => ({label: state, value: state}),
      identity,
    ),
  ),
  (options) => {
    return keys(options).map((value) => ({label: options[value], value}));
  },
);

const defaultOptions = [
  {label: 'Yes', value: true},
  {label: 'No', value: false},
];

const ButtonGroup = ({
  disabled,
  dynamic = false,
  label,
  options = defaultOptions,
  value,
  onChange,
  labelProps,
  onHover = noop,
  buttonsWidth,
  ...props
}) => {
  const finalOptions = handleOptions(options);
  return (
    <MouseHover
      type="div"
      css={{display: 'block', flexGrow: 1, width: '100%'}}
      onMouseEnter={noop}
      onMouseLeave={() => {
        onHover(null);
      }}>
      <Wrapper mb={24} {...props}>
        {label && <Label {...labelProps}>{label}</Label>}
        <Container width={buttonsWidth} withLabel={Boolean(label)}>
          {finalOptions.map(({label, value: key}) => (
            <MouseHover
              key={key}
              type={Button}
              onMouseEnter={() => {
                onHover(key);
              }}
              onMouseLeave={noop}
              active={key === value}
              disabled={disabled}
              dynamic={dynamic}
              options={options}
              onClick={() => !disabled && onChange(key)}>
              <>{label}</>
            </MouseHover>
          ))}
        </Container>
      </Wrapper>
    </MouseHover>
  );
};

ButtonGroup.propTypes = {
  disabled: PropTypes.bool,
  dynamic: PropTypes.bool,
  label: PropTypes.node,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]),
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool,
        PropTypes.shape({
          label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          value: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
            PropTypes.bool,
          ]),
        }),
      ]),
    ),
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  onHover: PropTypes.func,
  labelProps: PropTypes.object,
  buttonsWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ButtonGroup;
