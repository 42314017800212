import styled from '@emotion/styled';

import {Box, Flex, Link, Text} from '@renofi/components-internal';
import {basic03, basic80, maxSmall, minSmall} from '@renofi/theme/src';

export const Card = styled(Flex)({
  borderRadius: 8,
  backgroundColor: basic03,
  flexDirection: 'column',
  border: '1px solid #E9E9E9',
  padding: 16,
  fontSize: 14,
});

export const HistoryWrapper = styled(Flex)({
  cursor: 'pointer',
  ':hover': {
    opacity: 0.8,
  },
});

export const Label = styled(Text)({
  marginBottom: 8,
  marginRight: 4,
  color: basic80,
  fontSize: 14,
});

export const Value = styled(Link)({
  maxWidth: 200,
  marginBottom: 8,
  marginRight: 8,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: 14,
});

export const DocumentCard = styled(Box)(({internal}) => {
  const padding = internal ? 0 : 8;
  const width = !internal ? '50%' : '100%';

  return {
    width,
    maxWidth: width,
    flexBasis: width,
    flex: width,
    paddingLeft: padding,

    [minSmall]: {
      ':nth-of-type(2n + 1)': {
        paddingLeft: 0,
        paddingRight: padding,
      },
    },
    [maxSmall]: {
      width: '100%',
      maxWidth: '100%',
      flexBasis: '100%',
      flex: '100%',
    },
  };
});
