import styled from '@emotion/styled';

import Flex from '../Flex';

export const Wrapper = styled(Flex)(
  {
    flexDirection: 'column',
    width: '100%',
  },
  ({duration = 500}) => ({
    transition: `all ${duration}ms ease`,
  }),
);
