import * as React from 'react';

import PropTypes from 'prop-types';

const SvgFireplace = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M1.545 2h20.69c.301 0 .545.242.545.541V5.79a.543.543 0 0 1-.545.542h-1.088v12.991h1.088c.301 0 .545.242.545.541v2.166a.543.543 0 0 1-.545.541H1.545A.543.543 0 0 1 1 22.029v-2.166c0-.299.244-.54.545-.54h1.089V6.33h-1.09A.543.543 0 0 1 1 5.789V2.541C1 2.242 1.244 2 1.545 2zm19.312 18.405H2.09v1.082h19.602v-1.082h-.834zm-10.894-6.46c.173-.43.383-.726.583-1.01.271-.384.527-.745.637-1.403a.136.136 0 0 1 .201-.095c.127.06 1.575.786 1.964 2.32v-.003a1.56 1.56 0 0 0 .097-.44.136.136 0 0 1 .25-.064c.116.164.387.799.259 1.524-.076.43-.29.895-.755 1.318a.136.136 0 0 1-.092.035l-1.01.001a.136.136 0 0 1-.116-.205c.092-.179.164-.58.15-.986a2.373 2.373 0 0 0-.037-.358c-.049.227-.12.454-.21.666-.147.346-.347.651-.591.852a.136.136 0 0 1-.087.03l-.627.001a.136.136 0 0 1-.087-.032 1.7 1.7 0 0 1-.653-.95c-.082-.332-.065-.731.124-1.2zm-.523 3.942a.543.543 0 0 1-.545-.54c0-.3.244-.542.545-.542h4.9c.301 0 .545.242.545.541a.543.543 0 0 1-.545.541h-4.9zm-3.69-8.22a18.257 18.257 0 0 1 3.046-.872 16.433 16.433 0 0 1 3.094-.297c1.036 0 2.068.1 3.095.297a18.28 18.28 0 0 1 3.09.89.54.54 0 0 1 .347.502h.002v9.135h1.633V6.331H3.723v12.991h1.633v-9.135c0-.246.166-.455.394-.52zm3.25.19c-.853.163-1.705.398-2.555.704v8.761h10.89v-8.761a17.017 17.017 0 0 0-2.554-.705 15.367 15.367 0 0 0-2.891-.28c-.959 0-1.922.094-2.89.28zm12.69-6.774H2.09v2.165h19.6V3.083z"
        fill={color}
      />
    </svg>
  );
};

SvgFireplace.propTypes = {
  color: PropTypes.string,
};
export default SvgFireplace;
