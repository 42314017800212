import {useCallback} from 'react';

import {useHistory, withRouter} from 'react-router-dom';
import {isEmpty, toPairs} from 'ramda';

import {compose, withProps} from '@renofi/recompose/src';
import isPlainObject from '@renofi/utilities/src/isPlainObject';

import omitProps from './omitProps';

const toQueryString = (params) => {
  const searchParams = new URLSearchParams();
  const stringify = (data = {}, prefix = '') => {
    return toPairs(data)
      .filter(([key, value]) => key && value)
      .forEach(([key, value]) => {
        if (isPlainObject(value)) {
          return stringify(value, key);
        }

        const suffix = Array.isArray(value) ? '[]' : '';
        const finalKey = prefix
          ? `${prefix}.${key}${suffix}`
          : `${key}${suffix}`;
        const finalValue = Array.isArray(value) ? value.toString() : value;
        searchParams.append(finalKey, finalValue);
      });
  };

  stringify(params);
  return searchParams.toString();
};

const compileUrl = (base, parts = [], params = {}) => {
  const path = isEmpty(parts) ? '' : parts.join('');
  const query = isEmpty(params) ? '' : toQueryString(params);
  return [[base, path].join('/'), query].join('?');
};

export default compose(
  withRouter,
  omitProps(['staticContext']),
  withProps(() => {
    const history = useHistory();

    const navigate = useCallback((...args) => {
      if (args.length === 1) {
        history.push(args[0]);
      } else {
        history.push(compileUrl(...args));
      }
    }, []);

    return {navigate};
  }),
);
