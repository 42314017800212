import {gql} from '@apollo/client';

export default gql`
  mutation submitAppraisalRenovationDetails(
    $taskId: ID!
    $renovationDetails: AppraisalRenovationDetailsInputObject!
  ) {
    submitAppraisalRenovationDetails(
      taskId: $taskId
      appraisalRenovationDetails: $renovationDetails
    ) {
      appraisalRenovationDetails {
        id
        additionalDetails
        additionalDetailsPresent
        createdAt
        renovationChanges {
          basement {
            changeTypes
            details
          }
          bathrooms {
            additional {
              details
              location
              type
            }
            existing {
              changeType
              details
              location
              type
            }
          }
          bedrooms {
            additional {
              details
              location
              type
            }
            existing {
              changeType
              details
              location
              type
            }
          }
          bonusRoom {
            changeTypes
            details
          }
          cooling {
            additional {
              details
              location
              type
            }
            existing {
              changeType
              details
              location
              type
            }
          }
          custom {
            details
            name
          }
          deck {
            changeTypes
            details
          }
          diningRoom {
            changeTypes
            details
          }
          exterior {
            changeTypes
            details
          }
          fireplaces {
            additional {
              details
              location
              type
            }
            existing {
              changeType
              details
              location
              type
            }
          }
          garages {
            additional {
              details
              location
              type
            }
            existing {
              changeType
              details
              location
              type
            }
          }
          heating {
            additional {
              details
              location
              type
            }
            existing {
              changeType
              details
              location
              type
            }
          }
          interior {
            changeTypes
            details
          }
          kitchen {
            changeTypes
            details
          }
          landscaping {
            changeTypes
            details
          }
          laundryRoom {
            changeTypes
            details
          }
          livingRoom {
            changeTypes
            details
          }
          mudRoom {
            changeTypes
            details
          }
          office {
            changeTypes
            details
          }
          outerBuildings {
            changeTypes
            details
          }
          painting {
            changeTypes
            details
          }
          patio {
            changeTypes
            details
          }
          pool {
            changeTypes
            details
          }
          porch {
            changeTypes
            details
          }
          roofing {
            changeTypes
            details
          }
          siding {
            changeTypes
            details
          }
          squareFootage {
            basement
            total
          }
          structuralChanges {
            changeTypes
            details
          }
          windows {
            changeTypes
            details
          }
        }
        updatedAt
      }
    }
  }
`;
