import {gql} from '@apollo/client';

import {task} from '../../partials';

export default gql`
  mutation confirmProductConfirmationPage(
    $taskId: ID!
    $section: ProductConfirmationSection!
  ) {
    confirmProductConfirmationPage(
      taskId: $taskId
      section: $section
    ) {
      task {
        ${task}
      }
    }
  }
`;
