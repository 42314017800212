import {clamp} from 'ramda';

const HEX_REGEX = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

export default (hex, alpha = 1) => {
  const result = HEX_REGEX.exec(hex);
  if (!result) {
    return hex;
  }

  const clampedAlpha = clamp(0, 1, alpha);
  const red = parseInt(result[1], 16);
  const green = parseInt(result[2], 16);
  const blue = parseInt(result[3], 16);

  return `rgba(${red}, ${green}, ${blue}, ${clampedAlpha})`;
};
