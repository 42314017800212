import {UPDATE_SMS_CONSENT_RENOVATION_PROGRESS} from '../../../mutations';
import createMutationHook from '../../../utils/createMutationHook';

export default createMutationHook({
  query: UPDATE_SMS_CONSENT_RENOVATION_PROGRESS,
  name: 'updateSmsConsentRenovationProgress',
  responsePath: [
    'updateSmsConsentRenovationProgress',
    'smsConsentRenovationProgress',
  ],
});
