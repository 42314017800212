import {gql} from '@apollo/client';

export default gql`
  mutation loanApplicationApproved(
    $appliedOn: Date
    $approvedOn: Date
    $closedOn: Date
    $conditions: String
    $estimatedClosingOn: Date!
    $finalCreditScore: String
    $finalDtiRatio: Decimal
    $finalLoanAmount: Int
    $finalLoanRate: Decimal
    $lenderProvidedIncome: Int
    $lenderProvidedMonthlyDebt: Int
    $lenderVerifiedReserves: Int
    $loanNumber: String!
    $loanProductId: ID
    $renovationReadyFileId: ID!
  ) {
    loanApplicationApproved(
      appliedOn: $appliedOn
      approvedOn: $approvedOn
      closedOn: $closedOn
      conditions: $conditions
      estimatedClosingOn: $estimatedClosingOn
      finalCreditScore: $finalCreditScore
      finalDtiRatio: $finalDtiRatio
      finalLoanAmount: $finalLoanAmount
      finalLoanRate: $finalLoanRate
      lenderProvidedIncome: $lenderProvidedIncome
      lenderProvidedMonthlyDebt: $lenderProvidedMonthlyDebt
      lenderVerifiedReserves: $lenderVerifiedReserves
      loanNumber: $loanNumber
      loanProductId: $loanProductId
      renovationReadyFileId: $renovationReadyFileId
    ) {
      lenderLoanApplication {
        id
        appliedOn
        approvedOn
        closedOn
        conditions
        createdAt
        deniedCreditScoreExplanation
        deniedDebtExplanation
        deniedOn
        deniedOtherExplanation
        estimatedClosingOn
        finalCreditScore
        finalDtiRatio
        finalLoanAmount
        finalLoanRate
        isDeniedReasonCreditScore
        isDeniedReasonDebt
        isDeniedReasonOther
        lender {
          id
          loanProducts {
            id
            name
          }
        }
        lenderProvidedIncome
        lenderProvidedMonthlyDebt
        lenderVerifiedReserves
        loanNumber
        loanProduct {
          id
          createdAt
          name
          updatedAt
        }
        renovationReadyFileId
        status
        updatedAt
        withdrawnExplanation
        withdrawnOn
        withdrawnReason
      }
    }
  }
`;
