import {gql} from '@apollo/client';

import {webReview} from '../../partials';

export default gql`
  mutation updateWebReview(
    $comment: String!
    $id: ID!
    $rating: Int!
    $url: String!
    $website: String!
  ) {
    updateWebReview(
      comment: $comment
      id: $id
      rating: $rating
      url: $url
      website: $website
    ) {
      webReview {
        ${webReview}
      }
    }
  }
`;
