import * as React from 'react';

import PropTypes from 'prop-types';

const SvgRar = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <mask id="rar_svg__a" fill="#fff">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.56 0A3.56 3.56 0 0 0 8 3.56v24.88A3.56 3.56 0 0 0 11.56 32h16.88A3.56 3.56 0 0 0 32 28.44V3.539 8l-8-8H11.56z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.56 0A3.56 3.56 0 0 0 8 3.56v24.88A3.56 3.56 0 0 0 11.56 32h16.88A3.56 3.56 0 0 0 32 28.44V3.539 8l-8-8H11.56z"
        fill="#fff"
      />
      <path
        d="m32 3.539 1-.006-2 .006h1zM32 8l-.707.707L33 10.414V8h-1zm-8-8 .707-.707L24.414-1H24v1zM9 3.56A2.56 2.56 0 0 1 11.56 1v-2A4.56 4.56 0 0 0 7 3.56h2zm0 24.88V3.56H7v24.88h2zM11.56 31A2.56 2.56 0 0 1 9 28.44H7A4.56 4.56 0 0 0 11.56 33v-2zm16.88 0H11.56v2h16.88v-2zM31 28.44A2.56 2.56 0 0 1 28.44 31v2A4.56 4.56 0 0 0 33 28.44h-2zm0-24.88v24.88h2V3.56h-2zm0-.015v.015h2v-.027l-2 .012zm0-.006V8h2V3.539h-2zm1.707 3.754-8-8L23.293.707l8 8 1.414-1.414zM11.56 1H24v-2H11.56v2z"
        fill="#EBEBEB"
        mask="url(#rar_svg__a)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m24 0 8 8h-6a2 2 0 0 1-2-2V0z"
        fill="#A6ADBC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 8h5v5l-5-5z"
        fill="#D2D6DE"
      />
      <rect y={3} width={21} height={10} rx={2} fill="#C423ED" />
      <path
        d="M5.14 6.965a.74.74 0 0 0-.09-.375.602.602 0 0 0-.308-.246 1.101 1.101 0 0 0-.355-.074 6.249 6.249 0 0 0-.473-.016h-.543V7.82h.461c.24 0 .44-.011.602-.035a.862.862 0 0 0 .406-.16c.104-.08.18-.17.226-.266a.888.888 0 0 0 .075-.394zM7.48 11H5.648L4.063 8.867H3.37V11H1.88V5.184h2.516c.343 0 .639.02.886.058.248.04.48.124.696.254.218.13.391.3.52.508.13.206.194.465.194.777 0 .43-.1.78-.3 1.051-.198.27-.482.496-.852.676L7.48 11zm6.032 0H11.96l-.402-1.176H9.402L9 11H7.488l2.149-5.816h1.726L13.512 11zm-2.317-2.242-.715-2.086-.714 2.086h1.43zm6.414-1.793a.74.74 0 0 0-.09-.375.602.602 0 0 0-.308-.246 1.1 1.1 0 0 0-.355-.074 6.25 6.25 0 0 0-.473-.016h-.543V7.82h.46c.24 0 .44-.011.602-.035a.862.862 0 0 0 .407-.16c.104-.08.18-.17.226-.266a.889.889 0 0 0 .074-.394zM19.95 11h-1.832l-1.586-2.133h-.691V11h-1.492V5.184h2.515c.344 0 .64.02.887.058.247.04.48.124.695.254.22.13.392.3.52.508.13.206.195.465.195.777 0 .43-.1.78-.3 1.051-.198.27-.482.496-.852.676L19.949 11z"
        fill="#fff"
      />
      <path
        fill="#C9C9C9"
        d="M20 17h2v2h-2zm-2 2h2v2h-2zm2 2h2v2h-2zm-2 2h2v2h-2zm2 2h2v2h-2zm-2 2h2v2h-2zm2 2h2v2h-2z"
      />
      <path fill="#C9C9C9" d="M18 15h4v6h-4z" />
      <path fill="#fff" d="M19 18h2v2h-2z" />
    </svg>
  );
};

SvgRar.propTypes = {
  color: PropTypes.string,
};
export default SvgRar;
