import {gql} from '@apollo/client';

export default gql`
  mutation submitLenderProjectDocument(
    $document: DocumentInputObject!
    $documentType: DocumentType!
    $projectId: ID!
  ) {
    submitLenderProjectDocument(
      document: $document
      documentType: $documentType
      projectId: $projectId
    ) {
      document {
        id
      }
    }
  }
`;
