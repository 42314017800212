import {gql} from '@apollo/client';

export default gql`
  mutation submitSmsConsentRenovationProgress(
    $taskId: ID!
    $smsConsentRenovationProgress: SmsConsentRenovationProgressInputObject!
  ) {
    submitSmsConsentRenovationProgress(
      taskId: $taskId
      smsConsentRenovationProgress: $smsConsentRenovationProgress
    ) {
      smsConsentRenovationProgress {
        id
        accepted
        acceptedAt
        createdAt
        phoneNumber
        updatedAt
      }
    }
  }
`;
