import {assoc, find} from 'ramda';

import isCurrentResidence from './isCurrentResidence';

export default (addresses = []) => {
  if (addresses?.length === 1) {
    return assoc('currentAddress', true, addresses[0]);
  }
  return find(isCurrentResidence, addresses);
};
