import feasibilityStudyItem from './feasibilityStudyItem';

export default `
id
attachedRecords {
  createdAt
  recordId
}
changeOrderProcedureApproved
changeOrderProcedureApprovedSource
changeOrderProcedureNotes
clearDescriptionOfWorkApproved
clearDescriptionOfWorkApprovedSource
clearDescriptionOfWorkNotes
clearGuaranteeApproved
clearGuaranteeApprovedSource
clearGuaranteeNotes
clearGuaranteeRating
clearGuaranteeRatingSource
completedAt
complexityGrading
contactInfoApproved
contactInfoApprovedSource
contactInfoNotes
contractTimelineApproved
contractTimelineApprovedSource
contractTimelineNotes
contractTimelineRating
contractTimelineRatingSource
costEstimateSuppliedApproved
costEstimateSuppliedApprovedSource
costEstimateSuppliedNotes
createdAt
documents {
  id
  checksum
  createdAt
  documentType
  fileName
  objectName
  pdfObjectName
  requestIpAddress
  requestIpLocation
  shareWithLenderAllowed
  shareable
  updatedAt
}
estimatedDuration
estimatedDurationSource
feasibilityStudyStatus
contractReviewStatus
feasibilityStudyItems {
  ${feasibilityStudyItem}
}
generalCommentaryAndNotes
homeownerResponsibilityCompleted
inScopeAdjustedTotalRating
inScopeTotalRating
openedAt
outOfScopeCompleted
propertyPhotos {
  id
  createdAt
  externalUrl
  homeownerDescription
  objectName
  updatedAt
}
canceledAt
cancelable
requestedAt
returnedAt
status
termsOfPaymentApproved
termsOfPaymentApprovedSource
termsOfPaymentNotes
termsOfPayments {
  amount
  comment
  specialMaterialCosts
}
uncompletedDetails
uncompletedReason
updatedAt
`;
