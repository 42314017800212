export default `
id
applyUrl
contactInformation1
contactInformation2
friendlyId
joinUrl
logoObjectName
logoUrl
name
rrfTransferInstructions
tpoEnabled
`;
