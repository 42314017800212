import {compose, withHandlers} from '@renofi/recompose/src';

import withChangeRenovationState from './withChangeRenovationState';

export default () =>
  compose(
    withChangeRenovationState(),
    withHandlers({
      changeRenovationStateKind:
        ({changeRenovationState}) =>
        (kind) => {
          changeRenovationState({kind});
        },
    }),
  );
