import * as React from 'react';

import PropTypes from 'prop-types';

const SvgBell = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M11.81 19.606a1.999 1.999 0 0 0 2.449 1.414 1.999 1.999 0 0 0 1.414-2.45l-3.864 1.036zm7.352-5.95-1.255-4.684c-.7-2.616-3.22-4.288-5.985-4.168l-.155-.58c-.19-.708-.953-1.116-1.708-.914-.756.202-1.212.937-1.022 1.646l.155.58c-2.455 1.278-3.8 3.985-3.1 6.601l1.256 4.685-1.363 2.194.229.853 15.455-4.14-.23-.854-2.277-1.219z"
          fill={color}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

SvgBell.propTypes = {
  color: PropTypes.string,
};
export default SvgBell;
