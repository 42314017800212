import logger from '@renofi/utilities/src/logger';

import {UNAUTHORIZED_ERROR} from './constants';

const ENV = process.env.REACT_APP_NODE_ENV;

export default ({errors = [], operationName}) => {
  const fields = errors.reduce((arr, err) => {
    const {extensions = {}} = err;

    const isAuthError = extensions.code === UNAUTHORIZED_ERROR;
    const isSuccessRsp = extensions['http_status'] === 200;
    const path = (err?.path || []).join('.');

    return isAuthError && isSuccessRsp ? arr.concat(path) : arr;
  }, []);

  if (fields?.length && ENV !== 'production') {
    logger.warn(
      `🛑 You do not have permissions for these filds in ${operationName}()`,
      fields,
    );
  }
};
