import React from 'react';

import PropTypes from 'prop-types';

import {FileType, Icon} from '@renofi/components-internal';
import {
  Alert as AlertIcon,
  Progress as ProgressIcon,
  CloudCrossed,
} from '@renofi/icons';
import {dangerRegular} from '@renofi/theme';
import {taskDocumentStatuses} from '@renofi/utilities/src/enums/taskDocumentEnums';
import {isPasswordProtected} from '@renofi/utilities';

import {Circle} from '../styled';
import useHoverCancel from '../useHoverCancel';

const DocumentIconType = (props) => {
  const {disableCancel, fileName, internal, onCancel, status} = props;
  const {setHoverCancel} = useHoverCancel();

  switch (true) {
    case internal:
      return <FileType fileName={fileName} />;
    case status === taskDocumentStatuses.progress:
      return (
        <Circle
          disableCancel={disableCancel}
          onClick={(event) => {
            event.stopPropagation();
            onCancel();
          }}
          onMouseOver={() => !disableCancel && setHoverCancel(true)}
          onMouseOut={() => setHoverCancel(false)}>
          <ProgressIcon
            animate
            radius={16}
            stroke={4}
            duration={1000}
            showCenter={!disableCancel}
          />
        </Circle>
      );
    case isPasswordProtected(props):
      return <Icon name="encrypted" size={32} color={dangerRegular} />;
    case status === taskDocumentStatuses.rejected:
      return <AlertIcon width={32} height={32} />;
    case status === taskDocumentStatuses.failed:
      return <CloudCrossed width={32} height={32} />;
    default:
      return <FileType fileName={fileName} />;
  }
};

DocumentIconType.propTypes = {
  disableCancel: PropTypes.bool,
  fileName: PropTypes.string,
  internal: PropTypes.bool,
  onCancel: PropTypes.func,
  passwordProtectedFile: PropTypes.bool,
  rejectionReason: PropTypes.string,
  status: PropTypes.string,
};

export default DocumentIconType;
