import React, {memo, useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {omit, pick} from 'ramda';
import xss from 'xss';

import Flex from '@renofi/components-internal/src/Flex';
import {useTheme} from '@renofi/theme/src';
import noop from '@renofi/utilities/src/noop';

import {Error} from '../FieldFrame/styled';

import {Fake, Field, Help, Input, Label, Wrapper} from './styled';

const XSS_OPTIONS = {
  whiteList: {}, // empty, means filter out all tags
  stripIgnoreTag: true, // filter out all HTML not in the whilelist
  stripIgnoreTagBody: ['script'], // the script tag is a special case, we need to filter out its content
};
// const xssFilter = new xss(XSS_OPTIONS);

const containerKeys = [
  'className',
  'm',
  'mb',
  'ml',
  'mr',
  'mt',
  'mx',
  'my',
  'p',
  'pb',
  'pl',
  'pr',
  'pt',
  'px',
  'py',
  'style',
  'width',
];

const Textarea = memo(
  ({
    fake,
    error,
    required,
    enableXssFiltering = true,
    name,
    help,
    label,
    value: propsValue,
    style,
    disabled,
    className,
    placeholder,
    resizable = false,
    onChange = noop,
    onBlur = noop,
    onFocus = noop,
    active = false,
    rows = 3,
    ...props
  }) => {
    const [focus, setFocus] = useState(false);
    const {styles} = useTheme('textarea', {disabled, error, focus, ...props});
    const [value, setValue] = useState(propsValue || '');

    const blurHandler = (event) => {
      setFocus(false);
      onBlur(event);
    };

    const focusHandler = (event) => {
      setFocus(true);
      onFocus(event);
    };

    const changeHandler = (displayValue) => {
      const newValue = enableXssFiltering
        ? xss(displayValue, XSS_OPTIONS)
        : displayValue;

      setValue(displayValue);
      onChange(newValue);
    };

    const isEmpty = required && !value;
    const errorMessage = isEmpty ? 'Required' : error;

    useEffect(() => {
      if (!active) {
        return;
      }

      if (propsValue !== value) {
        setValue(propsValue);
      }
    }, [active, value, propsValue]);

    return (
      <Wrapper
        className="renofiTextField"
        mb={24}
        {...pick(containerKeys, props)}>
        {(label || help) && (
          <Flex
            justifyContent="space-between"
            alignItems="center"
            css={{fontSize: 15}}>
            <Label>{label}</Label>
            <Help>{help}</Help>
          </Flex>
        )}
        <Field
          error={errorMessage}
          style={style}
          css={styles}
          disabled={disabled}
          className={className}
          withLabel={label || help}>
          {fake && (
            <Fake
              rows={3}
              disabled={disabled}
              withLabel={Boolean(label)}
              {...omit(containerKeys, props)}>
              {value}
            </Fake>
          )}
          {!fake && (
            <Input
              rows={rows}
              name={name}
              value={value}
              disabled={disabled}
              resizable={resizable}
              placeholder={placeholder}
              withLabel={Boolean(label)}
              onChange={(event) => changeHandler(event.target.value)}
              {...omit(containerKeys, props)}
              onFocus={focusHandler}
              onBlur={blurHandler}
            />
          )}
          {/* {details && <Form.Text className="text-muted">{details}</Form.Text>} */}
        </Field>
        {errorMessage && (
          <Error className="renofiTextFieldError">{errorMessage}</Error>
        )}
      </Wrapper>
    );
  },
);

Textarea.propTypes = {
  fake: PropTypes.bool,
  required: PropTypes.bool,
  money: PropTypes.bool,
  label: PropTypes.node,
  help: PropTypes.node,
  name: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.string,
  style: PropTypes.object,
  details: PropTypes.node,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  resizable: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  enableXssFiltering: PropTypes.bool,
  active: PropTypes.bool,
  rows: PropTypes.number,
};

export default Textarea;
