import {gql} from '@apollo/client';

export default gql`
  mutation detachDocumentsFromUnderwritingCondition(
    $documentIds: [ID!]!
    $underwritingConditionId: ID!
  ) {
    detachDocumentsFromUnderwritingCondition(
      documentIds: $documentIds
      underwritingConditionId: $underwritingConditionId
    ) {
      underwritingCondition {
        id
      }
    }
  }
`;
