import styled from '@emotion/styled';

import {External, Rotate} from '@renofi/icons';
import {basic15, basic80} from '@renofi/theme';

import Flex from '../../../../Flex';

const setIconStyles = ({disabled}) => ({
  color: basic80,
  opacity: disabled ? 0.6 : 1,
  cursor: disabled ? 'default' : 'pointer',
  userSelect: disabled ? 'none' : 'inherit',
});

export const Wrapper = styled.div(({maxWidth}) => ({
  maxWidth,
  position: 'relative',
  width: '100%',
  height: '100%',
}));

export const CustomIcons = styled(Flex)(() => ({
  flexDirection: 'column',
  position: 'absolute',
  gap: 16,
  right: 18,
  bottom: 320,
  zIndex: 999,
  paddingBottom: 16,
  borderBottom: `1px solid ${basic15}`,
}));

export const ExternalIcon = styled(External)(setIconStyles);

export const RotateIcon = styled(Rotate)(setIconStyles);
