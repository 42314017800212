import React from 'react';

import PropTypes from 'prop-types';

import {Box, Icon} from '@renofi/components-internal';
import {actionRegular, basic20, dangerRegular} from '@renofi/theme';

import {Circle} from '../styled';

const RemoveDocument = ({
  documentId,
  error,
  hoverRemove,
  onClick,
  onMouseOver,
  onMouseOut,
}) => {
  return (
    <Box>
      <Circle
        error={error}
        onClick={(event) => {
          event.stopPropagation();
          onClick(documentId);
        }}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}>
        <Icon
          name="trash"
          color={
            hoverRemove ? (error ? dangerRegular : actionRegular) : basic20
          }
        />
      </Circle>
    </Box>
  );
};

RemoveDocument.propTypes = {
  documentId: PropTypes.string,
  error: PropTypes.bool,
  hoverRemove: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
};

export default RemoveDocument;
