import {gql} from '@apollo/client';

export default gql`
  mutation transmitRenovationReadyFile(
    $projectId: ID!
    $lenderId: ID!
    $lenderFriendlyId: String!
    $applicationProcess: RenovationReadyFileApplicationProcess!
  ) {
    transmitRenovationReadyFile(
      projectId: $projectId
      lenderId: $lenderId
      lenderFriendlyId: $lenderFriendlyId
      applicationProcess: $applicationProcess
    ) {
      renovationReadyFile {
        id
        createdAt
      }
    }
  }
`;
