import {gql} from '@apollo/client';

import {incomeCommentary} from '../../partials/renovation';

export default gql`
  mutation submitProjectIncomeCommentary($incomeCommentary: ProjectIncomeCommentaryInputObject!, $projectId: ID!) {
    submitProjectIncomeCommentary(incomeCommentary: $incomeCommentary, projectId: $projectId) {
      incomeCommentary {
        ${incomeCommentary}
      }
    }
  }
`;
