export const ANTI_CLOCKWISE = -90;
export const DIV_ID = 'renofi_pdf_viewer';

export const VIEWER_CONFIG = {
  clientId: process.env.REACT_APP_ADOBE_CLIENT_ID,
  divId: DIV_ID,
};

export const PREVIEW_CONFIG = {
  showAnnotationTools: false,
  enableFormFilling: false,
  showThumbnails: false,
  showBookmarks: false,
  showDownloadPDF: false,
  showPrintPDF: false,
};
