import {gql} from '@apollo/client';

export default gql`
  mutation refreshSoftCreditCheck($softCreditCheckId: ID!) {
    refreshSoftCreditCheck(softCreditCheckId: $softCreditCheckId) {
      softCreditCheck {
        id
      }
    }
  }
`;
