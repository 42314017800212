import React from 'react';

import PropTypes from 'prop-types';

import Button from '@renofi/components-internal/src/Button';
import Flex from '@renofi/components-internal/src/Flex';
import Text from '@renofi/components-internal/src/Text';
import House from '@renofi/icons/src/House';

const UpdateTableHeader = ({onSubmit}) => (
  <Flex width={1} justifyContent="space-between" alignItems="center">
    <Flex alignItems="center">
      <House />
      <Text ml={16}>Update history</Text>
    </Flex>
    <Button onClick={onSubmit}>Submit update</Button>
  </Flex>
);

UpdateTableHeader.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default UpdateTableHeader;
