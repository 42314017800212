import {forwardRef, useEffect, useMemo, useRef, useState} from 'react';

import React, {createPortal} from 'react-dom';
import PropTypes from 'prop-types';
import {either, isEmpty, isNil} from 'ramda';

import useClickOutside from '@renofi/utilities/src/useClickOutside';
import noop from '@renofi/utilities/src/noop';
import useDebounce from '@renofi/utilities/src/useDebounce';

const getAdjustedPosition = ({bounds = {}, position}) => {
  if (either(isNil, isEmpty)(bounds)) {
    return position;
  }

  const {innerHeight, innerWidth} = window;
  const totalRectTop = bounds?.bottom + bounds?.height;
  const totalRectLeft = bounds?.right + bounds?.width;
  switch (true) {
    case position === 'bottom' && totalRectTop >= innerHeight:
      return 'top';
    case position === 'left' && totalRectLeft >= innerWidth:
      return 'right';
    default:
      return position;
  }
};

const Portal = forwardRef(
  (
    {
      children,
      domNode,
      onClickOutside = noop,
      position = 'bottom',
      forceWidth,
      ...props
    },
    ref,
  ) => {
    const [lastScrolled, setLastScrolled] = useState(null);
    const selfRef = useRef(null);
    useClickOutside(onClickOutside, selfRef);
    const {current} = ref || {};

    const onScroll = () => {
      setLastScrolled(Date.now());
    };

    const onDebouncedScroll = useDebounce(onScroll, 75);

    useEffect(() => {
      const instance = window.addEventListener(
        'scroll',
        onDebouncedScroll,
        true,
      );

      return () => {
        window.removeEventListener(instance, onDebouncedScroll, true);
      };
    }, []);

    const offset = useMemo(() => {
      if (!current) {
        return {};
      }

      const bounds = current.getBoundingClientRect();
      const adjustedPosition = getAdjustedPosition({bounds, position});

      const width = forceWidth || bounds?.width || 0;

      switch (adjustedPosition) {
        case 'self':
          return {
            width,
            top: bounds.top,
            left: bounds.left,
          };
        case 'bottom':
          return {
            width,
            top: bounds.top + bounds.height,
            left: bounds.left,
          };
        case 'bottom-right':
          return {
            width,
            top: bounds.top + bounds.height,
            left: bounds.right - width,
          };
        case 'top':
          return {
            width,
            bottom: window.innerHeight - bounds.top,
            left: bounds.left,
          };
        case 'right':
          return {
            top: bounds.top,
            left: bounds.left + bounds.width,
          };
        case 'left':
          return {
            top: bounds.top,
            right: window.innerWidth - bounds.x,
          };
        default:
          return {};
      }
    }, [current, lastScrolled, position]);

    const css = {
      position: 'fixed',
      zIndex: 1060,
      ...offset,
      ...(props.styles || {}),
    };

    return createPortal(
      <div ref={selfRef} css={css}>
        {children}
      </div>,
      domNode || document.body,
    );
  },
);

Portal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  domNode: PropTypes.any,
  onClickOutside: PropTypes.func,
  position: PropTypes.oneOf(['top', 'bottom', 'left', 'right', 'self']),
  styles: PropTypes.object,
};

export default Portal;
