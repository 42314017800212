import styled from '@emotion/styled';

import Progress from '@renofi/icons/src/Progress';
import {actionExtraLight} from '@renofi/theme/src/colors';

import close from './close.svg';
import closeActive from './close-active.svg';

const RADIUS = 20;
const DIAMETER = RADIUS * 2;
const CLOSE_SIZE = 16;

export const Container = styled.div({
  position: 'relative',
  width: DIAMETER,
  height: DIAMETER,
  cursor: 'pointer',
  borderRadius: DIAMETER,

  ':hover': {
    backgroundColor: actionExtraLight,
    svg: {
      display: 'none',
    },
  },
});

export const ProgressIcon = styled(Progress)({
  position: 'absolute',
  top: 0,
  left: 0,
});

export const CloseIcon = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,

  width: '100%',
  height: '100%',

  backgroundImage: `url('${close}')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: `${CLOSE_SIZE}px ${CLOSE_SIZE}px`,
  backgroundPosition: 'center center',
  ':hover': {
    backgroundImage: `url('${closeActive}')`,
  },
});
