import {either, isEmpty, isNil, pipe, prop} from 'ramda';

import {branch, compose, renderNothing, withProps} from '@renofi/recompose/src';
import {withLender, withUser} from '@renofi/graphql/src';

export default compose(
  withUser(),
  withProps(({user}) => ({lenderId: user?.lenderId})),
  branch(pipe(prop('lenderId'), either(isNil, isEmpty)), renderNothing),
  withLender(),
);
