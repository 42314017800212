import {gql} from '@apollo/client';

import {underwritingRequest} from '../../partials';

export default gql`
  query renovationUnderwritingRequestById($id: ID!) {
    renovationUnderwritingRequestById(id: $id) {
      ${underwritingRequest}
    }
  }
`;
