export default `
id
projectId
taskId
applicantSubmittedAt
borrowerIsOnLoan
borrowerIsOnTitle
borrowersNumber
coborrowerIsPresent
coborrowerFirstName
coborrowerIsOnTitle
coborrowerLastName
coborrowerEmail
firstName
lastModifiedAt
lastModifiedById
lastName
propertyCity
propertyCounty
propertyOwnedByTrust
propertyState
propertyStreetAddressOne
propertyStreetAddressTwo
propertyType
propertyZipCode
propertyUseType
propertyStructureType
reasonForRenovating
renovationTypeDescription
`;
