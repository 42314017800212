import {gql} from '@apollo/client';

export default gql`
  mutation markInsuranceReviewAsCompleted($id: ID!) {
    markInsuranceReviewAsCompleted(id: $id) {
      insuranceReview {
        id
        carrier
        completedAt
        completedById
        completedBy {
          id
          firstName
          lastName
        }
        coverageDuringRenovationPeriod
        coverageProofReceivedAt
        coverageProofRequestedAt
        coverageProofRequestScheduledAt
        createdAt
        dwellingCoverage
        expansiveRenovation
        largeAmountRenovation
        policyDateVisible
        policyEffectiveDate
        renovationIncludesRoofWorks
        renovationIncludesStructuralChanges
        updatedAt
      }
    }
  }
`;
