import styled from '@emotion/styled';

import {
  actionRegular,
  basic55,
  border,
  dangerRegular,
  white,
} from '@renofi/theme/src/colors';
import show from '@renofi/icons/images/show.svg';
import hide from '@renofi/icons/images/hide.svg';

import Box from '../Box';

export const Wrapper = styled(Box)({
  width: '100%',
  position: 'relative',
});

export const Input = styled.input(
  {
    display: 'block',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    border: 'none',
    backgroundColor: 'transparent',
    outline: 'none',
  },
  ({invalid, visible}) => ({
    letterSpacing: visible ? 'normal' : '0.25em',
    color: invalid ? dangerRegular : basic55,
    fontSize: visible ? 16 : 24,
    ...(invalid
      ? {
          animation: 'shake 0.6s cubic-bezier(.36,.07,.19,.97) both',
          transform: 'translate3d(0, 0, 0)',
        }
      : {}),
  }),
);

export const Toggle = styled.div(
  {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: 52,
    cursor: 'pointer',
    borderLeft: `1px solid ${border}`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '20px 20px',
  },
  ({visible, large, focus}) => ({
    backgroundImage: `url('${visible ? hide : show}')`,
    height: large ? 50 : 42,
    borderColor: focus ? actionRegular : border,
  }),
);
