import * as React from 'react';

import PropTypes from 'prop-types';

import {basic20} from '@renofi/theme/src/colors';

const SvgBulletList = ({color, width, ...props}) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 13 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g id="toc">
        <path
          d="M12.8555 7.64286V6.21429H2.85547V7.64286H12.8555ZM12.8555 4.78571V3.35714H2.85547V4.78571H12.8555ZM12.8555 1.92857V0.5H2.85547V1.92857H12.8555ZM0.712612 7.64286C0.914993 7.64286 1.08452 7.57429 1.22118 7.43714C1.35833 7.30048 1.4269 7.13095 1.4269 6.92857C1.4269 6.72619 1.35833 6.55643 1.22118 6.41929C1.08452 6.28262 0.914993 6.21429 0.712612 6.21429C0.510231 6.21429 0.340708 6.28262 0.204041 6.41929C0.0668983 6.55643 -0.0016737 6.72619 -0.0016737 6.92857C-0.0016737 7.13095 0.0668983 7.30048 0.204041 7.43714C0.340708 7.57429 0.510231 7.64286 0.712612 7.64286ZM0.712612 4.78571C0.914993 4.78571 1.08452 4.71714 1.22118 4.58C1.35833 4.44333 1.4269 4.27381 1.4269 4.07143C1.4269 3.86905 1.35833 3.69929 1.22118 3.56214C1.08452 3.42548 0.914993 3.35714 0.712612 3.35714C0.510231 3.35714 0.340708 3.42548 0.204041 3.56214C0.0668983 3.69929 -0.0016737 3.86905 -0.0016737 4.07143C-0.0016737 4.27381 0.0668983 4.44333 0.204041 4.58C0.340708 4.71714 0.510231 4.78571 0.712612 4.78571ZM0.712612 1.92857C0.914993 1.92857 1.08452 1.86 1.22118 1.72286C1.35833 1.58619 1.4269 1.41667 1.4269 1.21429C1.4269 1.0119 1.35833 0.842143 1.22118 0.705C1.08452 0.568333 0.914993 0.5 0.712612 0.5C0.510231 0.5 0.340708 0.568333 0.204041 0.705C0.0668983 0.842143 -0.0016737 1.0119 -0.0016737 1.21429C-0.0016737 1.41667 0.0668983 1.58619 0.204041 1.72286C0.340708 1.86 0.510231 1.92857 0.712612 1.92857Z"
          fill={color}
        />
        <path
          d="M12.8555 10.5V9.07143H2.85547V10.5H12.8555ZM0.712612 10.5C0.914993 10.5 1.08452 10.4314 1.22118 10.2943C1.35833 10.1576 1.4269 9.9881 1.4269 9.78571C1.4269 9.58333 1.35833 9.41357 1.22118 9.27643C1.08452 9.13976 0.914993 9.07143 0.712612 9.07143C0.510231 9.07143 0.340708 9.13976 0.204041 9.27643C0.0668983 9.41357 -0.0016737 9.58333 -0.0016737 9.78571C-0.0016737 9.9881 0.0668983 10.1576 0.204041 10.2943C0.340708 10.4314 0.510231 10.5 0.712612 10.5Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

SvgBulletList.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
};

SvgBulletList.defaultProps = {
  color: basic20,
  width: 13,
};

export default SvgBulletList;
