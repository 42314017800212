import {STORAGE_PREFIX} from './constants';
import getEngine from './getEngine';

export default class Storage {
  constructor({engine = getEngine(), prefix = STORAGE_PREFIX} = {}) {
    this.engine = engine;
    this.prefix = prefix;
  }

  _getKey(key) {
    return `${this.prefix}${key}`;
  }

  setItem(key, value) {
    return this.engine.setItem(this._getKey(key), JSON.stringify(value));
  }

  getItem(key) {
    try {
      const value = this.engine.getItem(this._getKey(key));
      return JSON.parse(value);
    } catch (_err) {
      return null;
    }
  }

  removeItem(key) {
    return this.engine.removeItem(this._getKey(key));
  }

  clear() {
    return this.engine.clear();
  }
}
