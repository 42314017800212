import * as React from 'react';

import PropTypes from 'prop-types';

const SvgZip = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <mask id="zip_svg__a" fill="#fff">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.56 0A3.56 3.56 0 0 0 8 3.56v24.88A3.56 3.56 0 0 0 11.56 32h16.88A3.56 3.56 0 0 0 32 28.44V3.539 8l-8-8H11.56z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.56 0A3.56 3.56 0 0 0 8 3.56v24.88A3.56 3.56 0 0 0 11.56 32h16.88A3.56 3.56 0 0 0 32 28.44V3.539 8l-8-8H11.56z"
        fill="#fff"
      />
      <path
        d="m32 3.539 1-.006-2 .006h1zM32 8l-.707.707L33 10.414V8h-1zm-8-8 .707-.707L24.414-1H24v1zM9 3.56A2.56 2.56 0 0 1 11.56 1v-2A4.56 4.56 0 0 0 7 3.56h2zm0 24.88V3.56H7v24.88h2zM11.56 31A2.56 2.56 0 0 1 9 28.44H7A4.56 4.56 0 0 0 11.56 33v-2zm16.88 0H11.56v2h16.88v-2zM31 28.44A2.56 2.56 0 0 1 28.44 31v2A4.56 4.56 0 0 0 33 28.44h-2zm0-24.88v24.88h2V3.56h-2zm0-.015v.015h2v-.027l-2 .012zm0-.006V8h2V3.539h-2zm1.707 3.754-8-8L23.293.707l8 8 1.414-1.414zM11.56 1H24v-2H11.56v2z"
        fill="#EBEBEB"
        mask="url(#zip_svg__a)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m24 0 8 8h-6a2 2 0 0 1-2-2V0z"
        fill="#A6ADBC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 8h5v5l-5-5z"
        fill="#D2D6DE"
      />
      <rect y={3} width={21} height={10} rx={2} fill="#ECA527" />
      <path
        d="M7.852 11H2.965V9.91l2.898-3.6H3.105V5.184h4.59v1.109L4.801 9.875h3.05V11zm4.187 0H8.633V9.969h.953V6.215h-.953V5.184h3.406v1.03h-.953V9.97h.953V11zm6.024-3.98c0 .26-.046.515-.137.765a1.703 1.703 0 0 1-.39.625c-.233.23-.492.402-.778.52-.284.117-.638.175-1.063.175h-.933V11h-1.5V5.184h2.468c.37 0 .681.032.934.097.255.063.48.158.676.285.234.154.413.35.535.59.125.24.188.528.188.864zm-1.551.035a.68.68 0 0 0-.133-.422.716.716 0 0 0-.309-.25 1.356 1.356 0 0 0-.457-.102 11.861 11.861 0 0 0-.593-.011h-.258v1.742h.43c.255 0 .464-.016.628-.047a.895.895 0 0 0 .418-.188.708.708 0 0 0 .207-.297c.045-.117.067-.259.067-.425z"
        fill="#fff"
      />
      <path
        fill="#C9C9C9"
        d="M20 17h2v2h-2zm-2 2h2v2h-2zm2 2h2v2h-2zm-2 2h2v2h-2zm2 2h2v2h-2zm-2 2h2v2h-2zm2 2h2v2h-2z"
      />
      <path fill="#C9C9C9" d="M18 15h4v6h-4z" />
      <path fill="#fff" d="M19 18h2v2h-2z" />
    </svg>
  );
};

SvgZip.propTypes = {
  color: PropTypes.string,
};
export default SvgZip;
