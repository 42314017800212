import React from 'react';

import PropTypes from 'prop-types';

import {basic15} from '@renofi/theme/src/colors';

const SvgAcceptCircle = ({color, width}) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox={`0 0 ${width} ${width}`}
      fill="none">
      <path
        d="M7 12.5L10.5 16L17.09 9.41L15.68 8L10.5 13.17L8.41 11.09L7 12.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1ZM12 2.5C6.75329 2.5 2.5 6.75329 2.5 12C2.5 17.2467 6.75329 21.5 12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5Z"
        fill={color}
      />
    </svg>
  );
};

SvgAcceptCircle.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
};

SvgAcceptCircle.defaultProps = {
  color: basic15,
  width: 24,
};

export default SvgAcceptCircle;
