import {gql} from '@apollo/client';

import {gcddDocument} from '../../partials';

export default gql`
  mutation reviewDocument($documentType: DocumentTypeEnum!, $gcddReviewId: ID, $id: ID!, $reviewResult: ReviewResultEnum!) {
    reviewDocument(documentType: $documentType, gcddReviewId: $gcddReviewId, id: $id, reviewResult: $reviewResult) {
      document {
        ${gcddDocument}

      }
    }
  }
`;
