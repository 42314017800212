import * as React from 'react';

import PropTypes from 'prop-types';

const EventUpcoming = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M14 20V18H18V8H4V12H2V4C2 3.45 2.19567 2.97933 2.587 2.588C2.979 2.196 3.45 2 4 2H5V0H7V2H15V0H17V2H18C18.55 2 19.021 2.196 19.413 2.588C19.8043 2.97933 20 3.45 20 4V18C20 18.55 19.8043 19.021 19.413 19.413C19.021 19.8043 18.55 20 18 20H14ZM7 22L5.6 20.6L8.175 18H0V16H8.175L5.6 13.4L7 12L12 17L7 22ZM4 6H18V4H4V6Z"
        fill={color}
      />
    </svg>
  );
};

EventUpcoming.propTypes = {
  color: PropTypes.string,
};

export default EventUpcoming;
