import * as React from 'react';

import PropTypes from 'prop-types';

const SvgLivingRoom = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.453 8.426H5.687a.432.432 0 0 1-.432-.432V1.49c0-.239.193-.432.432-.432h12.766c.239 0 .432.193.432.432v6.504c0 .24-.194.432-.432.432zM6.119 7.563h11.902v-5.64H6.119v5.64zM17.068 7h-10a.432.432 0 0 1-.432-.432V2.932c0-.239.193-.432.432-.432h10c.238 0 .432.193.432.432v3.636c0 .24-.194.432-.432.432zM7.5 6.136h9.136V3.364H7.5v2.772zm12.16 5.143v2.364a1.694 1.694 0 0 1 .875 2.708c.15.067.256.218.256.394v2.71a.431.431 0 0 1-.432.432h-.7v1.347a.431.431 0 1 1-.864 0v-1.347H5.21v1.347a.431.431 0 1 1-.864 0v-1.347h-.7a.432.432 0 0 1-.432-.431v-2.71c0-.153.079-.287.198-.364A1.684 1.684 0 0 1 3 15.28c0-.814.578-1.496 1.345-1.656V11.28a.43.43 0 0 1 .432-.432h14.45c.239 0 .432.193.432.432zm-2.125 4c0 .39.132.748.354 1.034h-5.294v-4.602h6.2v1.932a1.694 1.694 0 0 0-1.26 1.636zM6.03 16.313h5.702v-4.602H5.209v1.957a1.694 1.694 0 0 1 .82 2.645zm13.897 2.71H4.078v-1.846h15.848v1.847zM3.864 15.28a.829.829 0 0 1 1.655 0 .829.829 0 0 1-1.655 0zm15.363-.827a.828.828 0 1 0 .002 1.656.828.828 0 0 0-.002-1.656z"
        fill={color}
      />
    </svg>
  );
};

SvgLivingRoom.propTypes = {
  color: PropTypes.string,
};
export default SvgLivingRoom;
