import {gql} from '@apollo/client';

export default gql`
  query feasibilityReviewers {
    feasibilityReviewers {
      id
      active
      email
      firstName
      fullName
      greetingName
      lastName
    }
  }
`;
