import styled from '@emotion/styled';

import {mediaQuery} from '@renofi/theme';

import Link from '../Link';

import arrowRed from './arrow-red.svg';

export default styled(Link)(
  mediaQuery({
    display: 'inline-block',
    width: 12,
    height: 18,
    cursor: 'pointer',
    marginRight: [8, 20],
    backgroundImage: `url('${arrowRed}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPosition: 'center center',
  }),
);
