const user = `
id
email
firstName
lastName
`;

export default `
id
external
assignedTo {
  ${user}
}
responsibleAgent
completableByCurrentUser
completableByAssigned
completedAt
condition
createdAt
createdBy {
  ${user}
}
documents {
  id
  fileName
  fileSizeBytes
  documentType
  objectName
  createdAt
}
reassignedAt
status
updatedAt
type
completionStage
history {
  createdAt
  updatedBy {
    fullName
  }
  performerNotes
  note {
    id
    body
  }
  updatedResponsibleAgent
  updatedAssignedTo {
    id
    firstName
    lastName
  }
  updatedAssignedToId
  updatedStatus
}
`;
