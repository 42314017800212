import React from 'react';

import PropTypes from 'prop-types';

import Context from './context';

const Provider = ({config, ...props}) => (
  <Context.Provider {...props} value={config} />
);

Provider.propTypes = {
  config: PropTypes.object.isRequired,
};

export default Provider;
