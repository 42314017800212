import {differenceInDays, formatDuration, intervalToDuration} from 'date-fns';

import {
  MILLISECONDS,
  MILLISECONDS_PER_DAY,
  MILLISECONDS_PER_HOUR,
  MILLISECONDS_PER_MINUTE,
  MILLISECONDS_PER_MONTH,
  MILLISECONDS_PER_WEEK,
  MILLISECONDS_PER_YEAR,
} from './constants';

const getFullDate = (value) =>
  value instanceof Date ? value : new Date(value);

export const dateTimeDiff = (date1, date2 = new Date()) => {
  const diff = Math.round(date2.getTime() - date1.getTime());
  if (!diff) return '';

  const {timePeriod, label} = getTimePeriod(diff);
  const value = (diff / timePeriod).toFixed(0);
  return `${value} ${label}${value >= 2 ? 's' : ''}`;
};

const getTimePeriod = (diff) => {
  if (diff >= MILLISECONDS_PER_YEAR)
    return {timePeriod: MILLISECONDS_PER_YEAR, label: 'year'};
  if (diff >= MILLISECONDS_PER_MONTH)
    return {timePeriod: MILLISECONDS_PER_MONTH, label: 'month'};
  if (diff >= MILLISECONDS_PER_WEEK)
    return {timePeriod: MILLISECONDS_PER_WEEK, label: 'week'};
  if (diff >= MILLISECONDS_PER_DAY)
    return {timePeriod: MILLISECONDS_PER_DAY, label: 'day'};
  if (diff >= MILLISECONDS_PER_HOUR)
    return {timePeriod: MILLISECONDS_PER_HOUR, label: 'hour'};
  if (diff >= MILLISECONDS_PER_MINUTE)
    return {timePeriod: MILLISECONDS_PER_MINUTE, label: 'minute'};
  if (diff >= MILLISECONDS) return {timePeriod: MILLISECONDS, label: 'second'};
};

export const getFullYearsFromDays = (numberOfDays = 0) => {
  let years = Math.floor(numberOfDays / 365);
  let months = Math.floor((numberOfDays % 365) / 30);
  if (months === 12) {
    years++;
    months = 0;
  }

  return {
    yearsDiff: years,
    diffText: years + ' years, ' + months + ' months',
  };
};

export const getFullDayCount = (updatedAddressDetails) => {
  let daysCount = 0;
  updatedAddressDetails.forEach((item) => {
    const {residenceFrom, residenceTo, currentlyLiveHere} = item;
    const start = getFullDate(residenceFrom);
    const end = currentlyLiveHere ? new Date() : getFullDate(residenceTo);

    if (residenceFrom && (residenceTo || currentlyLiveHere)) {
      daysCount += differenceInDays(end, start);
    }
  });

  return daysCount;
};

export const diffYearMonthDay = (
  fromDate = new Date(),
  toDate = new Date(),
) => {
  const start = getFullDate(fromDate);
  const end = getFullDate(toDate);
  let duration = {};

  try {
    duration = intervalToDuration({start, end});
  } catch (err) {
    duration = {};
  }

  return {
    yearsDiff: duration?.years || 0,
    daysDiff: duration?.days || 0,
    diffText: formatDuration(duration, {
      delimiter: ', ',
      format: ['years', 'months', 'days'],
    }),
  };
};

export const convertSlashDateToISOString = (dateString) => {
  if (typeof dateString !== 'string') {
    return dateString;
  }

  const [month, day, year] = dateString.split('/');
  const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(
    2,
    '0',
  )}`;

  return `${formattedDate}T00:00:00Z`;
};

export const formatIsoDate = (
  isoDateString,
  {separator = '/', includeTime = false} = {},
) => {
  if (!isoDateString) return null;

  const date = new Date(isoDateString);

  const day = `${date.getUTCDate()}`.padStart(2, '0');
  const month = `${date.getUTCMonth() + 1}`.padStart(2, '0');
  const year = date.getUTCFullYear();
  const time = `${date.getUTCHours()}${separator}${date.getUTCMinutes()}`;
  const parts = includeTime ? [month, day, year, time] : [month, day, year];

  return parts.join(separator);
};
