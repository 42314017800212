import * as React from 'react';

import PropTypes from 'prop-types';

const SvgCheckCircle = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" />
        <path
          d="M17.049 7.138 9.8 14.387l-3.949-3.938L4.3 12l5.5 5.5 8.8-8.8-1.551-1.562zM12 1C5.928 1 1 5.928 1 12s4.928 11 11 11 11-4.928 11-11S18.072 1 12 1zm-.032 20.5a9.497 9.497 0 0 1-9.5-9.5c0-5.249 4.251-9.5 9.5-9.5s9.5 4.251 9.5 9.5-4.251 9.5-9.5 9.5z"
          fill={color}
        />
      </g>
    </svg>
  );
};

SvgCheckCircle.propTypes = {
  color: PropTypes.string,
};
export default SvgCheckCircle;
