import {gql} from '@apollo/client';

export default gql`
  mutation markTpoRegistrationAsCompleted($id: ID!) {
    markTpoRegistrationAsCompleted(id: $id) {
      projectTimeline {
        id
        tpoRegistrationCompletedAt
      }
    }
  }
`;
