import {gql} from '@apollo/client';

export default gql`
  mutation submitContractorInformation(
    $taskId: ID!
    $id: ID
    $contractorInformation: ContractorInformationInputObject!
  ) {
    submitContractorInformation(
      taskId: $taskId
      contractorInformation: $contractorInformation
      id: $id
    ) {
      contractor {
        businessName
        city
        contactName
        contactTitle
        contractorGeneralLiabilityInsuranceDocument {
          checksum
          createdAt
          fileName
          fileSizeBytes
          id
          objectName
          rejectionReason
          shareWithLenderAllowed
          shareable
          source
          status
        }
        contractorLicenseDocument {
          checksum
          createdAt
          fileName
          fileSizeBytes
          id
          objectName
          rejectionReason
          shareWithLenderAllowed
          shareable
          source
          status
        }
        createdAt
        email
        estimatedCostOfWork
        id
        licenseNumber
        phoneNumber
        providedProofOfGeneralLiabilityInsurance
        providedLicenseNumberOrDocument
        rejectionDetails
        rejectionReason
        reviewedAt
        scopeOfWork
        secondaryAddress
        state
        status
        streetAddress
        updatedAt
        website
        zipCode
      }
    }
  }
`;
