import React, {useContext, useEffect, useMemo, useState} from 'react';

import PropTypes from 'prop-types';
import {isEmpty, propOr} from 'ramda';
import camelcaseKeys from 'camelcase-keys';
import {initializeApp} from 'firebase/app';
import {getAuth, signInAnonymously} from 'firebase/auth';
import {onSnapshot, doc, initializeFirestore} from 'firebase/firestore';

import {Context as ConfigContext} from '@renofi/utilities/src/config';
import logger from '@renofi/utilities/src/logger';
import {
  ReconfContext,
  useInjectReconfScript,
} from '@renofi/utilities/src/reconf';

import {getFirebaseOptions, getInitialData, hasAllEnvVars} from './utils';

const ReconfFactory = ({children}) => {
  const [config, setConfig] = useState(getInitialData());
  const [isReady, setIsReady] = useState(false);
  const systemConfig = useContext(ConfigContext);
  const {appName, appEnvironment} = useInjectReconfScript();
  const hasSettings = hasAllEnvVars();
  const environment = systemConfig['ENVIRONMENT'];

  useEffect(() => {
    const data = getInitialData();
    setConfig(data);
    setIsReady(Boolean(window.RECONF_CONFIG));
    if (window.RECONF_CONFIG && environment !== 'production') {
      logger.log(`Reconf: Remote ${environment} config.js file loaded:`, data);
    }
  }, [window.RECONF_CONFIG]);

  useEffect(() => {
    (async () => {
      if (!hasSettings) {
        return setIsReady(true);
      }

      const firebaseOptions = getFirebaseOptions();
      const firebaseApp = initializeApp(firebaseOptions);
      const db = initializeFirestore(firebaseApp, {
        experimentalForceLongPolling: true,
        useFetchStreams: false,
      });
      const auth = getAuth();
      await signInAnonymously(auth);

      onSnapshot(doc(db, appEnvironment, appName), (doc) => {
        const {data} = doc?.data() || {};
        if (!data) {
          return;
        }

        const remoteData = camelcaseKeys(data, {deep: true});
        setConfig(remoteData);
        setIsReady(true);

        if (environment !== 'production') {
          logger.log(
            `Reconf: Firebase ${environment} data loaded:`,
            remoteData,
          );
        }
      });
    })();
  }, []);

  const value = useMemo(() => {
    const flags = propOr({}, 'featureFlags', config);
    const hasNoFlags = isEmpty(flags);

    return {config, flags, hasNoFlags, isReady};
  }, [config, isReady]);

  return isReady ? (
    <ReconfContext.Provider value={value}>{children}</ReconfContext.Provider>
  ) : null;
};

ReconfFactory.propTypes = {
  children: PropTypes.node,
};

export default ReconfFactory;
