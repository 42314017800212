export default function isDateOnlyWithoutTime(date) {
  // Ensure the input is a valid Date object
  if (!(date instanceof Date) || isNaN(date)) {
    throw new Error('Invalid Date object');
  }

  // Extract local time components
  const localHours = date.getHours();
  const localMinutes = date.getMinutes();
  const localSeconds = date.getSeconds();
  const localMilliseconds = date.getMilliseconds();

  // Extract UTC time components
  const utcHours = date.getUTCHours();
  const utcMinutes = date.getUTCMinutes();
  const utcSeconds = date.getUTCSeconds();
  const utcMilliseconds = date.getUTCMilliseconds();

  // Check if all local and UTC time components are zero
  const isLocalMidnight =
    localHours === 0 &&
    localMinutes === 0 &&
    localSeconds === 0 &&
    localMilliseconds === 0;
  const isUTCMidnight =
    utcHours === 0 &&
    utcMinutes === 0 &&
    utcSeconds === 0 &&
    utcMilliseconds === 0;

  return isLocalMidnight || isUTCMidnight;
}
