import memoryStorage from './memory';

export default (type = 'local') => {
  switch (type) {
    case 'memory':
      return memoryStorage;
    case 'session':
      return sessionStorage;
    default:
      return localStorage;
  }
};
