export default `
callEndsAt
callStartsAt
createdAt
id
renofiAdvisorId
taskId
callEventId
updatedAt
`;
