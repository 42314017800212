import React from 'react';

import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import {basic15} from '@renofi/theme/src/colors';

const ANGLES = {
  up: 0,
  down: 180,
  left: -90,
  right: 90,
};

const Svg = styled('svg')(({direction}) => {
  const angle = ANGLES[direction] || 0;

  return {
    transform: `rotate(${angle}deg)`,
  };
});

const DEFAULT_WIDTH = 16;
const RATIO = DEFAULT_WIDTH / 9;

function SortArrow({direction, color, onClick, width}) {
  const height = Math.round(width / RATIO);

  return (
    <Svg
      direction={direction}
      width={width}
      height={height}
      viewBox="0 0 16 9"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.800049 7.64331L2.06905 8.99045L7.10005 3.65924L8.00005 2.80554L8.90005 3.65924L13.922 9L15.2 7.64331L8.00005 0L0.800049 7.64331Z"
        fill={color}
      />
    </Svg>
  );
}

SortArrow.propTypes = {
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
  color: PropTypes.string,
  onClick: PropTypes.func,
  width: PropTypes.number,
};

SortArrow.defaultProps = {
  direction: 'up',
  color: basic15,
  onClick: () => {},
  width: DEFAULT_WIDTH,
};

export default SortArrow;
