import {compose, withProps} from '@renofi/recompose/src';
import salesforceUrl from '@renofi/utilities/src/salesforceUrl';

import Link from './Link';

export default compose(
  withProps(({salesforceBaseUrl, objectType, objectId}) => ({
    blank: true,
    href: salesforceUrl(salesforceBaseUrl, objectType, objectId),
  })),
)(Link);
