import {gql} from '@apollo/client';

export default gql`
  mutation markContractorInformationAsDuplicate(
    $contractorId: ID!
    $contractorInformationId: ID!
  ) {
    markContractorInformationAsDuplicate(
      contractorId: $contractorId
      contractorInformationId: $contractorInformationId
    ) {
      success
    }
  }
`;
