import styled from '@emotion/styled';

import {Box, Flex} from '@renofi/components-internal';
import {maxSmall} from '@renofi/theme/src/breakpoints';

export const Container = styled(Flex)({
  alignItems: 'center',
  svg: {
    cursor: 'pointer',
  },
});

export const StyledTitle = styled.span({
  fontSize: 40,
  fontWeight: 700,
  color: '#212F49',
  '@media (max-width: 700px)': {
    fontSize: '27px',
  },
  [maxSmall]: {
    fontSize: '25px',
  },
});

export const IconContainer = styled(Box)({
  marginRight: 16,
  marginBottom: 0,

  '&:hover': {
    svg: {
      color: '#df4748',
    },
  },
});
