import React, {useMemo} from 'react';

import PropTypes from 'prop-types';
import {useDropzone} from 'react-dropzone';
import {omit, pick} from 'ramda';

import getMimeTypes from '@renofi/utilities/src/mimeTypes';

import Box from '../Box';
import Button from '../Button';
import Flex from '../Flex';

import {
  Details,
  Help,
  Label,
  Placeholder,
  Wrapper,
  focusedStyle,
  rejectStyle,
} from './styled';
import laptop from './laptop.svg';

const DropZone = ({
  compact = false,
  smallIcons = false,
  onAcceptFiles,
  onRejectFiles = () => {},
  accept,
  help,
  label,
  height,
  children,
  message = 'Drop files here or use the Add files button',
  buttonLabel = 'Add files',
  ...props
}) => {
  const {getRootProps, getInputProps, isFocused, isDragAccept, isDragReject} =
    useDropzone({
      onDrop: (acceptedFiles, rejectedFiles) => {
        if (acceptedFiles && acceptedFiles.length) {
          onAcceptFiles(acceptedFiles);
        }
        if (rejectedFiles && rejectedFiles.length) {
          onRejectFiles(rejectedFiles);
        }
      },
      accept: accept.join(', '),
    });
  const {onClick, ...handlers} = getRootProps();

  const css = useMemo(
    () => ({
      ...(isFocused || isDragAccept ? focusedStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  return (
    <Wrapper
      {...handlers}
      {...pick(['m', 'mb', 'ml', 'mr', 'mt', 'mx', 'my'], props)}>
      <input {...getInputProps()} />
      {(label || help) && (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          css={{fontSize: 15}}
          mb="4px">
          <Label>{label}</Label>
          <Help>{help}</Help>
        </Flex>
      )}
      <Placeholder
        compact={compact}
        css={{...css, height}}
        {...omit(['m', 'mb', 'ml', 'mr', 'mt', 'mx', 'my'], props)}>
        {children || (
          <>
            {!compact && (
              <Box mt={16}>
                <img src={laptop} width={93} />
              </Box>
            )}
            <Details mt={compact ? 0 : 20}>{message}</Details>
            <Box mt={compact ? '8px' : 32}>
              <Button variant="outline" {...{onClick}}>
                {buttonLabel}
              </Button>
            </Box>
          </>
        )}
      </Placeholder>
    </Wrapper>
  );
};

DropZone.propTypes = {
  help: PropTypes.node,
  label: PropTypes.node,
  compact: PropTypes.bool,
  accept: PropTypes.arrayOf(PropTypes.string),
  variant: PropTypes.string,
  height: PropTypes.number,
  children: PropTypes.node,
  message: PropTypes.node,
  buttonLabel: PropTypes.node,
  onAcceptFiles: PropTypes.func.isRequired,
  onRejectFiles: PropTypes.func.isRequired,
  smallIcons: PropTypes.bool,
};

DropZone.defaultProps = {
  accept: getMimeTypes(),
};

export default DropZone;
