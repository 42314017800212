import {NetworkStatus} from '@apollo/client';

import useLazyQuery from './useLazyQuery';
import useQuery from './useQuery';

export default ({lazy = false, options = {}, query}) => {
  const hook = lazy ? useLazyQuery : useQuery;
  let response = hook(query, options);

  if (lazy) {
    const [
      fetch,
      {data, error, loading, networkStatus, refetch, startPolling, stopPolling},
    ] = response;
    const refetching = networkStatus === NetworkStatus.refetch;
    response = {
      data,
      error,
      loading,
      fetch,
      refetch,
      refetching,
      startPolling,
      stopPolling,
    };
  } else {
    const {data, error, loading, networkStatus, refetch} = response;
    const refetching = networkStatus === NetworkStatus.refetch;
    response = {
      data,
      error,
      loading,
      fetch: refetch,
      refetch,
      refetching,
    };
  }

  return response;
};
