import {gql} from '@apollo/client';

export default gql`
  mutation submitRevisionRequest(
    $appraisalOrderId: ID!
    $comments: String!
    $documentIds: [String!]
  ) {
    submitRevisionRequest(
      appraisalOrderId: $appraisalOrderId
      comments: $comments
      documentIds: $documentIds
    ) {
      success
    }
  }
`;
