import {gql} from '@apollo/client';

export default gql`
  query groupedGcddReviewSearch(
    $query: String
    $filter: GcddReviewSearchFilterInputObject
    $sort: GcddReviewSearchSortInputObject
    $page: Int
    $limit: Int
  ) {
    groupedGcddReviewSearch(
      query: $query
      filter: $filter
      page: $page
      limit: $limit
      sort: $sort
    ) {
      metadata {
        totalCount
        totalPages
        facets {
          assignedToId {
            id
            count
          }
          cancelled {
            value
            count
          }
          completed {
            value
            count
          }
          opened {
            value
            count
          }
          questionnaireReturned {
            value
            count
          }
          questionnaireSent {
            value
            count
          }
          submittedStep {
            value
            count
          }
          unopened {
            value
            count
          }
          withAppraiser {
            value
            count
          }
          overallScore {
            score
            count
          }
          priority {
            priority
            count
          }
        }
      }
      collection {
        id
        gcddReviews {
          assignedToId
          assignedTo {
            email
            firstName
            id
            lastName
            phoneNumber
          }
          id
          contractor {
            businessName
            id
          }
          projectId
          project {
            id
            renovationCost
          }
          completedAt
          estimatedCostOfWork
          overallScore
          priority
          complexityLevel
          questionnaire {
            id
            businessName
            createdAt
            firstOpenedAt
            returnedAt
            sendingPdfRequestedAt
            submittedStep
            submissionType
          }
        }
      }
    }
  }
`;
