import {gql} from '@apollo/client';

export default gql`
  mutation setDocumentType($id: ID!, $documentType: DocumentType!) {
    setDocumentType(id: $id, documentType: $documentType) {
      document {
        id
        createdAt
        checksum
        documentType
        fileName
        objectName
        pdfObjectName
        shareWithLenderAllowed
        shareable
        updatedAt
      }
    }
  }
`;
