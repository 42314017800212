import {gql} from '@apollo/client';

export default gql`
  mutation acceptDocument($id: ID!) {
    acceptDocument(id: $id) {
      document {
        id
        checksum
        createdAt
        documentType
        fileName
        objectName
        pdfObjectName
        rejectionDetails
        rejectionReason
        shareWithLenderAllowed
        shareable
        status
      }
    }
  }
`;
