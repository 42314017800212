import {gql} from '@apollo/client';

import {task} from '../../partials';

export default gql`
  mutation consentLenderAcknowledgement(
    $taskId: ID!
    $applicantSsnSuffix: String!
    $coapplicantSsnSuffix: String
  ) {
    consentLenderAcknowledgement(
      taskId: $taskId
      applicantSsnSuffix: $applicantSsnSuffix
      coapplicantSsnSuffix: $coapplicantSsnSuffix
    ) {
      task {
        ${task}
      }
    }
  }
`;
