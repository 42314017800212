import React, {lazy, Suspense} from 'react';

function componentLoader(lazyComponent, timeout, attempts) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (attempts === 1) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attempts - 1).then(resolve, reject);
        }, timeout);
      });
  });
}

const wrapPromise = (loadable, {attempts, timeout}) => {
  const promise = loadable();
  return () => {
    return componentLoader(() => promise, timeout, attempts);
  };
};

export default (loadable, fallback, options = {}) => {
  const attempts = options?.attempts || 10;
  const timeout = options?.timeout || 500;
  const AsyncComponent = lazy(wrapPromise(loadable, {attempts, timeout}));
  return (props) => {
    return (
      <Suspense fallback={fallback}>
        <AsyncComponent {...props} />
      </Suspense>
    );
  };
};
