import * as React from 'react';

import PropTypes from 'prop-types';

import {basic20} from '@renofi/theme/src/colors';

const SvgRenofi = ({color = basic20, width = 24, ...props}) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M4.332 6.554A.694.694 0 0 0 4 7.147v14.506c0 .192.154.347.343.347h3.652c.19 0 .344-.155.344-.347V9.533c0-.248.13-.477.343-.6l2.982-1.74a.68.68 0 0 1 .686 0l2.983 1.74a.694.694 0 0 1 .343.6v12.12c0 .192.153.347.343.347h3.637c.19 0 .344-.155.344-.347V7.147a.694.694 0 0 0-.332-.593L12.35 2.093a.681.681 0 0 0-.686 0l-7.332 4.46z"
          fill={color}
        />
      </g>
    </svg>
  );
};

SvgRenofi.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
};

export default SvgRenofi;
