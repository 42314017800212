import {assoc} from 'ramda';

const locale = 'en-US';

const longFormatter = new Intl.DateTimeFormat(locale, {
  dateStyle: 'long',
  timeZone: 'UTC',
});

const shortFormatter = new Intl.DateTimeFormat(locale, {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  timeZone: 'UTC',
});

export const formatDate = (value, {long = false, parts = false} = {}) => {
  const formatter = long ? longFormatter : shortFormatter;
  const isDate = value instanceof Date;
  const date = isDate ? value : new Date(value);
  if (!value) {
    return '';
  }

  try {
    if (parts) {
      const dateObj = formatter
        .formatToParts(date)
        .reduce((result, {type, value}) => assoc(type, value, result), {});
      return dateObj;
    }

    return formatter.format(date);
  } catch (error) {
    return 'Invalid date';
  }
};

export const toISOStringWithoutTime = (date) => {
  return new Date(
    date.getTime() - date.getTimezoneOffset() * 60000,
  ).toISOString();
};
