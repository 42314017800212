import {gql} from '@apollo/client';

export default gql`
  query underwritingConditionsSearch(
    $filter: UnderwritingConditionsSearchFilterInputObject
    $limit: Int
    $page: Int
    $query: String
    $sort: UnderwritingConditionsSearchSortInputObject
  ) {
    underwritingConditionsSearch(
      filter: $filter
      limit: $limit
      page: $page
      query: $query
      sort: $sort
    ) {
      collection {
        external
        completableByAssigned
        responsibleAgent
        completableByCurrentUser
        assignedTo {
          id
          fullName
        }
        completedAt
        completedBy {
          id
          fullName
        }
        condition
        createdAt
        createdBy {
          id
          fullName
        }
        id
        project {
          id
          borrower {
            id
            firstName
            lastName
          }
        }
        documents {
          id
          fileName
          fileSizeBytes
          documentType
          objectName
          createdAt
        }
        reassignedAt
        status
        history {
          createdAt
          updatedBy {
            fullName
          }
          note {
            id
            body
          }
          updatedResponsibleAgent
          updatedAssignedTo {
            id
            firstName
            lastName
          }
          updatedAssignedToId
          updatedStatus
        }
        type
        completionStage
        updatedAt
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
        facets {
          assignedToId {
            user {
              id
              fullName
            }
          }
          createdById {
            user {
              id
              fullName
            }
          }
          completedById {
            user {
              id
              fullName
            }
          }
        }
      }
    }
  }
`;
