import * as React from 'react';

import PropTypes from 'prop-types';

const SvgCommentInverse = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M21.99 4c0-1.1-.89-2-1.99-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4-.01-18zM18 14H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z"
          fill={color}
        />
      </g>
    </svg>
  );
};

SvgCommentInverse.propTypes = {
  color: PropTypes.string,
};
export default SvgCommentInverse;
