import React from 'react';

import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import {white} from '@renofi/theme/src/colors';

const ANGLES = {
  up: 180,
  down: 0,
  left: 90,
  right: -90,
};

const Svg = styled('svg')(({direction}) => {
  const angle = ANGLES[direction] || 0;

  return {
    transform: `rotate(${angle}deg)`,
  };
});

function Arrow({color = white, direction = 'down', width = 12, ...props}) {
  return (
    <Svg
      direction={direction}
      width={width}
      height={width}
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.59 0.5L6 4.82659L1.41 0.5L-2.47757e-07 1.83198L6 7.5L12 1.83198L10.59 0.5Z"
        fill={color}
      />
    </Svg>
  );
}

Arrow.propTypes = {
  color: PropTypes.string,
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
  width: PropTypes.number,
};

export default Arrow;
