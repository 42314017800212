import * as React from 'react';

import PropTypes from 'prop-types';

const SvgConsent = ({color = '#13C427', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}>
      <defs>
        <path
          d="m16.005 19.047-2.695-2.695 1.086-1.085 1.609 1.601 3.989-3.98 1.085 1.085-5.074 5.074z"
          id="consent_svg__a"
        />
      </defs>
      <g transform="translate(-4 -2)" fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"
          fill={color}
        />
        <circle fill={color} cx={17.16} cy={16.16} r={6.16} />
        <use fill="#FFF" xlinkHref="#consent_svg__a" />
      </g>
    </svg>
  );
};

SvgConsent.propTypes = {
  color: PropTypes.string,
};
export default SvgConsent;
