import {gql} from '@apollo/client';

export default gql`
  mutation cancelAppraisalOrder(
    $appraisalOrderId: ID!
    $reason: CancellationReason!
    $comments: String!
  ) {
    cancelAppraisalOrder(
      appraisalOrderId: $appraisalOrderId
      reason: $reason
      comments: $comments
    ) {
      success
    }
  }
`;
