import styled from '@emotion/styled';

import {actionRegular, basic10, basic55} from '@renofi/theme/src/colors';

import Flex from '../Flex';

export const Wrapper = styled(Flex)({});

export const Page = styled.button(
  {
    display: 'inline-block',
    fontSize: 14,
    lineHeight: '17px',
    textAlign: 'center',
    border: 'none',
    background: 'transparent',
    outline: 'none',
    padding: '8px 12px 16px 12px',
  },
  ({active, disabled}) => ({
    color: disabled ? basic55 : actionRegular,
    cursor: disabled ? 'default' : 'pointer',
    fontWeight: active ? 'bold' : 'normal',
    borderBottom: `solid 1px ${active ? actionRegular : basic10}`,
    ...(disabled
      ? {}
      : {
          color: actionRegular,
          ':hover': {
            backgroundColor: basic10,
          },
        }),
  }),
);
