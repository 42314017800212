import {gql} from '@apollo/client';

import {gcddDocument} from '../../partials';

export default gql`
  query contractorLicenses($contractorId: ID!, $limit: Int!, $page: Int!) {
    contractorLicenses(contractorId: $contractorId, limit: $limit, page: $page) {
      collection {
        ${gcddDocument}
        number
        state
        submittedById
        submittedBy {
          email
          firstName
          id
          lastName
          phoneNumber
        }
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;
