import {keys, pathOr} from 'ramda';
import queryString from 'query-string';

import {useImpersonate} from '@renofi/graphql/src/hooks';

import {openTab} from '../window';
import {setCookie} from '../cookies';
import {isDeployPreviewUrl} from '../netlify';

export default () => {
  const {impersonate, loading} = useImpersonate();

  const impersonateByUserId = async ({
    id,
    params = {},
    redirectUrl = '',
  } = {}) => {
    if (!redirectUrl) {
      return console.warn(`[🥸] No redirect URL given`);
    }

    const response = await impersonate({variables: {id}});
    const {jwt, refreshToken} = pathOr({}, ['data', 'impersonate'], response);
    const isPreviewUrl = isDeployPreviewUrl();

    // include extra params in the URL as part of Netlify Preview domains
    const options = isPreviewUrl
      ? {...params, jwt, refreshToken}
      : {jwt, refreshToken};
    const qString = queryString.stringify(options);

    // Set params as cookies
    keys(params).forEach((key) => {
      setCookie(key, params[key]);
    });

    if (!jwt) {
      return console.warn(`[🥸] No JWT while trying to impersonate user ${id}`);
    }

    console.warn(`[🥸] Opening: ${redirectUrl}?${qString}`);
    openTab(`${redirectUrl}?${qString}`);
  };

  return {
    impersonateByUserId,
    loading,
  };
};
