import React from 'react';

import PropTypes from 'prop-types';
import {propEq, reject} from 'ramda';

import {sendEvent} from '@renofi/utilities/src/analytics';
import noop from '@renofi/utilities/src/noop';

import Item from './components/Item';
import {Wrapper} from './styled';

const Progress = ({
  data,
  compact,
  flat,
  level,
  onRemoveItem,
  onSelectItem,
  ...props
}) => {
  const visible = reject(propEq('hidden', true), data);

  const onSelect = (id, {value} = {}) => {
    sendEvent('Wizard-Progress-Step-Click', {stepId: id, value});
    onSelectItem(id);
  };

  if (!visible.length) {
    return null;
  }

  return (
    <Wrapper {...props}>
      {visible.map((item) => {
        const id = item?.id || item?.value;

        return (
          <Item
            key={id}
            {...{compact, flat, ...item}}
            level={level}
            onRemove={() => onRemoveItem(id)}
            onSelect={(itemId) => onSelect(itemId || id, item)}
          />
        );
      })}
    </Wrapper>
  );
};

Progress.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      children: PropTypes.array,
      clickable: PropTypes.bool,
      compact: PropTypes.bool,
      complete: PropTypes.bool,
      canConfirmRemove: PropTypes.bool,
      current: PropTypes.bool,
      flat: PropTypes.bool,
      hidden: PropTypes.bool,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      info: PropTypes.node,
      label: PropTypes.node,
      removable: PropTypes.bool,
      simple: PropTypes.bool,
      value: PropTypes.node,
    }),
  ),
  compact: PropTypes.bool,
  flat: PropTypes.bool,
  level: PropTypes.number,
  onRemoveItem: PropTypes.func,
  onSelectItem: PropTypes.func,
};

Progress.defaultProps = {
  data: [],
  level: 0,
  onRemoveItem: noop,
  onSelectItem: noop,
};

export default React.memo(Progress);
