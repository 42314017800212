import {gql} from '@apollo/client';

export default gql`
  mutation resolveAdditionalDocumentRequests($projectId: ID!) {
    resolveAdditionalDocumentRequests(projectId: $projectId) {
      project {
        id
      }
    }
  }
`;
