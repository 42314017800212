import * as React from 'react';

import PropTypes from 'prop-types';

const SvgPorch = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.351 2.854a.8.8 0 0 0-.863.797v.941a.8.8 0 0 0 .8.8h15.287v14.562H2.101a.598.598 0 0 0 0 1.195h17.783a.598.598 0 0 0 0-1.195H18.77V5.392h3.253a.49.49 0 0 0 .04-.977L2.35 2.854z"
        fill={color}
      />
      <path
        d="M11.11 19.59a11.698 11.698 0 0 0 3.941-1.581l-.523-.751a10.58 10.58 0 0 1-.84.48l-.946-4.65.947-.192-.12-.593-6.215 1.264-1.502-3.633-.899.348 2.572 6.223.486 2.387a10.63 10.63 0 0 1-1.92-.318l-.28.876a11.693 11.693 0 0 0 5.298.14zm1.676-1.443a10.821 10.821 0 0 1-3.785.77l-.447-2.2 3.785-.77.447 2.2zm-1.305-4.802.63-.129.362 1.778-.63.128-.362-1.777zm-1.262.256.631-.128.362 1.777-.631.129-.362-1.778zm-1.261.257.63-.128.362 1.777-.63.129-.362-1.778zm-.631.128.361 1.778-.393.08-.706-1.707.738-.15z"
        fill={color}
      />
    </svg>
  );
};

SvgPorch.propTypes = {
  color: PropTypes.string,
};
export default SvgPorch;
