import * as React from 'react';

import PropTypes from 'prop-types';

const SvgDriveway = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M5 5.618A.61.61 0 0 1 5.6 5c.331 0 .6.277.6.618l-2 13.764a.609.609 0 0 1-.6.618.609.609 0 0 1-.6-.618L5 5.618zm12.8 0A.61.61 0 0 1 18.4 5c.331 0 .6.277.6.618l2 13.764a.609.609 0 0 1-.6.618.609.609 0 0 1-.6-.618l-2-13.764zm-6.4 11.294a.61.61 0 0 1 .6-.618c.331 0 .6.277.6.618v1.853a.61.61 0 0 1-.6.617.61.61 0 0 1-.6-.617v-1.853zm0-11.118a.61.61 0 0 1 .6-.618c.331 0 .6.277.6.618v1.853a.609.609 0 0 1-.6.618.609.609 0 0 1-.6-.618V5.794zm0 5.559a.61.61 0 0 1 .6-.618c.331 0 .6.277.6.618v1.853a.609.609 0 0 1-.6.617.609.609 0 0 1-.6-.617v-1.853z"
        fill={color}
      />
    </svg>
  );
};

SvgDriveway.propTypes = {
  color: PropTypes.string,
};
export default SvgDriveway;
