import {gql} from '@apollo/client';

export default gql`
  query propertyPhotos {
    propertyPhotos @client {
      id
      url
      checksum
      thumbnail
      objectName
      description
    }
  }
`;
