import {gql} from '@apollo/client';

export default gql`
  mutation updateComment($id: ID!, $subject: String!, $body: String!) {
    updateComment(id: $id, subject: $subject, body: $body) {
      comment {
        id
        body
        createdAt
        source
        subject
        updatedAt
        userId
        user {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`;
