import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {pathOr, pick} from 'ramda';

import {Box, Button, FileSize, Flex, PasswordField} from '@renofi/components-internal';
import {useIsFilePasswordValid} from '@renofi/graphql';
import {MARGIN_KEYS, PADDING_KEYS, basic10} from '@renofi/theme';
import {sendEvent} from '@renofi/utilities/src/analytics';
import {taskDocumentStatuses} from '@renofi/utilities/src/enums';

import {SizeLabel, Wrapper} from '../styled';

import DocumentIconType from './DocumentIconType';
import DocumentTimestamp from './DocumentTimestamp';
import RemoveDocument from './RemoveDocument';

const getIsValid = pathOr(false, ['data', 'isFilePasswordValid']);

const PasswordPrompt = (props = {}) => {
  const {
    disableCancel,
    fileName,
    fileSizeBytes,
    fileType,
    hoverRemove,
    id: documentId,
    internal,
    isFileSelected,
    objectName,
    onCancel,
    onDecrypt,
    onFileSelect,
    onRemove,
    onMouseOverRemove,
    onMouseOutRemove,
    rejectionReason,
    status,
  } = props;
  const {fetch: validatePassword} = useIsFilePasswordValid({lazy: true});
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [hasError, setHasError] = useState(false);
  const failed = status === taskDocumentStatuses.failed;
  const rejected = status === taskDocumentStatuses.rejected;

  const onClickUnlock = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsLoading(true);
    const variables = {filePassword: password, objectName};
    const rsp = await validatePassword({variables});
    const isValid = getIsValid(rsp);

    sendEvent('DocumentUpload/ValidatePassword', {
      documentId,
      isValid,
      objectName,
    });

    setHasError(!isValid);
    if (isValid) {
      await onDecrypt({filePassword: password, ...props});
      return setIsLoading(false);
    }

    setIsLoading(false);
  };

  return (
    <Box as="div" {...pick([...MARGIN_KEYS, ...PADDING_KEYS], props)}>
      <Wrapper css={{borderBottomLeftRadius: 0, borderBottomRightRadius: 0}}>
        <Box>
          <DocumentIconType
            disableCancel={disableCancel}
            fileName={fileType || fileName}
            internal={internal}
            isFileSelected={isFileSelected}
            rejectionReason={rejectionReason}
            onCancel={onCancel}
            onFileSelect={onFileSelect}
            status={status}
          />
        </Box>

        <DocumentTimestamp {...props} />

        <Flex flexShrink={1} alignItems="center" justifyContent="space-between">
          {fileSizeBytes ? (
            <SizeLabel mr="8px">
              <FileSize value={fileSizeBytes} />
            </SizeLabel>
          ) : null}

          <RemoveDocument
            documentId={documentId}
            error={rejected || failed}
            hoverRemove={hoverRemove}
            onClick={onRemove}
            onMouseOver={onMouseOverRemove}
            onMouseOut={onMouseOutRemove}
          />
        </Flex>
      </Wrapper>
      <form onSubmit={onClickUnlock}>
        <Flex
          alignItems={hasError ? 'center' : 'flex-end'}
          p={3}
          css={{
            gap: 16,
            border: `1px solid ${basic10}`,
            borderTop: 0,
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
          }}>
          <Box flex={0.75}>
            <PasswordField
              label="Enter the PDF password to unlock this file"
              mb={0}
              error={hasError ? 'Incorrect password. Try again' : null}
              onChange={setPassword}
              value={password}
            />
          </Box>
          <Box mb={2}>
            <Button
              disabled={!password || isLoading}
              loading={isLoading}
              onClick={onClickUnlock}>
              Unlock
            </Button>
          </Box>
        </Flex>
      </form>
    </Box>
  );
};

PasswordPrompt.propTypes = {
  disableCancel: PropTypes.bool,
  fileName: PropTypes.string,
  fileSizeBytes: PropTypes.number,
  fileType: PropTypes.string,
  hoverRemove: PropTypes.bool,
  id: PropTypes.string,
  internal: PropTypes.bool,
  isFileSelected: PropTypes.bool,
  objectName: PropTypes.string,
  onCancel: PropTypes.func,
  onDecrypt: PropTypes.func,
  onFileSelect: PropTypes.func,
  onRemove: PropTypes.func,
  onMouseOverRemove: PropTypes.func,
  onMouseOutRemove: PropTypes.func,
  rejectionReason: PropTypes.string,
  status: PropTypes.string,
};

export default PasswordPrompt;
