import lender from './lender';

export default `
id
createdAt
lenderId
lender {
  ${lender}
}
project {
  id
  status
}
transferredAt
transmittedById
transmittedBy {
  id
  firstName
  lastName
}
`;
