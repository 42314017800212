import {gql} from '@apollo/client';

import {payStatementInformation} from '../../partials';

export default gql`
  mutation submitPayStatementInformation(
    $borrowerRole: BorrowerRole
    $taskId: ID!
    $payStatementInformation: PayStatementInformationInputObject!
  ) {
    submitPayStatementInformation(
      borrowerRole: $borrowerRole
      taskId: $taskId
      payStatementInformation: $payStatementInformation
    ) {
      payStatementInformation {
        ${payStatementInformation}
      }
    }
  }
`;
