import {createElement, Component} from 'react';

export default (test) => (BaseComponent) => {
  class ShouldUpdate extends Component {
    shouldComponentUpdate(nextProps) {
      return test(this.props, nextProps);
    }

    render() {
      return createElement(BaseComponent, this.props);
    }
  }

  return ShouldUpdate;
};
