import {identity} from 'ramda';

import {compose, mapProps} from '@renofi/recompose/src';

import {GET_PROJECT_TIMELINE} from '../queries';

import withQuery from './withQuery';

export default ({mapper = identity, options} = {}) =>
  compose(
    withQuery({
      query: GET_PROJECT_TIMELINE,
      props: ({data: {projectTimeline} = {}, ...props}) => ({
        projectTimeline,
        ...props,
      }),
      options:
        options ||
        (({projectId, project, id}) => ({
          variables: {id: projectId || project?.id || id},
        })),
    }),
    mapProps(mapper),
  );
