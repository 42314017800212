import React, {useMemo} from 'react';

import PropTypes from 'prop-types';
import {omit} from 'ramda';

import {actionRegular} from '@renofi/theme';
import noop from '@renofi/utilities/src/noop';

import Icon from '../Icon';

import {AnchorLink, RouterLink} from './styled';

const COMMON_PROPS = [
  'blank',
  'children',
  'hover',
  'isExternal',
  'staticContext',
];

const Link = (props) => {
  const {blank = false, children, disabled, to, isExternal = false} = props;

  const isRouter = Boolean(to);

  const target = blank ? '_blank' : '_self';
  const onClick = disabled ? noop : props?.onClick;

  const Component = isRouter ? RouterLink : AnchorLink;

  const omittedProps = useMemo(() => {
    const propsToOmit = isRouter ? COMMON_PROPS : [...COMMON_PROPS, 'to'];
    return omit(propsToOmit, props);
  }, [isRouter, props]);

  return (
    <Component onClick={onClick} target={target} {...omittedProps}>
      {children}
      {isExternal && (
        <Icon
          css={{marginLeft: 4, position: 'relative', top: 3}}
          name="external"
          size={16}
          color={actionRegular}
        />
      )}
    </Component>
  );
};

Link.propTypes = {
  add: PropTypes.string,
  blank: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  isExternal: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Link;
