import * as React from 'react';

import PropTypes from 'prop-types';

import {basic20} from '@renofi/theme/src/colors';

const SvgSpeed = ({color, ...props}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <mask
        id="mask0_4_2313"
        style={{maskType: 'alpha'}}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24">
        <rect width="24" height="24" fill={color} />
      </mask>
      <g mask="url(#mask0_4_2313)">
        <path
          d="M10.45 15.5C10.85 15.9 11.3667 16.0957 12 16.087C12.6333 16.079 13.1 15.85 13.4 15.4L19 7L10.6 12.6C10.15 12.9 9.91267 13.3583 9.888 13.975C9.86267 14.5917 10.05 15.1 10.45 15.5ZM12 4C12.9833 4 13.9293 4.13733 14.838 4.412C15.746 4.68733 16.6 5.1 17.4 5.65L15.5 6.85C14.95 6.56667 14.3793 6.35433 13.788 6.213C13.196 6.071 12.6 6 12 6C9.78333 6 7.896 6.779 6.338 8.337C4.77933 9.89567 4 11.7833 4 14C4 14.7 4.09567 15.3917 4.287 16.075C4.479 16.7583 4.75 17.4 5.1 18H18.9C19.2833 17.3667 19.5627 16.7083 19.738 16.025C19.9127 15.3417 20 14.6333 20 13.9C20 13.3 19.9293 12.7167 19.788 12.15C19.646 11.5833 19.4333 11.0333 19.15 10.5L20.35 8.6C20.85 9.38333 21.246 10.2167 21.538 11.1C21.8293 11.9833 21.9833 12.9 22 13.85C22.0167 14.8 21.9083 15.7083 21.675 16.575C21.4417 17.4417 21.1 18.2667 20.65 19.05C20.4667 19.35 20.2167 19.5833 19.9 19.75C19.5833 19.9167 19.25 20 18.9 20H5.1C4.75 20 4.41667 19.9167 4.1 19.75C3.78333 19.5833 3.53333 19.35 3.35 19.05C2.91667 18.3 2.58333 17.5043 2.35 16.663C2.11667 15.821 2 14.9333 2 14C2 12.6167 2.26267 11.3207 2.788 10.112C3.31267 8.904 4.02933 7.84567 4.938 6.937C5.846 6.029 6.90833 5.31233 8.125 4.787C9.34167 4.26233 10.6333 4 12 4Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

SvgSpeed.propTypes = {
  color: PropTypes.string,
};

SvgSpeed.defaultProps = {
  color: basic20,
};

export default SvgSpeed;
