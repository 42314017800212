import {gql} from '@apollo/client';

import {contractor, documentWithAllProps} from '@renofi/graphql/src/partials';

export default gql`
  mutation updateContractorDocument(
    $contractorInformationId: ID!
    $documentId: ID!
    $documentType: DocumentType!
  ) {
    updateContractorDocument(
      contractorInformationId: $contractorInformationId
      documentId: $documentId
      documentType: $documentType
    ) {
      contractorInformation {
        ${contractor}
      }
      document {
        ${documentWithAllProps}
      }
    }
  }

`;
