import PropTypes from 'prop-types';

const commonValuePropTypes = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
  PropTypes.bool,
  PropTypes.object,
  PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.string,
      PropTypes.bool,
    ]),
  ),
]);

export const OptionsPropTypes = {
  multi: PropTypes.bool,
  value: commonValuePropTypes,
  right: PropTypes.bool,
  bottom: PropTypes.bool,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.shape({
          disabled: PropTypes.bool,
          label: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
            PropTypes.node,
          ]),
          value: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
            PropTypes.bool,
          ]),
        }),
        PropTypes.shape({
          label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          options: PropTypes.array,
        }),
      ]),
    ),
    PropTypes.object,
  ]).isRequired,
  placeholder: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  optionCss: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  keyProp: PropTypes.string,
  focusIndex: PropTypes.number,
  headerSuffix: PropTypes.node,
  searchable: PropTypes.bool,
};

export const OptionsDefaultPropTypes = {
  options: [],
};
