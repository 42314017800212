import {gql} from '@apollo/client';

export default gql`
  mutation updateSmsConsentRenovationProgress(
    $projectId: ID!
    $accepted: Boolean!
    $phoneNumber: String
  ) {
    updateSmsConsentRenovationProgress(
      projectId: $projectId
      accepted: $accepted
      phoneNumber: $phoneNumber
    ) {
      smsConsentRenovationProgress {
        id
        accepted
        acceptedAt
        createdAt
        phoneNumber
        updatedAt
      }
    }
  }
`;
