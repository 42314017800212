import {gql} from '@apollo/client';

import {realEstateOwned} from '../../partials';

export default gql`
  mutation updateRealEstateOwned(
    $additionalProperties: [AdditionalPropertyInputObject!]
    $additionalPropertyNumber: Int
    $soldProperties: [SoldPropertyInputObject!]
    $soldPropertyNumber: Int
    $subjectProperty: SubjectPropertyInputObject
    $taskId: ID!
  ) {
    updateRealEstateOwned(
      additionalProperties: $additionalProperties
      additionalPropertyNumber: $additionalPropertyNumber
      soldProperties: $soldProperties
      subjectProperty: $subjectProperty
      soldPropertyNumber: $soldPropertyNumber
      taskId: $taskId
    ) {
      success
      realEstateOwned {
        ${realEstateOwned}
      }
    }
  }
`;
