import {withApollo} from '@apollo/client/react/hoc';

import {compose, withProps} from '@renofi/recompose/src';
import omitProps from '@renofi/utilities/src/omitProps';

import withErrorHandler from './withErrorHandler';

export default compose(
  withApollo,
  withErrorHandler,
  withProps(({client, onGraphqlError}) => ({
    client: {
      mutate: async (options) => {
        try {
          const result = await client.mutate(options);
          return result;
        } catch (error) {
          onGraphqlError(error);
        }
      },
      query: async (options) => {
        try {
          const result = await client.query(options);
          return result;
        } catch (error) {
          onGraphqlError(error);
        }
      },
      readQuery: (options) => client.readQuery(options),
      watchQuery: (options) => client.watchQuery(options),
      writeQuery: (options) => client.writeQuery(options),
    },
  })),
  omitProps(['onGraphqlError']),
);
