import React from 'react';

import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from 'lib/asyncComponent';
import {PrivatePage} from 'modules/layout';

import {FeatureFlags, Lender as LenderSettings} from '@renofi/modules-internal';

const Dashboard = asyncComponent(
  () => import(/* webpackChunkName: "dashboard" */ 'modules/dashboard'),
);

const Project = asyncComponent(
  () => import(/* webpackChunkName: "project" */ 'modules/project'),
);

const Projects = asyncComponent(
  () => import(/* webpackChunkName: "projects" */ 'modules/projects'),
);

const NotFound = asyncComponent(
  () => import(/* webpackChunkName: "not-found" */ 'modules/not-found'),
);

const Exception = asyncComponent(
  () => import(/* webpackChunkName: "exception" */ 'modules/exception'),
);

const Exceptions = asyncComponent(
  () => import(/* webpackChunkName: "exceptions" */ 'modules/exceptions'),
);

const Underwriting = asyncComponent(
  () => import(/* webpackChunkName: "underwriting" */ 'modules/underwriting'),
);

export default () => (
  <PrivatePage>
    <Switch>
      <Route exact path="/" component={() => <Redirect to="/dashboard" />} />
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/members" component={Projects} />
      <Route path="/members/:id/:tab?" component={Project} />
      <Route
        path="/settings/:tab?"
        render={(props) => {
          const basePath = '/settings';
          const baseRoute = '/settings';
          return (
            <LenderSettings
              mode="lender"
              {...{basePath, baseRoute, ...props}}
            />
          );
        }}
      />
      <Route exact path="/exceptions" component={Exceptions} />
      <Route exact path="/exceptions/:id/:tab?" component={Exception} />
      <Route path="/underwriting" component={Underwriting} />
      <Route exact path="/feature-flags" component={FeatureFlags} />
      <Route component={NotFound} />
    </Switch>
  </PrivatePage>
);
