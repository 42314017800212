import React from 'react';

import {identity} from 'ramda';
import PropTypes from 'prop-types';

import formatTime from '@renofi/utilities/src/formatTime';
import formatRelativeTime from '@renofi/utilities/src/formatRelativeTime';

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
const MONTH = DAY * 30;
const YEAR = DAY * 365;

const unitMap = {
  second: SECOND,
  minute: MINUTE,
  hour: HOUR,
  day: DAY,
  week: WEEK,
  month: MONTH,
  year: YEAR,
};

const getDiff = (date, unit) => {
  const diff = Date.now() - date.getTime();
  return Math.floor(diff / unitMap[unit]);
};

const getParams = (date) => {
  const diff = Date.now() - date.getTime();
  if (diff > YEAR) {
    return [Math.floor(diff / YEAR), 'year'];
  }
  if (diff > MONTH) {
    return [Math.floor(diff / MONTH), 'month'];
  }
  if (diff > WEEK) {
    return [Math.floor(diff / WEEK), 'week'];
  }
  if (diff > DAY) {
    return [Math.floor(diff / DAY), 'day'];
  }
  if (diff > HOUR) {
    return [Math.floor(diff / HOUR), 'hour'];
  }
  if (diff > MINUTE) {
    return [Math.floor(diff / MINUTE), 'minute'];
  }
  return [Math.floor(diff / SECOND), 'second'];
};

const TimeAgo = ({
  date,
  noValueFallback = '-',
  unit: force,
  time = false,
  timePrefix = '',
  formatter = identity,
}) => {
  if (!date) {
    return noValueFallback;
  }

  const isDate = date instanceof Date;
  const converted = isDate ? date : new Date(date);
  const [value, unit] = force
    ? [getDiff(converted, force), force]
    : getParams(converted);
  const {hour, minute, dayPeriod} =
    time && unit === 'day' ? formatTime(converted, true) : {};
  return (
    <>
      {formatter(formatRelativeTime(-1 * value, unit))}
      {time && unit === 'day' && (
        <>
          {timePrefix ? ' ' : ''}
          {timePrefix} {hour}:{minute}
          {dayPeriod.toLowerCase()}
        </>
      )}
    </>
  );
};

TimeAgo.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  noValueFallback: PropTypes.node,
  unit: PropTypes.string,
  time: PropTypes.bool,
  timePrefix: PropTypes.string,
  formatter: PropTypes.func,
};

export default TimeAgo;
