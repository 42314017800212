import {gql} from '@apollo/client';

export default gql`
  query productDetailsConfirmationSummary(
    $projectId: ID!
    $lenderId: ID!
    $loanProductId: Int
    $applicationProcess: RenovationReadyFileApplicationProcess!
    $interestRate: Float
  ) {
    productDetailsConfirmationSummary(
      projectId: $projectId
      lenderId: $lenderId
      loanProductId: $loanProductId
      applicationProcess: $applicationProcess
      interestRate: $interestRate
    ) {
      loanAmount
      appraisedValue
      interestRate
      dtiRatio
      creditScore
      currentHomeValue
      applicants
      estimatedRenovationHomeValue
      interestRateType
      loanProductType
    }
  }
`;
