import * as React from 'react';

import PropTypes from 'prop-types';

const SvgWhitelist = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={21}
      height={14}
      viewBox="0 0 21 14"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-2-6h24v24H-2z" />
        <path
          d="M12 4H0v2h12V4zm0-4H0v2h12V0zM0 10h8V8H0v2zm19.5-4.5L21 7l-6.99 7L9.5 9.5 11 8l3.01 3 5.49-5.5z"
          fill={color}
        />
      </g>
    </svg>
  );
};

SvgWhitelist.propTypes = {
  color: PropTypes.string,
};
export default SvgWhitelist;
