import {assoc} from 'ramda';

const formatter = new Intl.DateTimeFormat('en-US', {
  hour12: true,
  hour: '2-digit',
  minute: '2-digit',
});

export default (value, parts = false) =>
  parts
    ? formatter
        .formatToParts(value)
        .reduce((result, {type, value}) => assoc(type, value, result), {})
    : formatter.format(value);
