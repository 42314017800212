import {gql} from '@apollo/client';

export default gql`
  query lenderProvidedReports($lenderId: ID!) {
    lenderProvidedReports(lenderId: $lenderId) {
      createdAt
      fileName
      id
      processedAt
      updatedAt
    }
  }
`;
