import {propEq} from 'ramda';

import {compose, branch, renderComponent} from '@renofi/recompose/src';

// import debugProps from '@renofi/utilities/debugProps';
import ActiveField from './ActiveField';
import FakeField from './FakeField';
import MoneyField from './MoneyField';
import NumberField from './NumberField';
import PhoneField from './PhoneField';
import SimpleField from './SimpleField';

export default compose(
  // debugProps(),
  branch(propEq('active', true), renderComponent(ActiveField)),
  branch(propEq('fake', true), renderComponent(FakeField)),
  branch(propEq('phone', true), renderComponent(PhoneField)),
  branch(propEq('money', true), renderComponent(MoneyField)),
  branch(propEq('numeric', true), renderComponent(NumberField)),
)(SimpleField);
