import {gql} from '@apollo/client';

import {documentWithAllProps} from '../../partials';

export default gql`
  mutation submitEmploymentHistoryDocument($document: DocumentInputObject!, $documentType: DocumentType!, $employmentHistoryId: ID!) {
    submitEmploymentHistoryDocument(document: $document, documentType: $documentType, employmentHistoryId: $employmentHistoryId) {
      document {
        ${documentWithAllProps}
      }
    }
  }
`;
