import {gql} from '@apollo/client';

export default gql`
  mutation submitResumeFromHold($appraisalOrderId: ID!, $comments: String!) {
    submitResumeFromHold(
      appraisalOrderId: $appraisalOrderId
      comments: $comments
    ) {
      success
    }
  }
`;
