import React from 'react';

import PropTypes from 'prop-types';

import Box from './Box';
import Button from './Button';
import Flex from './Flex';
import Modal from './Modal';

const ModalWithButtons = ({
  loading,
  acceptLabel,
  acceptVariant = 'primary',
  rejectVariant = 'secondary',
  rejectLabel = 'Cancel',
  onClose,
  onAccept,
  onReject = onClose,
  disabled,
  showReject = true,
  ...props
}) => (
  <Modal
    footer={
      <Flex justifyContent="flex-end" alignItems="center">
        {showReject && (
          <Box mr={2}>
            <Button variant={rejectVariant} onClick={() => onReject()}>
              {rejectLabel}
            </Button>
          </Box>
        )}
        <Box>
          <Button
            variant={acceptVariant}
            loading={loading}
            onClick={() => onAccept()}
            disabled={disabled}>
            {acceptLabel}
          </Button>
        </Box>
      </Flex>
    }
    {...{onClose, ...props}}
  />
);

ModalWithButtons.propTypes = {
  ...Modal.propTypes,
  loading: PropTypes.bool,
  acceptLabel: PropTypes.string.isRequired,
  acceptVariant: PropTypes.string,
  rejectVariant: PropTypes.string,
  rejectLabel: PropTypes.string,
  onClose: PropTypes.func,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func,
  disabled: PropTypes.bool,
  showReject: PropTypes.bool,
};

export default ModalWithButtons;
