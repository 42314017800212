import {gql} from '@apollo/client';

import {borrowerProject} from '../../partials';

export default gql`
  query borrowerCurrentProject {
    borrowerCurrentProject {
      ${borrowerProject}
    }
  }

`;
