import {gql} from '@apollo/client';

export default gql`
  query projectSummary {
    projectSummary @client {
      tasks {
        appraisal
        contractor
        eligibility
        insurance
        renovation
        product
      }
    }
  }
`;
