import {gql} from '@apollo/client';

export default gql`
  query lenderByCrmId($crmId: ID!) {
    lenderByCrmId(crmId: $crmId) {
      id
      applyUrl
      contactInformation1
      contactInformation2
      crmId
      friendlyId
      fastTrackEnabled
      joinUrl
      raasEnabled
      tpoEnabled
      logoObjectName
      logoUrl
      loanProducts {
        id
        name
      }
      mfaRequired
      name
      notificationPreferences {
        id
        emailAddresses
        notificationType
      }
      users {
        id
      }
      tpoAcknowledgementContent
    }
  }
`;
