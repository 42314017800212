import * as React from 'react';

import PropTypes from 'prop-types';

const SvgAlertMessage = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M19.6 5H4.4c-.77 0-1.393.63-1.393 1.4L3 19.805l2.8-2.8h13.8c.77 0 1.4-.63 1.4-1.4V6.4c0-.77-.63-1.4-1.4-1.4z"
          fill={color}
          fillRule="nonzero"
        />
        <path
          d="M13.022 12.945v2.051h-2.03v-2.05h2.03zm0-5.974v5.003h-2.03V6.971h2.03z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

SvgAlertMessage.propTypes = {
  color: PropTypes.string,
};
export default SvgAlertMessage;
