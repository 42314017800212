import {useEffect, useRef} from 'react';

import logger from '@renofi/utilities/src/logger';
import noop from '@renofi/utilities/src/noop';

import {isSubscribed, subscribe, unsubscribe} from '../pusher';

export default ({channelName, onUpdate = noop}) => {
  const ref = useRef();

  useEffect(() => {
    const isChanged = ref.current !== channelName;
    if (!channelName || !isChanged || isSubscribed(channelName)) {
      return;
    }

    if (ref?.current) {
      logger.debug('🟥 UNSUBSCRIBE', channelName);
      unsubscribe(ref.current);
    }

    logger.debug('🟩 SUBSCRIBE', channelName);
    subscribe(channelName, onUpdate);
    ref.current = channelName;
  }, [channelName]);

  useEffect(() => {
    return () => {
      if (ref?.current) {
        logger.debug('🟥 UNSUBSCRIBE', channelName);
        unsubscribe(ref.current);
      }
    };
  }, []);

  return {
    channelName,
  };
};
