import {gql} from '@apollo/client';

export default gql`
  mutation updateLender(
    $id: ID!
    $applyUrl: Url
    $joinUrl: Url
    $contactInformation1: String
    $contactInformation2: String
  ) {
    updateLender(
      id: $id
      applyUrl: $applyUrl
      joinUrl: $joinUrl
      contactInformation1: $contactInformation1
      contactInformation2: $contactInformation2
    ) {
      lender {
        id
        applyUrl
        contactInformation1
        contactInformation2
        friendlyId
        joinUrl
        raasEnabled
        logoObjectName
        logoUrl
        loanProducts {
          id
          name
        }
        name
      }
    }
  }
`;
