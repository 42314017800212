import styled from '@emotion/styled';

import Text from '@renofi/components-internal/src/Text';
import {basic80} from '@renofi/theme/src/colors';

export const Header = styled(Text)({
  fontSize: 18,
  lineHeight: 21,
  color: basic80,
});
