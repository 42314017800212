import React, {useCallback, useRef} from 'react';

import PropTypes from 'prop-types';

import withForwardRef from '@renofi/utilities/src/withForwardRef';

import {Input} from './styled';

const FieldInput = ({
  forwardedRef = useRef(null),
  type = 'text',
  onChange,
  ...props
}) => {
  const changeHandler = useCallback((event) => {
    onChange(event.target.value);
  });
  return (
    <Input ref={forwardedRef} onChange={changeHandler} type={type} {...props} />
  );
};

FieldInput.propTypes = {
  forwardedRef: PropTypes.any,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default withForwardRef(FieldInput);
