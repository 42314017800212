import {propOr} from 'ramda';

import useToggledQuery from '../useToggledQuery';
import {GET_IS_FILE_PASSWORD_VALID} from '../../queries';

export default function useIsFilePasswordValid({
  fetchPolicy = 'no-cache',
  lazy = false,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_IS_FILE_PASSWORD_VALID,
    options: {
      fetchPolicy,
      ...options,
    },
  });

  const {data, error, loading, fetch} = response;
  const isValid = propOr(null, 'isFilePasswordValid', data);

  return {error, fetch, isValid, loading};
}
