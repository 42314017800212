import {propOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import {GENERATE_COMBINED_PDF} from '../../mutations';
import useMutation from '../useMutation';

export default function useGenerateCombinedPdf({
  onCompleted = noop,
  refetchQueries = [],
  ...options
} = {}) {
  const [generateCombinedPdf, {data, loading, error}] = useMutation(
    GENERATE_COMBINED_PDF,
    {
      onCompleted,
      refetchQueries,
      ...options,
    },
  );

  const response = propOr(null, 'generateCombinedPdf', data);
  return {generateCombinedPdf, response, loading, error};
}
