import {gql} from '@apollo/client';

export default gql`
  mutation changeSmsNumber($smsNumber: String!) {
    changeSmsNumber(smsNumber: $smsNumber) {
      user {
        id
      }
    }
  }
`;
