import {gql} from '@apollo/client';

export default gql`
  mutation bulkChangeTaskStatus($ids: [ID!]!, $status: TaskStatus!) {
    bulkChangeTaskStatus(ids: $ids, status: $status) {
      task {
        id
        additionalExplanation
        allDocumentsAccepted
        anyDocumentRejected
        allDocumentsReviewed
        completedAt
        contractor {
          id
          businessName
          city
          contactName
          contactTitle
          email
          phoneNumber
          rejectionDetails
          rejectionReason
          secondaryAddress
          state
          status
          streetAddress
          website
          zipCode
        }
        details
        documents {
          id
          checksum
          createdAt
          documentType
          fileName
          objectName
          pdfObjectName
          rejectionDetails
          rejectionReason
          shareWithLenderAllowed
          shareable
          status
        }
        informationKind
        requirements
        status
        submittedAt
        title
      }
    }
  }
`;
