import {pathOr} from 'ramda';

import {compose, withHandlers} from '@renofi/recompose/src';

import {UPDATE_COMMENT} from '../mutations';
import {GET_PROJECT, GET_RENOVATION_READY_FILE} from '../queries';

import withMutation from './withMutation';

export default () =>
  compose(
    withMutation(UPDATE_COMMENT, {
      name: 'updateComment',
    }),
    withHandlers({
      updateComment:
        ({client, updateComment}) =>
        async ({commentId, projectId, rrfId, ...variables}) => {
          const response = await updateComment({
            variables: {id: commentId, ...variables},
          });
          const updatedComment = pathOr(
            null,
            ['data', 'updateComment', 'comment'],
            response,
          );

          if (updatedComment) {
            if (rrfId) {
              const {renovationReadyFileById} = client.readQuery({
                query: GET_RENOVATION_READY_FILE,
                variables: {id: rrfId},
              });
              client.writeQuery({
                query: GET_RENOVATION_READY_FILE,
                variables: {id: rrfId},
                data: {
                  renovationReadyFileById: {
                    ...renovationReadyFileById,
                    project: {
                      ...renovationReadyFileById.project,
                      comments: renovationReadyFileById.project.comments.map(
                        (comment) =>
                          commentId === comment.id ? updateComment : comment,
                      ),
                    },
                  },
                },
              });
            } else {
              const {projectById: project} = client.readQuery({
                query: GET_PROJECT,
                variables: {
                  id: projectId,
                },
              });
              client.writeQuery({
                query: GET_PROJECT,
                variables: {
                  id: projectId,
                },
                data: {
                  projectById: {
                    ...project,
                    comments: project.comments.map((comment) =>
                      commentId === comment.id ? updateComment : comment,
                    ),
                  },
                },
              });
            }
          }

          return updatedComment;
        },
    }),
  );
