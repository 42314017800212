import React, {useEffect, useMemo, useState} from 'react';

import PropTypes from 'prop-types';
import {propOr, clone, propEq} from 'ramda';

import {Box, Card, Dot, Flex, Text, SelectField} from '@renofi/components-internal';
import {useGetUser, useLoanApplication} from '@renofi/graphql';
import {useNotifications} from '@renofi/utilities/src/hooks';

import {
  reduceStatus,
  getDecisions,
  getIsStatusChangeDisabled,
  transformLoanApplication,
} from './utils';
import {STATUS_COMPONENT_MAP, STATUSES} from './constants';
import useIsDisabled from './hooks/useIsDisabled';
import useLoanApplicationActions from './hooks/useLoanApplicationActions';

const Dummy = () => null;

const LoanApplicationStatus = ({
  renovationReadyFileId,
  mode,
  refetchQueries = [],
}) => {
  const {addNotification} = useNotifications();

  const [status, setStatus] = useState('in_progress');
  const [activeStatus, setActiveStatus] = useState('in_progress');
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [initialLoanApplication, setInitialLoanApplication] = useState({});
  const [loanApplicationState, setLoanApplicationState] = useState({});

  const {user} = useGetUser();
  const {fetch: fetchLoanApplication, refetch: refetchLoanApplication} =
    useLoanApplication({
      lazy: true,
    });

  const {
    approveLoanApplication,
    closeLoanApplication,
    denyLoanApplication,
    notAcceptLoanApplication,
    withdrawnLoanApplication,
  } = useLoanApplicationActions(refetchQueries);

  const options = useMemo(() => getDecisions(activeStatus), [activeStatus]);
  const isStatusDropdownDisabled = getIsStatusChangeDisabled({
    status,
    mode,
    user,
  });

  const {isSaveDisabled, persistable} = useIsDisabled({
    activeStatus,
    loanApplication: loanApplicationState,
    status,
    updated,
    mode,
    user,
  });

  async function initializeLoanApplication(data) {
    const lenderLoanApplication = propOr({}, 'lenderLoanApplication', data);
    const initialLoanApp = clone(lenderLoanApplication);
    const transformed = transformLoanApplication(initialLoanApp);

    setInitialLoanApplication(initialLoanApp);
    setLoanApplicationState(transformed);

    setStatus(lenderLoanApplication.status);
    setActiveStatus(lenderLoanApplication.status);
  }

  useEffect(() => {
    (async () => {
      if (!renovationReadyFileId) {
        return;
      }

      const {data} = await fetchLoanApplication({
        variables: {
          renovationReadyFileId,
        },
      });

      initializeLoanApplication(data);
    })();
  }, [renovationReadyFileId]);

  const onCancelChange = () => {
    const transformed = transformLoanApplication(initialLoanApplication);
    setLoanApplicationState({...transformed});

    setStatus(activeStatus);
    setUpdated(false);
  };

  const onChangeStatus = (status) => {
    setStatus(status);
  };

  const onChangeLoanProduct = (id) => {
    setLoanApplicationState({
      ...loanApplicationState,
      loanProduct: loanApplicationState.loanProducts.find(propEq('id', id)),
    });

    setUpdated(true);
  };

  const onChangeFieldValue = (field, value) => {
    setLoanApplicationState((state) => ({
      ...state,
      [field]: value,
    }));

    setUpdated(true);
  };

  const onSaveLoanApplication = async () => {
    let result;

    setLoading(true);

    try {
      switch (status) {
        case STATUSES.APPROVED:
          result = await approveLoanApplication(
            renovationReadyFileId,
            loanApplicationState,
          );
          break;
        case STATUSES.CLOSED:
          result = await closeLoanApplication(
            renovationReadyFileId,
            loanApplicationState,
          );
          break;
        case STATUSES.DENIED:
          result = await denyLoanApplication(
            renovationReadyFileId,
            loanApplicationState,
          );
          break;
        case STATUSES.NOT_ACCEPTED:
          result = await notAcceptLoanApplication(
            renovationReadyFileId,
            loanApplicationState,
          );
          break;
        case STATUSES.WITHDRAWN:
          result = await withdrawnLoanApplication(
            renovationReadyFileId,
            loanApplicationState,
          );
          break;
        default:
      }

      if (result?.errors instanceof Error) {
        throw new Error('Failed to submit');
      }

      const {data} = await refetchLoanApplication({
        renovationReadyFileId,
      });

      initializeLoanApplication(data);
    } catch (error) {
      addNotification({
        content: error.message,
        variant: 'danger',
      });
    } finally {
      setLoading(false);
      setUpdated(false);
    }
  };

  const Component = STATUS_COMPONENT_MAP[status] || Dummy;

  if (!activeStatus) {
    return null;
  }

  return (
    <>
      <Card
        title={
          <Flex
            width={1}
            height={64}
            justifyContent="space-between"
            alignItems="center">
            <Flex alignItems="center">
              <Dot status={reduceStatus(activeStatus)} />
              <Text ml={20} fontSize={20} lineHeight="24px">
                Loan application status
              </Text>
            </Flex>
            <Box width={360}>
              <SelectField
                key={activeStatus}
                disabled={isStatusDropdownDisabled}
                mb={0}
                value={status}
                options={options}
                onChange={onChangeStatus}
              />
            </Box>
          </Flex>
        }></Card>
      {status !== STATUSES.IN_PROGRESS ? (
        <Component
          {...{
            disabled: isSaveDisabled,
            persistable,
            activeStatus,
            status,
            loading,
            updated,
            onChangeStatus,
            onSaveLoanApplication,
            onChangeLoanProduct,
            onChangeFieldValue,
            onCancelChange,
            ...loanApplicationState,
          }}
        />
      ) : null}
    </>
  );
};

LoanApplicationStatus.propTypes = {
  mode: PropTypes.string,
  refetchQueries: PropTypes.array,
  renovationReadyFileId: PropTypes.string,
};

LoanApplicationStatus.defaultProps = {
  refetchQueries: [],
};

export default LoanApplicationStatus;
