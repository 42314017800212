/* eslint-disable */
export default function initAccessibe() {
  if(process.env.REACT_APP_ENV !== 'production') return;

  (function () {
    var s = document.createElement('script'),
      e = !document.body ? document.querySelector('head') : document.body;
    s.src = 'https://acsbapp.com/apps/app/assets/js/acsb.js';
    s.async = s.defer = true;
    s.onload = function () {
      acsbJS.init({
        statementLink: '',
        feedbackLink: '',
        footerHtml: '',
        hideMobile: false,
        hideTrigger: true,
        language: 'en',
        position: 'left',
        leadColor: '#333333',
        triggerColor: '#ff5253',
        triggerRadius: '50%',
        triggerPositionX: 'left',
        triggerPositionY: 'bottom',
        triggerIcon: 'default',
        triggerSize: 'medium',
        triggerOffsetX: 20,
        triggerOffsetY: 20,
        mobile: {
          triggerSize: 'small',
          triggerPositionX: 'left',
          triggerPositionY: 'center',
          triggerOffsetX: 0,
          triggerOffsetY: 0,
          triggerRadius: '0',
        },
      });
    };
    e.appendChild(s);
  })();
}
