import queryString from 'query-string';

export const PARSE_OPTIONS = {
  arrayFormat: 'bracket-separator',
  arrayFormatSeparator: ',',
  parseNumbers: true,
  parseBooleans: true,
};

export default queryString;
