import {gql} from '@apollo/client';

import {feasibilityReview} from '../../partials';

export default gql`
  mutation cancelFeasibilityReview($id: ID!) {
    cancelFeasibilityReview(id: $id) {
      feasibilityReview {
        ${feasibilityReview}
      }
    }
  }
`;
