import {gql} from '@apollo/client';

import {applicant} from '../partials';

export default gql`
  query renovationReadyFiles(
    $raas: Boolean
    $stageName: ProjectStageName
    $sortField: RenovationReadyFileSortField
    $sortDirection: SortDirection
    $page: Int
    $limit: Int
  ) {
    lenderRenovationReadyFiles(
      raas: $raas
      stageName: $stageName
      sortField: $sortField
      sortDirection: $sortDirection
      page: $page
      limit: $limit
    ) {
      collection {
        id
        createdAt
        project {
          id
          actualCloseDate
          borrower {
            id
            firstName
            lastName
          }
          primaryApplicant {
            ${applicant}
          }
          coborrower {
            ${applicant}
          }
          createdAt
          estimatedCloseDate
          raas
          wholesale
          loanValue
          stageName
          state
          status
          updatedAt
        }
        updatedAt
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;
