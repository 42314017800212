import {compose, withHandlers} from '@renofi/recompose/src';

import {SHARE_RENOVATION_UPDATE} from '../mutations';
import {GET_PROJECT_RENOVATION_UPDATES} from '../queries';

import withMutation from './withMutation';

export default () =>
  compose(
    withMutation(SHARE_RENOVATION_UPDATE, {
      name: 'shareRenovationUpdateWithLender',
      options: {
        refetchQueries: [GET_PROJECT_RENOVATION_UPDATES],
      },
    }),
    withHandlers({
      shareRenovationUpdate:
        ({shareRenovationUpdateWithLender}) =>
        (variables) => {
          return shareRenovationUpdateWithLender({variables});
        },
    }),
  );
