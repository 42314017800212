import React from 'react';

import PropTypes from 'prop-types';
import {pick, omit} from 'ramda';

import {useTheme} from '@renofi/theme/src';

import Container from './Container';
import Flex from './Flex';

const LAYOUT_PROP_TYPES = [PropTypes.string, PropTypes.number];

const paddingKeys = ['p', 'pb', 'pl', 'pr', 'pt', 'px', 'py'];

const Navbar = ({
  children,
  className,
  fixed,
  fluid,
  maxWidth,
  narrow,
  wide,
  height = 70,
  top = 0,
  justifyContent = 'center',
  alignItems = 'center',
  zIndex = 1,
  ...props
}) => {
  const {styles} = useTheme('navbar', {
    defaultVariant: 'primary',
    ...props,
  });

  return (
    <Flex
      css={{
        height,
        ...styles,
        ...(fixed
          ? {
              position: 'sticky',
              zIndex,
              width: '100vw',
              maxWidth: '100%',
              top,
            }
          : {}),
      }}
      justifyContent={justifyContent}
      alignItems={alignItems}
      className={className}
      {...omit(paddingKeys, props)}>
      <Container
        maxWidth={maxWidth}
        wide={wide}
        fluid={fluid}
        narrow={narrow}
        {...pick(paddingKeys, props)}>
        {children}
      </Container>
    </Flex>
  );
};

Navbar.propTypes = {
  top: PropTypes.number,
  maxWidth: PropTypes.oneOfType(LAYOUT_PROP_TYPES),
  height: PropTypes.oneOfType(LAYOUT_PROP_TYPES),
  className: PropTypes.string,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  fixed: PropTypes.bool,
  fluid: PropTypes.bool,
  children: PropTypes.node.isRequired,
  narrow: PropTypes.bool,
  wide: PropTypes.bool,
  shadow: PropTypes.bool,
  zIndex: PropTypes.number,
};

export default Navbar;
