import React from 'react';

import PropTypes from 'prop-types';
import {range} from 'ramda';

import {Bar, Container, Label, Wrapper} from './styled';

const ProgressBar = ({done = 0, label, size}) => (
  <Wrapper>
    {label && <Label>{label}</Label>}
    <Container size={size}>
      {range(0, size).map((i) => (
        <Bar active={i < done} key={i} />
      ))}
    </Container>
  </Wrapper>
);

ProgressBar.propTypes = {
  done: PropTypes.number,
  label: PropTypes.string,
  size: PropTypes.number.isRequired,
};

export default ProgressBar;
