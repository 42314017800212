import {gql} from '@apollo/client';

export default gql`
  query underwritingConditionTemplates {
    underwritingConditionTemplates {
      completionStage
      condition
      external
      id
      type
    }
  }
`;
