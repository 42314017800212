import amc from './amc';
import appraisalProduct from './appraisalProduct';
import appraisalReportDocument from './appraisalReportDocument';

export default `
id
acceptedAt
acceptedReportDocumentId
amc {
  ${amc}
}
amcCode
amcStatus
appraisalProduct {
  ${appraisalProduct}
}
appraisalReportDocuments {
  ${appraisalReportDocument}
}
appraiserAcceptedAt
appraiserValue
avmValue
cancellationReason
cancelledByAmcAt
cancelledByRenofiAt
comments {
  comments
  createdAt
  createdById
  createdBy {
    fullName
  }
  id
  submittedToAmcAt
  updatedAt
}
contactEmail
contactFirstName
contactHomePhone
contactLastName
contactWorkPhone
correctedReportReceivedAt
correctionRequestedAt
createdAt
createdById
documentIds
eventComments {
  id
  author
  createdAt
  datedAt
  relatedAmcStatus
  text
  updatedAt
}
eventStatusChanges {
  id
  createdAt
  datedAt
  relatedAmcStatus
  text
  updatedAt
}
expectedDeliveryOn
finalInspectionAppraisal
formType
homeownerName
importedFromApiAt
inspectionAppointmentAt
inspectionCompletedAt
loanAmount
noteForAppraiser
onHoldComments
orderAmcId
orderEvents {
  id
  author
  createdAt
  datedAt
  eventType
  relatedAmcStatus
  text
  updatedAt
}
postRenovationHomeValue
priorityOrder
projectId
propertyAddress
propertyCity
propertyCounty
propertyPostalCode
propertyType
propertyState
putOnHoldAt
rejectedAt
rejectionReason
reportAcceptedAt
reportAcceptedById
reportAcceptedBy {
  email
  fullName
  id
}
reportReceivedAt
resumeComments
resumedFromHoldAt
reviewFinishedAt
status
submissionFailedAt
submissionFailedMessage
submittedAt
updatedAt
`;
