import {gql} from '@apollo/client';

export default gql`
  mutation impersonate($id: ID!) {
    impersonate(id: $id) {
      jwt
      refreshToken
    }
  }
`;
