import {identity, pipe, propOr} from 'ramda';

import {compose, mapProps, withProps} from '@renofi/recompose/src';
import omitProps from '@renofi/utilities/src/omitProps';

import withRenovationReadyFile from './withRenovationReadyFile';

export default ({mapper = identity} = {}) =>
  compose(
    withRenovationReadyFile(),
    withProps(({renovationReadyFile}) => ({
      documents: pipe(propOr([], 'taskDocuments'))(renovationReadyFile),
      renofiProvidedDocuments: pipe(propOr([], 'renofiProvidedDocuments'))(
        renovationReadyFile,
      ),
      projectDocuments: renovationReadyFile?.project?.documents,
      lenderDocuments: renovationReadyFile?.project?.documents?.filter(
        (doc) =>
          doc.source === 'lender' &&
          doc.shareWithLenderAllowed &&
          doc.shareable,
      ),
    })),
    omitProps(['renovationReadyFile']),
    mapProps(mapper),
  );
