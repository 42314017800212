import {gql} from '@apollo/client';

export default gql`
  query borrowerInvitedToProject($project: ProjectInputObject!) {
    borrowerInvitedToProject(project: $project) {
      subject
      content
    }
  }
`;
