import camelcaseKeys from 'camelcase-keys';

import {
  AUTH_TOKEN,
  REFRESH_TOKEN,
  getImpersonationStorage,
} from '@renofi/utilities/src/storage';
import jwtDecode from '@renofi/utilities/src/jwtDecode';
import {
  JWT_KEY,
  REFRESH_TOKEN_KEY,
  getCookie,
} from '@renofi/utilities/src/cookies';

export default () => {
  const session = getImpersonationStorage();
  const jwt = session?.getItem(AUTH_TOKEN) || getCookie(JWT_KEY);
  const decodedJwt = camelcaseKeys(jwtDecode(jwt));

  return {
    decodedJwt,
    jwt,
    isImpersonating: Boolean(decodedJwt?.originalUserId),
    refreshToken:
      session?.getItem(REFRESH_TOKEN) || getCookie(REFRESH_TOKEN_KEY),
  };
};
