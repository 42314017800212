import React from 'react';

import PropTypes from 'prop-types';

const PinDropIcon = ({color = '#CCC', ...props}) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M12.5 16.475c1.65-1.333 2.896-2.617 3.738-3.85.841-1.233 1.262-2.392 1.262-3.475 0-.933-.17-1.73-.512-2.388-.342-.658-.763-1.191-1.263-1.6a4.862 4.862 0 0 0-1.625-.887C13.517 4.092 12.983 4 12.5 4c-.483 0-1.017.092-1.6.275a4.862 4.862 0 0 0-1.625.887c-.5.409-.92.942-1.262 1.6-.342.659-.513 1.455-.513 2.388 0 1.083.42 2.242 1.262 3.475.842 1.233 2.088 2.517 3.738 3.85Zm0 2.525c-2.35-1.733-4.104-3.417-5.263-5.05C6.079 12.317 5.5 10.717 5.5 9.15c0-1.183.213-2.221.638-3.113.425-.891.97-1.637 1.637-2.237a6.755 6.755 0 0 1 2.25-1.35A7.27 7.27 0 0 1 12.5 2c.817 0 1.642.15 2.475.45.833.3 1.583.75 2.25 1.35.667.6 1.213 1.346 1.638 2.237.425.892.637 1.93.637 3.113 0 1.567-.58 3.167-1.738 4.8S14.85 17.267 12.5 19Zm0-8c.55 0 1.021-.196 1.413-.588.391-.391.587-.862.587-1.412 0-.55-.196-1.021-.587-1.413A1.928 1.928 0 0 0 12.5 7c-.55 0-1.02.196-1.412.587A1.927 1.927 0 0 0 10.5 9c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588Zm-7 11v-2h14v2h-14Z"
      fill={color}
    />
  </svg>
);

PinDropIcon.propTypes = {
  color: PropTypes.string,
};
export default PinDropIcon;
