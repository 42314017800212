import {gql} from '@apollo/client';

export default gql`
  mutation updateSoftCreditCheck(
    $taskId: ID!
    $softCreditCheck: SoftCreditCheckInputObject!
    $authorizedRenofiToVerifyCredit: Boolean
  ) {
    updateSoftCreditCheck(
      taskId: $taskId
      softCreditCheck: $softCreditCheck
      authorizedRenofiToVerifyCredit: $authorizedRenofiToVerifyCredit
    ) {
      success
    }
  }
`;
