import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {Decimal} from 'decimal.js';

import isNilOrEmpty from '@renofi/utilities/src/isNilOrEmpty';

import TextField from './TextField';

const RatioField = ({value, onChange, ...props}) => {
  const [fractional, setFractional] = useState(false);
  const [zeros, setZeros] = useState('');
  const changeHandler = (value) => {
    if (isNilOrEmpty(value)) return null;
    try {
      if (value.endsWith('.')) {
        // fractional number starts
        setFractional(true);
        setZeros('');
      } else if (fractional && value.endsWith('0')) {
        // fractional number continues with zeros
        const parts = value.split('.');
        setZeros(parts[1]);
      } else if (value.includes('.') && value.endsWith('0')) {
        // another sequence of zeros or deleting value
        const lastZeros = [];
        let isFractional = false;
        let done = false;
        value
          .split('')
          .reverse()
          .forEach((val) => {
            if (done) return;
            if (val === '0') {
              lastZeros.push('0');
            } else if (val === '.') {
              isFractional = true;
            } else {
              done = true;
            }
          });
        setFractional(isFractional);
        setZeros(lastZeros.join(''));
      } else {
        setFractional(false);
        setZeros('');
      }
      const fixedValue = value.endsWith('.') ? `${value}0` : value;
      const numValue = new Decimal(fixedValue);
      return numValue.isNaN() ? null : numValue.div(100).toString();
    } catch (_error) {
      setFractional(false);
      setZeros('');
      return null;
    }
  };

  const numValue = new Decimal(isNilOrEmpty(value) ? NaN : value);
  const finalValue = numValue.isNaN() ? '' : numValue.times(100).toString();
  return (
    <TextField
      leftIcon="percent"
      onChange={(value) => {
        onChange && onChange(changeHandler(value));
      }}
      value={fractional ? `${finalValue}.${zeros}` : `${finalValue}${zeros}`}
      {...props}
    />
  );
};

RatioField.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export default RatioField;
