import * as React from 'react';

import PropTypes from 'prop-types';

const SvgBmp = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <mask id="bmp_svg__a" fill="#fff">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.56 0A3.56 3.56 0 0 0 8 3.56v24.88A3.56 3.56 0 0 0 11.56 32h16.88A3.56 3.56 0 0 0 32 28.44V3.539 8l-8-8H11.56z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.56 0A3.56 3.56 0 0 0 8 3.56v24.88A3.56 3.56 0 0 0 11.56 32h16.88A3.56 3.56 0 0 0 32 28.44V3.539 8l-8-8H11.56z"
        fill="#fff"
      />
      <path
        d="m32 3.539 1-.006-2 .006h1zM32 8l-.707.707L33 10.414V8h-1zm-8-8 .707-.707L24.414-1H24v1zM9 3.56A2.56 2.56 0 0 1 11.56 1v-2A4.56 4.56 0 0 0 7 3.56h2zm0 24.88V3.56H7v24.88h2zM11.56 31A2.56 2.56 0 0 1 9 28.44H7A4.56 4.56 0 0 0 11.56 33v-2zm16.88 0H11.56v2h16.88v-2zM31 28.44A2.56 2.56 0 0 1 28.44 31v2A4.56 4.56 0 0 0 33 28.44h-2zm0-24.88v24.88h2V3.56h-2zm0-.015v.015h2v-.027l-2 .012zm0-.006V8h2V3.539h-2zm1.707 3.754-8-8L23.293.707l8 8 1.414-1.414zM11.56 1H24v-2H11.56v2z"
        fill="#EBEBEB"
        mask="url(#bmp_svg__a)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m24 0 8 8h-6a2 2 0 0 1-2-2V0z"
        fill="#A6ADBC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 8h5v5l-5-5z"
        fill="#D2D6DE"
      />
      <rect y={3} width={21} height={10} rx={2} fill="#0D821A" />
      <path
        d="M6.445 9.219c0 .281-.057.532-.172.754a1.535 1.535 0 0 1-.464.55c-.23.172-.482.295-.758.368A4.188 4.188 0 0 1 4.008 11h-2.54V5.184h2.259c.468 0 .81.015 1.027.046.219.032.435.1.648.207.222.112.386.264.493.454.109.187.164.402.164.644 0 .281-.075.53-.223.746-.149.214-.358.38-.629.5v.032c.38.075.681.231.902.468.224.237.336.55.336.938zm-1.922-2.39a.628.628 0 0 0-.074-.29.44.44 0 0 0-.254-.215 1.217 1.217 0 0 0-.41-.066 22.67 22.67 0 0 0-.683-.008H2.96v1.23h.234c.237 0 .44-.003.606-.011.166-.008.298-.034.394-.078.136-.06.224-.137.266-.23a.83.83 0 0 0 .062-.333zm.368 2.366c0-.185-.037-.327-.11-.425-.07-.102-.191-.178-.363-.227a1.908 1.908 0 0 0-.484-.055 65.648 65.648 0 0 0-.645-.004h-.328v1.45h.11c.421 0 .723-.002.906-.004.182-.003.35-.037.503-.102.157-.065.263-.15.32-.258.06-.11.09-.234.09-.375zM13.66 11h-1.493V7.105L11.09 9.633h-1.035L8.977 7.105V11H7.562V5.184h1.743l1.308 2.918 1.305-2.918h1.742V11zm6.288-3.98c0 .26-.045.515-.137.765a1.701 1.701 0 0 1-.39.625 2.39 2.39 0 0 1-.777.52c-.284.117-.639.175-1.063.175h-.934V11h-1.5V5.184h2.47c.369 0 .68.032.933.097.255.063.48.158.676.285.234.154.412.35.535.59.125.24.187.528.187.864zm-1.55.035a.68.68 0 0 0-.133-.422.716.716 0 0 0-.309-.25 1.356 1.356 0 0 0-.457-.102 11.859 11.859 0 0 0-.594-.011h-.258v1.742h.43c.255 0 .465-.016.629-.047a.895.895 0 0 0 .418-.188.707.707 0 0 0 .207-.297c.044-.117.066-.259.066-.425z"
        fill="#fff"
      />
      <path
        d="M15.32 23.496a.922.922 0 0 1 1.274-.03l1.155 1.127a.355.355 0 0 0 .534-.06l3.2-5.274a.9.9 0 0 1 1.51-.03l4.86 7.527c.385.592-.03 1.392-.74 1.392H12.978c-.8 0-1.185-.948-.652-1.511l2.993-3.14z"
        fill="#0D821A"
      />
      <circle
        r={2.281}
        transform="matrix(-1 0 0 1 15.32 18.282)"
        fill="#0D821A"
      />
    </svg>
  );
};

SvgBmp.propTypes = {
  color: PropTypes.string,
};
export default SvgBmp;
