import React from 'react';

import PropTypes from 'prop-types';

const InfoIcon = ({color = '#CCC', ...props}) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11Zm0-1.65a9.35 9.35 0 1 1 0-18.7 9.35 9.35 0 0 1 0 18.7ZM10.9 6.5h2.2v2.2h-2.2V6.5Zm0 4.4h2.2v6.6h-2.2v-6.6Z"
      fill={color}
    />
    <path
      d="M13.1 10.9h-2.2v6.6h2.2v-6.6ZM13.1 6.5h-2.2v2.2h2.2V6.5Z"
      fill={color}
    />
  </svg>
);

InfoIcon.propTypes = {
  color: PropTypes.string,
};

export default InfoIcon;
