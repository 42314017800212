import {compose, withHandlers} from '@renofi/recompose/src';
import omitProps from '@renofi/utilities/src/omitProps';

import {GET_UNDERWRITING_REQUEST} from '../queries';

import withApollo from './withApollo';
import withQuery from './withQuery';
const IS_DEV = process.env.NODE_ENV === 'development';

const INITIAL_DATA = {
  requirements: {
    condition1: IS_DEV,
    condition2: IS_DEV,
    condition3: IS_DEV,
    condition4: IS_DEV,
    __typename: 'UnderwritingRequirements',
  },
  personalDetails: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    streetAddressOne: '',
    streetAddressTwo: '',
    zipCode: '',
    state: '',
    city: '',
    __typename: 'UnderwritingPersonalDetails',
  },
  eligibilityStats: {
    creditScore: '',
    assets: '',
    reserves: '',
    dtiRatio: '',
    __typename: 'UnderwritingEligibilityStats',
  },
  loanDetails: {
    currentHomeValue: '',
    currentMortgageBalance: '',
    lenderLoanNumber: '',
    loanValue: '',
    renovationCost: '',
    postRenovationHomeValue: '',
    secondMortgageBalance: '',
    intendedStartDate: null,
    returnDeadline: null,
    __typename: 'UnderwritingLoanDetails',
  },
  lenderAccountExecutiveDetails: {
    lenderAccountExecutiveEmail: '',
    lenderAccountExecutiveFirstName: '',
    lenderAccountExecutiveLastName: '',
    lenderAccountExecutivePhone: '',
    __typename: 'UnderwritingLenderAccountExecutiveDetails',
  },
  mloDetails: {
    mloFirstName: '',
    mloLastName: '',
    mloEmail: '',
    mloPhone: '',
    __typename: 'UnderwritingMloDetails',
  },
  __typename: 'UnderwritingRequestState',
};

export default () =>
  compose(
    withApollo,
    withQuery({
      query: GET_UNDERWRITING_REQUEST,
      props: ({data: {underwritingRequest}, ...props}) => ({
        underwritingRequest,
        ...props,
      }),
    }),
    withHandlers({
      updateUnderwritingRequest:
        ({underwritingRequest, client}) =>
        (partial) => {
          client.writeQuery({
            query: GET_UNDERWRITING_REQUEST,
            data: {
              underwritingRequest: {
                ...underwritingRequest,
                ...partial,
              },
            },
          });
        },
    }),
    withHandlers({
      resetUnderwritingRequest:
        ({updateUnderwritingRequest}) =>
        () => {
          updateUnderwritingRequest(INITIAL_DATA);
        },
      updateRequirements:
        ({underwritingRequest, updateUnderwritingRequest}) =>
        (partial) => {
          updateUnderwritingRequest({
            requirements: {
              ...underwritingRequest.requirements,
              ...partial,
            },
          });
        },
      updatePersonalDetails:
        ({underwritingRequest, updateUnderwritingRequest}) =>
        (partial) => {
          updateUnderwritingRequest({
            personalDetails: {
              ...underwritingRequest.personalDetails,
              ...partial,
            },
          });
        },
      updateEligibilityStats:
        ({underwritingRequest, updateUnderwritingRequest}) =>
        (partial) => {
          updateUnderwritingRequest({
            eligibilityStats: {
              ...underwritingRequest.eligibilityStats,
              ...partial,
            },
          });
        },
      updateLoanDetails:
        ({underwritingRequest, updateUnderwritingRequest}) =>
        (partial) => {
          updateUnderwritingRequest({
            loanDetails: {
              ...underwritingRequest.loanDetails,
              ...partial,
            },
          });
        },
      updateMloDetails:
        ({underwritingRequest, updateUnderwritingRequest}) =>
        (partial) => {
          updateUnderwritingRequest({
            mloDetails: {
              ...underwritingRequest.mloDetails,
              ...partial,
            },
          });
        },
      updateLenderAccountExecutiveDetails:
        ({underwritingRequest, updateUnderwritingRequest}) =>
        (partial) => {
          updateUnderwritingRequest({
            lenderAccountExecutiveDetails: {
              ...underwritingRequest.lenderAccountExecutiveDetails,
              ...partial,
            },
          });
        },
    }),
    omitProps(['client']),
  );
