import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {pick} from 'ramda';

import {MARGIN_KEYS, PADDING_KEYS} from '@renofi/theme';

import FieldFrame from '../FieldFrame';

import {Input, Toggle, Wrapper} from './styled';

const PasswordField = ({
  name,
  help,
  info,
  label,
  error,
  value = '',
  large,
  valid,
  animate,
  placeholder,
  onChange,
  style,
  focus,
  className,
  ...props
}) => {
  const [visible, setVisible] = useState(false);
  return (
    <Wrapper
      mb={24}
      className="renofiPasswordField"
      {...pick([...MARGIN_KEYS, ...PADDING_KEYS], props)}>
      <div css={{position: 'relative'}}>
        <FieldFrame
          customRightIcon={
            <Toggle
              focus={focus}
              visible={visible}
              large={large}
              onClick={() => setVisible(!visible)}
            />
          }
          error={error}
          focused={focus}
          name={name}
          label={label}
          help={help}
          large={large}
          style={style}
          withLabel={label || help}
          className={className}>
          <Input
            id={name}
            className="renofiPasswordFieldInput"
            type={visible ? 'text' : 'password'}
            name={name}
            value={value}
            invalid={error && animate}
            visible={visible}
            placeholder={placeholder}
            onChange={({target}) => onChange(target.value)}
            {...props}
          />
        </FieldFrame>
      </div>
    </Wrapper>
  );
};

PasswordField.propTypes = {
  info: PropTypes.node,
  help: PropTypes.node,
  label: PropTypes.node,
  name: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.string,
  style: PropTypes.object,
  large: PropTypes.bool,
  valid: PropTypes.bool,
  focus: PropTypes.bool,
  animate: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default PasswordField;
