export const SORT_DIRECTIONS = {
  ASC: 'asc',
  DESC: 'desc',
};

export const getNewSortDirection = ({sortColumn, sortDirection, column}) => {
  if (sortColumn !== column || sortDirection !== SORT_DIRECTIONS.ASC) {
    return SORT_DIRECTIONS.ASC;
  }

  return SORT_DIRECTIONS.DESC;
};
