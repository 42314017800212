import React, {Fragment} from 'react';

import PropTypes from 'prop-types';

import {Box, Text} from '@renofi/components-internal';
import noop from '@renofi/utilities/src/noop';
import {basic80} from '@renofi/theme';
import {hasCoBorrowerInProject} from '@renofi/utilities/src/coborrower';

import AssetsSummary from './components/AssetsSummary';

const AssetsDetails = ({
  areActionsDisabled,
  onClickDocument,
  project,
  task,
  internal,
  onAccept,
  onReject,
}) => {
  const {assetDetails = [], documents: taskDocuments = []} = task || {};

  const coborrowerName = project?.coborrower?.fullName;
  const borrowerName = project?.primaryApplicant?.fullName;

  const hasCoBorrower = hasCoBorrowerInProject(project);

  const hasBorowerAssets = assetDetails?.some(
    ({borrowerRole}) => borrowerRole === 'borrower',
  );
  const hasCoBorrowerAssets = assetDetails?.some(
    ({borrowerRole}) => borrowerRole === 'coborrower',
  );

  return (
    <Box flex={1}>
      {hasBorowerAssets ? (
        <Text fontSize={16} color={basic80} mb={2}>
          <strong style={{marginRight: 4, fontFamily: 'Averta-Bold'}}>
            {borrowerName}
          </strong>
          {hasCoBorrower && '(Primary borrower)'}
        </Text>
      ) : null}

      <AssetsSummary
        status={task?.status}
        areActionsDisabled={areActionsDisabled}
        borrowerRole="borrower"
        assetDetails={assetDetails}
        onClickDocument={onClickDocument}
        taskDocuments={taskDocuments}
        internal={internal}
        onAccept={onAccept}
        onReject={onReject}
      />

      {hasCoBorrower && (
        <Fragment>
          {hasCoBorrowerAssets ? (
            <Text fontSize={16} color={basic80} mt={4} mb={2}>
              <strong style={{marginRight: 4, fontFamily: 'Averta-Bold'}}>
                {coborrowerName}
              </strong>
              (Co-borrower)
            </Text>
          ) : null}

          <AssetsSummary
            areActionsDisabled={areActionsDisabled}
            borrowerRole="coborrower"
            assetDetails={assetDetails}
            onClickDocument={onClickDocument}
            taskDocuments={taskDocuments}
            internal={internal}
            onAccept={onAccept}
            onReject={onReject}
          />
        </Fragment>
      )}
    </Box>
  );
};

AssetsDetails.propTypes = {
  areActionsDisabled: PropTypes.bool,
  onClickDocument: PropTypes.func,
  project: PropTypes.object,
  task: PropTypes.shape({
    status: PropTypes.string,
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ),
    id: PropTypes.string.isRequired,
  }),
  internal: PropTypes.bool,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
};

AssetsDetails.defaultProps = {
  onAccept: noop,
  onReject: noop,
};

export default AssetsDetails;
