import {gql} from '@apollo/client';

import {employmentHistory} from '../../partials';

export default gql`
  mutation submitEmploymentHistory($employmentHistory: EmploymentHistoryInputObject!, $id: ID, $taskId: ID!) {
    submitEmploymentHistory(employmentHistory: $employmentHistory, id: $id, taskId: $taskId) {
      employmentHistory {
        ${employmentHistory}
      }
      task {
        id
      }
    }
  }
`;
