import {gql} from '@apollo/client';

import {appraisalOrder} from '../../partials';

export default gql`
  query appraisalOrdersByProjectId($projectId: ID!) {
    appraisalOrdersByProjectId(projectId: $projectId) {
      ${appraisalOrder}
    }
  }
`;
