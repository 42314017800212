import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';

import {actionRegular} from '@renofi/theme';

import Drawer from '../Drawer';
import Progress from '../Progress';

import {Change, Content, Head, Line} from './styled';

const ProgressDrawer = ({
  closed = false,
  data,
  duration = 300,
  fixed = true,
  status,
  top = 0,
}) => {
  const [open, setOpen] = useState(!closed);

  useEffect(() => {
    setOpen(!closed);
  }, [closed]);

  return (
    <Drawer
      show
      duration={duration}
      fixed={fixed}
      open={open}
      min={63}
      max={300}
      top={top}>
      <Head onClick={() => setOpen(!open)}>
        <Line duration={duration} visible={open}>
          Current status
        </Line>
        <Line
          duration={duration}
          visible={open}
          color={actionRegular}
          fontSize={12}
          lineHeight="15px"
          fontStyle="italic">
          {status}
        </Line>
        <Change duration={duration} reverse={!open} />
      </Head>
      <Content px={20} pt={24}>
        <Progress duration={duration} mb={24} compact={!open} data={data} />
      </Content>
    </Drawer>
  );
};

ProgressDrawer.propTypes = {
  closed: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  duration: PropTypes.number,
  fixed: PropTypes.bool,
  status: PropTypes.string.isRequired,
  top: PropTypes.number,
};

export default ProgressDrawer;
