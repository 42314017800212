import {gql} from '@apollo/client';

export default gql`
  query lendersByIds($ids: [ID!]!) {
    lendersByIds(ids: $ids) {
      id
      name
      friendlyId
      notificationPreferences {
        id
        notificationType
        emailAddresses
      }
      users {
        id
      }
    }
  }
`;
