import * as React from 'react';

import PropTypes from 'prop-types';

const SvgDollarCircle = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M12 1C5.928 1 1 5.928 1 12s4.928 11 11 11 11-4.928 11-11S18.072 1 12 1zm1.49 17.699V20.8h-2.917v-2.123C8.705 18.281 7.12 17.071 7 14.937h2.142c.109 1.155.896 2.057 2.895 2.057 2.142 0 2.623-1.078 2.623-1.749 0-.913-.48-1.771-2.918-2.354-2.71-.66-4.567-1.782-4.567-4.037 0-1.892 1.519-3.124 3.398-3.531V3.2h2.918v2.145c2.032.495 3.048 2.046 3.114 3.729h-2.142c-.054-1.221-.7-2.057-2.426-2.057-1.639 0-2.622.748-2.622 1.804 0 .924.71 1.529 2.917 2.101 2.208.572 4.568 1.529 4.568 4.301-.01 2.013-1.508 3.113-3.41 3.476z"
          fill={color}
        />
      </g>
    </svg>
  );
};

SvgDollarCircle.propTypes = {
  color: PropTypes.string,
};
export default SvgDollarCircle;
