import {gql} from '@apollo/client';

export default gql`
  mutation removeGcddDocument($id: ID!, $gcddReviewId: ID!) {
    removeGcddDocument(id: $id, gcddReviewId: $gcddReviewId) {
      gcddReview {
        id
      }
    }
  }
`;
