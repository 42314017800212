import {gql} from '@apollo/client';

export default gql`
  mutation signQuestionnaireOnContractorBehalf(
    $id: ID!
    $signatoryBusinessName: String!
    $signatoryName: String!
    $signatoryTitle: String
    $signatureDate: Date!
  ) {
    signQuestionnaireOnContractorBehalf(
      id: $id
      signatoryBusinessName: $signatoryBusinessName
      signatoryName: $signatoryName
      signatoryTitle: $signatoryTitle
      signatureDate: $signatureDate
    ) {
      questionnaire {
        id
      }
    }
  }
`;
