import {assoc} from 'ramda';

export const formatDateTime = (
  value,
  parts = false,
  showAtBetweenDateAndTime = false,
  dateOnly = false,
) => {
  const isDate = value instanceof Date;
  const date = isDate ? value : new Date(value);
  if (!value) {
    return '';
  }

  const formatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    ...(dateOnly ? {} : {hour12: true}),
    ...(dateOnly ? {} : {hour: '2-digit'}),
    ...(dateOnly ? {} : {minute: '2-digit'}),
  });

  try {
    if (parts) {
      return formatter
        .formatToParts(date)
        .reduce((result, {type, date}) => assoc(type, date, result), {});
    }

    let result = formatter.format(date);

    if (showAtBetweenDateAndTime) {
      result = result.split(',').join(' at');
    }

    return result;
  } catch (error) {
    return 'Invalid date';
  }
};
