import {gql} from '@apollo/client';

export default gql`
  mutation assignInternalAccount(
    $email: String!
    $firstName: String
    $lastName: String
  ) {
    assignInternalAccount(
      email: $email
      firstName: $firstName
      lastName: $lastName
    ) {
      user {
        email
        firstName
        lastName
      }
    }
  }
`;
