import React, {useCallback, useEffect, useState} from 'react';

import PropTypes from 'prop-types';

import LazyImage from '@renofi/components-internal/src/LazyImage';

import {Container} from './styled';

const DEFAULT_DRAG_DATA = {
  dx: 0,
  dy: 0,
  x: 0,
  y: 0,
};
const RESET_MATRIX_DATA = [0.4, 0, 0, 0.4, 0, 0]; // [zoom, skew, skew, zoom, dx, dy]

const preventDefault = (e) => {
  e.stopPropagation();
  e.nativeEvent.stopImmediatePropagation();
  e.preventDefault();
};

const ImageContainer = ({angle, objectName, scale, width}) => {
  const [dragData, setDragData] = useState(DEFAULT_DRAG_DATA);

  const [matrixData, setMatrixData] = useState(RESET_MATRIX_DATA);
  const [mouseDown, setMouseDown] = useState(false);
  const [mouseDragging, setMouseDragging] = useState(false);

  useEffect(() => {
    const newMatrixData = [...matrixData];
    newMatrixData[0] = scale;
    newMatrixData[3] = scale;
    setMatrixData(newMatrixData);
  }, [scale]);

  const onMouseDown = useCallback(
    (e) => {
      const isLeftBtn = e.button === 0;
      if (!isLeftBtn) {
        return;
      }

      setMouseDown(true);
      setDragData({
        dx: matrixData[4],
        dy: matrixData[5],
        x: e.pageX,
        y: e.pageY,
      });
      preventDefault(e);
    },
    [matrixData],
  );

  const onMouseMove = useCallback(
    (e) => {
      if (!mouseDown) {
        return setMouseDragging(false);
      }

      const deltaX = dragData.x - e.pageX;
      const deltaY = dragData.y - e.pageY;
      const newMatrixData = [...matrixData];
      newMatrixData[4] = dragData.dx - deltaX;
      newMatrixData[5] = dragData.dy - deltaY;

      setMouseDragging(true);
      setMatrixData(newMatrixData);
    },
    [dragData, matrixData],
  );

  const onMouseUp = useCallback(() => {
    setMouseDown(false);
    setMouseDragging(false);
  }, []);

  return (
    <Container
      data-private
      css={{transform: `matrix(${matrixData.toString()})`}}
      mouseDragging={mouseDragging}>
      <LazyImage
        css={{width, transform: `rotate(${angle}deg)`}}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        objectName={objectName}
        width="auto"
      />
    </Container>
  );
};

ImageContainer.propTypes = {
  angle: PropTypes.number,
  objectName: PropTypes.string.isRequired,
  scale: PropTypes.number,
  width: PropTypes.number,
};

ImageContainer.defaultProps = {
  angle: 0,
  scale: 1,
  width: '100%',
};

export default ImageContainer;
