import {gql} from '@apollo/client';

import {borrower, borrowersAndPropertyInformation} from '../../partials';

export default gql`
  query renovationUpdates(
    $limit: Int,
    $page: Int,
    $sharedWithLender: Boolean
  ) {
    renovationUpdates(limit: $limit, page: $page, sharedWithLender: $sharedWithLender) {
      collection {
        amount
        createdAt
        date
        details
        files
        id
        kind
        noteToLender
        project {
          borrower {
            ${borrower}
          }
          borrowersAndPropertyInformation {
            ${borrowersAndPropertyInformation}
          }
          homeowners {
            ${borrower}
          }
          primaryApplicantId
          id
          salesforceOpportunityId
        }
        shareWithLender
        sharedWithLenderAt
        source
        updatedAt
        userId
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;
