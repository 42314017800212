import React from 'react';

import PropTypes from 'prop-types';

import {Box} from '@renofi/components-internal';
import {compose} from '@renofi/recompose/src';
import withImpersonation from '@renofi/utilities/src/withImpersonation';

import Header from './Header';

const Page = ({children}) => (
  <>
    <Header />
    <Box as="div" pb={48}>
      {children}
    </Box>
  </>
);

Page.propTypes = {
  impersonation: PropTypes.bool,
  children: PropTypes.node,
};

export default compose(withImpersonation)(Page);
