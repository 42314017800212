import {gql} from '@apollo/client';

import {appraisalOrder} from '../../partials';

export default gql`
  query appraisalOrder($appraisalOrderId: ID!) {
    appraisalOrder(appraisalOrderId: $appraisalOrderId) {
      ${appraisalOrder}
    }
  }
`;
