export default `
comment
createdAt
gcddReviewId
id
rating
updatedAt
url
website
`;
