import {camelCase} from 'change-case';
import camelcaseKeys from 'camelcase-keys';

import {ENV_VAR_PREFIX, ENV_VAR_SUFFIXES} from './constants';

export const getInitialData = () =>
  camelcaseKeys(window.RECONF_CONFIG?.data || {}, {deep: true});

export const hasAllEnvVars = () =>
  ENV_VAR_SUFFIXES.every((suffix) =>
    Boolean(process.env[`${ENV_VAR_PREFIX}_${suffix}`]),
  );

export const getFirebaseOptions = () =>
  ENV_VAR_SUFFIXES.reduce(
    (options, suffix) => ({
      ...options,
      [camelCase(suffix)]: process.env[`${ENV_VAR_PREFIX}_${suffix}`],
    }),
    {},
  );
