import * as React from 'react';

import PropTypes from 'prop-types';

const EventDelayed = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M6 20V18H2V8H16V12H18V4C18 3.45 17.8043 2.97933 17.413 2.588C17.021 2.196 16.55 2 16 2H15V0H13V2H5V0H3V2H2C1.45 2 0.979 2.196 0.587 2.588C0.195667 2.97933 0 3.45 0 4V18C0 18.55 0.195667 19.021 0.587 19.413C0.979 19.8043 1.45 20 2 20H6ZM13 22L14.4 20.6L11.825 18H20V16H11.825L14.4 13.4L13 12L8 17L13 22ZM16 6H2V4H16V6Z"
        fill={color}
      />
    </svg>
  );
};

EventDelayed.propTypes = {
  color: PropTypes.string,
};

export default EventDelayed;
