import {gql} from '@apollo/client';

export default gql`
  query exceptionRequestById($id: ID!) {
    exceptionRequestById(id: $id) {
      id
      approvedAt
      approvedById
      approvedBy {
        id
        email
        firstName
        lastName
      }
      borrower {
        id
        email
        firstName
        lastName
      }
      project {
        id
        borrower {
          id
          email
          firstName
          lastName
        }
        city
        state
        streetAddressOne
        streetAddressTwo
        zipCode
      }
      comments {
        id
        addedById
        addedBy {
          id
          email
          firstName
          lastName
        }
        createdAt
        message
        source
        subject
        updatedAt
      }
      compensatingFactors
      createdAt
      deniedAt
      deniedById
      deniedBy {
        id
        email
        firstName
        lastName
      }
      lenderId
      lender {
        id
        name
      }
      lenderNotes
      metrics {
        id
        createdAt
        exceptional
        name
        notes
        updatedAt
        value
      }
      property {
        city
        state
        streetAddressOne
        streetAddressTwo
        zipCode
      }
      renofiNotes
      renovationScope
      status
      title
      updatedAt
    }
  }
`;
