export default `
borrowerRole
checksum
contentType
createdAt
documentType
fileName
fileSizeBytes
id
objectName
pdfObjectName
rejectedAt
rejectedBy {
  id
  firstName
  lastName
}
rejectedBySystem
rejectionDetails
rejectionReason
rejectionMessageScheduledAt
requestIpAddress
requestIpLocation
reviewedAt
shareable
shareWithLenderAllowed
source
status
systemGenerated
updatedAt
`;
