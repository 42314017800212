import * as React from 'react';

import PropTypes from 'prop-types';

import {successRegular} from '@renofi/theme';

const SvgCheckGreen = ({color, ...props}) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <circle cx={7.999} cy={8} r={7.333} fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 10.666 4.665 8.333l.94-.94L6.999 8.78l3.454-3.447.94.94-4.394 4.393z"
        fill="#fff"
      />
    </svg>
  );
};

SvgCheckGreen.propTypes = {
  color: PropTypes.string,
};

SvgCheckGreen.defaultProps = {
  color: successRegular,
};

export default SvgCheckGreen;
