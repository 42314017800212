import React from 'react';

import PropTypes from 'prop-types';
import {identity, ifElse, is, keys, map} from 'ramda';

import Flex from '../Flex';
import Label from '../Label';
import RadioButton from '../RadioButton';

import {Wrapper} from './styled';

const handleOptions = ifElse(
  Array.isArray,
  map(ifElse(is(String), (state) => ({label: state, value: state}), identity)),
  (options) => {
    return keys(options).map((value) => ({label: options[value], value}));
  },
);

const RadioGroup = ({
  alignItems = 'center',
  flexDirection = 'row',
  help,
  name,
  label,
  value,
  options,
  onChange,
  disabled = false,
  small,
  height,
  ...props
}) => {
  const finalOptions = handleOptions(options);
  const isVertical = flexDirection.includes('column');
  const buttonMarginKey = isVertical ? 'mb' : 'mr';

  return (
    <Wrapper mb={24} {...props}>
      {(label || help) && (
        <Label help={help} small={small}>
          {label}
        </Label>
      )}
      <Flex
        width={1}
        flexDirection={flexDirection}
        alignItems={alignItems}
        marginTop={label || help ? (small ? '4px' : '6px') : 0}>
        {finalOptions.map((option) => (
          <RadioButton
            height={height}
            small={small}
            disabled={disabled}
            width={isVertical ? '100%' : 'auto'}
            {...{[buttonMarginKey]: 12}}
            key={option.value}
            name={name}
            checked={value === option.value}
            {...{name, onChange, ...option}}
          />
        ))}
      </Flex>
    </Wrapper>
  );
};

RadioGroup.propTypes = {
  small: PropTypes.bool,
  height: PropTypes.number,
  alignItems: PropTypes.string,
  flexDirection: PropTypes.oneOf([
    'column',
    'column-reverse',
    'row',
    'row-reverse',
  ]),
  help: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.node,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
          ]),
        }),
      ]),
    ),
    PropTypes.object,
  ]).isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default RadioGroup;
