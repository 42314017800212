import {gql} from '@apollo/client';

export default gql`
  mutation requestProjectImport($projectId: String!) {
    requestProjectImport(projectId: $projectId) {
      project {
        id
      }
    }
  }
`;
