import {gql} from '@apollo/client';

export default gql`
  mutation rejectContractor(
    $contractorId: ID!
    $rejectionReason: ContractorRejectionReason!
    $message: String!
  ) {
    rejectContractorInformation(
      id: $contractorId
      rejectionReason: $rejectionReason
      message: $message
    ) {
      success
    }
  }
`;
