import {gql} from '@apollo/client';

export default gql`
  mutation setGcddReviewPriority($id: ID!, $priority: PriorityEnum!) {
    setGcddReviewPriority(id: $id, priority: $priority) {
      gcddReview {
        priority
      }
    }
  }
`;
