import {gql} from '@apollo/client';

export default gql`
  mutation updateFeasibilityStudyItem(
    $id: ID!
    $feasibilityStudyItem: FeasibilityStudyItemInputObject!
  ) {
    updateFeasibilityStudyItem(
      id: $id
      feasibilityStudyItem: $feasibilityStudyItem
    ) {
      feasibilityStudyItem {
        id
        addedById
        addedBy {
          id
        }
        adjustedCost
        cost
        descriptionOfWork
        reviewerNotes
        reviewerRating
        source
        type
      }
    }
  }
`;
