import * as React from 'react';

import PropTypes from 'prop-types';

const SvgExterior = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm6.18-11.784a.277.277 0 0 1-.14-.038l-.958-.57v7.482a.27.27 0 0 1-.273.27H7.19a.273.273 0 0 1-.274-.27V9.61l-.955.568a.271.271 0 1 1-.281-.465l6.18-3.674a.275.275 0 0 1 .282 0l6.176 3.674a.268.268 0 0 1 .096.372.274.274 0 0 1-.235.131z"
        fill={color}
      />
    </svg>
  );
};

SvgExterior.propTypes = {
  color: PropTypes.string,
};
export default SvgExterior;
