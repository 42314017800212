import * as React from 'react';

import PropTypes from 'prop-types';

const SvgPerson = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M12 12c3.039 0 5.5-2.461 5.5-5.5S15.039 1 12 1a5.498 5.498 0 0 0-5.5 5.5C6.5 9.539 8.961 12 12 12zm0 2.75c-3.671 0-11 1.843-11 5.5V23h22v-2.75c0-3.657-7.329-5.5-11-5.5z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

SvgPerson.propTypes = {
  color: PropTypes.string,
};
export default SvgPerson;
