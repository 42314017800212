import * as React from 'react';

import PropTypes from 'prop-types';

const SvgWithdrawn = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={22}
      height={22}
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-1-1h24v24H-1z" />
        <path
          d="M1.467 0 22 20.533 20.533 22l-2.227-2.227H1.934v-2.376c0-3.161 6.107-4.754 9.167-4.754h.076L0 1.468 1.467 0zM11.1.76c2.532 0 4.583 2.127 4.583 4.753a4.802 4.802 0 0 1-1.91 3.862l-6.49-6.492C8.103 1.603 9.506.76 11.1.76z"
          fill={color}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

SvgWithdrawn.propTypes = {
  color: PropTypes.string,
};
export default SvgWithdrawn;
