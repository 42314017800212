import React from 'react';

import PropTypes from 'prop-types';

import {dateTimeDiff} from '@renofi/utilities/src/dates';

const Duration = ({value}) => <>{dateTimeDiff(value)}</>;

Duration.propTypes = {
  value: PropTypes.object.isRequired,
};

export default Duration;
