import {gql} from '@apollo/client';

export default gql`
  query renovationUpdate {
    renovationUpdate @client {
      action
      kind
      data {
        id
        date
        details
        files {
          id
          fileName
          loading
          objectName
          thumbnail
          url
        }
      }
    }
  }
`;
