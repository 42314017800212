import styled from '@emotion/styled';

import Flex from '@renofi/components-internal/src/Flex';
import {maxSmall} from '@renofi/theme/src/breakpoints';

export const TabsContainer = styled(Flex)({
  [maxSmall]: {
    width: '100%',
  },
});
