import {gql} from '@apollo/client';

export default gql`
  query renovationDetails {
    renovationDetails @client {
      id
      changeTypes
      custom
      details
      name
      value
      additional {
        root
        type
        details
        location
      }
      existing {
        root
        type
        changeType
        details
        location
      }
      squareFootage {
        basement
        total
      }
    }
  }
`;
