import {pipe, propOr, find, propEq} from 'ramda';

const permissions = {
  BORROWER_USERS_MANAGE: 'borrower.users.manage',
  INTERNAL_USERS_MANAGE: 'internal.users.manage',
  LENDER_USERS_MANAGE: 'lender.users.manage',
  USERS_IMPERSONATE: 'users.impersonate',
  GCDD_VIEW: 'internal.gcdd.view',
  GCDD_EDIT: 'internal.gcdd.edit',
  BLUEPRINT_VIEW: 'internal.blueprint.view',
  BLUEPRINT_EDIT: 'internal.blueprint.edit',
  FEASIBILITY_STUDY_ITEMS_EDIT: 'internal.feasibility_study_items.edit',
  FEASIBILITY_ADMIN: 'internal.feasibility.admin',
  FEASIBILITY_REGULAR: 'feasibility.regular',
  FEASIBILITY_ADVANCED: 'feasibility.advanced',
  FEASIBILITY_MANAGER: 'feasibility.manager',
  LOAN_APPLICATION_REGULAR: 'internal.loan_application.regular',
  LOAN_APPLICATION_ADVANCED: 'internal.loan_application.advanced',
  IDENTITY_MANAGE: 'internal.identity_verification.manage',
};

export function checkPermission(permission, user) {
  return !!pipe(
    propOr([], 'permissions'),
    find(propEq('key', permission)),
  )(user);
}

export function canManageLenderUsers(user) {
  return checkPermission(permissions.LENDER_USERS_MANAGE, user);
}

export function canManageBorrowerUsers(user) {
  return checkPermission(permissions.BORROWER_USERS_MANAGE, user);
}

export function canManageInternalUsers(user) {
  return checkPermission(permissions.INTERNAL_USERS_MANAGE, user);
}

export function canImpersonate(user) {
  return checkPermission(permissions.USERS_IMPERSONATE, user);
}

export function canViewStandard(user) {
  return checkPermission(permissions.BLUEPRINT_VIEW, user);
}

export function canEditStandard(user) {
  return checkPermission(permissions.BLUEPRINT_EDIT, user);
}

export function canViewGcdd(user) {
  return checkPermission(permissions.GCDD_VIEW, user);
}

export function canEditGcdd(user) {
  return checkPermission(permissions.GCDD_EDIT, user);
}

export function isViewToggleVisible(user) {
  return canViewStandard(user) && canViewGcdd(user);
}

export function isFeasibilityAdmin(user) {
  return checkPermission(permissions.FEASIBILITY_ADMIN, user);
}

export function canEditFeasibilityReview(user) {
  return checkPermission(permissions.FEASIBILITY_STUDY_ITEMS_EDIT, user);
}

export function hasFeasibilityRegularPermission(user) {
  return checkPermission(permissions.FEASIBILITY_REGULAR, user);
}

export function hasFeasibilityAdvancedPermission(user) {
  return checkPermission(permissions.FEASIBILITY_ADVANCED, user);
}

export function isFeasibilityManager(user) {
  return checkPermission(permissions.FEASIBILITY_MANAGER, user);
}

export function hasLoanApplicationRegularPermission(user) {
  return checkPermission(permissions.LOAN_APPLICATION_REGULAR, user);
}

export function hasLoanApplicationAdvancedPermission(user) {
  return checkPermission(permissions.LOAN_APPLICATION_ADVANCED, user);
}

export function canManageIdentity(user) {
  return checkPermission(permissions.IDENTITY_MANAGE, user);
}

export default permissions;
