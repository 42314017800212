import {useEffect, useMemo} from 'react';

import {NetworkStatus, useQuery} from '@apollo/client';

import {useSystemState} from '@renofi/graphql/src/hooks/index';
import {useNotifications, useSystemContext} from '@renofi/utilities/src/hooks';

import getQueryName from '../utils/getQueryName';

import onClientError from './onClientError';

export default (query, props = {}) => {
  const {config, storage} = useSystemContext();
  const {addNotification} = useNotifications();
  const {toggleSystemQuery} = useSystemState();
  const queryName = useMemo(() => getQueryName(query), [query]);

  const {networkStatus, ...response} = useQuery(query, {
    ...props,
    errorPolicy: 'all',
    onError: (error) => {
      if (props.onError) {
        props.onError({config, error, storage});
      }
      onClientError({addNotification, config, error, storage});
    },
  });

  useEffect(() => {
    const isLoading = networkStatus === NetworkStatus.loading;
    toggleSystemQuery(queryName, isLoading);
  }, [networkStatus]);

  return {
    networkStatus,
    ...response,
  };
};
