import {gql} from '@apollo/client';

import {personalInfoAndResidency, task} from '../../partials';

export default gql`
  mutation removeAlternativeName(
    $taskId: ID!
    $id: ID!
  ) {
    removeAlternativeName(
      taskId: $taskId
      id: $id
    ) {
      personalInfoAndResidency {
        ${personalInfoAndResidency}
      }
      task {
        ${task}
      }
    }
  }
`;
