import styled from '@emotion/styled';

import {Form} from '@renofi/components-internal';
import {maxSmall} from '@renofi/theme/src/breakpoints';

export default styled(Form)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  maxWidth: 350,
  margin: '0 auto',
  [maxSmall]: {
    maxWidth: 'auto',
  },
});
