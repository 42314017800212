import {gql} from '@apollo/client';

import {references, webReview} from '../../partials';

export default gql`
  query gcddReviewsByContractorId(
    $contractorId: ID!, 
    $limit: Int, 
    $page: Int, 
    $sortBy: GcddReviewSortInputObject
  ) {
    gcddReviewsByContractorId(
      contractorId: $contractorId, 
      limit: $limit, 
      page: $page, 
      sortBy: $sortBy
    ) {
      collection {
        assignedToId
        assignedTo {
          email
          firstName
          id
          lastName
          phoneNumber
        }
        projectId
        project {
          city
          id
          renovationCost
          renovationCostToCurrentPropertyValueRatio
          renovationScope
          state
          status
          streetAddressOne
          streetAddressTwo
          tasks {
            id
            contractorInformations {
              email
              id
              removedAt
            }
            taskType
          }
          timeline {
            id
            expectedRenovationCompletionOn
            expectedRenovationStart
            renovationCompletedOn
            renovationStartedOn
          }
          zipCode
        }
        cancelledAt
        cancellationMessage
        cancellationReason
        completedAt
        complexityLevel
        contractorInformationId
        priority
        estimatedCostOfWork
        scopeOfWork
        completedAt
        overallScore
        overallScoreNotes
        contractor {
          businessName
          id
        }
        id
        questionnaire {
          contractorGeneralLiabilityInsurance {
            reviewResult
          }
          contractorLicense {
            reviewResult
          }
          contractorWorkersCompInsurance {
            reviewResult
          }
          hasWorkersCompInsurance
          createdAt
          firstOpenedAt
          references {
            ${references}
          }
          returnedAt
          reviewedAt
          score
          submittedStep
        }
        returnedAt
        webReviews {
          ${webReview}
        }
        webReviewsNotFound
        webReviewsScore
        webReviewsScoreNotes
        webReviewsScoreSubmittedAt
        webReviewsScoreSubmittedById
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
}
`;
