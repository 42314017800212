import {gql} from '@apollo/client';

export default gql`
  mutation createProjectComment(
    $projectId: ID!
    $subject: String!
    $body: String!
  ) {
    createProjectComment(
      projectId: $projectId
      subject: $subject
      body: $body
    ) {
      comment {
        id
        body
        createdAt
        source
        subject
        updatedAt
        userId
        user {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`;
