import styled from '@emotion/styled';

import Flex from '@renofi/components-internal/src/Flex';

import {PAGE_WIDTH} from '../constants';

export const Controls = styled(Flex)(
  {
    zIndex: '1100',
    bottom: 24,
    left: '50%',
    width: 332,
    height: 44,
    backgroundColor: 'rgba(51,51,51,0.9)',
    borderRadius: 4,
    alignItems: 'center',
    fontSize: 14,
    lineHeight: '17px',
    color: 'white',
    transition: 'all 500ms ease-out',
    userSelect: 'none',
  },
  ({active, fixed, width}) => ({
    opacity: active ? 1 : 0.1,
    position: fixed ? 'fixed' : 'absolute',
    marginLeft: width === PAGE_WIDTH ? '-166px' : '-450px',
  }),
);

export const Btn = styled(Flex)(
  {
    height: 32,
    width: 32,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    ':hover': {
      background: 'rgba(32,32,32,0.8)',
    },
  },
  ({disabled}) => {
    return {
      pointerEvents: disabled ? 'none' : 'inherit',
    };
  },
);
