import {gql} from '@apollo/client';

export default gql`
  mutation deleteNote($id: ID!) {
    deleteNote(id: $id) {
      note {
        id
      }
    }
  }
`;
