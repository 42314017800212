import React from 'react';

import PropTypes from 'prop-types';

import Progress from '@renofi/icons/src/Progress';

import Button from './Button';
import Flex from './Flex';
import Box from './Box';

const ProgressButton = ({
  onClick = () => {},
  progressing,
  text = 'Download all files',
  progressText = 'Downloading all files ...',
  disabled,
}) => {
  return (
    <Button onClick={onClick} disabled={disabled || progressing}>
      <Flex alignItems="center">
        {progressing && (
          <Box ml={-15} pr={10} mt={-1} css={{display: 'inherit'}}>
            <Progress css={{margin: '0 0 0 -15px'}} animate duration={1000} />
          </Box>
        )}
        <div>{progressing ? progressText : text}</div>
      </Flex>
    </Button>
  );
};

ProgressButton.propTypes = {
  onClick: PropTypes.func,
  progressing: PropTypes.bool,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  progressText: PropTypes.string,
};

export default ProgressButton;
