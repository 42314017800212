import React, {Fragment} from 'react';

import PropTypes from 'prop-types';

import {Text} from '@renofi/components-internal';
import noop from '@renofi/utilities/src/noop';
import {basic80} from '@renofi/theme';

import IncomeSourceSummary from './components/IncomeSourceSummary';

const IncomeSourcesDetails = ({
  areActionsDisabled,
  onClickDocument,
  project,
  task,
  internal,
  onAccept,
  onReject,
}) => {
  const {incomeSources = []} = task || {};
  const {borrowersAndPropertyInformation: borrowersInfo} = project || {};
  const taskDocuments = task?.documents || [];
  const {coborrowerIsPresent} = borrowersInfo || {};
  const coborrowerName = project?.coborrower?.fullName;
  const borrowerName = project?.primaryApplicant?.fullName;

  return (
    <Fragment>
      {coborrowerIsPresent && (
        <Text fontSize={16} color={basic80} mb={2}>
          <strong style={{marginRight: 4, fontFamily: 'Averta-Bold'}}>
            {borrowerName}
          </strong>
          {coborrowerIsPresent && '(Primary borrower)'}
        </Text>
      )}

      <IncomeSourceSummary
        status={task?.status}
        areActionsDisabled={areActionsDisabled}
        borrowerRole="borrower"
        incomeSources={incomeSources}
        onClickDocument={onClickDocument}
        taskDocuments={taskDocuments}
        internal={internal}
        onAccept={onAccept}
        onReject={onReject}
      />

      {coborrowerIsPresent && (
        <Fragment>
          <Text fontSize={16} color={basic80} mt={4} mb={2}>
            <strong style={{marginRight: 4, fontFamily: 'Averta-Bold'}}>
              {coborrowerName}
            </strong>
            (Co-borrower)
          </Text>

          <IncomeSourceSummary
            areActionsDisabled={areActionsDisabled}
            borrowerRole="coborrower"
            incomeSources={incomeSources}
            onClickDocument={onClickDocument}
            taskDocuments={taskDocuments}
            internal={internal}
            onAccept={onAccept}
            onReject={onReject}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

IncomeSourcesDetails.propTypes = {
  areActionsDisabled: PropTypes.bool,
  onClickDocument: PropTypes.func,
  project: PropTypes.object,
  task: PropTypes.object,
  internal: PropTypes.bool,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
};

IncomeSourcesDetails.defaultProps = {
  areActionsDisabled: true,
  onAccept: noop,
  onReject: noop,
};

export default IncomeSourcesDetails;
