import {
  GET_RENOVATION_UPDATE,
  GET_UNDERWRITING_REQUEST,
} from '@renofi/graphql/src/queries';

const IS_DEV = process.env.NODE_ENV === 'development';

export default (client) => {
  client.writeQuery({
    query: GET_RENOVATION_UPDATE,
    data: {
      renovationUpdate: {
        action: null,
        kind: null,
        data: {
          id: null,
          date: null,
          details: '',
          files: [],
          __typename: 'RenovationUpdateData',
        },
        __typename: 'RenovationUpdateModal',
      },
    },
  });
  client.writeQuery({
    query: GET_UNDERWRITING_REQUEST,
    data: {
      underwritingRequest: {
        requirements: {
          condition1: IS_DEV,
          condition2: IS_DEV,
          condition3: IS_DEV,
          condition4: IS_DEV,
          __typename: 'UnderwritingRequirements',
        },
        personalDetails: {
          firstName: '',
          lastName: '',
          phoneNumber: '',
          email: '',
          streetAddressOne: '',
          streetAddressTwo: '',
          zipCode: '',
          state: '',
          city: '',
          __typename: 'UnderwritingPersonalDetails',
        },
        eligibilityStats: {
          creditScore: '',
          assets: '',
          reserves: '',
          dtiRatio: '',
          __typename: 'UnderwritingEligibilityStats',
        },
        loanDetails: {
          currentHomeValue: '',
          currentMortgageBalance: '',
          lenderLoanNumber: '',
          loanValue: '',
          renovationCost: '',
          postRenovationHomeValue: '',
          secondMortgageBalance: '',
          intendedStartDate: null,
          returnDeadline: null,
          __typename: 'UnderwritingLoanDetails',
        },
        lenderAccountExecutiveDetails: {
          lenderAccountExecutiveEmail: '',
          lenderAccountExecutiveFirstName: '',
          lenderAccountExecutiveLastName: '',
          lenderAccountExecutivePhone: '',
          __typename: 'UnderwritingLenderAccountExecutiveDetails',
        },
        mloDetails: {
          mloFirstName: '',
          mloLastName: '',
          mloEmail: '',
          mloPhone: '',
          __typename: 'UnderwritingMloDetails',
        },
        __typename: 'UnderwritingRequestState',
      },
    },
  });
};
