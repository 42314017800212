import styled from '@emotion/styled';

import omitProps from '@renofi/utilities/src/omitProps';
import {Link} from '@renofi/components-internal';

import approve from '../../icons/approve.svg';
import approveActive from '../../icons/approve-active.svg';
import discard from '../../icons/discard.svg';
import discardActive from '../../icons/discard-active.svg';

const Action = styled(omitProps(['active'])(Link))(
  {
    display: 'block',
    width: 22,
    height: 22,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    packgroundSize: '22px 22px',
  },
  ({disabled}) => ({
    opacity: disabled ? 0.4 : 1,
  }),
);

export const Discard = styled(Action)(
  {
    ':hover': {
      backgroundImage: `url('${discardActive}')`,
    },
  },
  ({active}) => ({
    backgroundImage: `url('${active ? discardActive : discard}')`,
  }),
);

export const Approve = styled(Action)(
  {
    ':hover': {
      backgroundImage: `url('${approveActive}')`,
    },
  },
  ({active}) => ({
    backgroundImage: `url('${active ? approveActive : approve}')`,
  }),
);
