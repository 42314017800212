import {gql} from '@apollo/client';

export default gql`
  query appraisalOrderCreated(
    $appraisalOrder: EmailPreviewAppraisalOrderCreatedInputObject!
  ) {
    appraisalOrderCreated(appraisalOrder: $appraisalOrder) {
      content
      subject
    }
  }
`;
