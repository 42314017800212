import {makeVar, useReactiveVar} from '@apollo/client';
import {uniq, without} from 'ramda';

const mutationsVar = makeVar([]);
const queriesVar = makeVar([]);

const useSystemState = () => {
  const mutations = useReactiveVar(mutationsVar);
  const queries = useReactiveVar(queriesVar);

  /**
   * Don't check for duplicates when adding, 'cos you could have many
   * requests hitting the same Query (but with different return objects)
   * So we want to track ALL instances of myQuery() currently active.
   */
  const toggleSystemQuery = (queryName, add) => {
    const updated = add
      ? queries.concat(queryName)
      : without(queryName, queries);
    queriesVar(uniq(updated));
  };

  const toggleSystemMutation = (mutationName, add) => {
    const updated = add
      ? mutations.concat(mutationName)
      : without(mutationName, mutations);
    mutationsVar(uniq(updated));
  };

  return {
    toggleSystemMutation,
    mutations,
    queries,
    toggleSystemQuery,
  };
};

export default useSystemState;
