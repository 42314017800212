import {gql} from '@apollo/client';

export default gql`
  mutation moveTaskDocuments($documentIds: [ID!]!, $taskId: ID!) {
    moveTaskDocuments(documentIds: $documentIds, taskId: $taskId) {
      documents {
        fileName
        id
      }
    }
  }
`;
