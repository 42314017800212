import * as React from 'react';

import PropTypes from 'prop-types';

const SvgGif = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <mask id="gif_svg__a" fill="#fff">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.56 0A3.56 3.56 0 0 0 8 3.56v24.88A3.56 3.56 0 0 0 11.56 32h16.88A3.56 3.56 0 0 0 32 28.44V3.539 8l-8-8H11.56z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.56 0A3.56 3.56 0 0 0 8 3.56v24.88A3.56 3.56 0 0 0 11.56 32h16.88A3.56 3.56 0 0 0 32 28.44V3.539 8l-8-8H11.56z"
        fill="#fff"
      />
      <path
        d="m32 3.539 1-.006-2 .006h1zM32 8l-.707.707L33 10.414V8h-1zm-8-8 .707-.707L24.414-1H24v1zM9 3.56A2.56 2.56 0 0 1 11.56 1v-2A4.56 4.56 0 0 0 7 3.56h2zm0 24.88V3.56H7v24.88h2zM11.56 31A2.56 2.56 0 0 1 9 28.44H7A4.56 4.56 0 0 0 11.56 33v-2zm16.88 0H11.56v2h16.88v-2zM31 28.44A2.56 2.56 0 0 1 28.44 31v2A4.56 4.56 0 0 0 33 28.44h-2zm0-24.88v24.88h2V3.56h-2zm0-.015v.015h2v-.027l-2 .012zm0-.006V8h2V3.539h-2zm1.707 3.754-8-8L23.293.707l8 8 1.414-1.414zM11.56 1H24v-2H11.56v2z"
        fill="#EBEBEB"
        mask="url(#gif_svg__a)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m24 0 8 8h-6a2 2 0 0 1-2-2V0z"
        fill="#A6ADBC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 8h5v5l-5-5z"
        fill="#D2D6DE"
      />
      <rect y={3} width={21} height={10} rx={2} fill="#00B6F0" />
      <path
        d="M8.355 10.668c-.26.102-.605.203-1.035.305-.43.099-.858.148-1.285.148-.99 0-1.764-.268-2.324-.805-.56-.539-.84-1.282-.84-2.23 0-.904.283-1.632.848-2.184.565-.554 1.353-.832 2.363-.832.383 0 .747.035 1.094.106a4.82 4.82 0 0 1 1.156.41v1.363h-.168c-.073-.054-.18-.131-.32-.23a3.708 3.708 0 0 0-.407-.258 2.654 2.654 0 0 0-.53-.215 2.232 2.232 0 0 0-.641-.09c-.266 0-.507.04-.723.117-.216.079-.41.198-.582.36a1.656 1.656 0 0 0-.39.597 2.27 2.27 0 0 0-.141.832c0 .644.17 1.136.511 1.477.342.341.845.512 1.512.512a4.911 4.911 0 0 0 .379-.016v-1.14h-1.16V7.797h2.683v2.871zm4.493.332H9.44V9.969h.954V6.215H9.44V5.184h3.407v1.03h-.954V9.97h.954V11zm5.39-4.691h-2.675V7.39h2.48v1.125h-2.48V11H14.07V5.184h4.168v1.125z"
        fill="#fff"
      />
      <path
        d="M15.32 23.496a.922.922 0 0 1 1.274-.03l1.155 1.127a.355.355 0 0 0 .534-.06l3.2-5.274a.9.9 0 0 1 1.51-.03l4.86 7.527c.385.592-.03 1.392-.74 1.392H12.978c-.8 0-1.185-.948-.652-1.511l2.993-3.14z"
        fill="#00B6F0"
      />
      <circle
        r={2.281}
        transform="matrix(-1 0 0 1 15.32 18.282)"
        fill="#00B6F0"
      />
    </svg>
  );
};

SvgGif.propTypes = {
  color: PropTypes.string,
};
export default SvgGif;
