import {gql} from '@apollo/client';

export default gql`
  query lender($lenderId: ID!) {
    lender(id: $lenderId) {
      id
      applyUrl
      contactInformation1
      contactInformation2
      crmId
      friendlyId
      joinUrl
      raasEnabled
      tpoEnabled
      logoObjectName
      logoUrl
      loanProducts {
        id
        name
      }
      mfaRequired
      name
      notificationPreferences {
        id
        emailAddresses
        notificationType
      }
      rrfTransferInstructions
      wholesaleEnabled
      users {
        id
      }
      tpoAcknowledgementContent
    }
  }
`;
