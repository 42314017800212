import * as React from 'react';

import PropTypes from 'prop-types';

const SvgPdf = ({color = '#FF5253', ...props}) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <mask id="pdf_svg__a" fill="#fff">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.56 0A3.56 3.56 0 0 0 8 3.56v24.88A3.56 3.56 0 0 0 11.56 32h16.88A3.56 3.56 0 0 0 32 28.44V3.539 8l-8-8H11.56z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.56 0A3.56 3.56 0 0 0 8 3.56v24.88A3.56 3.56 0 0 0 11.56 32h16.88A3.56 3.56 0 0 0 32 28.44V3.539 8l-8-8H11.56z"
        fill="#fff"
      />
      <path
        d="m32 3.539 1-.006-2 .006h1zM32 8l-.707.707L33 10.414V8h-1zm-8-8 .707-.707L24.414-1H24v1zM9 3.56A2.56 2.56 0 0 1 11.56 1v-2A4.56 4.56 0 0 0 7 3.56h2zm0 24.88V3.56H7v24.88h2zM11.56 31A2.56 2.56 0 0 1 9 28.44H7A4.56 4.56 0 0 0 11.56 33v-2zm16.88 0H11.56v2h16.88v-2zM31 28.44A2.56 2.56 0 0 1 28.44 31v2A4.56 4.56 0 0 0 33 28.44h-2zm0-24.88v24.88h2V3.56h-2zm0-.015v.015h2v-.027l-2 .012zm0-.006V8h2V3.539h-2zm1.707 3.754-8-8L23.293.707l8 8 1.414-1.414zM11.56 1H24v-2H11.56v2z"
        fill="#EBEBEB"
        mask="url(#pdf_svg__a)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m24 0 8 8h-6a2 2 0 0 1-2-2V0z"
        fill="#A6ADBC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 8h5v5l-5-5z"
        fill="#D2D6DE"
      />
      <rect y={3} width={21} height={10} rx={2} fill={color} />
      <path
        d="M7.188 7.02c0 .26-.046.515-.137.765a1.701 1.701 0 0 1-.39.625c-.233.23-.492.402-.778.52-.284.117-.638.175-1.063.175h-.933V11h-1.5V5.184h2.468c.37 0 .681.032.934.097.255.063.48.158.676.285.234.154.413.35.535.59.125.24.188.528.188.864zm-1.551.035a.68.68 0 0 0-.133-.422.717.717 0 0 0-.309-.25 1.356 1.356 0 0 0-.457-.102 11.858 11.858 0 0 0-.593-.011h-.258v1.742h.43c.255 0 .464-.016.628-.047a.894.894 0 0 0 .418-.188.708.708 0 0 0 .207-.297c.045-.117.067-.259.067-.425zm8.113 1.043c0 .541-.124 1.027-.371 1.457a2.71 2.71 0 0 1-.938.984 2.877 2.877 0 0 1-.933.36c-.339.067-.74.101-1.203.101H8.254V5.184h2.11c.473 0 .882.04 1.226.12.344.079.633.19.867.337.401.244.716.575.945.992.232.414.348.902.348 1.465zm-1.55-.012c0-.383-.071-.71-.212-.98a1.445 1.445 0 0 0-.66-.641 1.67 1.67 0 0 0-.473-.152 4.437 4.437 0 0 0-.722-.043h-.38v3.64h.38c.357 0 .618-.015.785-.047.167-.034.33-.093.488-.18.274-.156.474-.364.602-.624.127-.263.191-.588.191-.973zm6.863-1.777h-2.676V7.39h2.48v1.125h-2.48V11h-1.492V5.184h4.168v1.125z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.321 24.42a15.2 15.2 0 0 1-4.605-1.31 14.998 14.998 0 0 0-2.996 1.355c-.311 1.469-.891 2.763-1.725 3.849a1.734 1.734 0 0 1-1.379.686c-.628 0-1.222-.343-1.478-.851-.253-.504-.155-1.077.272-1.576.39-.456 1.475-1.613 3.29-2.755a13.19 13.19 0 0 0 .103-3.369 16.075 16.075 0 0 1-2.47-3c-.339-.53-.36-1.129-.055-1.64.29-.49.835-.809 1.384-.809.57 0 1.04.336 1.26.898.169.435.728 1.983.961 4.061a13.684 13.684 0 0 0 2.932 2.003 14.194 14.194 0 0 1 4.514-.561c1.117.04 1.686.849 1.67 1.625-.017.884-.727 1.508-1.678 1.394zm-7.396-1.302c.494-.256.997-.484 1.506-.685-.5-.297-.984-.623-1.446-.977.013.569-.007 1.125-.06 1.662zm-3.772 4.573c-.06-.122-.018-.272.13-.445.28-.325.943-1.038 2.016-1.837a8.041 8.041 0 0 1-1.213 2.276c-.264.342-.796.28-.933.006zm1.509-11.626c-.12 0-.303.093-.404.267-.104.173-.091.364.037.564.378.59.79 1.146 1.23 1.665a14.81 14.81 0 0 0-.659-2.293c-.078-.203-.17-.203-.204-.203zm6.809 6.604c.933-.172 1.86-.241 2.814-.206.572.021.593.454.59.54-.002.11-.04.364-.338.364l-.079-.004a13.98 13.98 0 0 1-2.987-.694z"
        fill={color}
      />
    </svg>
  );
};

SvgPdf.propTypes = {
  color: PropTypes.string,
};
export default SvgPdf;
