import * as React from 'react';

import PropTypes from 'prop-types';

const SvgCalendar = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M19.2 3.822h-.9V2.004h-1.8v1.818h-9V2.004H5.7v1.818h-.9c-.99 0-1.8.818-1.8 1.818v14.546c0 1 .81 1.818 1.8 1.818h14.4c.99 0 1.8-.818 1.8-1.818V5.64c0-1-.81-1.818-1.8-1.818zm0 16.364H4.8V8.368h14.4v11.818z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

SvgCalendar.propTypes = {
  color: PropTypes.string,
};
export default SvgCalendar;
