import React from 'react';

import PropTypes from 'prop-types';

import {basic20} from '@renofi/theme';

const SvgBullhorn = ({color, width, ...props}) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_4600_88250"
        style={{maskType: 'alpha'}}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={width}
        height={width}>
        <rect width={width} height={width} fill={color} />
      </mask>
      <g mask="url(#mask0_4600_88250)">
        <path
          d="M23.9993 17.3334V14.6667H29.3327V17.3334H23.9993ZM25.5994 26.6667L21.3327 23.4667L22.9327 21.3334L27.1993 24.5334L25.5994 26.6667ZM22.9327 10.6667L21.3327 8.53337L25.5994 5.33337L27.1993 7.46671L22.9327 10.6667ZM6.66602 25.3334V20H5.33268C4.59935 20 3.97157 19.7389 3.44935 19.2167C2.92713 18.6945 2.66602 18.0667 2.66602 17.3334V14.6667C2.66602 13.9334 2.92713 13.3056 3.44935 12.7834C3.97157 12.2612 4.59935 12 5.33268 12H10.666L17.3327 8.00004V24L10.666 20H9.33268V25.3334H6.66602ZM18.666 20.4667V11.5334C19.266 12.0667 19.7494 12.7167 20.116 13.4834C20.4827 14.25 20.666 15.0889 20.666 16C20.666 16.9112 20.4827 17.75 20.116 18.5167C19.7494 19.2834 19.266 19.9334 18.666 20.4667Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

SvgBullhorn.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
};

SvgBullhorn.defaultProps = {
  color: basic20,
  width: 32,
};

export default SvgBullhorn;
