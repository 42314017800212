import logger from '@renofi/utilities/src/logger';

const ADOBE_URL = 'https://documentservices.adobe.com/view-sdk/viewer.js';

let script;

export const injectAdobeScript = (onLoad) => {
  if (script) {
    logger.debug('already injected');
    onLoad(true);
    return script;
  }

  script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = ADOBE_URL;
  // script.onload = () => onLoad(true);
  const head = document.getElementsByTagName('head')[0];
  head.appendChild(script);
  return script;
};
