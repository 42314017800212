import React, {useCallback, useEffect, useState} from 'react';

import PropTypes from 'prop-types';

import Check from '@renofi/icons/src/CheckGreen';

import Box from '../Box';
import Dot from '../Dot';
import Tooltip from '../Tooltip';

import {
  Content,
  Message,
  Toggle,
  ToggleWrapper,
  TooltipWrapper,
  Wrapper,
} from './styled';

const STATUS_MESSAGE_MAP = {
  danger:
    'This task is waiting to be completed. The sooner you complete it the faster we’ll get the work done!',
  success:
    'We reviewed the task and everything is looking good! Tackle the outstanding tasks to complete everything!',
  info: 'We reviewed the task and everything is looking good! Tackle the outstanding tasks to complete everything!',
  warning:
    'Nice work! We will review the documents you provided as soon as possible and reach out if we need more details - thank you!',
  complete:
    'This task is completed, hooray! We reviewed it and everything looks good!',
};

export const CAN_CLOSE_STATUSES = ['success', 'complete'];

const TaskItem = ({
  disabled,
  status,
  children,
  closedStatuses,
  onToggleDetails,
  keepTaskOpenOnStatusChange,
  allowToClose,
  ...props
}) => {
  const closeable = closedStatuses.includes(status) || allowToClose;
  const [closed, setClosed] = useState(closeable);

  const clickHandler = useCallback(() => {
    if (!closed) return;
    setClosed(false);
    onToggleDetails(false);
  }, [closed]);

  const onClickToggle = useCallback(
    (event) => {
      event.stopPropagation();
      setClosed(!closed);
      onToggleDetails(!closed);
    },
    [closed],
  );

  useEffect(() => {
    if (keepTaskOpenOnStatusChange) return;

    const isComplete = closedStatuses.includes(status);
    setClosed(isComplete);
    onToggleDetails(isComplete);
  }, [closedStatuses.length, status]);

  return (
    <Wrapper
      closed={closeable && closed}
      disabled={disabled}
      closeable={closeable}
      {...props}
      onClick={clickHandler}>
      <ToggleWrapper disabled={disabled}>
        {closeable ? (
          <Toggle closed={closed} onClick={onClickToggle} />
        ) : (
          <Box width={28} />
        )}
        <TooltipWrapper>
          <Tooltip
            place="top"
            content={<Message>{STATUS_MESSAGE_MAP[status]}</Message>}
            css={{width: 360, textAlign: 'left'}}>
            <div
              css={{
                width: 24,
                height: 24,
                position: 'absolute',
                top: 0,
                left: 0,
                cursor: 'pointer',
              }}></div>
          </Tooltip>
          {status === 'complete' ? <Check /> : <Dot large status={status} />}
        </TooltipWrapper>
      </ToggleWrapper>
      <Content closed={closeable && closed} css={{opacity: disabled ? 0.5 : 1}}>
        {typeof children === 'function' ? children({closed}) : children}
      </Content>
    </Wrapper>
  );
};

TaskItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  closedStatuses: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  large: PropTypes.bool,
  onToggleDetails: PropTypes.func,
  status: PropTypes.string.isRequired,
  keepTaskOpenOnStatusChange: PropTypes.bool,
  allowToClose: PropTypes.bool,
};

TaskItem.defaultProps = {
  closedStatuses: CAN_CLOSE_STATUSES,
  onToggleDetails: () => {},
};

export default TaskItem;
