import {gql} from '@apollo/client';

import {task} from '../../partials';

export default gql`
  mutation productConfirmationFinishedByApplicant(
    $productDetailsConfirmationId: ID!
  ) {
    productConfirmationFinishedByApplicant(
      productDetailsConfirmationId: $productDetailsConfirmationId
    ) {
      productConfirmationTask {
        ${task}
      }
    }
  }
`;
