import React from 'react';

import PropTypes from 'prop-types';

import Alert from '@renofi/components-internal/src/AlertWithIcon';
import Box from '@renofi/components-internal/src/Box';
import Button from '@renofi/components-internal/src/Button';
import Card from '@renofi/components-internal/src/Card';
import Flex from '@renofi/components-internal/src/Flex';
import SelectField from '@renofi/components-internal/src/SelectField';
import Text from '@renofi/components-internal/src/Text';
import TextArea from '@renofi/components-internal/src/Textarea';
import WithdrawnIcon from '@renofi/icons/src/Withdrawn';

import {Help} from '../styled';

import DateField from './DateField';

const reasons = {
  mind: 'Change of mind',
  market: 'Market change',
  competition: 'Went with other financing option',
  other: 'Other',
};

const Withdrawn = ({
  activeStatus,
  status,
  persistable,
  disabled,
  loading,
  withdrawnOn,
  withdrawnReason,
  withdrawnExplanation,
  onChangeStatus,
  onChangeFieldValue,
  onSaveLoanApplication,
}) => {
  const getChangeHandler = (field) => (value) =>
    onChangeFieldValue(field, value);
  return (
    <Card
      title={
        <Flex width={1} alignItems="center">
          <WithdrawnIcon />
          <Text ml={16}>Applicant withdrawn details</Text>
        </Flex>
      }
      mt={24}
      p={0}>
      {persistable && (
        <Alert variant="info">
          <Flex width={1} alignItems="center" justifyContent="space-between">
            <div>
              Enter details for the homeowner’s withdrawal below before
              confirming the status change.
            </div>
            <Box>
              <Button
                variant="link"
                onClick={() => onChangeStatus('inProgress')}>
                Cancel
              </Button>
              <Button
                loading={loading}
                disabled={disabled}
                onClick={onSaveLoanApplication}>
                {activeStatus !== status
                  ? 'Confirm status change'
                  : 'Update details'}
              </Button>
            </Box>
          </Flex>
        </Alert>
      )}

      <Flex width={1} mt={24}>
        <Box width={1 / 3} px={16}>
          <DateField
            label="Official withdrawal date?"
            value={withdrawnOn}
            onChange={getChangeHandler('withdrawnOn')}
          />
        </Box>
        <Box width={1 / 3} px={16}>
          <SelectField
            mb={0}
            label="Reason for withdrawal?"
            placeholder="Select a reason"
            value={withdrawnReason}
            options={reasons}
            onChange={getChangeHandler('withdrawnReason')}
          />
        </Box>
        <Box width={1 / 3} px={16}></Box>
      </Flex>

      <Flex width={1}>
        <Box width={2 / 3} px={16}>
          <TextArea
            value={withdrawnExplanation}
            rows={1}
            help={<Help>Optional</Help>}
            label="Additional details:"
            onChange={getChangeHandler('withdrawnExplanation')}
          />
        </Box>
      </Flex>
    </Card>
  );
};

Withdrawn.propTypes = {
  activeStatus: PropTypes.string,
  status: PropTypes.string,
  persistable: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  withdrawnOn: PropTypes.string,
  withdrawnReason: PropTypes.string,
  withdrawnExplanation: PropTypes.string,
  onChangeStatus: PropTypes.func.isRequired,
  onChangeFieldValue: PropTypes.func.isRequired,
  onSaveLoanApplication: PropTypes.func.isRequired,
};

export default Withdrawn;
