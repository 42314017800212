import {cloneElement, createElement, useState} from 'react';

import PropTypes from 'prop-types';

import noop from '@renofi/utilities/src/noop';

const MouseHover = ({
  children,
  hideDelay = 0,
  type = 'span',
  onMouseEnter = noop,
  onMouseLeave = noop,
  ...props
}) => {
  const [hover, setHover] = useState(false);
  const [timer, setTimer] = useState(null);

  const mouseEnter = (event) => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setHover(true);
    onMouseEnter(event);
  };

  const mouseLeave = (event) => {
    const timeoutRef = setTimeout(() => {
      setHover(false);
      onMouseLeave(event);
    }, hideDelay);
    setTimer(timeoutRef);
  };

  return createElement(
    type,
    {onMouseEnter: mouseEnter, onMouseLeave: mouseLeave, ...props},
    typeof children === 'function'
      ? children({hover})
      : cloneElement(children, {...children.props}),
  );
};

MouseHover.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  hideDelay: PropTypes.number,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  type: PropTypes.elementType,
};

export default MouseHover;
