import {gql} from '@apollo/client';

export default gql`
  query loanProducts($lenderCrmId: String, $propertyState: String) {
    loanProducts(lenderCrmId: $lenderCrmId, propertyState: $propertyState) {
      productType
      id
      name
      drawPeriod
      repaymentPeriod
    }
  }
`;
