import {omit} from 'ramda';

export default (keys) => (props) => ({
  ...keys.reduce(
    (result, key) => ({
      ...props[key],
      ...result,
    }),
    {},
  ),
  ...omit(keys, props),
});
