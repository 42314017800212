import {gql} from '@apollo/client';

import {note} from '../../partials';

export default gql`
  query projectNotes($id: ID!) {
    projectById(id: $id) {
      id
      notes {
        ${note}
      }
    }
  }
`;
