import {gql} from '@apollo/client';

export default gql`
  query system {
    system @client {
      mutations
      queries
    }
  }
`;
