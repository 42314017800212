import {gql} from '@apollo/client';

import {incomeSource} from '../../partials';

export default gql`
  mutation submitIncomeSource(
    $attributes: IncomeSourceInputObject!
    $id: ID
    $taskId: ID!
  ) {
    submitIncomeSource(attributes: $attributes, id: $id, taskId: $taskId) {
      incomeSource {
        ${incomeSource}
      }
    }
  }
`;
