import {gql} from '@apollo/client';

export default gql`
  query exceptionRequests {
    lenderExceptionRequests {
      id
      approvedAt
      borrower {
        id
        email
        firstName
        lastName
      }
      project {
        id
        borrower {
          id
          email
          firstName
          lastName
        }
      }
      metrics {
        name
        value
      }
      comments {
        id
      }
      commentsCount
      compensatingFactors
      createdAt
      deniedAt
      lenderNotes
      loanAmount
      renofiNotes
      renovationScope
      status
      title
      updatedAt
    }
  }
`;
