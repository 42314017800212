import {gql} from '@apollo/client';

export default gql`
  query internalUsers($limit: Int, $page: Int, $query: String) {
    internalUsers(limit: $limit, page: $page, query: $query) {
      collection {
        id
        email
        firstName
        lastName
        permissions {
          key
        }
        accountCategories
      }
      metadata {
        totalCount
        totalPages
      }
    }
  }
`;
