import styled from '@emotion/styled';
import {keyframes} from '@emotion/react';

import {basic55, basic80, dangerRegular, white} from '@renofi/theme/src/colors';

import Heading from '../Heading';
import Box from '../Box';
import Text from '../Text';

const slideIn = keyframes({
  from: {
    transform: 'translate(-50%, 0)',
    opacity: 0,
  },
  to: {
    transform: 'translate(-50%, -50%)',
    opacity: 1,
  },
});

export const Container = styled(Box)({
  backgroundColor: white,
  borderRadius: 16,
  bottom: 32,
  boxShadow: '0 2px 20px 0 rgba(0,0,0,0.1)',
  left: '50%',
  minWidth: 500,
  padding: 16,
  position: 'fixed',
  transform: 'translate(-50%, -50%)',
  zIndex: 1092,
  opacity: 0,
  animation: `${slideIn} 150ms ease-out`,
  animationFillMode: 'forwards',
});

export const Content = styled(Text)({
  color: basic55,
  fontSize: 14,
  strong: {
    color: dangerRegular,
  },
});

export const Title = styled(Heading)({
  color: basic80,
  fontSize: 20,
  fontWeight: 'bold',
  lineHeight: 1,
  marginBottom: 8,
});
