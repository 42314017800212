import {gql} from '@apollo/client';

import {applicant} from '../../partials';

export default gql`
  query feasibilityReviewsSearch(
    $query: String
    $filter: FeasibilityReviewsSearchFilterInputObject
    $sort: FeasibilityReviewsSearchSortInputObject
    $page: Int
    $limit: Int
  ) {
    feasibilityReviewsSearch(
      query: $query
      filter: $filter
      page: $page
      limit: $limit
      sort: $sort
    ) {
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
        facets {
          status {
            count
          }
          reviewerId {
            count
          }
        }
      }
      collection {
        assignedAt
        contractReviewStatus
        feasibilityStudyStatus
        id
        status
        projectId
        project {
          homeowners {
            firstName
            lastName
            id
          }
          primaryApplicant {
            ${applicant}
          }
          id
          primaryApplicantId
          renofiId
          renovationCost
          renovationScope
        }
        requestedAt
        returnedAt
        completedAt
        rejectedAt
        canceledAt
        cancelable
        canceledById
        canceledBy {
          fullName
        }
        reviewerId
        reviewer {
          firstName
          lastName
        }
        requestedBy {
          firstName
          lastName
        }
        uncompletedReason
      }
    }
  }
`;
