import {gql} from '@apollo/client';

export default gql`
  query isFilePasswordValid(
    $filePassword: String!
    $objectName: StorageObjectName!
  ) {
    isFilePasswordValid(filePassword: $filePassword, objectName: $objectName)
  }
`;
