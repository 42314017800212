import {gql} from '@apollo/client';

export default gql`
  mutation setDocumentSharable($id: ID!, $shareable: Boolean!) {
    setDocumentSharable(id: $id, shareable: $shareable) {
      document {
        id
        createdAt
        checksum
        documentType
        fileName
        objectName
        pdfObjectName
        shareWithLenderAllowed
        shareable
        updatedAt
      }
    }
  }
`;
