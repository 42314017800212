import * as React from 'react';

import PropTypes from 'prop-types';

import {basic20} from '@renofi/theme/src/colors';

const SvgPending = ({className, color = basic20, height = 22, width = 16}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 22"
      xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M-4-1h24v24H-4z" />
        <path
          d="M8 5v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L2.7 13.8A5.87 5.87 0 0 1 2 11c0-3.31 2.69-6 6-6zm6.76 1.74L13.3 8.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z"
          fill={color}
        />
      </g>
    </svg>
  );
};

SvgPending.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default SvgPending;
