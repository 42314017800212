import React from 'react';

import PropTypes from 'prop-types';

const getSubmitHandler = (onSubmit) => (event) => {
  event.preventDefault();
  onSubmit(event);
};

const Form = ({children, onSubmit, ...props}) => (
  <form
    css={{display: 'block', width: '100%'}}
    onSubmit={getSubmitHandler(onSubmit)}
    {...props}>
    {children}
  </form>
);

Form.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Form;
