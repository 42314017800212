import {gql} from '@apollo/client';

export default gql`
  mutation loanApplicationNotAccepted(
    $renovationReadyFileId: ID!
    $withdrawnExplanation: String
    $withdrawnOn: Date!
    $withdrawnReason: ApplicationWithdrawnReason!
  ) {
    loanApplicationNotAccepted(
      renovationReadyFileId: $renovationReadyFileId
      withdrawnOn: $withdrawnOn
      withdrawnReason: $withdrawnReason
      withdrawnExplanation: $withdrawnExplanation
    ) {
      lenderLoanApplication {
        id
        appliedOn
        approvedOn
        closedOn
        conditions
        createdAt
        deniedCreditScoreExplanation
        deniedDebtExplanation
        deniedOn
        deniedOtherExplanation
        estimatedClosingOn
        finalCreditScore
        finalDtiRatio
        finalLoanAmount
        finalLoanRate
        isDeniedReasonCreditScore
        isDeniedReasonDebt
        isDeniedReasonOther
        lender {
          id
          loanProducts {
            id
            name
          }
        }
        lenderProvidedIncome
        lenderProvidedMonthlyDebt
        lenderVerifiedReserves
        loanNumber
        loanProduct {
          id
          createdAt
          name
          updatedAt
        }
        renovationReadyFileId
        status
        updatedAt
        withdrawnExplanation
        withdrawnOn
        withdrawnReason
      }
    }
  }
`;
