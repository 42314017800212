import {propOr} from 'ramda';

import useQuery from '../useQuery';
import useGetUser from '../chainlink/useGetUser';
import {GET_RENOVATION_LENDER_REPORT} from '../../queries';

export default function useLenderReport({fetchPolicy = 'cache-first'} = {}) {
  const {user} = useGetUser();
  const response = useQuery(GET_RENOVATION_LENDER_REPORT, {
    fetchPolicy,
    variables: {
      lenderId: user?.lenderId,
    },
  });

  const {data, error, loading, fetch} = response;
  const lenderReport = propOr(null, 'lenderReport', data);

  return {error, fetch, lenderReport, loading};
}
