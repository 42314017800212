import lender from './lender';

export default `
id
approvedAt
approvedById
approvedBy {
  id
  email
  firstName
  lastName
}
comments {
  id
  addedById
  addedBy {
    id
    email
    firstName
    lastName
  }
  createdAt
  message
  source
  subject
  updatedAt
}
compensatingFactors
createdAt
deniedAt
deniedById
deniedBy {
  id
  email
  firstName
  lastName
}
lenderId
lender {
  ${lender}
}
metrics {
  id
  createdAt
  exceptional
  name
  notes
  updatedAt
  value
}
renofiNotes
renovationScope
status
title
updatedAt
`;
