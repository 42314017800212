import {gql} from '@apollo/client';

export default gql`
  mutation setRaasEnabled($lenderId: ID!, $raasEnabled: Boolean!) {
    setRaasEnabled(lenderId: $lenderId, raasEnabled: $raasEnabled) {
      lender {
        id
        applyUrl
        contactInformation1
        contactInformation2
        friendlyId
        joinUrl
        raasEnabled
        logoUrl
        loanProducts {
          id
          name
        }
        name
      }
    }
  }
`;
