import {identity} from 'ramda';

import {branch, compose, mapProps} from '@renofi/recompose/src';

import {GET_PROJECT} from '../queries';

import withProjectId from './withProjectId';
import withQuery from './withQuery';

export default ({mapper = identity, options} = {}) =>
  compose(
    branch(({projectId}) => !projectId, withProjectId()),
    withQuery({
      query: GET_PROJECT,
      props: ({data, ...props}) => ({
        project: data?.projectById || data?.previousData?.projectById,
        ...props,
      }),
      options:
        options || (({projectId, id}) => ({variables: {id: projectId || id}})),
    }),
    mapProps(mapper),
  );
