import {gql} from '@apollo/client';

export default gql`
  mutation acceptContractor($id: ID!) {
    acceptContractor(id: $id) {
      contractor {
        id
        businessName
        contactName
        contactTitle
        phoneNumber
        email
        website
        streetAddress
        secondaryAddress
        rejectionDetails
        rejectionReason
        city
        state
        status
        zipCode
      }
    }
  }
`;
