import lender from './lender';

export default `
id
assets
city
createdAt
createdBy {
  id
  email
  firstName
  lastName
}
creditScore
currentHomeValue
currentMortgageBalance
dtiRatio
email
firstName
intendedStartDate
lastName
lender {
  ${lender}
}
lenderLoanNumber
loanValue
phoneNumber
postRenovationHomeValue
renovationCost
reserves
ruStartedAt
secondMortgageBalance
state
status
streetAddressOne
streetAddressTwo
updatedAt
zipCode
`;
