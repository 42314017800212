export default `
applicantName
coapplicantName
content
createdAt
id
applicantSsnSuffix
coapplicantSsnSuffix
title
taskId
updatedAt
`;
