import {compose, withHandlers} from '@renofi/recompose/src';

import withChangeRenovationState from './withChangeRenovationState';

export default () =>
  compose(
    withChangeRenovationState(),
    withHandlers({
      changeRenovationStateAction:
        ({changeRenovationState}) =>
        (action) => {
          changeRenovationState({action});
        },
    }),
  );
