import {gql} from '@apollo/client';

export default gql`
  mutation markTaskDataAsReadyForProcessing($taskId: ID!) {
    markTaskDataAsReadyForProcessing(taskId: $taskId) {
      task {
        id
        dataSubmittedAt
        status
      }
    }
  }
`;
