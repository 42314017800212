import React from 'react';

import PropTypes from 'prop-types';

import {Box, DateFormat, Flex, Icon, Timer} from '@renofi/components-internal';
import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';

import ShareToggle from '../ShareToggle';
import {getRecordIconName, isRecent} from '../../utils';

const RenovationUpdateRow = ({
  renovationUpdate,
  onViewRecord,
  fetchRenovationUpdates,
  onToggleShare,
  isInternal,
}) => {
  const {
    id,
    kind,
    photos,
    sharedWithLenderAt,
    shareWithLender,
    updatedAt,
    createdAt,
    user,
  } = renovationUpdate;

  const iconName = getRecordIconName(kind);
  const hasPhotos = photos?.length > 0;
  const showSharingWithLender =
    !sharedWithLenderAt && shareWithLender && isRecent(updatedAt);

  return (
    <tr key={id} onClick={() => onViewRecord(id)}>
      <td>{createdAt ? <DateFormat value={createdAt} time={false} /> : '-'}</td>
      <td>
        <Flex justifyContent="start">
          <Icon name={iconName} />
          {hasPhotos && (
            <Box ml="40px">
              <Icon name="camera" />
            </Box>
          )}
        </Flex>
      </td>
      <td>{humanizeSnakeCase(kind)}</td>
      <td css={isInternal ? {} : {textAlign: 'right !important'}}>
        {user?.firstName} {user?.lastName}
      </td>
      {isInternal && (
        <td>
          <Flex width={1} alignItems="center" justifyContent="flex-end">
            {showSharingWithLender && (
              <Box mr={12}>
                Sharing with Lender in{' '}
                <Timer
                  onFinish={fetchRenovationUpdates}
                  start={new Date(updatedAt)}
                  value={61}
                />
                s
              </Box>
            )}
            <Box>
              <ShareToggle
                onToggle={(event) => {
                  event.stopPropagation();
                  onToggleShare(id, !shareWithLender);
                }}
                shareWithLender={shareWithLender}
                sharedWithLenderAt={sharedWithLenderAt}
              />
            </Box>
          </Flex>
        </td>
      )}
    </tr>
  );
};

RenovationUpdateRow.propTypes = {
  renovationUpdate: PropTypes.shape({
    id: PropTypes.string,
    kind: PropTypes.string,
    photos: PropTypes.array,
    sharedWithLenderAt: PropTypes.string,
    shareWithLender: PropTypes.bool,
    updatedAt: PropTypes.string,
    createdAt: PropTypes.string,
    user: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }),
  onViewRecord: PropTypes.func,
  fetchRenovationUpdates: PropTypes.func,
  onToggleShare: PropTypes.func,
  isInternal: PropTypes.bool,
};

export default RenovationUpdateRow;
