import styled from '@emotion/styled';

import {
  actionRegular,
  actionExtraLight,
  successRegular,
  successExtraLight,
  warningRegular,
  warningExtraLight,
  dangerRegular,
  dangerExtraLight,
  basic03,
  basic20,
} from '@renofi/theme/src/colors';

export default styled.div(
  {
    width: 1,
    height: 1,
    border: 'solid 2px white',
    borderRadius: '50%',
  },
  ({large, status}) => ({
    padding: large ? 4 : 3,
    ...(status === 'success'
      ? {
          backgroundColor: successRegular,
          boxShadow: `0px 0px 0px ${large ? 4 : 2}px ${successExtraLight}`,
        }
      : {}),
    ...(status === 'danger'
      ? {
          backgroundColor: dangerRegular,
          boxShadow: `0px 0px 0px ${large ? 4 : 2}px ${dangerExtraLight}`,
        }
      : {}),
    ...(status === 'warning'
      ? {
          backgroundColor: warningRegular,
          boxShadow: `0px 0px 0px ${large ? 4 : 2}px ${warningExtraLight}`,
        }
      : {}),
    ...(status === 'info'
      ? {
          backgroundColor: actionRegular,
          boxShadow: `0px 0px 0px ${large ? 4 : 2}px ${actionExtraLight}`,
        }
      : {}),
    ...(status === 'basic'
      ? {
          backgroundColor: basic20,
          boxShadow: `0px 0px 0px ${large ? 4 : 2}px ${basic03}`,
        }
      : {}),
  }),
);
