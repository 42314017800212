import {
  useLoanApplicationApproved,
  useLoanApplicationClosed,
  useLoanApplicationDenied,
  useLoanApplicationNotAccepted,
  useLoanApplicationWithdrawn,
} from '@renofi/graphql';

import {
  transformApprovedLoanApplication,
  transformClosedLoanApplication,
  transformDeniedLoanApplication,
  transformNotAcceptedAndWithdrawn,
} from '../utils';

const wait = (ms) => new Promise((res) => setTimeout(res, ms));
const delayRefetchedQuery = async (observableQuery) => {
  await wait(2000);
  observableQuery.refetch();
};

export default function useLoanApplicationActions(refetchQueries) {
  const {loanApplicationApproved} = useLoanApplicationApproved();
  const {loanApplicationClosed} = useLoanApplicationClosed();
  const {loanApplicationDenied} = useLoanApplicationDenied();
  const {loanApplicationNotAccepted} = useLoanApplicationNotAccepted();
  const {loanApplicationWithdrawn} = useLoanApplicationWithdrawn();

  const approveLoanApplication = async (
    renovationReadyFileId,
    loanApplication,
  ) => {
    const transformed = transformApprovedLoanApplication(loanApplication);

    const result = await loanApplicationApproved({
      variables: {
        renovationReadyFileId,
        ...transformed,
      },
      refetchQueries,
      onQueryUpdated: delayRefetchedQuery,
    });

    return result;
  };

  const closeLoanApplication = async (
    renovationReadyFileId,
    loanApplication,
  ) => {
    const transformed = transformClosedLoanApplication(loanApplication);

    const result = await loanApplicationClosed({
      variables: {
        renovationReadyFileId,
        ...transformed,
      },
      refetchQueries,
      onQueryUpdated: delayRefetchedQuery,
    });

    return result;
  };

  const denyLoanApplication = async (
    renovationReadyFileId,
    loanApplication,
  ) => {
    const transformed = transformDeniedLoanApplication(loanApplication);

    const result = await loanApplicationDenied({
      variables: {
        renovationReadyFileId,
        ...transformed,
      },
      refetchQueries,
      onQueryUpdated: delayRefetchedQuery,
    });

    return result;
  };

  const notAcceptLoanApplication = async (
    renovationReadyFileId,
    loanApplication,
  ) => {
    const transformed = transformNotAcceptedAndWithdrawn(loanApplication);

    const result = await loanApplicationNotAccepted({
      variables: {
        renovationReadyFileId,
        ...transformed,
      },
      refetchQueries,
      onQueryUpdated: delayRefetchedQuery,
    });

    return result;
  };

  const withdrawnLoanApplication = async (
    renovationReadyFileId,
    loanApplication,
  ) => {
    const transformed = transformNotAcceptedAndWithdrawn(loanApplication);

    const result = await loanApplicationWithdrawn({
      variables: {
        renovationReadyFileId,
        ...transformed,
      },
      refetchQueries,
      onQueryUpdated: delayRefetchedQuery,
    });

    return result;
  };

  return {
    approveLoanApplication,
    closeLoanApplication,
    denyLoanApplication,
    notAcceptLoanApplication,
    withdrawnLoanApplication,
  };
}
