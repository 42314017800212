import React, {useState, useEffect} from 'react';

import PropTypes from 'prop-types';

import Flex from '@renofi/components-internal/src/Flex';
import {Rotate, Download, Minus, Plus, Zoom} from '@renofi/icons/src';
import {basic55} from '@renofi/theme/src/colors';

import {Btn, Controls} from './styled';

const ViewerControls = ({
  loading,
  handleDownload,
  current,
  pages,
  changeAngle,
  scale,
  changeScale,
  fixed,
  width,
}) => {
  const [hover, setHover] = useState(false);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    return () => {
      if (!timer) return;
      clearTimeout(timer);
    };
  }, []);

  const mouseEnter = () => {
    setHover(true);
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
  };

  const mouseLeave = () => {
    if (timer) {
      clearTimeout(timer);
    }
    const timeout = setTimeout(() => {
      setHover(false);
    }, 1000);
    setTimer(timeout);
  };

  return (
    <Controls
      active={hover}
      width={width}
      fixed={fixed}
      onClick={(event) => event.stopPropagation()}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}>
      <Flex
        px={12}
        flexGrow={0}
        flexShrink={0}
        width={100}
        height="100%"
        alignItems="center"
        css={{
          borderRight: `1px solid ${basic55}`,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}>
        Page {current} / {pages}
      </Flex>
      <Flex
        flexGrow={0}
        flexShrink={0}
        width={50}
        height="100%"
        alignItems="center"
        justifyContent="center"
        css={{borderRight: `1px solid ${basic55}`}}>
        <Btn disabled={loading} onClick={handleDownload}>
          <Download />
        </Btn>
      </Flex>
      <Flex
        flexGrow={0}
        flexShrink={0}
        width={50}
        height="100%"
        alignItems="center"
        justifyContent="center"
        css={{borderRight: `1px solid ${basic55}`}}>
        <Btn onClick={changeAngle}>
          <Rotate />
        </Btn>
      </Flex>
      <Flex
        flexGrow={1}
        height="100%"
        alignItems="center"
        justifyContent="space-around">
        <Btn
          onClick={() => {
            changeScale(scale - 0.1);
          }}>
          <Minus />
        </Btn>
        <Zoom />
        <Btn
          onClick={() => {
            changeScale(scale + 0.1);
          }}>
          <Plus />
        </Btn>
      </Flex>
    </Controls>
  );
};

ViewerControls.propTypes = {
  current: PropTypes.number,
  handleDownload: PropTypes.func,
  loading: PropTypes.bool,
  pages: PropTypes.number,
  angle: PropTypes.number,
  changeAngle: PropTypes.func,
  scale: PropTypes.number,
  changeScale: PropTypes.func,
  fixed: PropTypes.bool,
  width: PropTypes.number,
};

ViewerControls.defaultProps = {
  fixed: true,
};

export default ViewerControls;
