import {gql} from '@apollo/client';

export default gql`
  mutation submitInsuranceReview(
    $insuranceReview: InsuranceReviewInputObject!
    $projectId: ID!
  ) {
    submitInsuranceReview(
      insuranceReview: $insuranceReview
      projectId: $projectId
    ) {
      insuranceReview {
        id
        carrier
        completedAt
        completedById
        completedBy {
          id
          firstName
          lastName
        }
        coverageDuringRenovationPeriod
        coverageProofReceivedAt
        coverageProofRequestedAt
        coverageProofRequestScheduledAt
        createdAt
        dwellingCoverage
        expansiveRenovation
        largeAmountRenovation
        policyDateVisible
        policyEffectiveDate
        renovationIncludesRoofWorks
        renovationIncludesStructuralChanges
        updatedAt
      }
    }
  }
`;
