import * as React from 'react';

import PropTypes from 'prop-types';

import {basic20} from '@renofi/theme/src/colors';

const SvgCross = ({color = basic20, width = 24, ...props}) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox={'0 0 24 24'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M20 5.611 18.389 4 12 10.389 5.611 4 4 5.611 10.389 12 4 18.389 5.611 20 12 13.611 18.389 20 20 18.389 13.611 12z"
        clipRule="evenodd"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

SvgCross.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
};

export default SvgCross;
