import React from 'react';

import PropTypes from 'prop-types';

const Plus = ({color = 'white', ...props}) => {
  return (
    <svg width="30" height="30" fill="none" viewBox="0 0 30 30" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9495 6.52145L14.0504 6.52145L14.0504 14.0505L6.52138 14.0505V15.9495L14.0504 15.9495L14.0504 23.4785L15.9495 23.4785L15.9495 15.9495H23.4785V14.0505H15.9495L15.9495 6.52145Z"
        fill={color}
      />
    </svg>
  );
};

Plus.propTypes = {
  color: PropTypes.string,
};

export default Plus;
