import {compose, withHandlers} from '@renofi/recompose/src';

import {GET_RENOVATION_UPDATE} from '../queries';

import withApollo from './withApollo';

export default () =>
  compose(
    withApollo,
    withHandlers({
      changeRenovationState:
        ({client}) =>
        async (partial) => {
          const {renovationUpdate} = client.readQuery({
            query: GET_RENOVATION_UPDATE,
          });
          client.writeQuery({
            query: GET_RENOVATION_UPDATE,
            data: {
              renovationUpdate: {
                ...renovationUpdate,
                ...partial,
              },
            },
          });
        },
    }),
  );
