import styled from '@emotion/styled';

import Box from '../Box';
import Flex from '../Flex';

export const Wrapper = styled(Box)({
  width: '100%',
  position: 'relative',
});

export const Container = styled(Flex)(
  {
    alignItems: 'center',
    flexWrap: 'wrap',
    overflow: 'hidden',
  },
  ({withLabel, width = '100%'}) => ({
    marginTop: withLabel ? 6 : 0,
    width,
  }),
);
