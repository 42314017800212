import * as React from 'react';

import PropTypes from 'prop-types';

const SvgPersonCircle = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={22}
      height={22}
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-1-1h24v24H-1z" />
        <path
          d="M11 0C4.928 0 0 4.928 0 11s4.928 11 11 11 11-4.928 11-11S17.072 0 11 0zm0 4c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2a7.2 7.2 0 0 1-6-3.22c.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08a7.2 7.2 0 0 1-6 3.22z"
          fill={color}
        />
      </g>
    </svg>
  );
};

SvgPersonCircle.propTypes = {
  color: PropTypes.string,
};
export default SvgPersonCircle;
