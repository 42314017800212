export default `
confirmationRequestedAt
confirmationRequestedById
confirmationRequestedMessage
confirmedAt
createdAt
email
id
manualEntryDetails
name
phoneNumber
questionnaireId
score
scoreSubmittedAt
scoreSubmittedById
type
updatedAt
`;
