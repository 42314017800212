import {gql} from '@apollo/client';

export default gql`
  mutation submitOverallScore(
    $gcddReviewId: ID!
    $overallScore: ScoreEnum
    $overallScoreNotes: String!
  ) {
    submitOverallScore(
      gcddReviewId: $gcddReviewId
      overallScore: $overallScore
      overallScoreNotes: $overallScoreNotes
    ) {
      gcddReview {
        completedAt
        createdAt
        id
        overallScore
        overallScoreNotes
        returnedAt
        updatedAt
      }
    }
  }
`;
