import {gql} from '@apollo/client';

export default gql`
  mutation updateNote(
    $body: String!
    $id: ID!
    $incomeCommentaryId: ID
    $title: String!
  ) {
    updateNote(
      body: $body
      id: $id
      incomeCommentaryId: $incomeCommentaryId
      title: $title
    ) {
      note {
        id
      }
    }
  }
`;
