import {useCallback} from 'react';

import {useApolloClient} from '@apollo/client';
import {find, flatten, map, pipe, propEq, propOr} from 'ramda';

import {GET_PROJECT_TASKS} from '@renofi/graphql/src';
import {facetsEnum} from '@renofi/utilities/src/facets';

const FACETS = Object.keys(facetsEnum);

export default function useTaskByDocumentId({projectId}) {
  const client = useApolloClient();

  const getTaskByDocumentId = useCallback(
    (documentId) => {
      const result = pipe(
        map(
          pipe(
            (facet) =>
              client.readQuery({
                query: GET_PROJECT_TASKS,
                variables: {projectId, facet},
              }),
            propOr([], 'projectTasks'),
          ),
        ),
        flatten,
        find(pipe(propOr([], 'documents'), find(propEq('id', documentId)))),
      )(FACETS);
      return result;
    },
    [projectId],
  );

  return {getTaskByDocumentId};
}
