import {gql} from '@apollo/client';

export default gql`
  mutation setRenovationUpdateSharingSettingValue(
    $projectId: ID!
    $sharingSetting: RenovationUpdateSharingSettings!
  ) {
    setRenovationUpdateSharingSettingValue(
      projectId: $projectId
      renovationUpdateSharingSettingValue: $sharingSetting
    ) {
      project {
        id
        renovationUpdateSharingSettingValue
      }
    }
  }
`;
