import React from 'react';

import PropTypes from 'prop-types';

import {basic20} from '@renofi/theme';

const SvgEncrypted = ({color, width, ...props}) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g id="encrypted">
        <mask
          id="mask0_2842_74300"
          style={{maskType: 'alpha'}}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width={width}
          height={width}>
          <rect
            id="Bounding box"
            x="0.5"
            width={width}
            height={width}
            fill="#D9D9D9"
          />
        </mask>
        <g mask="url(#mask0_2842_74300)">
          <path
            id="encrypted_2"
            d="M14.5002 20.0013H18.5002L17.7335 15.7013C18.1779 15.4791 18.5279 15.1569 18.7835 14.7346C19.0391 14.3124 19.1668 13.8457 19.1668 13.3346C19.1668 12.6013 18.9057 11.9735 18.3835 11.4513C17.8613 10.9291 17.2335 10.668 16.5002 10.668C15.7668 10.668 15.1391 10.9291 14.6168 11.4513C14.0946 11.9735 13.8335 12.6013 13.8335 13.3346C13.8335 13.8457 13.9613 14.3124 14.2168 14.7346C14.4724 15.1569 14.8224 15.4791 15.2668 15.7013L14.5002 20.0013ZM16.5002 29.3346C13.4113 28.5569 10.8613 26.7846 8.85016 24.018C6.83905 21.2513 5.8335 18.1791 5.8335 14.8013V6.66797L16.5002 2.66797L27.1668 6.66797V14.8013C27.1668 18.1791 26.1613 21.2513 24.1502 24.018C22.1391 26.7846 19.5891 28.5569 16.5002 29.3346Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

SvgEncrypted.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
};

SvgEncrypted.defaultProps = {
  color: basic20,
  width: 32,
};

export default SvgEncrypted;
