import {gql} from '@apollo/client';

export default gql`
  query projectTimeline($id: ID!) {
    projectTimeline(id: $id) {
      id
      advancedToRuAt
      appraisalReportReviewFinishedAt
      appraisalCompletedAt
      appraisalFeeConsentOn
      appraisalRequestedAt
      completionCertificateCompletedAt
      completionCertificateRequestedAt
      contractReviewCompletedAt
      contractReviewRequestedAt
      contractorDueDiligenceCancelledAt
      contractorDueDiligenceCompletedAt
      contractorDueDiligenceRequestedAt
      contractorDueDiligenceReturnedAt
      contractorsReadyForContactAt
      estimatedCloseDate
      exceptionApprovedAt
      exceptionRejectedAt
      exceptionSentAt
      expectedRenovationCompletionOn
      expectedRenovationStart
      feasibilityCompletedAt
      feasibilityRequestedAt
      gcddReviewCompletedAt
      gcddReviewRequestedAt
      invitationAcceptedAt
      invitationSentAt
      loanApprovedOn
      loanClosedOn
      readyFor1003PrepOn
      renovationCompletedOn
      renovationStartedOn
      tpoRegistrationCompletedAt
      x1003CompleteOn
      x1003PrepCompleteOn
    }
  }
`;
