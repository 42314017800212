import React from 'react';

import PropTypes from 'prop-types';

const CarIcon = ({color = '#CCC', ...props}) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M6 19v1c0 .283-.096.52-.287.712A.968.968 0 0 1 5 21H4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 3 20v-8l2.1-6c.1-.3.28-.542.538-.725C5.896 5.092 6.183 5 6.5 5h11c.317 0 .604.092.863.275.258.183.437.425.537.725l2.1 6v8c0 .283-.096.52-.288.712A.965.965 0 0 1 20 21h-1a.965.965 0 0 1-.712-.288A.965.965 0 0 1 18 20v-1H6Zm-.2-9h12.4l-1.05-3H6.85L5.8 10Zm1.7 6c.417 0 .77-.146 1.062-.438.292-.291.438-.645.438-1.062 0-.417-.146-.77-.438-1.062A1.444 1.444 0 0 0 7.5 13c-.417 0-.77.146-1.062.438A1.444 1.444 0 0 0 6 14.5c0 .417.146.77.438 1.062.291.292.645.438 1.062.438Zm9 0c.417 0 .77-.146 1.062-.438.292-.291.438-.645.438-1.062 0-.417-.146-.77-.438-1.062A1.444 1.444 0 0 0 16.5 13c-.417 0-.77.146-1.062.438A1.444 1.444 0 0 0 15 14.5c0 .417.146.77.438 1.062.291.292.645.438 1.062.438ZM5 17h14v-5H5v5Z"
      fill={color}
    />
  </svg>
);

CarIcon.propTypes = {
  color: PropTypes.string,
};
export default CarIcon;
