import {gql} from '@apollo/client';

import {task} from '../../partials';

export default gql`
  mutation borrowersOnboardingFinishedByApplicant(
    $borrowersAndPropertyInformationId: ID!
  ) {
    borrowersOnboardingFinishedByApplicant(
      borrowersAndPropertyInformationId: $borrowersAndPropertyInformationId
    ) {
      task {
        ${task}
      }
    }
  }
`;
