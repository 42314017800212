import {gql} from '@apollo/client';

export default gql`
  mutation inviteLenderUser(
    $email: String!
    $lenderId: String!
    $firstName: String
    $lastName: String
    $manageUsers: Boolean!
    $notificationPreferences: [NotificationPreferenceInputObject!]!
    $mfaEnabled: Boolean
  ) {
    inviteLenderUser(
      email: $email
      lenderId: $lenderId
      firstName: $firstName
      lastName: $lastName
      manageUsers: $manageUsers
      notificationPreferences: $notificationPreferences
      mfaEnabled: $mfaEnabled
    ) {
      user {
        id
        active
        email
        invitationSentAt
        firstName
        lastName
        notificationPreferences {
          id
          active
          notificationType
        }
        permissions {
          id
          key
        }
      }
    }
  }
`;
