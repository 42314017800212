import {gql} from '@apollo/client';

export default gql`
  query tasksOverview($id: ID!) {
    projectById(id: $id) {
      tasksOverview {
        documentsUploaded
        lastDocumentUploadedAt
        outstandingNoDocuments
        outstandingTotal
        withRejectedDocuments
        withDocumentsInReview
      }
    }
  }
`;
