import {gql} from '@apollo/client';

export default gql`
  mutation transmitRaasProjectToLender($projectId: ID!) {
    transmitRaasProjectToLender(projectId: $projectId) {
      renovationReadyFile {
        id
        createdAt
      }
    }
  }
`;
