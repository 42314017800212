import * as React from 'react';

import PropTypes from 'prop-types';

const SvgFlag = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={23}
      height={26}
      viewBox="0 0 23 26"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M1.067 0C.477 0 0 .478 0 1.067v23.466a1.066 1.066 0 1 0 2.133 0V1.067C2.133.477 1.655 0 1.067 0zM22.19 2.982a.54.54 0 0 0-.463-.091c-.033.008-3.294.885-5.813.885h-.001c-.866 0-1.532-.101-1.978-.302-.533-.239-.602-.557-.602-.807 0-.731-.375-1.956-2.884-1.956-2.747 0-6.726 1.394-6.894 1.452a.534.534 0 0 0-.355.504v12.8a.532.532 0 0 0 .71.501c.04-.013 3.974-1.39 6.54-1.39 1.817 0 1.817.668 1.817.888 0 1 .642 2.192 3.704 2.192 2.654 0 5.896-.856 6.032-.892a.532.532 0 0 0 .397-.515V3.406a.533.533 0 0 0-.21-.424z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

SvgFlag.propTypes = {
  color: PropTypes.string,
};
export default SvgFlag;
