import React from 'react';

import PropTypes from 'prop-types';

import {basic20} from '@renofi/theme';

const SideMenuIcon = ({color = basic20, ...props}) => (
  <svg
    width={24}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1.7A1.7 1.7 0 0 1 1.7 0h19.55a1.7 1.7 0 1 1 0 3.4H1.7A1.7 1.7 0 0 1 0 1.7Zm0 6.8a1.7 1.7 0 0 1 1.7-1.7h19.55a1.7 1.7 0 1 1 0 3.4H1.7A1.7 1.7 0 0 1 0 8.5Zm1.7 5.1a1.7 1.7 0 1 0 0 3.4h19.55a1.7 1.7 0 1 0 0-3.4H1.7Z"
      fill={color}
    />
  </svg>
);

SideMenuIcon.propTypes = {
  color: PropTypes.string,
};

export default SideMenuIcon;
