import {gql} from '@apollo/client';

export default gql`
  mutation submitRealEstateOwnedPropertyDocument(
    $checksum: String
    $documentId: ID!
    $documentType: DocumentType!
    $filePassword: String
    $objectName: String!
    $passwordProtectedFile: Boolean
    $realEstateOwnedPropertyId: ID!
    $taskId: ID!
  ) {
    submitRealEstateOwnedPropertyDocument(
      checksum: $checksum
      documentId: $documentId
      documentType: $documentType
      filePassword: $filePassword
      objectName: $objectName
      passwordProtectedFile: $passwordProtectedFile
      realEstateOwnedPropertyId: $realEstateOwnedPropertyId
      taskId: $taskId
    ) {
      document {
        id
        checksum
        createdAt
        documentType
        fileName
        fileSizeBytes
        objectName
        pdfObjectName
        requestIpAddress
        requestIpLocation
        shareWithLenderAllowed
        shareable
        source
        status
        updatedAt
      }
    }
  }
`;
