import React, {useState} from 'react';

import PropTypes from 'prop-types';

import {basic20, white} from '@renofi/theme/src/colors';

import {CloseIcon, Container, ProgressIcon} from './styled';

function Close({duration, onClick, onLoopComplete}) {
  const [paused, setPaused] = useState(false);

  return (
    <Container
      onClick={onClick}
      onMouseOver={() => setPaused(true)}
      onMouseOut={() => setPaused(false)}>
      {duration && (
        <ProgressIcon
          animate
          paused={paused}
          duration={duration}
          onLoopComplete={onLoopComplete}
          radius={20}
          showCenter={false}
          stroke={4}
          strokeOpacity={0.2}
          strokeActiveColor={basic20}
          strokeBgColor={white}
        />
      )}
      <CloseIcon />
    </Container>
  );
}

Close.propTypes = {
  duration: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  onLoopComplete: PropTypes.func.isRequired,
};

export default Close;
