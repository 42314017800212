import camelcaseKeys from 'camelcase-keys';

import jwtDecode from '@renofi/utilities/src/jwtDecode';
import queryString from '@renofi/utilities/src/queryString';
import {getLocation} from '@renofi/utilities/src/window';

export default () => {
  const data = queryString.parse(getLocation().search || '') || {};
  const jwt = data?.jwt;

  return {
    ...data,
    jwt,
    decodedJwt: camelcaseKeys(jwtDecode(jwt)),
  };
};
