import {gql} from '@apollo/client';

export default gql`
  mutation submitAdditionalCreditLiability(
    $additionalCreditLiabilityId: ID
    $attributes: AdditionalCreditLiabilityInputObject!
    $projectId: ID!
  ) {
    submitAdditionalCreditLiability(
      additionalCreditLiabilityId: $additionalCreditLiabilityId
      projectId: $projectId
      attributes: $attributes
    ) {
      additionalCreditLiability {
        id
        createdAt
      }
    }
  }
`;
