import {gql} from '@apollo/client';

export default gql`
  query softInquiryByEntityId($entityId: ID!) {
    softInquiryByEntityId(entityId: $entityId) {
      borrowers {
        dateOfBirth
        firstName
        lastName
        residentialAddressCity
        residentialAddressState
        residentialAddressStreet
        residentialAddressZip
      }
      creditFiles {
        borrowerRole
        fileFrozen
        modelName
        score
        source
        errorMessages
      }
      creditLiabilities {
        accountType
        closeDate
        creditorName
        borrowerRole
        joint
        digest
        firstMortgage
        id
        lastActivityDate
        lateCount
        loanType
        loanTypeDescription
        maxBalance
        monthlyPaymentAmount
        openDate
        secondMortgage
        secured
        unpaidBalance
        unsecured
      }
      creditPublicRecords {
        accountOwnershipType
        courtName
        dispositionDate
        dispositionType
        docketIdentifier
        filedDate
        type
      }
      creditReportIdentifier
      creditReportObjectName
      creditReportUrl
      historicalDelinquenciesLateCount
      judgementLiens {
        borrowerRole
        courtName
        creditFileId
        creditPublicRecordId
        creditRepositoryOtherDescription
        creditRepositorySourceType
        derogatoryDataIndicator
        dispositionType
        docketIdentifier
        filedDate
        legalObligationAmount
        reportedDate
        resultStatusType
        type
      }
      haltedByBusinessRules
      responseKeys {
        caseNumber
        creditFileInquiryType
        creditReportTransactionIdentifer
        decisionCode
        decisionFactor
        decisionTypeCode
        decisionTypeText
        echoedVariable
        loanNumber
      }
      id
      rawDataUrl
      receivedAt
      status
    }
  }
`;
