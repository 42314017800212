import React, {useEffect} from 'react';

import {isNil} from 'ramda';

import {branch, compose, withLifecycle, withProps} from '@renofi/recompose/src';

const original = document.body.style.overflow;

const windowLock =
  (Component) =>
  // eslint-disable-next-line react/prop-types
  ({locked, ...props}) => {
    useEffect(() => {
      document.body.style.overflow = locked ? 'hidden' : original;
    }, [locked]);
    return <Component {...props} />;
  };

export default (selector) =>
  compose(
    branch(
      () => isNil(selector),
      withLifecycle({
        componentDidMount() {
          document.body.style.overflow = 'hidden';
        },
        componentWillUnmount() {
          document.body.style.overflow = original;
        },
      }),
      compose(
        withProps((props) => ({
          locked: selector(props),
        })),
        windowLock,
      ),
    ),
  );
