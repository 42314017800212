import {gql} from '@apollo/client';

export default gql`
  query licenses {
    licenses {
      state
      name
      note
      numbers
    }
  }
`;
