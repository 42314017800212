import dark from './dark';
import light from './light';

export const DARK_THEME_KEY = 'dark';
export const LIGHT_THEME_KEY = 'light';
export const ALL_THEME_KEYS = [DARK_THEME_KEY, LIGHT_THEME_KEY];

export const DEFAULT_VARIANT = 'default';

export const MUTATION_VARIANTS = ['vibrant', 'inverse', 'muted'];
export const DISABLED_VARIANTS = ['disabled', 'fakeDisabled'];
export const OVERRIDE_VARIANTS = [
  'secondary',
  'primary',
  'outline',
  'error',
  'transparent',
];

export const ALL_THEMES = {dark, light};
