import * as React from 'react';

import PropTypes from 'prop-types';

const SvgBuilding = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m12.448 2.758-.072-.068a.546.546 0 0 0-.75 0l-8.894 8.506c-.399.318-.177.972.34.972h.911v8.717c0 .316.22.574.546.574h14.914c.317 0 .575-.22.575-.545v-8.746l.911-.002c.484 0 .73-.598.375-.936-2.951-2.825-5.904-5.648-8.856-8.472zm.39 3.553h-1.677A2.462 2.462 0 0 0 8.71 8.764v1.768h6.582V8.764a2.465 2.465 0 0 0-2.453-2.453zm-1.677 1.092h1.678c.75 0 1.36.61 1.36 1.36v.678H9.801v-.677c0-.75.61-1.361 1.36-1.361zm3.591 12.964v-6.362H9.247v6.362H5.075v-8.199h13.852v8.2h-4.175zM4.43 11.078 12 3.836l2.27 2.171 5.301 5.07H4.43zm9.232 9.29H10.34v-5.27h3.322v5.27z"
        fill={color}
      />
    </svg>
  );
};

SvgBuilding.propTypes = {
  color: PropTypes.string,
};
export default SvgBuilding;
