import React from 'react';

import PropTypes from 'prop-types';

const MilitaryIcon = ({color = '#CCC'}) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 2h10v7.85c0 .383-.083.725-.25 1.025-.167.3-.4.542-.7.725L13 13.7l.7 2.3h3.8l-3.1 2.2 1.2 3.8-3.1-2.35L9.4 22l1.2-3.8L7.5 16h3.8l.7-2.3-3.55-2.1a1.946 1.946 0 0 1-.7-.725c-.167-.3-.25-.642-.25-1.025V2Zm2 2v5.85l2 1.2V4h-2Zm6 0h-2v7.05l2-1.2V4Z"
      fill={color}
    />
  </svg>
);

MilitaryIcon.propTypes = {
  color: PropTypes.string,
};
export default MilitaryIcon;
