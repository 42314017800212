import * as React from 'react';

import PropTypes from 'prop-types';

const SvgWindow = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M4.835 1.84h14.922a.32.32 0 0 1 .32.321v17.743h1.43a.32.32 0 0 1 .32.32v1.464a.32.32 0 0 1-.32.32H3.085a.32.32 0 0 1-.32-.32v-1.464a.32.32 0 0 1 .32-.32h1.429V2.16a.32.32 0 0 1 .32-.32zm14.93 18.704H3.406v.824h17.78v-.824h-1.42zm-.328-18.063H5.155v17.423h14.282V2.48z"
        fill={color}
        stroke={color}
        strokeWidth={0.3}
      />
      <path
        d="M6.797 3.876h10.995a.32.32 0 0 1 .32.32V18.19a.32.32 0 0 1-.32.32H6.797a.32.32 0 0 1-.32-.32V4.196a.32.32 0 0 1 .32-.32zm6.544.64v5.666h4.131V4.516h-4.13zm4.131 7.76h-4.13v5.593h4.13v-5.594zm-6.224 5.593v-5.594H7.117v5.594h4.131zm-4.131-7.687h4.131V4.516H7.117v5.666zm4.771-5.666v6.306h-4.77v.813h4.769v6.234h.813v-6.234h4.771v-.813H12.7V4.516h-.813z"
        fill={color}
        stroke={color}
        strokeWidth={0.3}
      />
      <path
        d="M15.152 5.658a.32.32 0 0 1 0-.64c.005 0 1.647-.085 1.817 1.79a.32.32 0 0 1-.635.059c-.115-1.266-1.179-1.209-1.182-1.209zM16.74 8.02a.326.326 0 1 0 0-.65.326.326 0 0 0 0 .65z"
        fill={color}
        stroke={color}
        strokeWidth={0.3}
      />
    </svg>
  );
};

SvgWindow.propTypes = {
  color: PropTypes.string,
};
export default SvgWindow;
