import React from 'react';

import PropTypes from 'prop-types';

import Flex from '../Flex';
import Text from '../Text';

const Fallback = ({error, details}) => (
  <div css={{padding: 4}}>
    <p>
      <Text fontWeight="bold">Something went wrong.</Text>
    </p>
    <p>
      <Flex>
        <Text fontWeight="bold" mr="4px">
          Error:
        </Text>
        <Text>{error?.message}</Text>
      </Flex>
      {(error?.message || '').startsWith('Loading chunk') && (
        <Text fontWeight="bold">Try reloading page</Text>
      )}
    </p>
    {details}
  </div>
);

Fallback.propTypes = {
  details: PropTypes.node,
  error: PropTypes.object,
};

export default Fallback;
