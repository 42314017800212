import {ascend, descend, ifElse, prop, propEq, sortWith} from 'ramda';

import {
  branch,
  compose,
  mapProps,
  withHandlers,
  withProps,
  withState,
} from '@renofi/recompose/src';
import {getNewSortDirection} from '@renofi/utilities/src/sortHelper';
import {
  withCreateProjectComment,
  withLenderProject,
  withLenderProjectComments,
  withProject,
  withProjectComments,
  withRemoveComment,
  withUpdateComment,
} from '@renofi/graphql/src/hoc';
import withNavigate from '@renofi/utilities/src/withNavigate';

import Component from './Component';

export default compose(
  withNavigate,
  withProps(({match}) => ({
    ...match.params,
  })),
  branch(
    propEq('mode', 'lender'),
    compose(withLenderProject(), withLenderProjectComments()),
    compose(withProject(), withProjectComments()),
  ),
  mapProps(({id, itemId, mode, project: {id: projectId}, ...props}) => ({
    ...props,
    mode,
    id: itemId,
    projectId,
    ...(mode === 'lender' ? {rrfId: id} : {}),
  })),
  withState('sortColumn', 'setSortColumn', 'createdAt'),
  withState('sortDirection', 'setSortDirection', 'desc'),
  withCreateProjectComment(),
  withRemoveComment(),
  withUpdateComment(),
  withProps(({comments}) => ({
    comments: comments.map((comment) => ({
      ...comment,
      createdBy: `${comment?.user?.firstName} ${comment?.user?.lastName}`,
    })),
  })),
  withProps(({comments, sortColumn, sortDirection}) => ({
    comments: sortWith([
      ifElse(
        () => sortDirection === 'desc',
        descend(prop(sortColumn)),
        ascend(prop(sortColumn)),
      ),
    ])(comments),
  })),
  withState('action', 'setAction', null),
  withState('comment', 'setComment', null),
  withState('display', 'setDisplay', false),
  withState('loading', 'setLoading', false),
  withState('message', 'setMessage', ''),
  withState('subject', 'setSubject', ''),
  withHandlers({
    resetState:
      ({setAction, setComment, setLoading, setMessage, setSubject}) =>
      () => {
        setAction(null);
        setComment(null);
        setLoading(false);
        setMessage('');
        setSubject('');
      },
  }),
  withHandlers({
    onSortComments:
      ({sortColumn, sortDirection, setSortColumn, setSortDirection}) =>
      (column) => {
        setSortColumn(column);
        const newSortDirection = getNewSortDirection({
          sortColumn,
          column,
          sortDirection,
        });

        setSortDirection(newSortDirection);
      },
    onCancelSubmit:
      ({action, resetState, setAction, setMessage, setSubject}) =>
      () => {
        if (action === 'create') {
          resetState();
        } else {
          setAction('select');
          setMessage('');
          setSubject('');
        }
      },
    onRemoveAccept:
      ({rrfId, comment, projectId, removeComment, resetState, setLoading}) =>
      async () => {
        setLoading(true);
        await removeComment({commentId: comment.id, projectId, rrfId});
        resetState();
      },
    onRemoveReject:
      ({setAction}) =>
      () => {
        setAction('select');
      },
    onCreateComment:
      ({setAction}) =>
      () => {
        setAction('create');
      },
    onRemoveComment:
      ({setAction}) =>
      () => {
        setAction('remove');
      },
    onUpdateComment:
      ({comment, setAction, setMessage, setSubject}) =>
      () => {
        const {body: message, subject} = comment;
        setMessage(message);
        setSubject(subject);
        setAction('update');
      },
    onSelectComment:
      ({comments, setComment, setAction}) =>
      (id) => {
        setComment(comments.find(propEq('id', id)));
        setAction('select');
      },
    onCloseModal:
      ({setComment, setAction}) =>
      () => {
        setComment(null);
        setAction(null);
      },
    onChangeMessage:
      ({setMessage}) =>
      (value) => {
        setMessage(value);
      },
    onChangeSubject:
      ({setSubject}) =>
      (value) => {
        setSubject(value);
      },
    onSubmitComment:
      ({
        action,
        comment,
        rrfId,
        projectId,
        message: body,
        subject,
        createProjectComment,
        setLoading,
        resetState,
        updateComment,
      }) =>
      async () => {
        setLoading(true);
        if (action === 'create') {
          await createProjectComment({rrfId, projectId, body, subject});
        }
        if (action === 'update') {
          await updateComment({
            commentId: comment.id,
            projectId,
            rrfId,
            body,
            subject,
          });
        }
        resetState();
      },
  }),
  withProps(({action, comment, mode, message, subject}) => ({
    disabled: !(message && subject),
    hidden:
      action === 'select' && mode === 'lender' && comment.source === 'internal',
  })),
)(Component);
