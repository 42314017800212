import {gql} from '@apollo/client';

export default gql`
  mutation markFeasibilityReviewAsCompleted($id: ID!) {
    markFeasibilityReviewAsCompleted(id: $id) {
      feasibilityReview {
        id
        changeOrderProcedureApproved
        changeOrderProcedureApprovedSource
        changeOrderProcedureNotes
        clearDescriptionOfWorkApproved
        clearDescriptionOfWorkApprovedSource
        clearDescriptionOfWorkNotes
        clearGuaranteeApproved
        clearGuaranteeApprovedSource
        clearGuaranteeNotes
        clearGuaranteeRating
        clearGuaranteeRatingSource
        completedAt
        completedById
        completedBy {
          id
          firstName
          lastName
        }
        complexityGrading
        contactInfoApproved
        contactInfoApprovedSource
        contactInfoNotes
        contractTimelineApproved
        contractTimelineApprovedSource
        contractTimelineNotes
        contractTimelineRating
        contractTimelineRatingSource
        costEstimateSuppliedApproved
        costEstimateSuppliedApprovedSource
        costEstimateSuppliedNotes
        createdAt
        estimatedDuration
        estimatedDurationSource
        feasibilityStudyItems {
          id
          addedById
          addedBy {
            id
          }
          adjustedCost
          cost
          descriptionOfWork
          reviewerNotes
          reviewerRating
          source
          type
        }
        generalCommentaryAndNotes
        homeownerResponsibilityCompleted
        inScopeAdjustedTotalRating
        inScopeTotalRating
        openedAt
        outOfScopeCompleted
        propertyPhotos {
          id
          createdAt
          homeownerDescription
          objectName
          updatedAt
        }
        requestedAt
        requestedBy {
          id
          firstName
          lastName
        }
        returnedAt
        reviewerId
        reviewer {
          id
          firstName
          lastName
        }
        termsOfPaymentApproved
        termsOfPaymentApprovedSource
        termsOfPaymentNotes
        termsOfPayments {
          amount
          comment
          specialMaterialCosts
        }
        uncompletedDetails
        uncompletedReason
        updatedAt
      }
    }
  }
`;
