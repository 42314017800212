export default `
  body
  createdAt
  id
  noteCapturedAt
  noteCapturedBy {
    id
    email
    firstName
    lastName
  }
  noteCapturedById
  projectId
  type
  updatedAt
  updatedBy {
    id
    email
    firstName
    lastName
  }
  updatedById
`;
