export default `
authorId
author {
  id
  email
  firstName
  lastName
}
body
createdAt
editable
id
mentions {
  label
  userEmail
  userId
  userName
}
projectId
salesforceAuthorEmail
salesforceAuthorId
salesforceContentDocumentId
salesforceOpportunityId
title
updatedAt
`;
