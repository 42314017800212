import {gql} from '@apollo/client';

import {underwritingCondition} from '../../partials/renovation';

export default gql`
  mutation deleteUnderwritingCondition(
    $id: ID!
    $notes: String
  ) {
    deleteUnderwritingCondition(
      id: $id
      notes: $notes
    ) {
      underwritingCondition {
        ${underwritingCondition}
      }
    }
  }
`;
