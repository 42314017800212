import {gql} from '@apollo/client';

export default gql`
  query exceptionRequestSubmittedLenderNotification(
    $exceptionRequest: ExceptionRequestInputObject!
  ) {
    exceptionRequestSubmittedLenderNotification(
      exceptionRequest: $exceptionRequest
    ) {
      content
      subject
    }
  }
`;
