import {gql} from '@apollo/client';

export default gql`
  query referenceResponses($questionnaireId: ID!) {
    referenceResponses(questionnaireId: $questionnaireId) {
      referenceId
      responses {
        answer
        question
        questionParsed
      }
    }
  }
`;
