import {pathOr} from 'ramda';

import {compose, withHandlers} from '@renofi/recompose/src';

import {CREATE_PROJECT_COMMENT} from '../mutations';
import {GET_PROJECT, GET_RENOVATION_READY_FILE} from '../queries';

import withApollo from './withApollo';
import withMutation from './withMutation';

export default () =>
  compose(
    withApollo,
    withMutation(CREATE_PROJECT_COMMENT, {
      name: 'createProjectComment',
    }),
    withHandlers({
      createProjectComment:
        ({client, createProjectComment}) =>
        async ({rrfId, ...variables}) => {
          const response = await createProjectComment({variables});
          const comment = pathOr(
            null,
            ['data', 'createProjectComment', 'comment'],
            response,
          );
          if (comment) {
            const {projectId: id} = variables;
            if (rrfId) {
              const {renovationReadyFileById} = client.readQuery({
                query: GET_RENOVATION_READY_FILE,
                variables: {id: rrfId},
              });
              client.writeQuery({
                query: GET_RENOVATION_READY_FILE,
                variables: {id: rrfId},
                data: {
                  renovationReadyFileById: {
                    ...renovationReadyFileById,
                    project: {
                      ...renovationReadyFileById.project,
                      comments:
                        renovationReadyFileById.project.comments.concat(
                          comment,
                        ),
                    },
                  },
                },
              });
            } else {
              const {projectById} = client.readQuery({
                query: GET_PROJECT,
                variables: {id},
              });
              client.writeQuery({
                query: GET_PROJECT,
                variables: {id},
                data: {
                  projectById: {
                    ...projectById,
                    comments: projectById.comments.concat(comment),
                  },
                },
              });
            }
          }
        },
    }),
  );
