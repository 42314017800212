/* eslint-disable max-lines */
import React, {useMemo, useState} from 'react';

import PropTypes from 'prop-types';
import {ascend, descend, ifElse, propEq, sortWith} from 'ramda';

import {useProjectRenovationUpdatesV2} from '@renofi/graphql';
import Container from '@renofi/components-internal/src/Container';
import Card from '@renofi/components-internal/src/Card';
import Flex from '@renofi/components-internal/src/Flex';
import Table, {HeadCell} from '@renofi/components-internal/src/Table';
import {useNotifications} from '@renofi/utilities/src/hooks';
import {getNewSortDirection} from '@renofi/utilities/src/sortHelper';

import CompletionDate from './components/CompletionDate';
import UpdateTableHeader from './components/UpdateTableHeader';
import RenovationUpdateRow from './components/RenovationUpdateRow/RenovationUpdateRow';
import ShareWithLenderHeadCell from './components/ShareWithLenderHeadCell/ShareWithLenderHeadCell';
import RenovationUpdateModal from './components/RenovationUpdateModal/RenovationUpdateModal';
import {
  getComparator,
  isInternalMode,
  prepareSubmitParams,
  isSubmitUpdateBtnDisabled,
} from './utils';
import {DEFAULT_RENOVATION_STATE, SHAREABLE} from './constants';

const Renovation = ({
  id,
  mode,
  action,
  project,
  baseRoute,
  projectId,
  changeRenovationState,
  navigate,
  basePath,
  removeRenovationUpdate,
  changeRenovationUpdate,
  createRenovationUpdate,
  shareRenovationUpdate,
  setRenovationUpdateSharingSetting,
  renovationUpdate: renovationUpdateState,
}) => {
  const {addNotification} = useNotifications();

  const variables = {id: projectId, size: 'medium'};
  const {fetch: fetchRenovationUpdates, renovationUpdates} =
    useProjectRenovationUpdatesV2({variables});

  const [lenderNote, setLenderNote] = useState('');
  const [sortColumn, setSortColumn] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState('desc');
  const [inProgress, setInProgress] = useState(false);
  const [autoSharing, setAutoSharing] = useState(
    project?.renovationUpdateSharingSettingValue === SHAREABLE,
  );

  const renovationUpdate = renovationUpdates.find(propEq('id', id)) || {};
  const isSubmitUpdateDisabled = isSubmitUpdateBtnDisabled(
    renovationUpdateState,
  );
  const isInternal = isInternalMode(mode);
  const isDescendingDirection = sortDirection === 'desc';

  const sortedUpdates = useMemo(
    () =>
      sortWith([
        ifElse(
          () => isDescendingDirection,
          descend(getComparator(sortColumn)),
          ascend(getComparator(sortColumn)),
        ),
      ])(renovationUpdates),
    [renovationUpdates, isDescendingDirection, sortColumn],
  );

  const resetData = () => {
    changeRenovationState(DEFAULT_RENOVATION_STATE);
  };

  const onCloseModal = () => {
    resetData();
    navigate(basePath);
  };

  const onViewRecord = (id) => {
    navigate(`${basePath}/view/${id}`);
  };

  const onCreateRecord = () => {
    navigate(`${basePath}/create`);
  };

  const onRemoveRecord = async (id) => {
    setInProgress(true);
    await removeRenovationUpdate({id, projectId});
    setInProgress(false);
    navigate(basePath);
  };

  const onUpdateRecord = (id) => {
    navigate(`${basePath}/update/${id}`);
  };

  const onSubmitRecord = async () => {
    const isEditMode = Boolean(id);
    let result = null;

    const params = prepareSubmitParams(renovationUpdateState);

    setInProgress(true);

    try {
      if (isEditMode) {
        result = await changeRenovationUpdate({...params, id});

        if (!result) {
          throw new Error('Failed to change update');
        }

        addNotification({
          variant: 'success',
          content: 'Update is successfully changed',
        });
        return;
      }

      result = await createRenovationUpdate({...params, projectId});

      if (!result) {
        throw new Error('Failed to submit update');
      }

      addNotification({
        variant: 'success',
        content: 'Update is successfully submitted',
      });
    } catch (error) {
      addNotification({
        variant: 'danger',
        content: error.message,
      });
    } finally {
      setInProgress(false);
      if (result?.data) {
        resetData();
        setTimeout(() => {
          navigate(basePath);
        }, 0);
      }
    }
  };

  const onCancelUpdate = () => {
    navigate(`${basePath}/view/${id}`);
  };

  const onSortRecords = (column) => {
    const newSortDirection = getNewSortDirection({
      sortColumn,
      column,
      sortDirection,
    });

    setSortColumn(column);
    setSortDirection(newSortDirection);
  };

  const onToggleShare = async (id, shareWithLender) => {
    await shareRenovationUpdate({id, shareWithLender});
  };

  const onToggleSharing = async (value) => {
    setAutoSharing(value);
    await setRenovationUpdateSharingSetting({
      projectId,
      sharingSetting: value ? SHAREABLE : 'none',
    });
  };

  const onSaveAndShare = async (renovationUpdate) => {
    try {
      await changeRenovationUpdate({
        id,
        type: renovationUpdate.type,
        noteToLender: lenderNote,
      });
      await shareRenovationUpdate({id, shareWithLender: true});
      onCloseModal();
    } catch {
      addNotification({
        variant: 'danger',
        content: 'Failed to Save and Share',
      });
    }
  };

  const onChangeLenderNote = (value) => {
    setLenderNote(value);
  };

  return (
    <>
      <Flex flexDirection="column" alignItems="center">
        <Container narrow mt={24}>
          <Card title={<CompletionDate />} />
          <Card
            p={0}
            mt={16}
            title={<UpdateTableHeader onSubmit={onCreateRecord} />}>
            <Table css={{marginBottom: 0}} hover>
              <thead>
                <tr>
                  <HeadCell
                    width="140px"
                    sortable
                    active={sortColumn === 'createdAt'}
                    reverse={isDescendingDirection}
                    onClick={() => onSortRecords('createdAt')}>
                    Date
                  </HeadCell>
                  <HeadCell />
                  <HeadCell
                    sortable
                    active={sortColumn === 'kind'}
                    reverse={isDescendingDirection}
                    onClick={() => onSortRecords('kind')}>
                    Update
                  </HeadCell>
                  <HeadCell
                    sortable
                    active={sortColumn === 'createdBy'}
                    reverse={isDescendingDirection}
                    css={isInternal ? {} : {textAlign: 'right !important'}}
                    onClick={() => onSortRecords('createdBy')}>
                    Submitted by
                  </HeadCell>
                  <ShareWithLenderHeadCell
                    isInternal={isInternal}
                    onToggleSharing={onToggleSharing}
                    autoSharing={autoSharing}
                  />
                </tr>
              </thead>
              <tbody>
                {sortedUpdates.map((renovationUpdate) => {
                  return (
                    <RenovationUpdateRow
                      key={renovationUpdate.id}
                      renovationUpdate={renovationUpdate}
                      fetchRenovationUpdates={fetchRenovationUpdates}
                      onViewRecord={onViewRecord}
                      onToggleShare={onToggleShare}
                      isInternal={isInternal}
                    />
                  );
                })}
              </tbody>
            </Table>
          </Card>
        </Container>
      </Flex>
      <RenovationUpdateModal
        id={id}
        action={action}
        renovationUpdate={renovationUpdate}
        baseRoute={baseRoute}
        inProgress={inProgress}
        mode={mode}
        project={project}
        lenderNote={lenderNote}
        onCloseModal={onCloseModal}
        onSaveAndShare={onSaveAndShare}
        onCancelUpdate={onCancelUpdate}
        onChangeLenderNote={onChangeLenderNote}
        onSubmitRecord={onSubmitRecord}
        onRemoveRecord={onRemoveRecord}
        onUpdateRecord={onUpdateRecord}
        isSubmitUpdateDisabled={isSubmitUpdateDisabled}
      />
    </>
  );
};

Renovation.propTypes = {
  id: PropTypes.string,
  mode: PropTypes.string,
  action: PropTypes.oneOf(['view', 'create', 'update']),
  project: PropTypes.shape({
    renovationUpdateSharingSettingValue: PropTypes.string,
  }),
  baseRoute: PropTypes.string,
  projectId: PropTypes.string.isRequired,

  changeRenovationState: PropTypes.func,
  navigate: PropTypes.func,
  basePath: PropTypes.string,

  removeRenovationUpdate: PropTypes.func,
  changeRenovationUpdate: PropTypes.func,
  createRenovationUpdate: PropTypes.func,
  shareRenovationUpdate: PropTypes.func,
  setRenovationUpdateSharingSetting: PropTypes.func,

  renovationUpdate: PropTypes.object,
};

export default Renovation;
