import React from 'react';

import PropTypes from 'prop-types';
import {isNil, pipe, propOr} from 'ramda';

import {branch, renderNothing} from '@renofi/recompose/src';
import {formatDate, formatDateTime} from '@renofi/utilities/src/dates';
import isDateOnlyWithoutTime from '@renofi/utilities/src/dates/isDateOnlyWithoutTime';

const DateFormat = ({value, long = false, time = true}) => {
  try {
    const date = new Date(value);
    const result =
      time && !isDateOnlyWithoutTime(date)
        ? formatDateTime(date)
        : formatDate(date, {long});
    return <>{result}</>;
  } catch (error) {
    return 'Invalid date';
  }
};

DateFormat.propTypes = {
  value: PropTypes.any.isRequired,
  long: PropTypes.bool,
  time: PropTypes.bool,
};

export default branch(
  pipe(propOr(null, 'value'), isNil),
  renderNothing,
)(DateFormat);
