import React from 'react';

import PropTypes from 'prop-types';

import {Content, Wrapper} from './styled';

const Drawer = ({
  contentCss = {},
  min,
  max,
  open,
  fixed,
  sticky,
  children,
  ...props
}) => (
  <Wrapper fixed={fixed} sticky={sticky} width={open ? max : min} {...props}>
    <Content fixed={fixed} sticky={sticky} css={contentCss} width={max}>
      {children}
    </Content>
  </Wrapper>
);

Drawer.propTypes = {
  children: PropTypes.node,
  contentCss: PropTypes.object,
  open: PropTypes.bool,
  fixed: PropTypes.bool,
  sticky: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default Drawer;
