import {useEffect} from 'react';

const appEnvironment = process.env.REACT_APP_RECONF_FIREBASE_APP_ENV;
const appName = process.env.REACT_APP_RECONF_FIREBASE_APP_NAME;

export default () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://storage.googleapis.com/renofi-reconf-configs/${appEnvironment}/${appName}/config.js`;
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(script);
  }, [appName, appEnvironment]);

  return {appName, appEnvironment};
};
