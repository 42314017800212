import styled from '@emotion/styled';

import Heading from '@renofi/components-internal/src/Heading';

export const Title = styled(Heading)({
  color: '#333333',
  fontSize: 25,
  fontWeight: 300,
  lineHeight: '30px',
  textShadow: '0 1px 1px 0 rgba(0,0,0,0.5)',
});
