import {graphql} from '@apollo/client/react/hoc';

import {compose, withHandlers, withProps} from '@renofi/recompose/src';
import omitProps from '@renofi/utilities/src/omitProps';
import noop from '@renofi/utilities/src/noop';

import useSystemState from '../hooks/system/useSystemState';
import getQueryName from '../utils/getQueryName';

import withErrorHandler from './withErrorHandler';

export default (mutation, {name, ...options}) => {
  const mutationName = getQueryName(mutation);
  return compose(
    withErrorHandler,
    withProps(() => {
      const {toggleSystemMutation} = useSystemState();
      return {toggleSystemMutation};
    }),
    graphql(mutation, {name, ...options}),
    withHandlers({
      [name]:
        ({onGraphqlError = noop, toggleSystemMutation, ...props}) =>
        async (options) => {
          const mutate = props[name];
          try {
            toggleSystemMutation(mutationName, true);
            const result = await mutate(options);
            return result;
          } catch (error) {
            onGraphqlError(error);
            return error;
          } finally {
            toggleSystemMutation(mutationName, false);
          }
        },
    }),
    omitProps(['onGraphqlError']),
  );
};
