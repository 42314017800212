import {gql} from '@apollo/client';

import {documentWithAllProps} from '../../partials/';

export default gql`
  mutation setDocumentName($id: ID!, $name: String!) {
    setDocumentName(id: $id, name: $name) {
      document {
        ${documentWithAllProps}
      }
    }
  }
`;
