import styled from '@emotion/styled';

import {actionRegular, border, dangerLight} from '@renofi/theme/src/colors';

import Box from '../Box';

export const Wrapper = styled(Box)({
  width: '100%',
  position: 'relative',
});

export const Container = styled.div(
  {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    position: 'relative',
  },
  ({large, small, withLabel}) => ({
    marginTop: withLabel ? (large ? 8 : small ? 4 : 6) : 0,
    height: large ? 52 : small ? 36 : 44,
  }),
);

export const ClearBox = styled(Box)({
  cursor: 'pointer',
  marginLeft: 'auto',
  svg: {
    position: 'relative',
    top: 3,
  },
  '&:hover': {
    opacity: 0.6,
  },
});

export const Field = styled.div({
  flexGrow: 1,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  textAlign: 'left',
});

export const Info = styled.div(
  {
    fontSize: 13,
    lineHeight: '15px',
    fontStyle: 'italic',
    marginTop: 8,
  },
  ({valid}) => ({
    color: valid ? '#28A745' : 'rgba(0,0,0,0.3)',
  }),
);

export const Error = styled.div(
  {
    color: dangerLight,
    marginTop: 4,
  },
  ({small}) => ({
    fontSize: small ? 12 : 13,
    lineHeight: small ? '12px' : '15px',
  }),
);

export const IconBox = styled.span(
  {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ({invalid, primary, large, small, left, right, showIconDivider = true}) => ({
    ...(primary ? {backgroundColor: actionRegular} : {}),
    height: large ? 50 : 42,
    width: large ? 52 : small ? 36 : 45,
    flexGrow: 0,
    flexShrink: 0,
    borderColor: invalid ? dangerLight : border,
    borderStyle: 'solid',
    borderWidth: 0,
    ...(left && small ? {marginRight: -15} : {}),
    ...(showIconDivider && left ? {borderRightWidth: 1} : {}),
    ...(showIconDivider && right ? {borderLeftWidth: 1} : {}),
  }),
);
