import {propOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import {IMPERSONATE} from '../../mutations';
import useMutation from '../useMutation';

export default function useImpersonate({onCompleted = noop} = {}) {
  const [impersonate, {data, loading, error}] = useMutation(IMPERSONATE, {
    onCompleted,
  });

  const response = propOr(null, 'impersonate', data);
  return {impersonate, response, loading, error};
}
