import React, {useEffect, useMemo, useState} from 'react';

import PropTypes from 'prop-types';
import {find, prop, propEq, sortBy} from 'ramda';

import {Flex, SelectField, Switch, Tooltip} from '@renofi/components-internal';
import {useEnums, useSetDocumentSharable} from '@renofi/graphql';
import noop from '@renofi/utilities/src/noop';
import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';

import {Approve, Discard} from './styled';

const InternalActions = ({
  allowedDocumentTypes,
  canAcceptReject,
  canChangeDocumentType,
  canShareWithLender,
  disabled,
  documentId,
  isAccepted,
  isRejected,
  onAccept,
  onReject,
  shareable,
  ...props
}) => {
  const [isShared, setIsShared] = useState(shareable);
  const [documentType, setDocumentType] = useState(props?.documentType);

  const {setDocumentSharable} = useSetDocumentSharable();
  const {documentTypes, loading} = useEnums();

  useEffect(() => {
    setIsShared(shareable);
  }, [shareable]);

  const docTypes = useMemo(() => {
    const sorted = sortBy(prop('label'))(documentTypes);
    if (!allowedDocumentTypes?.length) {
      return sorted;
    }
    return sorted.filter(({value}) => allowedDocumentTypes.includes(value));
  }, [allowedDocumentTypes, documentTypes]);

  const displayValue = useMemo(() => {
    const selected = find(propEq('value', documentType), docTypes);
    return Boolean(selected?.label)
      ? selected?.label
      : humanizeSnakeCase(documentType);
  }, [docTypes, documentType]);

  const onChangeDocumentType = (type) => {
    setDocumentType(type);
    props?.onChangeDocumentType({id: documentId, documentType: type});
  };

  const onShare = () => {
    setIsShared(!shareable);
    setDocumentSharable({variables: {id: documentId, shareable: !shareable}});
  };

  const onAcceptDocument = (e) => {
    e.stopPropagation();
    onAccept(documentId);
  };

  const onRejectDocument = async (e) => {
    e.stopPropagation();
    onReject(documentId);
  };

  return (
    <Flex css={{gap: 10}} alignItems="center">
      {canChangeDocumentType ? (
        <SelectField
          style={{width: 250, marginRight: 16}}
          displayValue={displayValue}
          disabled={
            loading ||
            Boolean(documentType && (shareable || isAccepted || isRejected))
          }
          m={0}
          onChange={onChangeDocumentType}
          options={docTypes}
          placeholder="Select a document type"
          value={documentType}
        />
      ) : null}
      {isAccepted && (
        <>
          <Tooltip place="top" content="Share with lender, appraiser etc.">
            <div>
              <Switch
                checked={isShared}
                disabled={disabled || !canShareWithLender}
                onClick={(e) => {
                  e.stopPropagation();
                  onShare();
                }}
              />
            </div>
          </Tooltip>
        </>
      )}
      {canAcceptReject && (
        <>
          <Discard
            disabled={disabled}
            active={false}
            onClick={onRejectDocument}
          />
          <Approve
            disabled={disabled}
            active={isAccepted}
            onClick={onAcceptDocument}
          />
        </>
      )}
    </Flex>
  );
};

InternalActions.propTypes = {
  allowedDocumentTypes: PropTypes.arrayOf(PropTypes.string),
  canAcceptReject: PropTypes.bool,
  canChangeDocumentType: PropTypes.bool,
  canShareWithLender: PropTypes.bool,
  disabled: PropTypes.bool,
  documentId: PropTypes.string,
  documentType: PropTypes.string,
  isAccepted: PropTypes.bool,
  isRejected: PropTypes.bool,
  onAccept: PropTypes.func,
  onChangeDocumentType: PropTypes.func,
  onReject: PropTypes.func,
  shareable: PropTypes.bool,
};

InternalActions.defaultProps = {
  onChangeDocumentType: noop,
};

export default InternalActions;
