import styled from '@emotion/styled';

import {Link, Text} from '@renofi/components-internal';
import {basic80} from '@renofi/theme';

export const Label = styled(Text)({
  marginBottom: 8,
  marginRight: 4,
  color: basic80,
});

export const Value = styled(Link)({
  maxWidth: 300,
  marginBottom: 8,
  marginRight: 8,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});
