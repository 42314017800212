import {propOr, prop, omit} from 'ramda';

import {formatDate} from '@renofi/utilities/src/dates';

import {
  MODES,
  KIND_ICON_MAPPER,
  RENOVATION_UPDATE_KINDS,
  KINDS_WITH_DATE,
  KIND_OPTIONS,
} from './constants';

export const doesKindSupportDate = (kind) => {
  return KINDS_WITH_DATE.includes(kind);
};

export const getComparator =
  (column) =>
  ({createdAt, kind, user, __typename}) => {
    if (column === 'createdAt') {
      return createdAt;
    }

    if (column === 'createdBy') {
      return `${user?.firstName} ${user?.lastName}`;
    }

    if (column === 'kind') {
      return kind;
    }

    return __typename;
  };

export const isSubmitUpdateBtnDisabled = (renovationUpdate) => {
  const {kind} = renovationUpdate;
  const data = propOr({}, 'data', renovationUpdate);
  const {date, files = []} = data;
  const isLoadingFiles = files.some(({loading}) => loading);

  return isLoadingFiles || !kind || (doesKindSupportDate(kind) && !date);
};

export const getEditModalHeader = (action, {createdAt} = {}) => {
  if (action === 'view' || action === 'update') {
    const formattedDate = formatDate(createdAt);

    return `Renovation update ${formattedDate}`;
  }

  return 'New renovation update';
};

export const getRecordIconName = (kind) => {
  if (!kind) {
    return '';
  }

  return KIND_ICON_MAPPER[kind]?.iconName || 'pending';
};

export const isRecent = (value) => {
  const date = new Date(value);
  return Date.now() - date.getTime() < 60 * 1000;
};

export const isInternalMode = (mode) => {
  return mode === MODES.INTERNAL;
};

export const prepareSubmitParams = (renovationUpdate = {}) => {
  const {
    data: {date, details, files},
    kind,
  } = renovationUpdate;

  return {
    kind,
    date: date ? date : null,
    details,
    files: files.map(prop('objectName')),
  };
};

export const getDatePickerLabel = (kind) => {
  if (kind === RENOVATION_UPDATE_KINDS.expected_renovation_start) {
    return 'Revised estimated start date';
  }

  if (kind === RENOVATION_UPDATE_KINDS.renovation_started) {
    return 'Renovation start date';
  }

  return 'Revised renovation completion date';
};

export const generateKindOptions = ({
  expectedRenovationCompletionOn,
  renovationStartedOn,
}) => {
  const skippedOptions = [];

  const date = expectedRenovationCompletionOn
    ? new Date(expectedRenovationCompletionOn)
    : null;

  if (renovationStartedOn) {
    skippedOptions.push(RENOVATION_UPDATE_KINDS.expected_renovation_start);
  }

  if (date) {
    const diff = date.getTime() - Date.now();
    if (diff < 0) {
      skippedOptions.push(RENOVATION_UPDATE_KINDS.ahead_of_schedule);
    }
  }

  return omit(skippedOptions, KIND_OPTIONS);
};
