import {gql} from '@apollo/client';

export default gql`
  query feasibilityReviewReturnedInternalNotification(
    $feasibilityReview: EmailPreviewFeasibilityReviewInputObject!
    $project: ProjectInputObject!
  ) {
    feasibilityReviewReturnedInternalNotification(
      feasibilityReview: $feasibilityReview
      project: $project
    ) {
      content
      subject
    }
  }
`;
