import {allPass, propEq} from 'ramda';

/**
 * @description BE logic is such that we consider a Document still
 * password protected if:
 * 1. The field passwordProtectedFile: true
 * 2. `status` is rejected (unlocked Documents return to "processing" statuses)
 * 3. `rejectionReason` is still for automated pwd-protected flag
 */
export default allPass([
  propEq('passwordProtectedFile', true),
  propEq('status', 'rejected'),
  propEq('rejectionReason', 'password_protected_file'),
]);
