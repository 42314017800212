import {gql} from '@apollo/client';

export default gql`
  query gcddReviewsCounts {
    gcddReviewsCounts {
      sent
      returned
      completed
      withAppraiser
    }
  }
`;
