import {gql} from '@apollo/client';

export default gql`
  query lenderOwnReport($from: Date, $to: Date) {
    lenderOwnReport(from: $from, to: $to) {
      closedLoansVolume
      month
    }
  }
`;
