import React from 'react';

import PropTypes from 'prop-types';

import {useTheme} from '@renofi/theme/src';

import {Wrapper} from './styled';

const Option = ({children, depth, css = {}, ...props}) => {
  const {styles} = useTheme('option', props);
  const padding = (depth + 1) * 8;

  return (
    <Wrapper css={{...styles, ...css}} {...props} px={`${padding}px`}>
      {children}
    </Wrapper>
  );
};

Option.propTypes = {
  children: PropTypes.node,
  depth: PropTypes.number,
  css: PropTypes.object,
};

export default Option;
