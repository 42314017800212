import React, {forwardRef} from 'react';

import PropTypes from 'prop-types';
import {
  filter,
  find,
  ifElse,
  isNil,
  join,
  map,
  pipe,
  propEq,
  propOr,
  reject,
} from 'ramda';

import FieldFrame from '../../FieldFrame';
import {getLayoutProps} from '../../utils';

const translateValue = ({displayValue, multi, options, placeholder, value}) => {
  const isMultiSelect = !displayValue && multi;

  return isMultiSelect
    ? pipe(
        ifElse(
          () => Array.isArray(value),
          filter((option) => value.includes(option?.value)),
          filter(propEq('value', value)),
        ),
        map(propOr(null, 'label')),
        reject(isNil),
        join(', '),
      )(options) || placeholder
    : pipe(find(propEq('value', value)), propOr(placeholder, 'label'))(options);
};

const TriggerFieldFrame = forwardRef((props, parentRef) => {
  const {
    children,
    disabled,
    displayValue,
    empty,
    error,
    help,
    fake,
    info,
    label,
    leftIcon,
    open,
    placeholder,
    searchable,
    small,
  } = props;

  return (
    <FieldFrame
      {...getLayoutProps(props)}
      css={{visibility: searchable && open ? 'hidden' : 'visible'}}
      disabled={disabled}
      error={error}
      info={info}
      inactive={empty || fake}
      leftIcon={leftIcon}
      ref={parentRef}
      rightIcon="expand"
      label={label}
      small={small}
      help={help}>
      {children || displayValue || translateValue(props) || placeholder}
    </FieldFrame>
  );
});

TriggerFieldFrame.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  disabled: PropTypes.bool,
  displayValue: PropTypes.string,
  empty: PropTypes.bool,
  small: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  help: PropTypes.string,
  info: PropTypes.node,
  fake: PropTypes.bool,
  label: PropTypes.string,
  leftIcon: PropTypes.string,
  open: PropTypes.bool,
  placeholder: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  searchable: PropTypes.bool,
};

export default TriggerFieldFrame;
