import styled from '@emotion/styled';

import {maxSmall} from '@renofi/theme/src/breakpoints';

import Box from '../Box';
import Flex from '../Flex';
import Text from '../Text';

import arrow from './arrow.svg';

export const Content = styled(Box)({
  flexGrow: 1,
  position: 'relative',
  height: 'auto',
});

export const Wrapper = styled(Flex)(
  {
    padding: 24,
    borderTop: '1px solid rgba(229,229,229,0.3)',
    [maxSmall]: {
      padding: 14,
    },
  },
  ({closed}) => ({
    cursor: closed ? 'pointer' : 'default',
  }),
);

export const Message = styled(Text)({
  fontSize: 14,
  lineHeight: '22px',
});

export const Toggle = styled(Box)(
  {
    width: 12,
    height: 12,
    backgroundImage: `url('${arrow}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '12px 8px',
    backgroundPosition: 'center center',
    cursor: 'pointer',
    marginRight: 15,
    [maxSmall]: {
      marginRight: 8,
    },
  },
  ({closed}) => ({
    ...(closed ? {} : {transform: 'rotate(180deg)'}),
  }),
);

export const ToggleWrapper = styled(Flex)(
  {
    marginRight: 12,
    height: 24,
    alignItems: 'center',
    [maxSmall]: {
      marginRight: 6,
    },
  },
  ({disabled}) => ({
    opacity: disabled ? 0.5 : 1,
  }),
);

export const TooltipWrapper = styled(Flex)({
  width: 24,
  height: 24,
  flexGrow: 1,
  flexShrink: 1,
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
});
