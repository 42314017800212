import {gql} from '@apollo/client';

export default gql`
  mutation rejectDocuments(
    $delayedMessage: Boolean
    $documents: [RejectedDocumentInputObject!]!
    $message: String!
  ) {
    rejectDocumentsWithMessage(
      delayedMessage: $delayedMessage
      documents: $documents
      message: $message
    ) {
      success
    }
  }
`;
