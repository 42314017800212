import {gql} from '@apollo/client';

export default gql`
  query projectOwnersByIds($ids: [ID!]!) {
    projectOwnersByIds(ids: $ids) {
      id
      firstName
      lastName
      name
    }
  }
`;
