import * as React from 'react';

import PropTypes from 'prop-types';

const SvgContact = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={22}
      height={22}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        clipRule="evenodd"
        d="M1 1h20v20H1V1z"
        stroke="#000"
        strokeOpacity={0.012}
        strokeWidth={0.5}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.833 3.5H16V1.833h-1.667V3.5H7.667V1.833H6V3.5h-.833c-.925 0-1.667.75-1.667 1.666v11.667c0 .917.742 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667V5.166c0-.916-.75-1.666-1.667-1.666zM11 6c1.383 0 2.5 1.116 2.5 2.5 0 1.383-1.117 2.5-2.5 2.5a2.497 2.497 0 0 1-2.5-2.5C8.5 7.116 9.617 6 11 6zm5 10H6v-.834c0-1.666 3.333-2.583 5-2.583 1.667 0 5 .917 5 2.583V16z"
        fill={color}
      />
    </svg>
  );
};

SvgContact.propTypes = {
  color: PropTypes.string,
};
export default SvgContact;
