import {gql} from '@apollo/client';

export default gql`
  mutation submitReferenceScore($id: ID!, $score: ScoreEnum!) {
    submitReferenceScore(id: $id, score: $score) {
      reference {
        createdAt
        email
        id
        name
        phoneNumber
        questionnaireId
        score
        scoreSubmittedAt
        scoreSubmittedById
        type
        updatedAt
      }
    }
  }
`;
