import styled from '@emotion/styled';

import {actionRegular, actionExtraLight} from '@renofi/theme/src/colors';

import Button from '../Button';

export const StyledButton = styled(Button)(
  {
    outline: 'none',
    fontSize: 18,
  },
  ({selected}) => ({
    ...(selected
      ? {
          borderColor: actionRegular,
          backgroundColor: actionExtraLight,
          ':hover': {
            borderColor: actionRegular,
          },
        }
      : {}),
  }),
);
