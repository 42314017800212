/*
Currently we're using the constant below to determine which
fields shouldn't be updated for a SoftCreditCheck.
Please be sure to include new unwanted update fields on it to avoid
issues on production.
We'll refactor when possible the way we're handling this.
https://github.com/RenoFi/internal-apps/blob/b80578b26bd970a9d47ca61a7118fae151ded8b0/apps/secure/src/modules/project/components/Borrowers/components/SoftCredit/SoftCreditWizard/constants.js#L25
*/
export default `
authorizedRenofiToVerifyCreditAt
coborrowerAuthorizedRenofiToVerifyCreditAt
coborrowerDateOfBirth
coborrowerEmail
coborrowerFirstName
coborrowerLastName
coborrowerMiddleName
coborrowerNameSuffix
coborrowerFullName
coborrowerLivesAtTheSameAddress
coborrowerPhoneNumber
coborrowerResidentialAddressCity
coborrowerResidentialAddressState
coborrowerResidentialAddressStreet
coborrowerResidentialAddressZip
coborrowerChosenCreditScore @ifAuthorized
coborrowerSelfReportedCreditScore
dateOfBirth
email
firstName
fullName
middleName
nameSuffix
haltedByBusinessRules
id
inquiryErrors
lastName
phoneNumber
residentialAddressCity
residentialAddressState
residentialAddressStreet
residentialAddressZip
selfReportedCreditScore
primaryApplicantSubmittedAt
taskId
status
chosenCreditFile {
  fileFrozen
  modelName
  score @ifAuthorized
  source
  errorMessages
}
creditFiles {
  fileFrozen
  modelName
  score @ifAuthorized
  source
  errorMessages
}
creditAlertMessages {
  borrowerRole
  categoryType
  code
  id
  requiresReview
  text
}
`;
