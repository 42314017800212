import {isEmpty, isNil, join, pick, pipe, reject, values} from 'ramda';

const STREET_KEYS = [
  'streetAddress',
  'streetAddressOne',
  'streetAddressTwo',
  'propertyStreetAddress',
  'propertyStreetAddressOne',
  'propertyStreetAddressTwo',
];
// some structures use only streetAddres, some addressOne/Two; prioritise left-right

const ADDRESS_KEYS = [
  'city',
  'state',
  'zipCode',
  'propertyCity',
  'propertyState',
  'propertyZipCode',
];

const joinByKeys = (keys = [], joinWith) =>
  pipe(pick(keys), values, reject(isNil), join(joinWith));

export default (data = {}, joinWith = ', ') => {
  if (!data || isEmpty(data)) {
    return '';
  }

  const street = joinByKeys(STREET_KEYS, joinWith)(data);
  const rest = joinByKeys(ADDRESS_KEYS, joinWith)(data);

  return join(joinWith, [street, rest]);
};
