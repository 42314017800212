import styled from '@emotion/styled';

import {Link} from '@renofi/components-internal';

import arrowRed from './arrow-red.svg';

export default styled(Link)({
  display: 'inline-block',
  width: 12,
  height: 18,
  cursor: 'pointer',
  margin: '0 20px 0 0',
  backgroundImage: `url('${arrowRed}')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100%',
  backgroundPosition: 'center center',
});
