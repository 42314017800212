import styled from '@emotion/styled';

export const Input = styled.input({
  display: 'block',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  border: 'none',
  outline: 'none',
  backgroundColor: 'transparent',
  '::placeholder': {
    opacity: 0.3,
  },
});
