import {getFileType} from '@renofi/utilities';

export const getPrintableType = (fileName) => {
  const {image, iosImage, pdf} = getFileType(fileName);
  switch (true) {
    case image:
      return 'image';
    case iosImage:
    case pdf:
    default:
      return 'pdf';
  }
};
