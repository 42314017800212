import React from 'react';

import PropTypes from 'prop-types';
import {pick, omit} from 'ramda';

import Text from '../Text';

import {Wrapper, LabelWrapper} from './styled';

const keys = [
  'm',
  'mb',
  'ml',
  'mr',
  'mt',
  'mx',
  'my',
  'p',
  'pb',
  'pl',
  'pr',
  'pt',
  'px',
  'py',
  'style',
  'width',
  'className',
];

const RadioButton = ({
  checked,
  disabled,
  label,
  value,
  onChange,
  help,
  small,
  height,
  ...props
}) => (
  <Wrapper
    small={small}
    height={height}
    active={checked}
    disabled={disabled}
    hasHelpLabel={Boolean(help)}
    {...pick(keys, props)}
    onClick={() => {
      if (disabled) {
        return;
      }

      onChange(value);
    }}>
    <input
      type="radio"
      value={value}
      checked={checked}
      disabled={disabled}
      css={{margin: '0 4px 0 0', padding: 0}}
      onChange={(event) => {
        event.stopPropagation();
      }}
      {...omit(keys, props)}
    />
    <LabelWrapper hasHelpLabel={Boolean(help)}>
      {label}
      {help && (
        <Text fontStyle="italic" fontSize={12} mt="5px">
          {help}
        </Text>
      )}
    </LabelWrapper>
  </Wrapper>
);

RadioButton.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  help: PropTypes.bool,
  label: PropTypes.node,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func,
  small: PropTypes.bool,
  height: PropTypes.number,
};

export default RadioButton;
