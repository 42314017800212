import {gql} from '@apollo/client';

export default gql`
  query insuranceReview($projectId: ID!) {
    insuranceReview(projectId: $projectId) {
      id
      carrier
      completedAt
      completedById
      completedBy {
        id
        firstName
        lastName
      }
      coverageDuringRenovationPeriod
      coverageProofReceivedAt
      coverageProofRequestedAt
      coverageProofRequestScheduledAt
      createdAt
      dwellingCoverage
      expansiveRenovation
      largeAmountRenovation
      policyDateVisible
      policyEffectiveDate
      renovationIncludesRoofWorks
      renovationIncludesStructuralChanges
      updatedAt
    }
  }
`;
