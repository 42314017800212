import {propOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import {UPDATE_LENDER} from '../../../mutations';
import useMutation from '../../useMutation';

export default function useUpdateLender({
  onCompleted = noop,
  refetchQueries = [],
  ...options
} = {}) {
  const [updateLender, {data, loading, error}] = useMutation(UPDATE_LENDER, {
    onCompleted,
    refetchQueries,
    ...options,
  });

  const response = propOr(null, 'updateLender', data);
  return {updateLender, response, loading, error};
}
