export const renofiBlue = '#212F49';

export const black = '#000000';
export const white = '#FFFFFF';

export const basic88 = '#202020';
export const basic80 = '#333333';
export const basic75 = '#404040';
export const basic55 = '#737373';
export const basic20 = '#CCCCCC';
export const basic15 = '#D8D8D8';
export const basic10 = '#E5E5E5';
export const basic03 = '#F8F8F8';

export const border = basic10;
export const borderDark = basic15;

export const actionDark = '#006099';
export const actionDark20 = '#0093EB';
export const actionRegular = '#00A0FF';
export const actionLight = '#80CFFF';
export const actionExtraLight = '#E5F5FF';

export const successDark = '#0B7517';
export const successRegular = '#13C427';
export const successLight = '#89E193';
export const successExtraLight = '#E7F9E9';

export const dangerDark = '#993131';
export const dangerRegular = '#FF5253';
export const dangerLight = '#FFA8A8';
export const dangerExtraLight = '#FFEEEE';

export const warningDark = '#996C1C';
export const warningRegular = '#FFB430';
export const warningLight = '#FFD997';
export const warningExtraLight = '#FFF7E9';
