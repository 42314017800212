import React from 'react';

import PropTypes from 'prop-types';

import {TextField as Input} from '@renofi/components-internal';

import {Help, Icon} from './styled';
import dollar from './dollar.svg';
import dollarRed from './dollar-red.svg';
import email from './email.svg';
import emailRed from './email-red.svg';
import phone from './phone.svg';
import phoneRed from './phone-red.svg';
import location from './location.svg';
import locationRed from './location-red.svg';

const iconsMap = {dollar, email, phone, location};
const redIconsMap = {
  dollar: dollarRed,
  email: emailRed,
  location: locationRed,
  phone: phoneRed,
};

const TextField = ({error, help, icon, ...props}) => {
  const more = icon
    ? {leftIcon: <Icon icon={error ? redIconsMap[icon] : iconsMap[icon]} />}
    : {};
  return (
    <Input
      error={error}
      help={help && <Help>{help}</Help>}
      {...{...more, ...props}}
    />
  );
};

TextField.propTypes = {
  error: PropTypes.string,
  help: PropTypes.node,
  icon: PropTypes.string,
  label: PropTypes.node.isRequired,
};

export default TextField;
