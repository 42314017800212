import Approved from './components/Approved';
import Denied from './components/Denied';
import Withdrawn from './components/Withdrawn';

export const STATUSES = {
  APPROVED: 'approved',
  CLOSED: 'closed',
  DENIED: 'denied',
  WITHDRAWN: 'withdrawn',
  NOT_ACCEPTED: 'not_accepted',
  IN_PROGRESS: 'in_progress',
};

export const STATUS_COMPONENT_MAP = {
  approved: Approved,
  closed: Approved,
  denied: Denied,
  not_accepted: Withdrawn,
  withdrawn: Withdrawn,
};

export const DISABLED_STATUSES_REGULAR = [STATUSES.APPROVED, STATUSES.CLOSED];
