import * as React from 'react';

import PropTypes from 'prop-types';

const SvgDollarCircleInvert = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.466c-6.072 0-11 4.928-11 11s4.928 11 11 11 11-4.928 11-11-4.928-11-11-11zm0 20c-4.961 0-9-4.04-9-9 0-4.962 4.039-9 9-9s9 4.038 9 9c0 4.96-4.039 9-9 9zm.346-9.922c-2.009-.482-2.656-1.007-2.656-1.789 0-.9.897-1.532 2.384-1.532 1.566 0 2.156.707 2.201 1.757h1.941c-.056-1.436-.987-2.754-2.826-3.182V4.966h-2.644v1.81C9.032 7.12 7.659 8.17 7.659 9.787c0 1.918 1.69 2.882 4.154 3.44 2.213.492 2.655 1.232 2.655 2.003 0 .568-.442 1.49-2.383 1.49-1.816 0-2.53-.772-2.633-1.758H7.5c.113 1.822 1.544 2.85 3.246 3.182v1.822h2.656v-1.79c1.725-.31 3.087-1.242 3.098-2.967-.011-2.358-2.156-3.172-4.154-3.665z"
        fill={color}
      />
    </svg>
  );
};

SvgDollarCircleInvert.propTypes = {
  color: PropTypes.string,
};
export default SvgDollarCircleInvert;
