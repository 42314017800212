import {gql} from '@apollo/client';

export default gql`
  mutation submitAssetDetail(
    $attributes: AssetDetailInputObject!
    $id: ID
    $taskId: ID!
  ) {
    submitAssetDetail(attributes: $attributes, id: $id, taskId: $taskId) {
      assetDetail {
        id
        accountNumber
        accountHolderName
        adjustedCashOrMarketValue
        assetType
        borrowerRole
        financialInstitution
        description
        jointAccount
        cashOrMarketValue
        taskId
        shareWithLender
      }
    }
  }
`;
