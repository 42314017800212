import * as React from 'react';

import PropTypes from 'prop-types';

const SvgCloudCrossed = ({color = '#FF5253', ...props}) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8 13.386c-.907-4.6-4.947-8.053-9.8-8.053a9.89 9.89 0 0 0-5.347 1.56L12.6 8.84a7.33 7.33 0 0 1 10.733 6.493V16h2c2.214 0 4 1.786 4 4 0 1.506-.853 2.813-2.08 3.493l1.934 1.933C30.88 24.213 32 22.24 32 20c0-3.52-2.733-6.374-6.2-6.614zM4 7.026l3.667 3.654C3.413 10.866 0 14.36 0 18.666c0 4.414 3.587 8 8 8h15.64l2.667 2.667L28 27.64 5.693 5.333 4 7.026zm6.307 6.307L20.973 24H8a5.332 5.332 0 0 1-5.333-5.334A5.332 5.332 0 0 1 8 13.333h2.307z"
        fill={color}
      />
    </svg>
  );
};

SvgCloudCrossed.propTypes = {
  color: PropTypes.string,
};
export default SvgCloudCrossed;
