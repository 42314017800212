import {gql} from '@apollo/client';

export default gql`
  query feasibilityReviews($projectId: ID!) {
    feasibilityReviews(projectId: $projectId) {
      id
      changeOrderProcedureApproved
      changeOrderProcedureApprovedSource
      changeOrderProcedureNotes
      clearDescriptionOfWorkApproved
      clearDescriptionOfWorkApprovedSource
      clearDescriptionOfWorkNotes
      clearGuaranteeApproved
      clearGuaranteeApprovedSource
      clearGuaranteeNotes
      clearGuaranteeRating
      clearGuaranteeRatingSource
      completedAt
      completedById
      completedBy {
        id
        firstName
        lastName
      }
      complexityGrading
      contactInfoApproved
      contactInfoApprovedSource
      contactInfoNotes
      contractTimelineApproved
      contractTimelineApprovedSource
      contractTimelineNotes
      contractTimelineRating
      contractTimelineRatingSource
      costEstimateSuppliedApproved
      costEstimateSuppliedApprovedSource
      costEstimateSuppliedNotes
      createdAt
      documents {
        id
        checksum
        createdAt
        documentType
        fileName
        objectName
        pdfObjectName
        requestIpAddress
        requestIpLocation
        shareWithLenderAllowed
        shareable
        updatedAt
      }
      estimatedDuration
      estimatedDurationSource
      feasibilityStudyStatus
      contractReviewStatus
      feasibilityStudyItems {
        id
        addedById
        addedBy {
          id
        }
        adjustedCost
        cost
        descriptionOfWork
        reviewerNotes
        reviewerRating
        source
        type
      }
      generalCommentaryAndNotes
      homeownerResponsibilityCompleted
      inScopeAdjustedTotalRating
      inScopeTotalRating
      openedAt
      outOfScopeCompleted
      propertyPhotos {
        id
        createdAt
        homeownerDescription
        objectName
        updatedAt
      }
      status
      requestedAt
      requestedById
      requestedBy {
        id
        firstName
        lastName
      }
      returnedAt
      reviewerId
      reviewer {
        id
        firstName
        lastName
      }
      canceledAt
      canceledBy {
        fullName
      }
      termsOfPaymentApproved
      termsOfPaymentApprovedSource
      termsOfPaymentNotes
      termsOfPayments {
        amount
        comment
        specialMaterialCosts
      }
      uncompletedDetails
      uncompletedReason
      updatedAt
      xactRemodelUrl
    }
  }
`;
