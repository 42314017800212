import * as React from 'react';

import PropTypes from 'prop-types';

const SvgXls = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <mask id="xls_svg__a" fill="#fff">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.56 0A3.56 3.56 0 0 0 8 3.56v24.88A3.56 3.56 0 0 0 11.56 32h16.88A3.56 3.56 0 0 0 32 28.44V3.539 8l-8-8H11.56z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.56 0A3.56 3.56 0 0 0 8 3.56v24.88A3.56 3.56 0 0 0 11.56 32h16.88A3.56 3.56 0 0 0 32 28.44V3.539 8l-8-8H11.56z"
        fill="#fff"
      />
      <path
        d="m32 3.539 1-.006-2 .006h1zM32 8l-.707.707L33 10.414V8h-1zm-8-8 .707-.707L24.414-1H24v1zM9 3.56A2.56 2.56 0 0 1 11.56 1v-2A4.56 4.56 0 0 0 7 3.56h2zm0 24.88V3.56H7v24.88h2zM11.56 31A2.56 2.56 0 0 1 9 28.44H7A4.56 4.56 0 0 0 11.56 33v-2zm16.88 0H11.56v2h16.88v-2zM31 28.44A2.56 2.56 0 0 1 28.44 31v2A4.56 4.56 0 0 0 33 28.44h-2zm0-24.88v24.88h2V3.56h-2zm0-.015v.015h2v-.027l-2 .012zm0-.006V8h2V3.539h-2zm1.707 3.754-8-8L23.293.707l8 8 1.414-1.414zM11.56 1H24v-2H11.56v2z"
        fill="#EBEBEB"
        mask="url(#xls_svg__a)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m24 0 8 8h-6a2 2 0 0 1-2-2V0z"
        fill="#A6ADBC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 8h5v5l-5-5z"
        fill="#D2D6DE"
      />
      <rect y={3} width={21} height={10} rx={2} fill="#0D821A" />
      <path
        d="M8.066 11H6.332L5.078 9.086 3.801 11H2.145l2.062-2.93-2.02-2.886h1.731l1.21 1.812 1.247-1.812h1.66L6.004 8.012 8.066 11zm5.032 0H8.902V5.184h1.5v4.691h2.696V11zm5.562-1.828c0 .573-.243 1.039-.73 1.398-.485.357-1.143.535-1.977.535a5.6 5.6 0 0 1-1.262-.125 6.66 6.66 0 0 1-1.004-.324V9.262h.165c.31.247.656.437 1.039.57.385.133.755.2 1.109.2.091 0 .21-.009.36-.024.148-.016.269-.042.363-.078a.88.88 0 0 0 .28-.176c.076-.07.114-.175.114-.313a.414.414 0 0 0-.164-.328c-.107-.093-.264-.165-.473-.215-.218-.052-.45-.1-.695-.144a5.566 5.566 0 0 1-.683-.176c-.49-.159-.843-.374-1.059-.644-.214-.274-.32-.612-.32-1.016 0-.542.242-.983.726-1.324.487-.344 1.112-.516 1.875-.516.383 0 .76.038 1.133.113.375.073.7.166.973.278v1.34h-.16a3.05 3.05 0 0 0-.864-.47 2.923 2.923 0 0 0-1.039-.19c-.125 0-.25.009-.375.027a1.38 1.38 0 0 0-.355.094.674.674 0 0 0-.262.18.384.384 0 0 0-.11.269.41.41 0 0 0 .176.355c.118.081.339.155.664.223l.614.129c.198.041.41.099.636.172.446.145.774.345.985.597.213.25.32.576.32.977z"
        fill="#fff"
      />
      <rect x={13} y={25} width={4} height={2} rx={0.5} fill="#0D821A" />
      <rect x={13} y={19} width={4} height={2} rx={0.5} fill="#0D821A" />
      <rect x={18} y={25} width={4} height={2} rx={0.5} fill="#0D821A" />
      <rect x={18} y={19} width={4} height={2} rx={0.5} fill="#0D821A" />
      <rect x={23} y={25} width={4} height={2} rx={0.5} fill="#0D821A" />
      <rect x={23} y={19} width={4} height={2} rx={0.5} fill="#0D821A" />
      <rect x={13} y={22} width={4} height={2} rx={0.5} fill="#0D821A" />
      <rect x={13} y={16} width={4} height={2} rx={0.5} fill="#0D821A" />
      <rect x={18} y={22} width={4} height={2} rx={0.5} fill="#0D821A" />
      <rect x={18} y={16} width={4} height={2} rx={0.5} fill="#0D821A" />
      <rect x={23} y={22} width={4} height={2} rx={0.5} fill="#0D821A" />
      <rect x={23} y={16} width={4} height={2} rx={0.5} fill="#0D821A" />
    </svg>
  );
};

SvgXls.propTypes = {
  color: PropTypes.string,
};
export default SvgXls;
