import {v4} from 'uuid';

import LogRocket from '@renofi/utilities/src/logrocket2';

import hashBufferToHex from './hashBufferToHex';
import getCryptoService from './crypto';

const cryptoService = getCryptoService();
const ALGORITHM = 'SHA-256';
const IS_DEV = process.env.NODE_ENV === 'development';
const IS_INSECURE = window?.location?.protocol !== 'https:';

export default async (data) => {
  if (IS_DEV && IS_INSECURE) {
    const baseString = parseInt(v4(), 36);
    return `${baseString}`.replace(/[^a-z0-9]/gi, '');
  }

  try {
    const hashBuffer = await cryptoService.subtle.digest(ALGORITHM, data);
    return hashBufferToHex(hashBuffer);
  } catch (e) {
    LogRocket.captureException(e);
    LogRocket.captureException(
      new Error(
        'Crypto service not available. Check checksum.js in both monorepos for details.',
      ),
    );
    return null;
  }
};
