import {createElement, useState} from 'react';

import isFunction from '@renofi/utilities/src/isFunction';

export default (stateName, stateUpdaterName, initialState) =>
  (BaseComponent) => {
    return (props) => {
      const [state, setState] = useState(
        isFunction(initialState) ? initialState(props) : initialState,
      );
      return createElement(BaseComponent, {
        ...props,
        [stateName]: state,
        [stateUpdaterName]: setState,
      });
    };
  };
