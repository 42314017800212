import {gql} from '@apollo/client';

import {user} from '../../partials';

export default gql`
  query userById($id: ID!) {
    userById(id: $id) {
      ${user}
    }
  }
`;
