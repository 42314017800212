import {gql} from '@apollo/client';

export default gql`
  mutation sendQuestionnairePdf($gcddReviewId: ID!) {
    sendQuestionnairePdf(gcddReviewId: $gcddReviewId) {
      questionnaire {
        id
        sendingPdfRequestedAt
      }
    }
  }
`;
