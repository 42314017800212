import * as React from 'react';

import PropTypes from 'prop-types';

const SvgAccept = ({color = '#13C427', width, ...props}) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}>
      <defs>
        <path
          d="M10.5 16 7 12.5l1.41-1.41 2.09 2.08L15.68 8l1.41 1.41L10.5 16z"
          id="accept_svg__a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle fill={color} cx={12} cy={12} r={11} />
        <use fill="#FFF" xlinkHref="#accept_svg__a" />
      </g>
    </svg>
  );
};

SvgAccept.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
};

SvgAccept.defaultProps = {
  width: 24,
};

export default SvgAccept;
