import LogRocket from 'logrocket';

import jwtDecode from '@renofi/utilities/src/jwtDecode';

import {sendEvent} from '../analytics';
import {JWT_KEY, getCookie} from '../cookies';
import {AUTH_TOKEN} from '../storage';

const hostname = window.location.hostname || '';
const isLoginApp = hostname.match(/^login.renofi/);

export default ({storage, decode = false}) => {
  const impersonationJwt = storage.getItem(AUTH_TOKEN);
  const jwt = impersonationJwt || getCookie(JWT_KEY);
  if (!jwt && !isLoginApp) {
    sendEvent('JWT/Invalid-or-Missing-JWT', {LogRocket: LogRocket.sessionURL});
    return null;
  }
  return decode ? jwtDecode(jwt) : jwt;
};
