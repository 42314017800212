import * as React from 'react';

import PropTypes from 'prop-types';

const SvgPpt = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <mask id="ppt_svg__a" fill="#fff">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.56 0A3.56 3.56 0 0 0 8 3.56v24.88A3.56 3.56 0 0 0 11.56 32h16.88A3.56 3.56 0 0 0 32 28.44V3.539 8l-8-8H11.56z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.56 0A3.56 3.56 0 0 0 8 3.56v24.88A3.56 3.56 0 0 0 11.56 32h16.88A3.56 3.56 0 0 0 32 28.44V3.539 8l-8-8H11.56z"
        fill="#fff"
      />
      <path
        d="m32 3.539 1-.006-2 .006h1zM32 8l-.707.707L33 10.414V8h-1zm-8-8 .707-.707L24.414-1H24v1zM9 3.56A2.56 2.56 0 0 1 11.56 1v-2A4.56 4.56 0 0 0 7 3.56h2zm0 24.88V3.56H7v24.88h2zM11.56 31A2.56 2.56 0 0 1 9 28.44H7A4.56 4.56 0 0 0 11.56 33v-2zm16.88 0H11.56v2h16.88v-2zM31 28.44A2.56 2.56 0 0 1 28.44 31v2A4.56 4.56 0 0 0 33 28.44h-2zm0-24.88v24.88h2V3.56h-2zm0-.015v.015h2v-.027l-2 .012zm0-.006V8h2V3.539h-2zm1.707 3.754-8-8L23.293.707l8 8 1.414-1.414zM11.56 1H24v-2H11.56v2z"
        fill="#EBEBEB"
        mask="url(#ppt_svg__a)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m24 0 8 8h-6a2 2 0 0 1-2-2V0z"
        fill="#A6ADBC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 8h5v5l-5-5z"
        fill="#D2D6DE"
      />
      <rect y={3} width={21} height={10} rx={2} fill="#BC4C30" />
      <path
        d="M7.45 7.02a2.2 2.2 0 0 1-.138.765 1.701 1.701 0 0 1-.39.625 2.39 2.39 0 0 1-.777.52c-.284.117-.638.175-1.063.175h-.934V11h-1.5V5.184h2.47c.369 0 .68.032.933.097.255.063.48.158.676.285.234.154.412.35.535.59.125.24.187.528.187.864zm-1.552.035a.68.68 0 0 0-.132-.422.717.717 0 0 0-.309-.25A1.356 1.356 0 0 0 5 6.28a11.858 11.858 0 0 0-.594-.011h-.258v1.742h.43c.255 0 .465-.016.629-.047a.894.894 0 0 0 .418-.188.708.708 0 0 0 .207-.297c.044-.117.066-.259.066-.425zm7.418-.035c0 .26-.045.515-.136.765a1.703 1.703 0 0 1-.39.625c-.233.23-.492.402-.778.52-.284.117-.638.175-1.063.175h-.933V11h-1.5V5.184h2.468c.37 0 .681.032.934.097.255.063.48.158.676.285.234.154.413.35.535.59.125.24.187.528.187.864zm-1.55.035a.68.68 0 0 0-.133-.422.716.716 0 0 0-.309-.25 1.356 1.356 0 0 0-.457-.102 11.859 11.859 0 0 0-.594-.011h-.257v1.742h.43c.255 0 .464-.016.628-.047a.895.895 0 0 0 .418-.188.708.708 0 0 0 .207-.297c.044-.117.067-.259.067-.425zm7.168-.746h-1.817V11h-1.5V6.309h-1.816V5.184h5.133v1.125z"
        fill="#fff"
      />
      <path
        d="M19.546 25a4.545 4.545 0 0 0 4.545-4.546h-4.546V15.91a4.545 4.545 0 1 0 0 9.091z"
        fill="#BC4C30"
      />
      <path
        d="M20.454 15v4.546H25A4.546 4.546 0 0 0 20.454 15z"
        fill="#BC4C30"
      />
      <rect x={13} y={27} width={14} height={2} rx={0.5} fill="#BC4C30" />
    </svg>
  );
};

SvgPpt.propTypes = {
  color: PropTypes.string,
};
export default SvgPpt;
