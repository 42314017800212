import {gql} from '@apollo/client';

export default gql`
  mutation startVerification(
    $email: String!
    $projectId: ID!
    $taskId: ID!
    $borrowerRole: BorrowerRole
  ) {
    startVerification(
      email: $email
      projectId: $projectId
      taskId: $taskId
      borrowerRole: $borrowerRole
    ) {
      clientSecret
      success
    }
  }
`;
