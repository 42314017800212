import {gql} from '@apollo/client';

export default gql`
  mutation submitReviewNotes(
    $gcddReviewId: ID!
    $insuranceNotes: String
    $licenseNotes: String
    $referencesNotes: String
    $webReviewsNotes: String
  ) {
    submitReviewNotes(
      gcddReviewId: $gcddReviewId
      insuranceNotes: $insuranceNotes
      licenseNotes: $licenseNotes
      referencesNotes: $referencesNotes
      webReviewsNotes: $webReviewsNotes
    ) {
      gcddReview {
        id
        licenseNotes
        referencesNotes
        webReviewsNotes
        insuranceNotes
      }
    }
  }
`;
