import {createElement, Component, memo} from 'react';

export default (spec) => (BaseComponent) => {
  class Lifecycle extends Component {
    render() {
      return createElement(BaseComponent, {
        ...this.props,
        ...this.state,
      });
    }
  }

  Object.keys(spec).forEach((hook) => (Lifecycle.prototype[hook] = spec[hook]));

  return memo(Lifecycle);
};
