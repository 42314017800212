export default `
id
borrowerRole
createdAt
items {
  payDate
  payPeriodFrom
  payPeriodTo
}
paymentFrequency
updatedAt
`;
