import {
  always,
  fromPairs,
  ifElse,
  is,
  keys,
  map,
  omit,
  pipe,
  prop,
  toPairs,
} from 'ramda';

import isNilOrEmpty from './isNilOrEmpty';
import {getUtcDate} from './dates';

const PLACEHOLDER = '__PLACEHOLDER__';
const TO_STRING = ifElse(
  is(Object),
  (value) => value.toString(),
  (value) => `${value}`,
);

export const __ = PLACEHOLDER;

export const toDate = (value, defaultValue = null) => {
  return isNilOrEmpty(value) ? defaultValue : getUtcDate(value);
};

export const toNumber = (value, defaultValue = null) =>
  isNilOrEmpty(value) ? defaultValue : Number(value);

export const toRoundedInt = (value, defaultValue = null) =>
  isNilOrEmpty(value) ? defaultValue : Math.round(value);

export const toFlooredInt = (value, defaultValue = null) =>
  isNilOrEmpty(value) ? defaultValue : Math.floor(value);

export const toBoolean = (value) => Boolean(value);

export const dateToString = (value, defaultValue = null) =>
  value && value instanceof Date ? value.toISOString() : defaultValue;

export const toNullishString = ifElse(isNilOrEmpty, always(null), TO_STRING);

export const toString = ifElse(isNilOrEmpty, always(''), TO_STRING);

export default (config, container, full = false) => {
  return {
    ...pipe(
      toPairs,
      map(([key, transforms = [PLACEHOLDER]]) => [
        key,
        pipe(
          ...transforms.map((func, i) => {
            if (i > 0) return func;
            if (isNilOrEmpty(func)) return prop(key);
            if (func === PLACEHOLDER) return prop(key);
            if (is(String, func)) return prop(key);
            return func;
          }),
        )(container),
      ]),
      fromPairs,
    )(config),
    ...(full ? omit(keys(config), container) : {}),
  };
};
