import {gql} from '@apollo/client';

export default gql`
  mutation addRenovationUpdate(
    $projectId: ID!
    $details: String
    $files: [String!]
    $date: Date
    $kind: RenovationUpdateKind!
  ) {
    addRenovationUpdate(
      projectId: $projectId
      details: $details
      files: $files
      date: $date
      kind: $kind
    ) {
      renovationUpdate {
        id
        createdAt
        date
        details
        kind
        source
        noteToLender
        photos {
          original {
            objectName
            url
          }
          thumbnail {
            objectName
            url
          }
        }
        shareWithLender
        sharedWithLenderAt
        updatedAt
        user {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`;
