import * as React from 'react';

import PropTypes from 'prop-types';

const SvgDeck = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M3.816 19.455H21l-3.91-4.985V9.293h3.084l-3.773-3.387V3.482h-1.734v.854l-2.259-2.01-7.765 6.967h3.084v5.177l-3.91 4.985zm6.307-4.241 3.14 3.14H12.05l-3.139-3.14h1.212zm1.982 0 3.14 3.14h-1.212l-3.14-3.14h1.212zm1.983 0 3.14 3.14h-1.212l-3.14-3.14h1.212zm-6.251.908.523-.688 2.92 2.92h-1.212l-2.231-2.232zm1.46 2.231H8.085l-1.13-1.129.524-.688 1.818 1.817zm-2.672-.688.689.688h-1.24l.551-.688zM18 18.353l-3.14-3.14h1.432l2.451 3.14H18zM7.534 8.191l4.874-4.378 4.875 4.378h-1.295v5.921h-2.23l-.028-.027-.027.027h-1.928l-.027-.027-.028.027H9.792l-.027-.027-.028.027h-.909v-5.92H7.534zM3.816 19.455h1v2h-1zm16.184 0h1v2h-1zm-2.695 0h1v2h-1zm-8.094 0h1v2h-1zm-2.695 0h1v2h-1zm5.39 0h1v2h-1zm2.699 0h1v2h-1z"
        fill={color}
      />
      <path fill={color} d="M3.816 20.827H21v.847H3.816z" />
    </svg>
  );
};

SvgDeck.propTypes = {
  color: PropTypes.string,
};
export default SvgDeck;
