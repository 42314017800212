import {gql} from '@apollo/client';

import {gcddDocument} from '../../partials';

export default gql`
  mutation updateInsurance($expiryDate: Date, $id: ID!, $insurance: InsuranceInputObject, $namesMatch: Boolean) {
    updateInsurance(expiryDate: $expiryDate, id: $id, insurance: $insurance, namesMatch: $namesMatch) {
      insurance {
        ${gcddDocument}
        limitAggregate
        limitEachOccurrence
        limitWorkersCompensation
        workersCompDetailsAvailableInGeneralLiability
      }
    }
  }
`;
