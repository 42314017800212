import {gql} from '@apollo/client';

export default gql`
  mutation submitRentalIncomeFromProperties(
    $incomeSourceId: ID!
    $rentalIncomeFromPropertiesInput: [RentalIncomeFromPropertyInputObject!]!
  ) {
    submitRentalIncomeFromProperties(
      incomeSourceId: $incomeSourceId
      rentalIncomeFromPropertiesInput: $rentalIncomeFromPropertiesInput
    ) {
      rentalIncomeFromProperties {
        id
      }
    }
  }
`;
