import React from 'react';

import {Box, Flex} from 'rebass';
import PropTypes from 'prop-types';
import {isNil} from 'lodash';
import Skeleton from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonFlex = ({
  children,
  skeletonHeight,
  skeletonWidth,
  count = 1,
  animate = false,
  style = {},
  ...props
}) => {
  if (isNil(children))
    return (
      <Box {...props}>
        <Skeleton
          style={style}
          height={skeletonHeight || '100%'}
          width={skeletonWidth || '100%'}
          count={count}
          enableAnimation={animate}
        />
      </Box>
    );
  return <Flex {...props}>{children}</Flex>;
};

SkeletonFlex.propTypes = {
  children: PropTypes.node,
  count: PropTypes.number,
  style: PropTypes.object,
  animate: PropTypes.bool,
  skeletonWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  skeletonHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default SkeletonFlex;
