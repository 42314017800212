import styled from '@emotion/styled';

import {basic10, basic75, basic80, white} from '@renofi/theme';

import Flex from '../Flex';

export const ListWrapper = styled.div(({theme}) => ({
  marginTop: 8,
  border: theme === 'dark' ? 'none' : `1px solid ${basic10}`,
  background: theme === 'dark' ? basic75 : white,
  borderRadius: 4,
}));

export const OptionsContainer = styled.div(({height, theme}) => ({
  maxHeight: height,
  minHeight: height,
  overflow: 'auto',
  borderTop: `1px solid ${theme === 'dark' ? basic80 : basic10}`,
}));

export const Option = styled(Flex)(({theme}) => ({
  alignItems: 'center',
  paddingLeft: 16,
  paddingRight: 16,
  height: 50,
  borderBottom: `1px solid ${theme === 'dark' ? basic80 : basic10}`,
  '&:last-of-type': {
    borderBottom: 0,
  },
}));
