import {find, pipe, propEq, prop, propOr, reject, isNil} from 'ramda';

import {compose, withHandlers, withState} from '@renofi/recompose/src';
import {
  withProject,
  withGetSignedUrls,
  withSubmitDocument,
  withSubmitProjectDocument,
  withUploadHandler,
} from '@renofi/graphql/src/hoc/index';

const getNonNil = reject(isNil);

export default ({basePath}) =>
  compose(
    withGetSignedUrls(),
    withSubmitDocument(),
    withSubmitProjectDocument(),
    withProject(),

    withState('submitted', 'setSubmitted', []),
    withUploadHandler({basePath}),

    withHandlers({
      uploadDocuments:
        ({
          submitted: documents,
          getSignedUrls,
          uploadFiles,
          setSubmitted,
          submitDocument,
          submitProjectDocument,
          project,
        }) =>
        async ({documentType, files, taskId}) => {
          const {id: projectId} = project;
          const uploadedFiles = await uploadFiles(files, taskId);
          const submitFunc = documentType
            ? submitProjectDocument
            : submitDocument;

          setSubmitted(documents.concat(uploadedFiles));

          const submittedFiles = await Promise.all(
            uploadedFiles.map(
              async ({checksum, fileName, objectName, id: documentId}) =>
                submitFunc({
                  projectId,
                  taskId,
                  checksum,
                  documentType,
                  documentId,
                  fileName,
                  objectName,
                }),
            ),
          );

          const completedFiles = getNonNil(submittedFiles);
          setSubmitted(documents.concat(completedFiles));
          const signedUrls = completedFiles.length
            ? await getSignedUrls(completedFiles.map(prop('objectName')))
            : [];

          const finalFiles = completedFiles.map(
            ({id, objectName, ...rest}) => ({
              ...rest,
              id,
              itemId: taskId,
              objectName,
              fileUrl: pipe(
                find(propEq('objectName', objectName)),
                propOr(null, 'url'),
              )(signedUrls),
            }),
          );

          const submitted = documents.concat(finalFiles);
          setSubmitted(submitted);

          return submitted;
        },
    }),
  );
