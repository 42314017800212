import {gql} from '@apollo/client';

export default gql`
  mutation loanApplicationClosed(
    $appliedOn: Date!
    $approvedOn: Date!
    $closedOn: Date!
    $conditions: String
    $estimatedClosingOn: Date!
    $finalCreditScore: String!
    $finalDtiRatio: Decimal!
    $finalLoanAmount: Int!
    $finalLoanRate: Decimal!
    $lenderProvidedIncome: Int!
    $lenderProvidedMonthlyDebt: Int!
    $lenderVerifiedReserves: Int!
    $loanNumber: String!
    $loanProductId: ID!
    $renovationReadyFileId: ID!
  ) {
    loanApplicationClosed(
      renovationReadyFileId: $renovationReadyFileId
      estimatedClosingOn: $estimatedClosingOn
      loanNumber: $loanNumber
      appliedOn: $appliedOn
      approvedOn: $approvedOn
      closedOn: $closedOn
      finalLoanRate: $finalLoanRate
      finalLoanAmount: $finalLoanAmount
      finalCreditScore: $finalCreditScore
      finalDtiRatio: $finalDtiRatio
      loanProductId: $loanProductId
      lenderProvidedIncome: $lenderProvidedIncome
      lenderProvidedMonthlyDebt: $lenderProvidedMonthlyDebt
      lenderVerifiedReserves: $lenderVerifiedReserves
      conditions: $conditions
    ) {
      lenderLoanApplication {
        id
        appliedOn
        approvedOn
        closedOn
        conditions
        createdAt
        deniedCreditScoreExplanation
        deniedDebtExplanation
        deniedOn
        deniedOtherExplanation
        estimatedClosingOn
        finalCreditScore
        finalDtiRatio
        finalLoanAmount
        finalLoanRate
        isDeniedReasonCreditScore
        isDeniedReasonDebt
        isDeniedReasonOther
        lender {
          id
          loanProducts {
            id
            name
          }
        }
        lenderProvidedIncome
        lenderProvidedMonthlyDebt
        lenderVerifiedReserves
        loanNumber
        loanProduct {
          id
          createdAt
          name
          updatedAt
        }
        renovationReadyFileId
        status
        updatedAt
        withdrawnExplanation
        withdrawnOn
        withdrawnReason
      }
    }
  }
`;
