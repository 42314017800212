import React from 'react';

import PropTypes from 'prop-types';

import {Container} from './styled';

const Card = ({children, ...props}) => (
  <Container px={24} py={16} mb={24} {...props}>
    {children}
  </Container>
);

Card.propTypes = {
  children: PropTypes.node,
};

export default Card;
