import {identity} from 'ramda';

import {compose, mapProps} from '@renofi/recompose/src';

import {GET_EXCEPTION_REQUESTS} from '../queries';

import withQuery from './withQuery';

export default ({mapper = identity, options, ...config} = {}) =>
  compose(
    withQuery({
      query: GET_EXCEPTION_REQUESTS,
      props: ({data: {loading, lenderExceptionRequests}, ...props}) => ({
        loading,
        exceptionRequests: lenderExceptionRequests,
        ...props,
      }),
      options,
      ...config,
    }),
    mapProps(mapper),
  );
