import {gql} from '@apollo/client';

export default gql`
  query gcddReviewSearch(
    $query: String
    $filter: GcddReviewSearchFilterInputObject
    $sort: GcddReviewSearchSortInputObject
    $page: Int
    $limit: Int
  ) {
    gcddReviewSearch(
      query: $query
      filter: $filter
      page: $page
      limit: $limit
      sort: $sort
    ) {
      metadata {
        totalCount
        totalPages
        facets {
          assignedToId {
            id
            count
          }
          cancelled {
            value
            count
          }
          completed {
            value
            count
          }
          opened {
            value
            count
          }
          questionnaireReturned {
            value
            count
          }
          questionnaireSent {
            value
            count
          }
          submittedStep {
            value
            count
          }
          unopened {
            value
            count
          }
          withAppraiser {
            value
            count
          }
          overallScore {
            score
            count
          }
          priority {
            priority
            count
          }
        }
      }
      collection {
        assignedToId
        assignedTo {
          email
          firstName
          id
          lastName
          phoneNumber
        }
        id
        contractor {
          businessName
          id
          city
          state
          secondaryAddress
          streetAddress
          website
          zipCode
        }
        projectId
        project {
          city
          id
          renovationCost
          state
          streetAddressOne
          streetAddressTwo
          zipCode
        }
        completedAt
        complexityLevel
        overallScore
        estimatedCostOfWork
        priority
        questionnaire {
          id
          businessName
          createdAt
          firstOpenedAt
          returnedAt
          sendingPdfRequestedAt
          submittedStep
          submissionType
        }
      }
    }
  }
`;
