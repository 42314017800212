import {gql} from '@apollo/client';

export default gql`
  query usersSearch($query: String!) {
    usersSearch(query: $query) {
      id
      email
      firstName
      lastName
      crmId
      accountCategories
    }
  }
`;
