import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {Route, Switch} from 'react-router-dom';

import {Modal} from '@renofi/components-internal';
import asyncComponent from '@renofi/utilities/src/asyncComponent';

import {getEditModalHeader} from '../../utils';
import {Header} from '../../styled';

const CreateRecord = asyncComponent(() =>
  import(/* webpackChunkName: "renovation-create" */ '../CreateRecord'),
);

const ChangeRecord = asyncComponent(() =>
  import(/* webpackChunkName: "renovation-change" */ '../ChangeRecord'),
);

const ViewRecord = asyncComponent(() =>
  import(/* webpackChunkName: "renovation-view" */ '../ViewRecord'),
);

const ModalFooter = asyncComponent(() =>
  import(/* webpackChunkName: "renovation-footer" */ '../ModalFooter'),
);

const RenovationUpdateModal = ({
  id,
  action,
  renovationUpdate,
  baseRoute,
  mode,
  inProgress,
  project,
  lenderNote,
  isSubmitUpdateDisabled,

  onCloseModal,
  onCancelUpdate,
  onRemoveRecord,
  onUpdateRecord,
  onSubmitRecord,
  onSaveAndShare,
  onChangeLenderNote,
}) => {
  const [remove, setRemove] = useState(false);
  const headerTitle = getEditModalHeader(action, renovationUpdate);

  const footer = (
    <ModalFooter
      {...{
        action,
        isSubmitUpdateDisabled,
        remove,
        mode,
        loading: inProgress,
        renovationUpdate,
        onClose: onCloseModal,
        onCancelUpdate,
        onRemove: () => setRemove(true),
        onRemoveCancel: () => setRemove(false),
        onRemoveConfirm: async () => {
          await onRemoveRecord(id);
          setRemove(false);
        },
        onUpdate: () => onUpdateRecord(id),
        onSubmit: () => onSubmitRecord(),
        onSaveAndShare: () => onSaveAndShare(renovationUpdate),
      }}
    />
  );

  return (
    <Modal
      lock
      fixed
      p={24}
      width={700}
      css={{minHeight: 400}}
      show={!!action}
      footer={footer}
      header={<Header>{headerTitle}</Header>}
      onClose={() => onCloseModal()}>
      <Switch>
        <Route
          exact
          path={`${baseRoute}/:action(view)/:itemId`}
          render={() => (
            <ViewRecord
              project={project}
              {...renovationUpdate}
              noteToLender={lenderNote}
              onChangeLenderNote={onChangeLenderNote}
            />
          )}
        />
        <Route
          exact
          path={`${baseRoute}/:action(update)/:itemId`}
          render={(props) => <ChangeRecord mode={mode} {...props} />}
        />
        <Route
          exact
          path={`${baseRoute}/:action(create)`}
          render={(props) => (
            <CreateRecord projectId={project?.id} mode={mode} {...props} />
          )}
        />
      </Switch>
    </Modal>
  );
};

RenovationUpdateModal.propTypes = {
  id: PropTypes.string,
  action: PropTypes.string,
  isSubmitUpdateDisabled: PropTypes.bool,
  renovationUpdate: PropTypes.shape({
    id: PropTypes.string,
    kind: PropTypes.string,
    photos: PropTypes.array,
    sharedWithLenderAt: PropTypes.string,
    shareWithLender: PropTypes.bool,
    updatedAt: PropTypes.string,
    createdAt: PropTypes.string,
    user: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }),
  baseRoute: PropTypes.string,
  mode: PropTypes.string,
  inProgress: PropTypes.bool,
  project: PropTypes.shape({
    id: PropTypes.string,
  }),
  lenderNote: PropTypes.string,
  onCloseModal: PropTypes.func,
  onCancelUpdate: PropTypes.func,
  onRemoveRecord: PropTypes.func,
  onUpdateRecord: PropTypes.func,
  onSubmitRecord: PropTypes.func,
  onSaveAndShare: PropTypes.func,
  onChangeLenderNote: PropTypes.func,
};

export default RenovationUpdateModal;
