import {isNil} from 'ramda';

import capitalizeString from './capitalize';

const DEFAULT_VALUE = '-';

const getYesNoFromBool = (
  value,
  {capitalize = false, fallback = DEFAULT_VALUE} = {},
) => {
  if (isNil(value)) {
    return fallback;
  }

  const result = value ? 'yes' : 'no';
  return capitalize ? capitalizeString(result) : result;
};

export default getYesNoFromBool;
