import {isNil, memoizeWith} from 'ramda';

const getFormatter = memoizeWith(
  (options) => Object.values(options).join('-'),
  ({currency, fractional, style}) =>
    new Intl.NumberFormat('en-US', {
      style,
      currency,
      minimumFractionDigits: fractional ? 2 : 0,
      maximumFractionDigits: fractional ? 2 : 0,
    }),
);

export default (
  value,
  {
    currency = 'USD',
    defaultValue = '-',
    fractional = true,
    style = 'currency',
  } = {},
) => {
  if (isNil(value)) {
    return defaultValue;
  }
  return getFormatter({currency, fractional, style}).format(value);
};
