import React from 'react';

import PropTypes from 'prop-types';

const FlightIcon = ({color = '#CCC'}) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="m8.755 23.707.75-1.3 2.482-.299 2.75-4.763-8.61-2.084 1-1.733 9.86-.08 2.75-4.763c.209-.36.512-.594.91-.7a1.444 1.444 0 0 1 1.14.151c.36.208.594.512.7.91.107.399.057.778-.151 1.14l-2.75 4.762 4.86 8.58-1 1.733-6.11-6.416-2.75 4.764.982 2.299-.75 1.299-2.531-2.616-3.532-.884Z"
      fill={color}
    />
  </svg>
);

FlightIcon.propTypes = {
  color: PropTypes.string,
};
export default FlightIcon;
