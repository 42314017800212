export default `
id
email
emailToCase
firstName
lastName
name
salesforceUserId
nmlsId
userId
`;
