import facepaint from 'facepaint';

const SMALL = 960;

export const getScreenWidth = () => window.innerWidth;

export const mobile = SMALL;
export const small = SMALL;

export const maxSmall = `@media (max-width: ${SMALL}px)`;
export const minSmall = `@media (min-width: ${SMALL}px)`;

export const isMobile = () => getScreenWidth() <= mobile;

export const mediaQuery = facepaint(
  [mobile].map((bp) => `@media (min-width: ${bp}px)`),
);
