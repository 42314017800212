import {gql} from '@apollo/client';

import {borrowersAndPropertyInformation} from '../../partials';

export default gql`
  query borrowersOnboardingTasks(
    $projectId: ID!
    $facet: Facet
    $status: [TaskStatus!]
    $taskType: TaskType
  ) {
    projectTasks(
      projectId: $projectId
      facet: $facet
      status: $status
      taskType: $taskType
    ) {
      id
      borrowersAndPropertyInformation {
        ${borrowersAndPropertyInformation}
      }
      status
      taskType
    }
  }
`;
