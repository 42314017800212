import {gql} from '@apollo/client';

export default gql`
  mutation markTaskFinishedByBorrower($taskId: ID!) {
    markTaskFinishedByBorrower(taskId: $taskId) {
      task {
        id
        borrowerFinishedAt
        status
        identityVerifications {
          borrowerRole
          status
        }
      }
    }
  }
`;
