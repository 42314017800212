import React from 'react';

import Context from './context';

export default (Component) => (props) =>
  (
    <Context.Consumer>
      {(config) => <Component {...props} config={config} />}
    </Context.Consumer>
  );
