import styled from '@emotion/styled';

import {minSmall, maxSmall} from '@renofi/theme/src/breakpoints';
import {basic03, basic80, border, white} from '@renofi/theme/src/colors';
import Flex from '@renofi/components-internal/src/Flex';
import Box from '@renofi/components-internal/src/Box';

import close from './close.svg';

const OFFSET_Y = 48;
const HEADER_HEIGHT = 60;
const FOOTER_HEIGHT = 60;

export const CONTENT_OFFSET = OFFSET_Y * 2 + HEADER_HEIGHT + FOOTER_HEIGHT;

const getMobileOffset = ({hasHeader, hasFooter}) =>
  (hasHeader ? HEADER_HEIGHT : 0) + (hasFooter ? FOOTER_HEIGHT : 0);

const calcHeight = (height) => {
  const isPercentage = String(height).endsWith('%');
  const suffix = isPercentage ? height : `${height}px`;

  if (height === 'auto') {
    return height;
  }

  return `calc(${suffix} - ${OFFSET_Y}px)`;
};

export const Cover = styled.div(
  {
    width: '100%',
    height: '100%',
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgb(0,0,0)',
    zIndex: 1050,
  },
  ({visible, opacity = 0.5}) => ({
    ...(visible ? {} : {display: 'none'}),
    opacity,
  }),
);

export const Wrapper = styled(Flex)(
  {
    zIndex: 1050,
    justifyContent: 'center',
    alignItems: 'flex-start',
    [maxSmall]: {
      minWidth: 340,
      maxHeight: '100vh',
    },
  },
  ({visible, lock, fixed = true, top = 48}) => ({
    ...(visible ? {} : {display: 'none'}),
    ...(!lock && fixed
      ? {
          width: '100%',
          height: '100%',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          overflow: 'auto',
          paddingBottom: 36,
          paddingTop: 36,
        }
      : {
          position: fixed ? 'fixed' : 'absolute',
          width: '100%',
          height: 'auto',
          top,
          ...(lock
            ? {
                height: '100%',
                overflow: 'auto',
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
                alignItems: 'flex-start',
                [maxSmall]: {
                  paddingTop: 0,
                },
                [minSmall]: {
                  paddingTop: OFFSET_Y,
                  paddingBottom: OFFSET_Y,
                },
              }
            : {}),
        }),
  }),
);

export const Dialog = styled(Flex)(
  {
    backgroundColor: white,
    flexDirection: 'column',
    marginBottom: 0,
  },
  ({width = 562, height = 'auto', sx}) => ({
    ...sx,
    [maxSmall]: {
      width: '100%',
      minWidth: 340,
      height: '100%',
      marginBottom: 0,
    },
    [minSmall]: {
      width,
      height: calcHeight(height),
      border: `1px solid ${border}`,
      borderRadius: 4,
    },
  }),
);

export const Content = styled(Box)((props) => ({
  flexGrow: 1,
  [maxSmall]: {
    height: `calc(100vh - ${getMobileOffset(props)}px)`,
    overflow: 'auto',
  },
  [minSmall]: {
    overflow: 'auto',
  },
}));

export const Header = styled(Flex)({
  height: HEADER_HEIGHT,
  fontSize: 18,
  lineHeight: '21px',
  color: basic80,
  borderBottom: `1px solid ${border}`,
  alignItems: 'center',
  padding: 16,
  justifyContent: 'space-between',
});

export const Footer = styled.div({
  height: FOOTER_HEIGHT,
  padding: 16,
  borderTop: `1px solid ${border}`,
  backgroundColor: basic03,
});

export const Close = styled.div({
  width: 12,
  height: 12,
  cursor: 'pointer',
  backgroundImage: `url('${close}')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '12px 12px',
  backgroundPosition: 'center center',
});
