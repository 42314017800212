import styled from '@emotion/styled';
import {Button, Link} from '@rebass/emotion';

import omitProps from '@renofi/utilities/src/omitProps';

const getStyles = ({small, large, medium, rectangle}) => {
  const styles = rectangle
    ? {
        borderRadius: 4,
        textTransform: 'none',
        borderWidth: 2,
        overflow: 'hidden',
      }
    : {};
  if (small) {
    return {
      height: 20,
      padding: '0 16px',
      borderRadius: 10,
      fontSize: 9,
      lineHeight: '20px',
      ...styles,
    };
  }
  if (medium) {
    return {
      height: 44,
      padding: '0 20px',
      borderRadius: 22,
      fontSize: 14,
      lineHeight: '44px',
      ...styles,
    };
  }
  if (large) {
    return {
      height: 50,
      padding: '0 25px',
      borderRadius: 25,
      fontSize: rectangle ? 21 : 16,
      lineHeight: '50px',
      whiteSpace: 'nowrap',
      ...styles,
    };
  }
  return {
    height: 30,
    padding: '0 20px',
    borderRadius: 15,
    fontSize: 12,
    lineHeight: '30px',
    ...styles,
  };
};

export const Wrapper = styled(omitProps(['fakeDisabled'])(Button))(
  {
    position: 'relative',
    textTransform: 'uppercase',
  },
  ({block, disabled, fakeDisabled, variant, theme, ...props}) => {
    return {
      cursor: disabled || fakeDisabled ? 'default' : 'pointer',
      ...(block ? {display: 'block', width: '100%'} : {}),
      ...getStyles(props),
      ...theme,
      ...(disabled && variant === 'transparent'
        ? {
            color: 'white!important',
            backgroundColor: 'transparent!important',
            opacity: 0.3,
          }
        : {}),
    };
  },
);

export const ButtonLinkStyled = styled(Link)(
  {
    display: 'inline-block',
    textTransform: 'uppercase',
    textDecoration: 'none',
  },
  (props) => getStyles(props),
);
