import {gql} from '@apollo/client';

export default gql`
  mutation createNote(
    $body: String!
    $incomeCommentaryId: ID
    $projectId: ID!
    $title: String!
  ) {
    createNote(
      body: $body
      incomeCommentaryId: $incomeCommentaryId
      projectId: $projectId
      title: $title
    ) {
      note {
        authorId
        author {
          id
          email
          firstName
          lastName
        }
        body
        createdAt
        editable
        id
        mentions {
          label
          userEmail
          userId
          userName
        }
        projectId
        salesforceAuthorEmail
        salesforceAuthorId
        salesforceContentDocumentId
        salesforceOpportunityId
        title
        updatedAt
      }
    }
  }
`;
