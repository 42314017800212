import React, {useContext} from 'react';

import PropTypes from 'prop-types';
import {BrowserRouter as Router} from 'react-router-dom';

import Notifications from '@renofi/components-internal/src/Notifications';
import {Context as ConfigContext} from '@renofi/utilities/src/config';
import {Context as StorageContext} from '@renofi/utilities/src/storage';
import Loader from '@renofi/components-internal/src/Loader';

import {useAuthentication} from '../hooks';

const AuthRouter = ({children}) => {
  const config = useContext(ConfigContext);
  const storage = useContext(StorageContext);
  const {loading} = useAuthentication({config, storage});
  const isSecureApp = config.SERVICE_NAME === 'secure-frontend';

  if (loading) {
    return <Loader labelText="Getting user" />;
  }

  return (
    <Notifications fixed top={isSecureApp ? 0 : 70}>
      <Router>{children}</Router>
    </Notifications>
  );
};

AuthRouter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.string,
  ]),
};

export default AuthRouter;
