import * as React from 'react';

import PropTypes from 'prop-types';

const SvgThumbsUp = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={22}
      height={20}
      viewBox="0 0 22 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-1-2h24v24H-1z" />
        <path
          d="M14 20H5c-.83 0-1.54-.5-1.84-1.22L.14 11.73C.05 11.5 0 11.26 0 11V9.09l.01-.01L0 9c0-1.1.9-2 2-2h6.31l-.95-4.57-.03-.32c0-.41.17-.79.44-1.06L8.83 0l6.59 6.59c.36.36.58.86.58 1.41v10c0 1.1-.9 2-2 2zm4 0V8h4v12h-4z"
          fill={color}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

SvgThumbsUp.propTypes = {
  color: PropTypes.string,
};
export default SvgThumbsUp;
