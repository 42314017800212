import * as React from 'react';

import PropTypes from 'prop-types';

const SvgComment = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="m20.99 4 .009 15.584L18.414 17H4a.996.996 0 0 1-.705-.295A.996.996 0 0 1 3 16V4c0-.275.114-.524.295-.705A.996.996 0 0 1 4 3h16a.98.98 0 0 1 .697.291c.181.182.293.433.293.71z"
          stroke={color}
          strokeWidth={2}
        />
        <path
          d="M17.5 12.5v1h-11v-1h11zm0-3v1h-11v-1h11zm0-3v1h-11v-1h11z"
          stroke={color}
        />
      </g>
    </svg>
  );
};

SvgComment.propTypes = {
  color: PropTypes.string,
};
export default SvgComment;
