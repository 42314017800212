import * as React from 'react';

import PropTypes from 'prop-types';

const SvgBathroom = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m2.993 12.38.004.001h18.956v.944H2.05v-.944h.944zm.478-.943v-7.55A.947.947 0 0 1 5.283 3.5a1.892 1.892 0 0 0-1.338 1.804v.944c0 .26.212.472.474.472h2.843a.473.473 0 0 0 .474-.472v-.944c0-.895-.629-1.647-1.47-1.84a1.897 1.897 0 0 0-3.743.424v7.55h-.948a.473.473 0 0 0-.473.472v1.887c0 .261.212.472.473.472h.59l1.478 5.148a2.026 2.026 0 0 0 1.941 1.459h1.093l-.1.05a.47.47 0 0 0 .212.893.473.473 0 0 0 .211-.05l1.796-.893h6.885l1.796.893a.475.475 0 0 0 .635-.21.47.47 0 0 0-.211-.634l-.1-.05h.618c.896 0 1.695-.6 1.941-1.458l1.477-5.148h.59a.473.473 0 0 0 .475-.472V11.91a.473.473 0 0 0-.474-.472h-1.742c.21-.438.32-.921.32-1.415a3.314 3.314 0 0 0-3.317-3.303 3.31 3.31 0 0 0-2.755 1.463 3.325 3.325 0 0 0-2.706.735 2.367 2.367 0 0 0-3.496 2.52h-5.26zm16.113 0h-9.87a1.42 1.42 0 0 1 2.257-1.553.475.475 0 0 0 .654-.042 2.381 2.381 0 0 1 2.407-.67.475.475 0 0 0 .553-.236 2.365 2.365 0 0 1 2.104-1.274 2.367 2.367 0 0 1 2.37 2.36c0 .513-.168 1.008-.475 1.415zM3.15 14.268h17.7l-1.402 4.89a1.076 1.076 0 0 1-1.03.773h-2.616a.59.59 0 0 0-.02 0H5.585c-.475 0-.899-.318-1.03-.774l-1.403-4.889zm3.64-8.965v.472H4.893v-.472a.947.947 0 0 1 1.896 0zM4.893 9.55a.473.473 0 0 1-.474-.472V7.662a.473.473 0 0 1 .948 0v1.416c0 .26-.213.472-.474.472zm.948.472a.473.473 0 0 0 .947 0V8.606a.473.473 0 0 0-.947 0v1.416z"
        fill={color}
      />
    </svg>
  );
};

SvgBathroom.propTypes = {
  color: PropTypes.string,
};
export default SvgBathroom;
