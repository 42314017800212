import React from 'react';

import PropTypes from 'prop-types';

import formatMoney from '@renofi/utilities/src/formatMoney';

const Money = ({value, fractional = true}) => (
  <>{formatMoney(value, {fractional})}</>
);

Money.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  fractional: PropTypes.bool,
};

export default Money;
