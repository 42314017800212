import {gql} from '@apollo/client';

export default gql`
  mutation enforceSoftCreditCheckForProject($softCreditCheckId: ID!) {
    enforceSoftCreditCheckForProject(softCreditCheckId: $softCreditCheckId) {
      softCreditCheck {
        id
      }
    }
  }
`;
