import {gql} from '@apollo/client';

export default gql`
  query renovationReadyFileTransmittedBorrowerNotification(
    $renovationReadyFile: RenovationReadyFileInputObject!
  ) {
    renovationReadyFileTransmittedBorrowerNotification(
      renovationReadyFile: $renovationReadyFile
    ) {
      content
      subject
    }
  }
`;
