import {gql} from '@apollo/client';

export default gql`
  query internalUsersByPermission($permission: String!) {
    internalUsersByPermission(permission: $permission) {
      id
      email
      firstName
      lastName
    }
  }
`;
