import noop from '@renofi/utilities/src/noop';

import {GET_USER_BY_ID} from '../../queries';
import useToggledQuery from '../useToggledQuery';

export default function useUserById({
  fetchPolicy = 'cache-first',
  lazy = true,
  onCompleted = noop,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_USER_BY_ID,
    options: {
      onCompleted,
      fetchPolicy,
      ...options,
    },
  });

  const {data, error, loading, fetch: getUserById} = response;
  return {
    getUserById,
    user: data?.userById,
    loading,
    error,
  };
}
