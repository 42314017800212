import {gql} from '@apollo/client';

export default gql`
  query correspondenceMessages(
    $entityId: ID
    $entityIds: [ID!]
    $limit: Int!
    $page: Int!
  ) {
    correspondenceMessages(
      entityId: $entityId
      entityIds: $entityIds
      limit: $limit
      page: $page
    ) {
      collection {
        authorId
        author {
          email
          firstName
          id
          lastName
          phoneNumber
        }
        bodyText
        createdAt
        entityId
        entityType
        id
        source
        subject
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;
