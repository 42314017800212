import {gql} from '@apollo/client';

export default gql`
  mutation loanApplicationDenied(
    $deniedCreditScoreExplanation: String
    $deniedDebtExplanation: String
    $deniedOn: Date!
    $deniedOtherExplanation: String
    $finalCreditScore: String
    $finalDtiRatio: Decimal
    $isDeniedReasonCreditScore: Boolean!
    $isDeniedReasonDebt: Boolean!
    $isDeniedReasonOther: Boolean!
    $renovationReadyFileId: ID!
  ) {
    loanApplicationDenied(
      renovationReadyFileId: $renovationReadyFileId
      deniedOn: $deniedOn
      isDeniedReasonCreditScore: $isDeniedReasonCreditScore
      isDeniedReasonDebt: $isDeniedReasonDebt
      isDeniedReasonOther: $isDeniedReasonOther
      finalCreditScore: $finalCreditScore
      finalDtiRatio: $finalDtiRatio
      deniedCreditScoreExplanation: $deniedCreditScoreExplanation
      deniedDebtExplanation: $deniedDebtExplanation
      deniedOtherExplanation: $deniedOtherExplanation
    ) {
      lenderLoanApplication {
        id
        appliedOn
        approvedOn
        closedOn
        conditions
        createdAt
        deniedCreditScoreExplanation
        deniedDebtExplanation
        deniedOn
        deniedOtherExplanation
        estimatedClosingOn
        finalCreditScore
        finalDtiRatio
        finalLoanAmount
        finalLoanRate
        isDeniedReasonCreditScore
        isDeniedReasonDebt
        isDeniedReasonOther
        lender {
          id
          loanProducts {
            id
            name
          }
        }
        lenderProvidedIncome
        lenderProvidedMonthlyDebt
        lenderVerifiedReserves
        loanNumber
        loanProduct {
          id
          createdAt
          name
          updatedAt
        }
        renovationReadyFileId
        status
        updatedAt
        withdrawnExplanation
        withdrawnOn
        withdrawnReason
      }
    }
  }
`;
