import * as React from 'react';

import PropTypes from 'prop-types';

const SvgStructuralChanges = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M17.512 3h-2.17v6.46H8.707V3h-2.22L1 6.118v9.428h.848V6.367l4.889-2.743h.449l-.324.2-4.19 2.718v9.004h18.732V6.542l-4.216-2.719-.324-.2h.399l4.889 2.744v9.18H23v-9.43L17.512 3zM3.494 6.99 7.86 4.173v5.513L3.494 14.1V6.99zm.599 7.708 4.365-4.415h7.134l4.365 4.415H4.093zm16.438-7.707V14.1l-4.365-4.415V4.172l4.364 2.819z"
        fill={color}
      />
      <path
        d="M6.664 13.975a.324.324 0 1 0 0-.648.324.324 0 0 0 0 .648zm1.469-1.148a.324.324 0 1 0 0-.648.324.324 0 0 0 0 .648zm5.937.823a.324.324 0 1 0 0-.648.324.324 0 0 0 0 .648zm-.3-2.27a.324.324 0 1 0 0-.648.324.324 0 0 0 0 .648zm1.199.799a.324.324 0 1 0 0-.648.324.324 0 0 0 0 .648zm.375 1.471a.324.324 0 1 0 0-.647.324.324 0 0 0 0 .647zm1.992.325a.324.324 0 1 0 0-.648.324.324 0 0 0 0 .648zm-.973-1.472a.324.324 0 1 0 0-.648.324.324 0 0 0 0 .648zm-4.039-.324a.324.324 0 1 0 0-.648.324.324 0 0 0 0 .648zm.996.648a.324.324 0 1 0 0-.648.324.324 0 0 0 0 .648zm-1.172 1.148a.324.324 0 1 0 0-.648.324.324 0 0 0 0 .648zm-1.269-.948a.324.324 0 1 0 0-.648.324.324 0 0 0 0 .648zm-1.723.948a.324.324 0 1 0 0-.648.324.324 0 0 0 0 .648zm.473-2.095a.324.324 0 1 0 0-.649.324.324 0 0 0 0 .648zm1.574-.5a.324.324 0 1 0 0-.648.324.324 0 0 0 0 .648zm-2.695 0a.324.324 0 1 0 0-.648.324.324 0 0 0 0 .648zm10.902 9.633v-.998H5.242v.998l-2.07-1.496 2.07-1.522v1.023H19.41v-1.023l2.045 1.522-2.045 1.496z"
        fill={color}
      />
    </svg>
  );
};

SvgStructuralChanges.propTypes = {
  color: PropTypes.string,
};
export default SvgStructuralChanges;
