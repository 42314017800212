import * as React from 'react';

import PropTypes from 'prop-types';

const SvgHouse = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M19 9.3V4h-3v2.6L12 3 2 12h3v8h5v-6h4v6h5v-8h3z"
          fill={color}
        />
      </g>
    </svg>
  );
};

SvgHouse.propTypes = {
  color: PropTypes.string,
};
export default SvgHouse;
