import {gql} from '@apollo/client';

export default gql`
  query usersByIds($ids: [ID!]!) {
    usersByIds(ids: $ids) {
      active
      email
      greetingName
      invitationSentAt
      id
      firstName
      lastName
      fullName
      registrationCompletedAt
      notificationPreferences {
        active
        id
        notificationType
      }
      permissions {
        id
        key
      }
    }
  }
`;
