import {STATUSES} from '../constants';
import {
  getIsClosedValuesPresent,
  getIsDeniedValuesPresent,
  getIsDisabledForInternalMode,
} from '../utils';

export default function useIsDisabled({
  activeStatus,
  loanApplication,
  status,
  updated,
  mode = '',
  user,
}) {
  const {estimatedClosingOn, loanNumber, withdrawnOn, withdrawnReason} =
    loanApplication || {};

  const isApprovedValuesPresent = Boolean(loanNumber && estimatedClosingOn);
  const isApprovedDisabled =
    status === STATUSES.APPROVED && !isApprovedValuesPresent;

  const isClosedValuesPresent = getIsClosedValuesPresent(loanApplication);
  const isClosedDisabled = status === STATUSES.CLOSED && !isClosedValuesPresent;

  const isDeniedValuesPresent = getIsDeniedValuesPresent(loanApplication);
  const isDeniedDisabled = status === STATUSES.DENIED && !isDeniedValuesPresent;

  const isWithdrawnValuesPresent = withdrawnOn && withdrawnReason;
  const isWithdrawnOrNotAcceptedDisabled =
    (status === STATUSES.WITHDRAWN || status === STATUSES.NOT_ACCEPTED) &&
    !isWithdrawnValuesPresent;

  const isDisabledForInternalMode = getIsDisabledForInternalMode({
    user,
    mode,
    status,
  });

  const hasMissingValues =
    isApprovedDisabled ||
    isClosedDisabled ||
    isDeniedDisabled ||
    isWithdrawnOrNotAcceptedDisabled;
  const isDisabled = hasMissingValues || isDisabledForInternalMode;

  return {
    isSaveDisabled: isDisabled,
    persistable: activeStatus !== status || updated,
  };
}
