import {identity} from 'ramda';

import {compose, mapProps, withProps} from '@renofi/recompose/src';
import omitProps from '@renofi/utilities/src/omitProps';

import withRenovationReadyFile from './withRenovationReadyFile';

export default ({mapper = identity} = {}) =>
  compose(
    withRenovationReadyFile(),
    withProps(({loading, renovationReadyFile}) => ({
      project: loading ? null : renovationReadyFile?.project,
    })),
    omitProps(['renovationReadyFile']),
    mapProps(mapper),
  );
