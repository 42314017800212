import React, {Fragment, useContext, useEffect} from 'react';

import {pathOr, pick} from 'ramda';

import Flex from '@renofi/components-internal/src/Flex';
import Text from '@renofi/components-internal/src/Text';
import {Context as StorageContext} from '@renofi/utilities/src/storage';
import logoutWithRedirect from '@renofi/utilities/src/logoutWithRedirect';

import {getQueryName, getVariableNames} from '../../utils';

const STATUS_CODE = ['networkError', 'statusCode'];

const withGraphQlErrorBoundary =
  (query) =>
  (Component) =>
  // eslint-disable-next-line react/prop-types
  ({graphqlError, ...props}) => {
    const storage = useContext(StorageContext);

    useEffect(() => {
      if (!graphqlError) {
        return;
      }
      const statusCode = pathOr(null, STATUS_CODE, graphqlError);

      if (statusCode === 401 || statusCode === 403) {
        storage.clear();
        logoutWithRedirect();
        return;
      }
    }, [graphqlError]);

    if (graphqlError) {
      const queryName = getQueryName(query);
      const queryVariables = getVariableNames(query);
      const variables = queryVariables ? pick(queryVariables)(props) : {};

      return (
        <Fragment>
          <div>
            <Flex>
              <Text fontWeight="bold">Query:</Text>
              <Text ml={1}>
                {queryName}({JSON.stringify(variables, null, 2)})
              </Text>
            </Flex>
          </div>
          <div>
            <Text fontWeight="bold">Response:</Text>
            <pre>{JSON.stringify(graphqlError, null, 2)}</pre>
          </div>
        </Fragment>
      );
    }

    return <Component {...props} />;
  };

export default withGraphQlErrorBoundary;
