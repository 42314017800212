import {gql} from '@apollo/client';

export default gql`
  mutation addExceptionRequestComment(
    $exceptionRequestId: ID!
    $comment: ExceptionRequestCommentInputObject!
  ) {
    addExceptionRequestComment(
      exceptionRequestId: $exceptionRequestId
      comment: $comment
    ) {
      exceptionRequestComment {
        id
        addedById
        addedBy {
          id
          email
          firstName
          lastName
        }
        createdAt
        message
        source
        subject
        updatedAt
      }
    }
  }
`;
