import {gql} from '@apollo/client';

import {personalInfoAndResidency, task} from '../../partials';

export default gql`
  mutation submitAlternativeName(
    $taskId: ID!
    $alternativeName: AlternativeNameInputObject!
  ) {
    submitAlternativeName(
      taskId: $taskId
      alternativeName: $alternativeName
    ) {
      personalInfoAndResidency {
        ${personalInfoAndResidency}
      }
      task {
        ${task}
      }
    }
  }
`;
