import * as React from 'react';

import PropTypes from 'prop-types';

const SvgRoof = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M18.393 5.412A.514.514 0 0 0 17.9 5H6.09a.515.515 0 0 0-.493.412L3.02 16.482a.484.484 0 0 0-.015.068L3 18.455c0 .176.08.342.213.444a.485.485 0 0 0 .462.07l1.946-.73 1.964.73a.47.47 0 0 0 .333 0l1.955-.73 1.957.73a.47.47 0 0 0 .332 0l1.956-.73 1.956.73c.109.042.227.04.332 0l1.957-.73 1.955.73a.483.483 0 0 0 .46-.07.556.556 0 0 0 .214-.444V16.66l.008-.02-2.607-11.228zm-6.904 2.93-1.004-.374a.475.475 0 0 0-.333 0l-.919.34.258-2.22h1.998v2.254zm2.573 3.107a.474.474 0 0 0-.333 0l-1.226.457V9.494l1.171-.437 1.225.455.276 2.355-1.113-.418zm3.133-3.482a.476.476 0 0 0-.332.001l-1.073.403-.267-2.283h1.983l.507 2.18-.818-.301zm-2.91 7.358a.47.47 0 0 0-.333 0l-1.449.54v-2.807l1.392-.52 1.419.535.321 2.755-1.35-.503zm3.577-3.875a.473.473 0 0 0-.335-.001l-1.323.49-.286-2.463 1.115-.419 1.273.47.535 2.294-.98-.371zm.666 3.875a.467.467 0 0 0-.331 0l-1.531.571-.332-2.854 1.356-.503 1.445.547.63 2.7-1.237-.461zm-2.287 1.701zm-4.25.15.069-.006a.145.145 0 0 0 .026-.004l2.032-.75 1.961.73.161.03a.52.52 0 0 0 .046-.003l.075-.014a.35.35 0 0 0 .045-.014l1.957-.73 1.614.602v.673l-1.449-.54a.467.467 0 0 0-.331 0l-1.957.728-1.955-.729a.47.47 0 0 0-.333 0l-1.956.729-1.956-.729a.467.467 0 0 0-.332 0l-1.957.73-1.965-.73a.477.477 0 0 0-.333 0l-1.438.54v-.672l1.606-.602 1.988.737.144.023c.011 0 .024-.002.018-.002l2.102-.758 1.945.724.173.036zm-4.203-5.238-1.323-.49a.476.476 0 0 0-.334.001l-.98.372.535-2.295 1.274-.469 1.114.42-.286 2.461zm.568 3.89.322-2.755 1.42-.534 1.39.52v2.806l-1.448-.54a.465.465 0 0 0-.332 0l-1.352.503zm-.698-2.786-.333 2.854-1.539-.571a.477.477 0 0 0-.333 0l-1.225.459.627-2.698 1.447-.547 1.356.503zm3.83-1.136-1.224-.458a.493.493 0 0 0-.335.001l-1.112.418.276-2.355 1.225-.455 1.17.437v2.412zM8.203 8.37 7.13 7.968a.48.48 0 0 0-.333-.001l-.817.301.508-2.18h1.981l-.266 2.283zm5.638-.404a.476.476 0 0 0-.332.001l-1.005.374V6.088H14.5l.26 2.22-.92-.34zm-1.927 9.05z"
        fill={color}
      />
    </svg>
  );
};

SvgRoof.propTypes = {
  color: PropTypes.string,
};
export default SvgRoof;
