import React from 'react';

import PropTypes from 'prop-types';
import prettysize from 'prettysize';

const FileSize = ({value}) => <>{prettysize(value)}</>;

FileSize.propTypes = {
  value: PropTypes.number.isRequired,
};

export default FileSize;
