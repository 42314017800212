export const RENOVATION_UPDATE_KINDS = {
  ahead_of_schedule: 'ahead_of_schedule',
  behind_schedule: 'behind_schedule',
  expected_renovation_start: 'expected_renovation_start',
  on_schedule: 'on_schedule',
  renovation_completed: 'renovation_completed',
  renovation_started: 'renovation_started',
  unclassified: 'unclassified',
};

export const KINDS_WITH_DATE = [
  RENOVATION_UPDATE_KINDS.behind_schedule,
  RENOVATION_UPDATE_KINDS.ahead_of_schedule,
  RENOVATION_UPDATE_KINDS.renovation_completed,
  RENOVATION_UPDATE_KINDS.expected_renovation_start,
  RENOVATION_UPDATE_KINDS.renovation_started,
];

export const KIND_LABEL_MAP = {
  [RENOVATION_UPDATE_KINDS.on_schedule]: 'What update can you share?',
  [RENOVATION_UPDATE_KINDS.behind_schedule]:
    'Renovations are often behind schedule, and that’s ok! Can you tell us what caused the delay?',
  [RENOVATION_UPDATE_KINDS.ahead_of_schedule]:
    'Nice! What developed ahead of schedule?',
  [RENOVATION_UPDATE_KINDS.expected_renovation_start]:
    'What caused the change?',
  [RENOVATION_UPDATE_KINDS.renovation_started]: 'Details',
};

export const KIND_OPTIONS = {
  [RENOVATION_UPDATE_KINDS.expected_renovation_start]:
    'Expected renovation start changed',
  [RENOVATION_UPDATE_KINDS.behind_schedule]: 'Behind schedule',
  [RENOVATION_UPDATE_KINDS.on_schedule]: 'On schedule',
  [RENOVATION_UPDATE_KINDS.ahead_of_schedule]: 'Ahead of schedule',
  [RENOVATION_UPDATE_KINDS.renovation_completed]: 'Renovation completed',
  [RENOVATION_UPDATE_KINDS.renovation_started]: 'Renovation started',
};

export const MODES = {
  INTERNAL: 'internal',
  LENDER: 'lender',
  BORROWER: 'borrower',
};

export const KIND_ICON_MAPPER = {
  [RENOVATION_UPDATE_KINDS.behind_schedule]: {
    iconName: 'calendar',
  },
  [RENOVATION_UPDATE_KINDS.renovation_completed]: {
    iconName: 'home',
  },
  [RENOVATION_UPDATE_KINDS.expected_renovation_start]: {
    iconName: 'renovationStartDelayed',
  },
  [RENOVATION_UPDATE_KINDS.unclassified]: {
    iconName: 'pending',
  },
};

export const DEFAULT_RENOVATION_STATE = {
  action: null,
  kind: null,
  data: {
    id: null,
    amount: '',
    date: null,
    details: '',
    files: [],
    __typename: 'RenovationUpdateData',
  },
};

export const SHAREABLE = 'shareable';
