import styled from '@emotion/styled';
import {isNil} from 'ramda';

import {
  actionExtraLight,
  actionRegular,
  basic80,
  dangerRegular,
  white,
} from '@renofi/theme/src/colors';

import Flex from '../Flex';
import TextField from '../TextField';
import Badge from '../Badge';

export const POPOVER_WIDTH = 550;

export const Popover = styled(Flex)(({inline}) => ({
  width: inline ? 'auto' : POPOVER_WIDTH,
  borderRadius: 4,
  backgroundColor: white,
  boxShadow: '0 5px 10px 0 rgba(22,53,81,0.2)',
  flexDirection: 'column',
  color: basic80,
  maxHeight: '90vh',
  overflowY: 'scroll',
}));

export const Project = styled(Flex)(
  {
    cursor: 'pointer',
    ':hover': {
      backgroundColor: actionExtraLight,
    },
  },
  ({active}) => ({
    backgroundColor: active ? actionExtraLight : white,
  }),
);

export const SearchField = styled(TextField)(
  {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    marginBottom: 0,
    '> span': {
      borderColor: basic80,
    },
  },
  ({width}) => ({
    width: width || 350,
  }),
);

export const StatusBadge = styled(Badge)(
  {
    textTransform: 'uppercase',
  },
  ({closed}) => ({
    ...(!isNil(closed)
      ? {
          borderColor: closed ? dangerRegular : actionRegular,
          color: closed ? dangerRegular : actionRegular,
        }
      : {}),
  }),
);

export const LoaderWrapper = styled.div({
  position: 'absolute',
  top: 'calc(50% - 10px)',
  right: 20,
  zIndex: 1001,
});
