import {gql} from '@apollo/client';

export default gql`
  mutation completeUnderwritingConditions($ids: [ID!]!, $notes: String) {
    completeUnderwritingConditions(ids: $ids, notes: $notes) {
      underwritingConditions {
        id
      }
      errors {
        message
      }
    }
  }
`;
