export default `
amcCode
createdAt
enabled
finalInspection
id
productAmcId
productName
renofiFormType
updatedAt
`;
