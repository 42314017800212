import {gql} from '@apollo/client';

export default gql`
  mutation confirmSmsNumber($smsNumber: String!, $code: String) {
    confirmSmsNumber(smsNumber: $smsNumber, code: $code) {
      user {
        id
      }
    }
  }
`;
