import {gql} from '@apollo/client';

export default gql`
  query lenderLoanApplication($renovationReadyFileId: ID!) {
    lenderLoanApplication(renovationReadyFileId: $renovationReadyFileId) {
      id
      appliedOn
      approvedOn
      closedOn
      conditions
      createdAt
      deniedCreditScoreExplanation
      deniedDebtExplanation
      deniedOn
      deniedOtherExplanation
      estimatedClosingOn
      finalCreditScore
      finalDtiRatio
      finalLoanAmount
      finalLoanRate
      isDeniedReasonCreditScore
      isDeniedReasonDebt
      isDeniedReasonOther
      lender {
        id
        loanProducts {
          id
          name
        }
      }
      lenderProvidedIncome
      lenderProvidedMonthlyDebt
      lenderVerifiedReserves
      loanNumber
      loanProduct {
        id
        createdAt
        name
        updatedAt
      }
      renovationReadyFileId
      status
      updatedAt
      withdrawnExplanation
      withdrawnOn
      withdrawnReason
    }
  }
`;
