import * as React from 'react';

const BackIcon = (props) => (
  <svg
    width={40}
    height={40}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.75 31.475 17.933 20 28.75 8.525 25.42 5 11.25 20l14.17 15 3.33-3.525Z"
      fill="currentcolor"
    />
  </svg>
);

export default BackIcon;
