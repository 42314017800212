import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';

export const Wrapper = styled('div', {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'local',
})(
  {
    zIndex: 1099,
  },
  ({
    fixed = false,
    left = 0,
    local,
    topRect = 0,
    bottomRect = 0,
    css,
    top,
    bottom,
  }) => ({
    position: fixed ? 'fixed' : 'absolute',
    left: local ? 0 : left,
    ...(top
      ? {
          bottom: local ? 'calc(100% - 25px)' : window.innerHeight - bottomRect,
        }
      : {}),
    ...(bottom ? {top: local ? '100%' : topRect} : {}),
    ...css,
  }),
);
