import styled from '@emotion/styled';

import Box from '@renofi/components-internal/src/Box';
import Flex from '@renofi/components-internal/src/Flex';
import {
  actionLight,
  basic75,
  basic80,
  white,
  black,
  actionExtraLight,
} from '@renofi/theme/src/colors';
import ArrowIcon from '@renofi/icons/src/Arrow';

export const ArrowIconBox = styled(Box)(
  {
    width: 40,
    height: 29,
    borderLeftWidth: 1,
    borderLeftStyle: 'solid',
    pointerEvents: 'all',
  },
  ({disabled}) => ({
    borderLeftColor: disabled
      ? 'rgba(0, 0, 0, 0.1)'
      : 'rgba(255, 255, 255, 0.3)',
    pointerEvents: disabled ? 'none' : 'all',
  }),
);

export const Arrow = styled(ArrowIcon)(({active}) => ({
  ...(active ? {transform: 'rotate(-180deg)'} : {}),
}));

export const Container = styled(Flex)(
  {
    width: 320,
    position: 'absolute',
    left: 0,
    zIndex: 2,
    flexDirection: 'column',
    background: white,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)',
    borderRadius: 4,
    padding: '8px 0',
    marginBottom: 4,
  },
  ({position, variant}) => ({
    ...(position === 'top' ? {bottom: '100%'} : {}),
    ...(position === 'bottom' ? {top: '100%', marginTop: 8} : {}),
    ...(variant === 'primary'
      ? {background: basic80, border: `1px solid ${basic75}`}
      : {background: white, border: '1px solid transparent'}),
  }),
);

export const Item = styled(Flex)(
  {
    alignItems: 'center',
    height: 40,
    width: '100%',
    ':last-of-type': {
      borderBottom: 'none',
    },
    cursor: 'pointer',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  ({variant, disabled}) => ({
    borderBottomColor: variant === 'primary' ? basic75 : 'transparent',
    color: variant === 'primary' ? actionLight : basic80,

    ':hover': {
      color: variant === 'primary' ? basic80 : black,
      backgroundColor: variant === 'primary' ? actionLight : actionExtraLight,
    },
    ...(disabled
      ? {pointerEvents: 'none', color: 'rgba(0,0,0,0.2)'}
      : {pointerEvents: 'all'}),
  }),
);
