import * as React from 'react';

import PropTypes from 'prop-types';

const SvgInterior = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.552 10.05a.297.297 0 0 0 .406-.1.29.29 0 0 0-.104-.404l-6.699-3.985a.298.298 0 0 0-.305 0L5.145 9.546a.294.294 0 1 0 .305.504l1.037-.615v8.113c0 .162.134.294.297.294h10.432a.295.295 0 0 0 .297-.294V9.433l1.039.617z"
        fill={color}
      />
      <path
        d="m18.552 10.05-.052.086.052-.086zm.406-.1.085.051-.085-.051zm-.104-.404-.051.086.002.001.049-.087zm-6.699-3.985.051-.086-.05.086zm-.305 0-.05-.086h-.001l.05.086zM5.145 9.546l-.05-.086h-.001l.051.086zm-.103.404.085-.052-.085.052zm.408.1.051.087-.05-.087zm1.037-.615h.1v-.176l-.151.09.05.086zm11.026-.002.05-.086-.15-.09v.176h.1zm1.19.56a.2.2 0 0 1-.1-.029l-.102.173a.402.402 0 0 0 .202.055v-.2zm.17-.095a.197.197 0 0 1-.17.094v.2a.397.397 0 0 0 .34-.19l-.17-.104zm-.068-.265a.19.19 0 0 1 .067.265l.171.103a.39.39 0 0 0-.14-.542l-.098.174zm-6.701-3.986 6.699 3.985.102-.172-6.699-3.985-.102.172zm-.204 0a.198.198 0 0 1 .204 0l.102-.172a.398.398 0 0 0-.407 0l.101.172zM5.196 9.632l6.705-3.985-.102-.172L5.094 9.46l.102.172zm-.07.265a.194.194 0 0 1 .07-.265l-.102-.171a.394.394 0 0 0-.138.54l.17-.104zm.274.067a.199.199 0 0 1-.273-.066l-.171.103a.399.399 0 0 0 .545.136L5.4 9.964zm1.036-.615-1.037.615.103.172 1.036-.615-.102-.172zm.15 8.199V9.435h-.2v8.113h.2zm.198.194a.196.196 0 0 1-.197-.194h-.2c0 .219.18.394.397.394v-.2zm10.432 0H6.784v.2h10.432v-.2zm.197-.194a.195.195 0 0 1-.197.194v.2c.22 0 .397-.176.397-.394h-.2zm0-8.115v8.115h.2V9.433h-.2zm1.19.531-1.04-.617-.101.172 1.038.617.103-.172z"
        fill={color}
      />
    </svg>
  );
};

SvgInterior.propTypes = {
  color: PropTypes.string,
};
export default SvgInterior;
