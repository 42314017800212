import * as React from 'react';

import PropTypes from 'prop-types';

const SvgThumbsDown = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={22}
      height={20}
      viewBox="0 0 22 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-1-2h24v24H-1z" />
        <path
          d="M14 0H5c-.83 0-1.54.5-1.84 1.22L.14 8.27C.05 8.5 0 8.74 0 9v1.91l.01.01L0 11c0 1.1.9 2 2 2h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L8.83 20l6.59-6.59c.36-.36.58-.86.58-1.41V2c0-1.1-.9-2-2-2zm4 0v12h4V0h-4z"
          fill={color}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

SvgThumbsDown.propTypes = {
  color: PropTypes.string,
};
export default SvgThumbsDown;
