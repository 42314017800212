import {gql} from '@apollo/client';

export default gql`
  mutation unassignPermissions($userId: ID!, $permissions: [String!]!) {
    unassignPermissions(userId: $userId, permissions: $permissions) {
      user {
        id
        email
        firstName
        lastName
        permissions {
          id
          key
        }
      }
    }
  }
`;
