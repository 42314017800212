import React from 'react';

import PropTypes from 'prop-types';

import {basic15} from '@renofi/theme/src/colors';

const HelpClinic = ({color, width}) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="help_clinic">
        <mask
          id="mask0_291_22513"
          style={{maskType: 'alpha'}}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width={width}
          height={width}>
          <rect id="Bounding box" width={width} height={width} fill={color} />
        </mask>
        <g mask="url(#mask0_291_22513)">
          <path
            id="help_clinic_2"
            d="M9.16683 14.1667H10.8335V10H9.16683V14.1667ZM10.0002 8.33333C10.2363 8.33333 10.4342 8.25347 10.5939 8.09375C10.7536 7.93403 10.8335 7.73611 10.8335 7.5C10.8335 7.26389 10.7536 7.06597 10.5939 6.90625C10.4342 6.74653 10.2363 6.66667 10.0002 6.66667C9.76405 6.66667 9.56614 6.74653 9.40641 6.90625C9.24669 7.06597 9.16683 7.26389 9.16683 7.5C9.16683 7.73611 9.24669 7.93403 9.40641 8.09375C9.56614 8.25347 9.76405 8.33333 10.0002 8.33333ZM3.3335 17.5V7.5L10.0002 2.5L16.6668 7.5V17.5H3.3335ZM5.00016 15.8333H15.0002V8.33333L10.0002 4.58333L5.00016 8.33333V15.8333Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

HelpClinic.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
};

HelpClinic.defaultProps = {
  color: basic15,
  width: 20,
};

export default HelpClinic;
