import {gql} from '@apollo/client';

import {gcddDocument} from '../../partials';

export default gql`
  mutation updateLicense($expiryDate: Date, $id: ID!, $namesMatch: Boolean, $number: String, $state: String) {
    updateLicense(expiryDate: $expiryDate, id: $id, namesMatch: $namesMatch, number: $number, state: $state) {
      license {
        ${gcddDocument}
        number
        state
      }
    }
  }
`;
