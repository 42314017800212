import PropTypes from 'prop-types';

import {basic20} from '@renofi/theme';

const SvgDots = ({color = basic20, size = 24, ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}>
      <defs>
        <path
          d="M2 10a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-6a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 12a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
          id="dots_svg__a"
        />
        <path id="dots_svg__c" d="M0 0h24v24H0z" />
      </defs>
      <g transform="translate(10 4)" fill="none" fillRule="evenodd">
        <mask id="dots_svg__b" fill="#fff">
          <use xlinkHref="#dots_svg__a" />
        </mask>
        <g mask="url(#dots_svg__b)">
          <use fill={color} xlinkHref="#dots_svg__c" />
        </g>
      </g>
    </svg>
  );
};

SvgDots.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

export default SvgDots;
