import styled from '@emotion/styled';

import Flex from '../Flex';

export const Wrapper = styled(Flex)(
  {
    minWidth: 100,
    height: 50,
    borderRadius: 4,
    alignItems: 'center',
    fontSize: 15,
    lineHeight: '18px',
    color: '#6C757D',
  },
  ({active, disabled, small, height, hasHelpLabel}) => ({
    border: active
      ? `2px solid ${disabled ? '#CED4DA' : '#3B88FD'}`
      : '1px solid #CED4DA',
    backgroundColor: disabled ? '#F8F8F8' : active ? '#F1F9FB' : 'white',
    padding: active ? 15 : 16,
    height: hasHelpLabel ? 'auto' : small ? 36 : height || 50,
    alignItems: hasHelpLabel ? 'baseline' : 'center',
    cursor: disabled ? 'not-allowed' : 'pointer',
  }),
);

export const LabelWrapper = styled(Flex)(({hasHelpLabel}) => ({
  flexDirection: hasHelpLabel ? 'column' : 'row',
}));
