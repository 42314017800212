import {gql} from '@apollo/client';

export default gql`
  mutation submitGovernmentIssuedIdInformation(
    $taskId: ID!
    $govIdDetails: GovernmentIssuedIdInputObject!
  ) {
    submitGovernmentIssuedIdInformation(
      taskId: $taskId
      governmentIssuedId: $govIdDetails
    ) {
      governmentIssuedId {
        taskId
        id
        idNumber
        idType
        issuingCountry
        issuingState
        issueDate
        expirationDate
      }
    }
  }
`;
