import {gql} from '@apollo/client';

export default gql`
  mutation submitAppraisalOrderFinalInspection(
    $amcCode: String
    $details: AppraisalOrderForm1004dInputObject!
    $documentIds: [ID!]!
    $previousAppraisalOrderId: ID
    $projectId: ID
    $propertyDetails: PropertyDetailsInputObject
  ) {
    submitAppraisalOrderFinalInspection(
      amcCode: $amcCode
      details: $details
      documentIds: $documentIds
      previousAppraisalOrderId: $previousAppraisalOrderId
      projectId: $projectId
      propertyDetails: $propertyDetails
    ) {
      appraisalOrder {
        id
        acceptedAt
        amc {
          code
          hasApi
          name
        }
        amcCode
        appraisalReportDocuments {
          id
          amcFilename
          contentType
          createdAt
          documentType
          objectName
          updatedAt
        }
        cancellationReason
        cancelledByAmcAt
        cancelledByRenofiAt
        contactEmail
        contactFirstName
        contactHomePhone
        contactLastName
        contactWorkPhone
        createdAt
        createdById
        documentIds
        formType
        homeownerName
        inspectionAppointmentAt
        inspectionCompletedAt
        loanAmount
        noteForAppraiser
        onHoldComments
        orderAmcId
        postRenovationHomeValue
        projectId
        propertyAddress
        propertyCity
        propertyCounty
        propertyPostalCode
        propertyType
        propertyState
        putOnHoldAt
        rejectedAt
        rejectionReason
        reportReceivedAt
        resumeComments
        resumedFromHoldAt
        status
        updatedAt
      }
    }
  }
`;
