import * as React from 'react';

import PropTypes from 'prop-types';

import {basic20} from '@renofi/theme';

const SvgPaperclip = ({color, width, ...props}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={width}
      height={width}
      {...props}>
      <path
        d="M3.6 12.6c-1.4-1.4-1.4-3.8 0-5.2l4.9-4.9c1-1 2.7-1 3.8 0 1 1 1 2.7 0 3.8l-4 4c-.7.7-1.7.7-2.4 0s-.7-1.7 0-2.4l3.5-3.5.9.9-3.4 3.6c-.3.2.2.7.4.4l4-4c.5-.5.5-1.4 0-1.9s-1.4-.5-1.9 0l-4.9 5c-.9.9-.9 2.4 0 3.3.9.9 2.4.9 3.3 0l4.5-4.5.9.9-4.5 4.5C7.3 14 5 14 3.6 12.6z"
        fill={color}
      />
    </svg>
  );
};

SvgPaperclip.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
};

SvgPaperclip.defaultProps = {
  color: basic20,
  width: 32,
};

export default SvgPaperclip;
