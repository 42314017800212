import {pick} from 'ramda';

import {LAYOUT_KEYS, MARGIN_KEYS, PADDING_KEYS, STYLE_KEYS} from './constants';

export const getLayoutProps = pick([
  ...LAYOUT_KEYS,
  ...MARGIN_KEYS,
  ...PADDING_KEYS,
  ...STYLE_KEYS,
]);
