import styled from '@emotion/styled';

import {maxSmall} from '@renofi/theme/src/breakpoints';
import {renofiBlue} from '@renofi/theme/src/colors';

import Card from '../Card';

export const StyledCard = styled(Card)({
  padding: '8px',
  '&:hover': {
    boxShadow: '0px 4px 20px 0px #00000026',
    transition: 'all 200ms ease',
  },
  [maxSmall]: {
    padding: 'unset',
  },
});

export const StyledTitle = styled.span({
  display: 'block',
  marginBottom: '24px',
  color: '#333333',
  fontWeight: 700,
  fontSize: '24px',
});

export const StyledContent = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [maxSmall]: {
    flexDirection: 'column',
    rowGap: '21px',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
});

export const StyledContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
});
export const StyledInfo = styled.div({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '5px',
  [maxSmall]: {
    rowGap: '10px',
  },
});
export const IconContainer = styled.span({
  width: '60px',
  height: '60px',
  background: renofiBlue,
  borderRadius: '50%',
  marginRight: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  svg: {
    width: '60px',
  },
});

export const ButtonsSection = styled.div({
  display: 'flex',
  columnGap: '16px',
  justifyContent: 'flex-end',
  [maxSmall]: {
    flexDirection: 'column',
    rowGap: '8px',
    marginTop: 16,
    width: '100%',
  },
});
export const StyledDescription = styled.span({
  fontSize: '16px',
  color: '#737373',
});
export const StyledName = styled.span({
  fontSize: '20px',
  color: '#212F49',
});
export const StyledButton = styled.button({
  padding: '10px 32px',
  border: ' 1px solid rgba(51, 51, 51, 0.1)',
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
  borderRadius: '8px',
  fontSize: '16px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: '#fff',
  cursor: 'pointer',
  justifyContent: 'space-between',
  span: {
    fontSize: '16px',
    color: '#163551',
  },
  '&:hover': {
    background: '#f6f6f6',
  },
  '&:active': {
    background: '#e8e8e8',
  },
  [maxSmall]: {
    width: '100%',
    flexDirection: 'row',
    padding: '20px',
    justifyContent: 'flex-start',
    columnGap: '30px',
    borderRadius: '4px',
    span: {
      fontSize: '18px',
    },
  },
});
