import React, {Fragment, useMemo} from 'react';

import PropTypes from 'prop-types';
import {assoc, isEmpty, propEq, propOr, reject} from 'ramda';

import {Flex} from '@renofi/components-internal';
import {getFormattedAddress} from '@renofi/utilities/src/data';
import formatMoney from '@renofi/utilities/src/formatMoney';
import getYesNoFromBool from '@renofi/utilities/src/getYesNoFromBool';
import {formatDate} from '@renofi/utilities/src/dates';
import noop from '@renofi/utilities/src/noop';

import {Label, Value} from '../styled';

import {getCurrentResidence, getResidenceDuration} from './utils';

const ResidenceHistory = ({coborrower, data, onValueClick}) => {
  const dataKey = coborrower
    ? 'coborrowerResidentialAddresses'
    : 'borrowerResidentialAddresses';
  const mailingKey = coborrower
    ? 'coborrowerMailingAddress'
    : 'borrowerMailingAddress';
  const addresses = propOr([], dataKey, data);
  const mailingAddress = propOr({}, mailingKey, data);

  const [currentAddress, otherAddresses] = useMemo(() => {
    // If there's only one address, assume it's "current"
    // force currentAddress:true, as new Projects ingest from
    // borrowersAndPropertyInformation to seed data - but sets currentAddress: false.
    const current = getCurrentResidence(addresses);
    if (addresses?.length === 1) {
      return [assoc('currentAddress', true, current), []];
    }

    return [current, reject(propEq('id', current?.id), addresses)];
  }, [addresses]);
  const {duration: currentDuration} = getResidenceDuration(currentAddress);
  const sharesAddress =
    coborrower &&
    addresses.some(
      ({coborrowerSharesSameAddressAndExpenses}) =>
        coborrowerSharesSameAddressAndExpenses,
    );

  const onClick = () => {
    onValueClick(coborrower);
  };

  return (
    <Fragment>
      {coborrower ? (
        <Flex flexWrap="wrap">
          <Label>Shares address and expenses as Primary borrower:</Label>
          <Value onClick={onClick}>{getYesNoFromBool(sharesAddress)}</Value>
        </Flex>
      ) : null}

      <Flex flexWrap="wrap">
        <Label>Current address:</Label>
        <Value onClick={onClick}>{getFormattedAddress(currentAddress)}</Value>

        <Label>Housing type:</Label>
        <Value onClick={onClick}>{currentAddress?.housingType || '-'}</Value>

        <Label>Monthly expense:</Label>
        <Value onClick={onClick}>
          {formatMoney(currentAddress?.monthlyExpenses, {
            defaultValue: '-',
            fractional: false,
          })}
        </Value>

        <Label>Start date:</Label>
        <Value onClick={onClick}>
          {formatDate(currentAddress?.residenceFrom)}
        </Value>
      </Flex>

      <Flex flexWrap="wrap">
        <Label>Residence total:</Label>
        <Value onClick={onClick}>{currentDuration}</Value>
      </Flex>

      {otherAddresses.map((address, index) => {
        const {duration} = getResidenceDuration(address);
        return (
          <Fragment key={address.id}>
            <Flex flexWrap="wrap">
              <Label>Former address {index + 1}:</Label>
              <Value onClick={onClick}>{getFormattedAddress(address)}</Value>

              <Label>Housing type:</Label>
              <Value onClick={onClick}>{address?.housingType || '-'}</Value>

              <Label>Monthly expense:</Label>
              <Value onClick={onClick}>
                {formatMoney(address?.monthlyExpenses, {
                  defaultValue: '-',
                  fractional: false,
                })}
              </Value>

              <Label>Start date:</Label>
              <Value onClick={onClick}>
                {formatDate(address?.residenceFrom)}
              </Value>

              {Boolean(address?.residenceTo) ? (
                <Fragment>
                  <Label>End date:</Label>
                  <Value onClick={onClick}>
                    {formatDate(address?.residenceTo)}
                  </Value>
                </Fragment>
              ) : null}
            </Flex>
            <Flex flexWrap="wrap">
              <Label>Residence total:</Label>
              <Value onClick={onClick}>{duration}</Value>
            </Flex>
          </Fragment>
        );
      })}
      <Flex flexWrap="wrap">
        <Label>Mailing address:</Label>
        <Value onClick={onClick}>
          {isEmpty(mailingAddress)
            ? 'Same as current address'
            : getFormattedAddress(mailingAddress)}
        </Value>
      </Flex>
    </Fragment>
  );
};

ResidenceHistory.propTypes = {
  coborrower: PropTypes.bool,
  data: PropTypes.object,
  onValueClick: PropTypes.func,
};

ResidenceHistory.defaultProps = {
  onValueClick: noop,
};

export default ResidenceHistory;
