import * as React from 'react';

import PropTypes from 'prop-types';

import {basic20} from '@renofi/theme/src/colors';

const SvgExternal = ({color = basic20, title, width = 24, ...props}) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      {title && <title>{title}</title>}
      <path
        d="M19 19H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

SvgExternal.propTypes = {
  color: PropTypes.string,
  title: PropTypes.node,
  width: PropTypes.number,
};

export default SvgExternal;
