import Pusher from 'pusher-js';
import {identity, memoizeWith} from 'ramda';

import logger from '@renofi/utilities/src/logger';
import noop from '@renofi/utilities/src/noop';

const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY || '';

const getPusher = memoizeWith(
  identity,
  (pusherKey) =>
    new Pusher(pusherKey, {
      cluster: 'mt1',
    }),
);

export const isSubscribed = (channelId) => {
  const pusher = getPusher(PUSHER_KEY);
  return Boolean(pusher?.channel(channelId));
};

export const subscribe = (channelId, handler = noop) => {
  logger.debug(`Subscribing to channel: ${channelId}`);
  const pusher = getPusher(PUSHER_KEY);
  if (isSubscribed(channelId)) {
    return pusher.channel(channelId);
  }

  const channel = pusher.subscribe(channelId);
  channel.bind('update', handler);
  return channel;
};

export const unsubscribe = (channelId) => {
  logger.debug(`Unsubscribing from channel: ${channelId}`);
  const pusher = getPusher(PUSHER_KEY);
  const channel = pusher.channel(channelId);
  if (channel) {
    channel.unbind();
    pusher.unsubscribe(channelId);
  }
};
