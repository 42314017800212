import React, {Fragment, useMemo} from 'react';

import PropTypes from 'prop-types';
import {Tooltip as ReactTooltip} from 'react-tooltip';

import {basic75} from '@renofi/theme/src/colors';

const Tooltip = ({
  content,
  children,
  type = 'dark',
  triggerEvent,
  place = 'bottom',
  elementCss = {},
  tag: Tag = 'a',
  ...props
}) => {
  const randomId = useMemo(() => {
    if (process.env.NODE_ENV === 'test') {
      return 'jest-id';
    }

    return String(Math.random());
  }, []);

  return (
    <Fragment>
      <Tag
        data-tooltip-id={`tooltip-${randomId}`}
        style={elementCss}
        data-event={triggerEvent}>
        {children}
      </Tag>
      {content ? (
        <ReactTooltip
          id={`tooltip-${randomId}`}
          padding="10px"
          backgroundColor={basic75}
          type={type}
          place={place}
          globalEventOff="click"
          effect="solid"
          isCapture
          {...props}
          style={{zIndex: 10005, ...props.style}}>
          {content}
        </ReactTooltip>
      ) : null}
    </Fragment>
  );
};

Tooltip.propTypes = {
  place: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  content: PropTypes.node,
  children: PropTypes.node,
  type: PropTypes.string,
  trigger: PropTypes.string,
  triggerEvent: PropTypes.string,
  elementCss: PropTypes.object,
  tag: PropTypes.string,
};

export default Tooltip;
