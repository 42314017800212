import {gql} from '@apollo/client';

export default gql`
  query missingRenovationUpdates {
    missingRenovationUpdates {
      firstReminderSentAt
      lastUpdatePostedAt
      project {
        borrower {
          email
          firstName
          id
          lastName
        }
        homeowners {
          email
          firstName
          id
          lastName
        }
        primaryApplicantId
        expectedRenovationCompletionOn
        expectedRenovationStart
        id
        renovationCompletedOn
        renovationStartedOn
        salesforceOpportunityId
      }
      renovationProgress
      secondReminderSentAt
    }
  }
`;
