import {useContext, useMemo} from 'react';

import {Context as StorageContext} from '@renofi/utilities/src/storage';

import isImpersonation from '../isImpersonation';

export default function useIsImpersonation() {
  const storage = useContext(StorageContext);

  return useMemo(() => isImpersonation(storage), []);
}
