import styled from '@emotion/styled';

import {maxSmall} from '@renofi/theme/src/breakpoints';

import Drawer from '../Drawer';

export const StyledDrawer = styled(Drawer)({
  padding: '7px',
  borderRight: '1px solid #d9e7f0',
  boxShadow: 'unset',
  [maxSmall]: {
    width: '100%',
    padding: 0,
    borderRight: 'unset',
  },
});

export const ContentWrapper = styled.div({
  width: '100%',
  height: '100%',
  position: 'relative',
});

export const LogoSection = styled.div({
  padding: '20px 25px',
  display: 'flex',
  alignItems: 'center',
  background: '#fff',
  [maxSmall]: {
    padding: '23px 20px',
    borderBottom: '1px solid rgba(0, 96, 153, 0.15)',
    position: 'fixed',
    width: '100%',
    left: 0,
    height: '70px',
  },
});

export const CloseIcon = styled.span({
  display: 'none',
  [maxSmall]: {
    display: 'flex',
    marginRight: '28px',
    cursor: 'pointer',
  },
});

export const SideMenuButton = styled.span({
  display: 'none',
  [maxSmall]: {
    display: 'flex',
    marginRight: '28px',
    cursor: 'pointer',
  },
});
