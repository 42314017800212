export const getDateWithoutOffset = (date) => {
  const userOffset = date.getTimezoneOffset();

  if (userOffset >= 0) {
    return new Date(date.getTime() + userOffset * 60000);
  }

  return new Date(date.getTime() - userOffset * 60000);
};

export const getUtcDate = (value) => {
  if (!value) {
    return null;
  }

  if (value instanceof Date) {
    const date = getDateWithoutOffset(value);
    return date;
  }

  const date = new Date(value);

  const utcDate = date.getUTCDate();
  const utcMonth = date.getUTCMonth();
  const utcYear = date.getFullYear();

  date.setFullYear(utcYear, utcMonth, utcDate);

  return date;
};
