import * as React from 'react';

import PropTypes from 'prop-types';

const SvgPhone = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.923 1.334h-9.846C9.378 1.334 8 2.827 8 4.667v22.667c0 1.84 1.378 3.333 3.077 3.333h9.846c1.699 0 3.077-1.493 3.077-3.333V4.667c0-1.84-1.378-3.333-3.077-3.333zm-4.923 28c-1.021 0-1.846-.893-1.846-2s.825-2 1.846-2c1.021 0 1.846.893 1.846 2s-.825 2-1.846 2zm-5.539-5.333h11.077V5.334H10.461v18.667z"
        fill={color}
      />
    </svg>
  );
};

SvgPhone.propTypes = {
  color: PropTypes.string,
};
export default SvgPhone;
