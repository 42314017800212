import {gql} from '@apollo/client';

export default gql`
  query lenderReport($lenderId: ID!) {
    lenderReport(lenderId: $lenderId) {
      countLoans
      countMembers
      renovationReadyFilesCompletedByDay {
        forecast
        periodStart
        periodUnit
        value
      }
      renovationReadyFilesCompletedByMonth {
        forecast
        periodStart
        periodUnit
        value
      }
      renovationReadyFilesReceivedByDay {
        forecast
        periodStart
        periodUnit
        value
      }
      renovationReadyFilesReceivedByMonth {
        forecast
        periodStart
        periodUnit
        value
      }
      renovationsCompletedByDay {
        forecast
        periodStart
        periodUnit
        value
      }
      renovationsCompletedByMonth {
        forecast
        periodStart
        periodUnit
        value
      }
      rrfsApproved
      rrfsCompleted
      rrfsPendingDecision
      rrfsRenovating
      sumLoanAmounts
    }
  }
`;
