import {gql} from '@apollo/client';

import {
  correspondenceMessages,
  gcddDocument,
  applicant,
  references,
} from '../../partials';

export default gql`
  query gcddReviewById($id: ID!) {
    gcddReviewById(id: $id) {
      assignedToId
      assignedAt
      assignedTo {
        email
        firstName
        id
        lastName
        phoneNumber
      }
      borrower {
        email
        firstName
        id
        lastName
        phoneNumber
      }
      borrowerId
      contractor {
        bbbCheckedAt
        bbbGrade
        bbbListed
        businessName
        id
        insurances {
          ${gcddDocument}
          limitAggregate
          limitEachOccurrence
          limitWorkersCompensation
          workersCompDetailsAvailableInGeneralLiability
        }
        licenses {
          ${gcddDocument}
          number
          state
        }
      }
      correspondenceMessages {
        ${correspondenceMessages}
      }
      projectId
      project {
        city
        estimatedCloseDate
        id
        renovationCost
        renovationCostToCurrentPropertyValueRatio
        renovationScope
        state
        status
        streetAddressOne
        streetAddressTwo
        primaryApplicant {
          ${applicant}
        }
        homeowners {
          email
          firstName
          id
          lastName
          phoneNumber
        }
        primaryApplicantId
        tasks {
          id
          contractorInformations {
            email
            id
            removedAt
          }
          taskType
        }
        timeline {
          id
          expectedRenovationCompletionOn
          expectedRenovationStart
          renovationCompletedOn
        }
        zipCode
      }
      cancelledAt
      cancellationMessage
      cancellationReason
      completedAt
      complexityLevel
      contractorInformationId
      overallScore
      overallScoreNotes
      insuranceNotes
      referencesNotes
      webReviewsNotes
      licenseNotes
      priority
      estimatedCostOfWork
      scopeOfWork
      completedAt
      id
      questionnaire {
        avgNumberOfRenovationsPerYear
        avgPriceOfProjects
        bankruptcyDetails
        borrowerReminderSentAt
        businessName
        businessType
        businessTypeOtherDetails
        city
        contactFirstName
        contactLastName
        contactTitle
        contractorGeneralLiabilityInsurance {
          ${gcddDocument}
          limitAggregate
          limitEachOccurrence
          limitWorkersCompensation
          workersCompDetailsAvailableInGeneralLiability
        }
        contractorLicense {
          ${gcddDocument}
          number
          state
        }
        contractorReminderSentAt
        contractorWorkersCompInsurance {
          ${gcddDocument}
          limitAggregate
          limitEachOccurrence
          limitWorkersCompensation
        }
        createdAt
        email
        firstOpenedAt
        hasBeenInvolvedInLawsuit
        hasEverFiledForBankruptcy
        hasLicense
        hasTaxLiens
        hasW2Employees
        hasWorkersCompInsurance
        id
        involvedInLawsuitDetails
        lastSentAt
        licenseNumber
        noInsuranceReasons
        noInsuranceReasonsOther
        noLicenseReasons
        noLicenseReasonsOther
        numberOfCompletedRenovations
        officePhoneNumber
        percentageBusinessWithCommercialInLast2y
        percentageBusinessWithResidentialInLast2y
        percentageCompletedRenovationsAmountRanges
        percentageLeadsNeedingExpertDesign
        percentageOfCompletedRenovationsIn30PercentAmountRange
        percentageSalesSpentOnMarketing
        phoneNumber
        propertyState
        references {
          ${references}
        }
        residentialHomeRemodelBlueprintingOption
        residentialHomeRemodelBlueprintingOptionOther
        residentialHomeRemodelRole
        residentialHomeRemodelRoleOther
        returnedAt
        reviewedAt
        sendingPdfRequestedAt
        secondaryAddress
        signatoryBusinessName
        signatoryName
        signatoryTitle
        remindersEnabled
        signatoryType
        signatureDate
        state
        streetAddress
        submittedStep
        subsidaries
        taxLiensDetails
        teamSize
        typicalRenovationProjects
        typicalRenovationProjectsOtherDetails
        updatedAt
        website
        workersCompIncludedInGlid
        yearsAsAContractor
        zipCode
      }
      returnedAt
      webReviewsNotFound
      webReviewsScore
      webReviewsScoreNotes
      webReviewsScoreSubmittedAt
      webReviewsScoreSubmittedById
    }
  }
`;
