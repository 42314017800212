import React from 'react';

import PropTypes from 'prop-types';

import {basic20} from '@renofi/theme/src/colors';

const UnorderedList = ({color, width, ...props}) => {
  const viewBox = `0 0 ${width} ${width}`;

  return (
    <svg
      width={width}
      height={width}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M4 11.5C3.17 11.5 2.5 12.17 2.5 13C2.5 13.83 3.17 14.5 4 14.5C4.83 14.5 5.5 13.83 5.5 13C5.5 12.17 4.83 11.5 4 11.5ZM4 5.5C3.17 5.5 2.5 6.17 2.5 7C2.5 7.83 3.17 8.5 4 8.5C4.83 8.5 5.5 7.83 5.5 7C5.5 6.17 4.83 5.5 4 5.5ZM4 17.5C3.17 17.5 2.5 18.18 2.5 19C2.5 19.82 3.18 20.5 4 20.5C4.82 20.5 5.5 19.82 5.5 19C5.5 18.18 4.83 17.5 4 17.5ZM7 20H21V18H7V20ZM7 14H21V12H7V14ZM7 6V8H21V6H7Z"
        fill={color}
      />
    </svg>
  );
};

UnorderedList.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
};

UnorderedList.defaultProps = {
  color: basic20,
  width: 24,
};

export default UnorderedList;
