import React from 'react';

import PropTypes from 'prop-types';

import {actionRegular} from '@renofi/theme';

import {Wrapper, Item, Badge} from './styled';

const Tabs = ({
  active,
  activeColor = actionRegular,
  items,
  onChange,
  ...props
}) => (
  <Wrapper {...props}>
    {items.map(({label, value, badge = null, badgeVariant = null}) => (
      <Item
        data-testid={`tab-${value}`}
        key={value}
        activeColor={activeColor}
        active={active === value}
        onClick={() => onChange(value)}>
        {label}
        {badge && <Badge variant={badgeVariant}>{badge}</Badge>}
      </Item>
    ))}
  </Wrapper>
);

Tabs.propTypes = {
  active: PropTypes.string,
  activeColor: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      badge: PropTypes.node,
      badgeVariant: PropTypes.string,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Tabs;
