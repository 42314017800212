import {gql} from '@apollo/client';

export default gql`
  mutation updatePersonalInfoAndResidency(
    $taskId: ID!
    $personalInfo: PersonalInfoInputObject!
  ) {
    updatePersonalInfoAndResidency(
      taskId: $taskId
      personalInfo: $personalInfo
    ) {
      success
    }
  }
`;
