import React from 'react';

import PropTypes from 'prop-types';

import {useTheme} from '@renofi/theme/src';
import InfoIcon from '@renofi/icons/src/Info';
import NewInfoIcon from '@renofi/icons/src/InfoIcon';
import CheckIcon from '@renofi/icons/src/CheckCircle';
import SatisfiedSmile from '@renofi/icons/src/SatisfiedSmile';
import UnsatisfiedSmile from '@renofi/icons/src/DissatisfiedSmile';

import Alert from './Alert';

const AlertWithIcon = ({
  children,
  variant,
  icon = variant === 'success' ? 'check' : 'info',
  ...props
}) => {
  const {styles} = useTheme('alert', {
    defaultVariant: variant || 'info',
    ...props,
  });

  return (
    <Alert variant={variant} {...props}>
      {icon === 'check' && (
        <CheckIcon css={{flex: 'none'}} color={styles.color} />
      )}
      {icon === 'info' && (
        <InfoIcon css={{flex: 'none'}} color={styles.color} />
      )}
      {icon === 'newInfo' && (
        <NewInfoIcon css={{flex: 'none'}} color={styles.color} />
      )}
      {icon === 'sentiment_satisfied' && (
        <SatisfiedSmile css={{flex: 'none'}} />
      )}
      {icon === 'sentiment_dissatisfied' && (
        <UnsatisfiedSmile css={{flex: 'none'}} />
      )}
      <div css={{paddingLeft: 15, flexGrow: 1}}>{children}</div>
    </Alert>
  );
};

AlertWithIcon.propTypes = {
  block: PropTypes.bool,
  icon: PropTypes.string,
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
};

export default AlertWithIcon;
