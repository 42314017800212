import React from 'react';

import PropTypes from 'prop-types';

import {basic15} from '@renofi/theme/src/colors';

const DEFAULT_WIDTH = 16;

function ClockInverse({color, width}) {
  const viewBox = `0 0 ${width} ${width}`;
  return (
    <svg
      width={width}
      height={width}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM11.36 11.36L7.2 8.8V4H8.4V8.16L12 10.32L11.36 11.36Z"
        fill={color}
      />
    </svg>
  );
}

ClockInverse.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
};

ClockInverse.defaultProps = {
  color: basic15,
  width: DEFAULT_WIDTH,
};

export default ClockInverse;
