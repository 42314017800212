import {createElement, useMemo} from 'react';

import {map} from 'ramda';

import isFunction from '@renofi/utilities/src/isFunction';

export default (params) => (BaseComponent) => {
  return (props) => {
    const converted = useMemo(() => {
      const handlers = isFunction(params) ? params(props) : params;

      return map((handler) => {
        return (...args) => handler(props)(...args);
      }, handlers);
    }, [params, props]);

    return createElement(BaseComponent, {
      ...props,
      ...converted,
    });
  };
};
