import styled from '@emotion/styled';

import {actionRegular, basic20} from '@renofi/theme/src/colors';

import Box from '../Box';
import Flex from '../Flex';
import Text from '../Text';

export const Wrapper = styled(Flex)({
  flexDirection: 'column',
  width: '100%',
});

export const Label = styled(Text)({
  fontSize: 14,
  lineHeight: '17px',
  marginBottom: 12,
  color: '#999999',
});

export const Container = styled(Flex)(
  {
    justifyContent: 'space-between',
  },
  ({size}) => ({
    width: size * 22 + (size - 1) * 2,
  }),
);

export const Bar = styled(Box)(
  {
    width: 22,
    height: 3,
    ':first-of-type': {
      borderRadius: '3px 0 0 3px',
    },
    ':last-of-type': {
      borderRadius: '0 3px 3px 0',
    },
  },
  ({active}) => ({
    backgroundColor: active ? actionRegular : basic20,
  }),
);
