export default `
contentType
contractorId
createdAt
documentType
expiryDate
fileName
fileSizeBytes
id
namesMatch
objectName
pdfObjectName
rejectedAt
rejectedBySystem
rejectionDetails
rejectionReason
reviewResult
reviewedAt
reviewedById
source
submittedById
updatedAt
`;
