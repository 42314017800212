import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useToggledQuery from '../../useToggledQuery';
import {GET_RENOVATION_READY_FILES} from '../../../queries';

export default function useLenderRenovationReadyFiles({
  fetchPolicy = 'cache-first',
  lazy = false,
  onCompleted = noop,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_RENOVATION_READY_FILES,
    options: {
      fetchPolicy,
      onCompleted,
      ...options,
    },
  });
  const {data, error, loading, fetch} = response;

  const renovationReadyFiles = pathOr(
    [],
    ['lenderRenovationReadyFiles', 'collection'],
    data,
  );
  const metadata = pathOr(
    null,
    ['lenderRenovationReadyFiles', 'metadata'],
    data,
  );

  return {
    fetch,
    renovationReadyFiles,
    metadata,
    loading,
    error,
  };
}
