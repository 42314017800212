import {gql} from '@apollo/client';

import {appraisalComparableSaleUrl} from '../../partials';

export default gql`
  mutation setAppraisalComparableSaleUrlShareable($id: ID!, $shareable: Boolean!) {
    setAppraisalComparableSaleUrlShareable(id: $id, shareable: $shareable) {
      appraisalComparableSaleUrl {
        ${appraisalComparableSaleUrl}
      }
    }
  }
`;
