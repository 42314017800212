import styled from '@emotion/styled';

import {
  actionExtraLight,
  actionRegular,
  basic03,
  basic55,
  border,
  white,
} from '@renofi/theme/src/colors';

import Flex from '../Flex';
import Text from '../Text';

export const Wrapper = styled.div({
  position: 'relative',
});

export const Container = styled.div({
  width: '100%',
  borderRadius: 5,
  boxShadow: '0 2px 8px 0 rgba(0,0,0,0.2)',

  background: white,
});

export const Address = styled(Flex)(
  {
    flexDirection: 'column',
    padding: '12px 16px',
    height: 70,
    borderBottom: `1px solid ${border}`,
    justifyContent: 'space-between',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: basic03,
    },
  },
  ({active}) => ({
    ...(active ? {backgroundColor: actionExtraLight} : {}),
  }),
);

export const Street = styled(Text)({
  fontSize: 16,
  lineHeight: '24px',
  color: actionRegular,
});

export const City = styled(Text)({
  fontSize: 12,
  lineHeight: '15px',
  color: basic55,
});

export const Nothing = styled(Flex)({
  height: 56,
  width: '100%',
  fontSize: 12,
  lineHeight: '15px',
  color: basic55,
  alignItems: 'center',
  padding: '0 16px',
});
