import {gql} from '@apollo/client';

export default gql`
  mutation sendCorrespondenceMessage(
    $bodyText: String!
    $entityId: ID!
    $entityType: CorrespondenceMessageEntityTypeTypeEnum!
    $projectId: ID!
    $recipientEmailAddress: String!
    $source: CreationSourceTypeEnum!
    $subject: String!
  ) {
    sendCorrespondenceMessage(
      bodyText: $bodyText
      entityId: $entityId
      entityType: $entityType
      projectId: $projectId
      recipientEmailAddress: $recipientEmailAddress
      source: $source
      subject: $subject
    ) {
      correspondenceMessage {
        authorId
        bodyText
        createdAt
        entityId
        entityType
        id
        recipientEmailAddress
        source
        subject
      }
    }
  }
`;
