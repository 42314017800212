import {
  differenceInMilliseconds,
  formatDuration,
  intervalToDuration,
} from 'date-fns';

import isCurrentResidence from './isCurrentResidence';

export default (residenceHistory = {}) => {
  const {residenceFrom, residenceTo} = residenceHistory;
  const isCurrent = isCurrentResidence(residenceHistory);

  const end = isCurrent
    ? new Date()
    : residenceTo
    ? new Date(residenceTo)
    : null;
  const start = residenceFrom ? new Date(residenceFrom) : null;
  if (!start || !end) {
    return {difference: 0, duration: ''};
  }
  const interval = intervalToDuration({start, end});
  const duration = formatDuration(interval, {
    format: ['years', 'months', 'days'],
  });
  return {
    difference: differenceInMilliseconds(end, start),
    duration,
  };
};
