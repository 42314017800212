import styled from '@emotion/styled';

import {basic15} from '@renofi/theme/src/colors';

import Flex from '../../../Flex';
import expand from '../../expand.svg';

export const Header = styled(Flex)(({searchable}) => ({
  // height: 'auto',
  position: searchable ? 'sticky' : 'static',
  top: 0,
  alignItems: 'center',
  borderBottom: `1px solid ${basic15}`,

  backgroundRepeat: 'no-repeat',
  backgroundSize: '16px 16px',
  backgroundPosition: 'center right 16px',
  backgroundColor: 'transparent',
  backgroundImage: `url('${expand}')`,

  // The SearchInput
  '[class*="Wrapper"]': {
    marginBottom: 0,
  },
}));
