import initAnalytics from '@renofi/utilities/src/segment';
import {initLogRocket} from '@renofi/utilities/src/logrocket2';
import isImpersonation from '@renofi/utilities/src/isImpersonation';
import logger from '@renofi/utilities/src/logger';
import {initAccessibe, initSentry} from '@renofi/utilities/src/analytics';

export default async ({config, storage}) => {
  const {
    DEVELOPMENT,
    LOGROCKET_ID,
    PRODUCTION,
    SEGMENT_KEY: segmentKey,
    SEGMENT_URL: baseUrl,
  } = config;
  const impersonation = isImpersonation(storage);

  window.addEventListener('error', (error) => {
    logger.error('ERROR', error);
  });

  if (impersonation) return;

  if (!DEVELOPMENT && segmentKey) {
    initAnalytics({
      segmentKey,
      baseUrl,
      options: {
        integrations: {All: true, 'Bing Ads': false, 'Facebook Pixel': false},
      },
    });
  }

  if (PRODUCTION) {
    initLogRocket(LOGROCKET_ID);
    initSentry();
    initAccessibe();
  }
};
