import styled from '@emotion/styled';

import {basic10} from '@renofi/theme/src/colors';

import Box from './Box';

export default styled(Box)({
  backgroundColor: basic10,
  height: 1,
  width: '100%',
});
