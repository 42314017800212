import styled from '@emotion/styled';
import {isNil} from 'ramda';

import {
  actionDark20,
  basic03,
  basic55,
  border,
  dangerRegular,
} from '@renofi/theme/src/colors';

import Flex from '../Flex';
import Text from '../Text';

export const Wrapper = styled.div(
  {
    outline: 'none',
  },
  ({mb}) => ({
    marginBottom: !isNil(mb) ? mb : 24,
  }),
);

export const Placeholder = styled(Flex)(
  {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    border: `1px dashed ${border}`,
    borderRadius: 3,
    backgroundColor: basic03,
  },
  ({compact, height}) => ({
    height: height || compact ? 96 : 240,
    padding: compact ? 16 : 24,
  }),
);

export const Details = styled(Text)({
  fontSize: 15,
  lineHeight: 1,
  color: 'rgba(0,0,0,0.40)',
});

export const Help = styled(Text)({
  color: basic55,
  fontSize: 14,
  lineHeight: '17px',
});

export const Label = styled.label({
  display: 'block',
  color: basic55,
  fontSize: 14,
  lineHeight: '17px',
  marginBottom: 0,
});

export const focusedStyle = {
  borderColor: actionDark20,
};

export const rejectStyle = {
  borderColor: dangerRegular,
};
