import {gql} from '@apollo/client';

export default gql`
  query gcddReviews(
    $filter: GcddReviewFilterEnum!
    $limit: Int
    $page: Int
    $assignedToId: [ID!]
    $status: GcddReviewStatusEnum
    $sortBy: GcddReviewSortInputObject
  ) {
    gcddReviews(
      filter: $filter
      limit: $limit
      page: $page
      assignedToId: $assignedToId
      status: $status
      sortBy: $sortBy
    ) {
      collection {
        assignedToId
        assignedTo {
          email
          firstName
          id
          lastName
          phoneNumber
        }
        projectId
        project {
          city
          id
          renovationCost
          renovationCostToCurrentPropertyValueRatio
          renovationScope
          state
          status
          streetAddressOne
          streetAddressTwo
          zipCode
        }
        priority
        estimatedCostOfWork
        scopeOfWork
        cancelledAt
        cancellationMessage
        cancellationReason
        completedAt
        complexityLevel
        contractorInformationId
        overallScore
        overallScoreNotes
        contractor {
          businessName
          id
        }
        id
        questionnaire {
          createdAt
          firstOpenedAt
          returnedAt
          submittedStep
        }
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;
