import {keyframes} from '@emotion/react';

export default ({x = 0, y = 60, z = 0} = {}) => keyframes`
  from {
    transform: translate3d(${x}px, ${y}px, ${z}px);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;
