import styled from '@emotion/styled';

import Flex from '../Flex';

export const Container = styled(Flex)({
  position: 'fixed',
  width: '100%',
  height: '100%',
  overflow: 'auto',
  backgroundColor: 'transparent',
  zIndex: 1092,
  left: 0,
  top: 0,
  bottom: 0,
  right: 0,
});

export const Cover = styled.div(
  {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: 'auto',
    minHeight: 'calc(100%)',
    zIndex: 1091,
    backgroundColor: '#172E44',
    opacity: 0.6,
  },
  ({isNestedModal}) => ({
    display: isNestedModal ? 'none' : 'block',
  }),
);

export const Wrapper = styled.div(
  {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    minHeight: 'calc(100%)',
    zIndex: 1090,
  },
  ({isNestedModal}) => ({
    top: isNestedModal ? 70 : 0,
  }),
);
