import {gql} from '@apollo/client';

export default gql`
  mutation submitRenofiProvidedDocument(
    $projectId: ID!
    $documentId: ID!
    $documentType: DocumentType
    $objectName: String!
    $checksum: String
  ) {
    submitRenofiProvidedDocument(
      projectId: $projectId
      documentId: $documentId
      documentType: $documentType
      objectName: $objectName
      checksum: $checksum
    ) {
      document {
        id
        checksum
        createdAt
        documentType
        fileName
        objectName
        pdfObjectName
        rejectionDetails
        rejectionReason
        shareWithLenderAllowed
        shareable
        status
      }
    }
  }
`;
