import React from 'react';

import PropTypes from 'prop-types';

import {basic20} from '../../theme/src/colors';

const SvgExpand = ({color = basic20, width = 16}) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="m11.29 9 .71.754L8 14 4 9.754l.705-.749L7.5 11.967l.5.474.5-.474L11.29 9zM8 2l4 4.246-.71.754L8.5 4.033 8 3.559l-.5.474-2.795 2.962L4 6.246 8 2z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

SvgExpand.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
};

export default SvgExpand;
