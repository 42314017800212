export default `
id
email
firstName
middleName
lastName
nameSuffix
fullName
phoneNumber
dateOfBirth
`;
