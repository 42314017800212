import {gql} from '@apollo/client';

export default gql`
  query taskTemplates(
    $autoAssignable: Boolean
    $financial: Boolean
    $renovationUnderwriting: Boolean
  ) {
    taskTemplates(
      autoAssignable: $autoAssignable
      financial: $financial
      renovationUnderwriting: $renovationUnderwriting
    ) {
      id
      title
      details
      requirements
      informationKind
      taskType
    }
  }
`;
