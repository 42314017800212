import React from 'react';

import PropTypes from 'prop-types';

import {useTheme} from '@renofi/theme/src';

import {Wrapper} from './styled';

const Button = ({children, ...props}) => {
  const {styles} = useTheme('buttonGroup', props);
  return (
    <Wrapper css={styles} {...props}>
      {children}
    </Wrapper>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Button;
