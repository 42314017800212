import styled from '@emotion/styled';

import Flex from '@renofi/components-internal/src/Flex';
import Text from '@renofi/components-internal/src/Text';
import {
  actionRegular,
  basic03,
  basic80,
  border,
} from '@renofi/theme/src/colors';

export const Help = styled(Text)({
  fontSize: 12,
  lineHeight: '15px',
  color: actionRegular,
});

export const Note = styled(Flex)({
  height: 50,
  width: '100%',
  backgroundColor: basic03,
  color: basic80,
  fontSize: 14,
  lineHeight: '18px',
  padding: 16,
  alignItems: 'center',
});

export const Row = styled.tr({
  borderTop: `1px solid ${border}`,
});
