import styled from '@emotion/styled';

import {Box, Flex} from '@renofi/components-internal';
import {basic03, basic80, minSmall} from '@renofi/theme/src';

export const Card = styled(Flex)({
  borderRadius: 8,
  backgroundColor: basic03,
  flexDirection: 'column',
  border: '1px solid #E9E9E9',
  padding: 16,
  marginBottom: 8,
  fontSize: 14,
});

export const DocumentCard = styled(Box)(({internal}) => {
  const padding = internal ? 0 : 8;
  return {
    width: '100%',

    [minSmall]: {
      width: !internal ? '50%' : '100%',
      paddingLeft: padding,
      ':nth-of-type(2n + 1)': {
        paddingLeft: 0,
        paddingRight: padding,
      },
    },
  };
});

export const TotalIncome = styled.strong({
  color: basic80,
});
