import {gql} from '@apollo/client';

export default gql`
  query previewAppraisalPackage(
    $appraisalOrder: AppraisalOrderInputObject!
    $projectId: ID!
    $documentIds: [ID!]
  ) {
    previewAppraisalPackage(
      appraisalOrder: $appraisalOrder
      projectId: $projectId
      documentIds: $documentIds
    ) {
      url
    }
  }
`;
