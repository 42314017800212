import {gql} from '@apollo/client';

import {amc} from '../../partials';

export default gql`
  query appraisalCompanies {
    appraisalManagementCompanies {
      ${amc}
    }
  }
`;
