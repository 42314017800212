import {pathOr} from 'ramda';

import {compose, withHandlers} from '@renofi/recompose/src';

import {ADD_EXCEPTION_REQUEST_COMMENT} from '../mutations';
import {GET_EXCEPTION_REQUEST} from '../queries';

import withApollo from './withApollo';
import withMutation from './withMutation';

export default () =>
  compose(
    withApollo,
    withMutation(ADD_EXCEPTION_REQUEST_COMMENT, {
      name: 'addExceptionRequestComment',
    }),
    withHandlers({
      addExceptionRequestComment:
        ({client, addExceptionRequestComment}) =>
        async (variables) => {
          const response = await addExceptionRequestComment({variables});
          const comment = pathOr(
            null,
            ['data', 'addExceptionRequestComment', 'exceptionRequestComment'],
            response,
          );
          if (comment) {
            const {exceptionRequestId: id} = variables;
            const {
              exceptionRequestById: {comments = [], ...exceptionRequestById},
            } = client.readQuery({
              query: GET_EXCEPTION_REQUEST,
              variables: {id},
            });
            if (exceptionRequestById) {
              client.writeQuery({
                query: GET_EXCEPTION_REQUEST,
                variables: {id},
                data: {
                  exceptionRequestById: {
                    ...exceptionRequestById,
                    comments: comments.concat(comment),
                  },
                },
              });
            }
          }
          return response;
        },
    }),
  );
