import {gql} from '@apollo/client';

export default gql`
  mutation updateLenderLogo($id: ID!, $logoObjectName: String!) {
    updateLenderLogo(id: $id, logoObjectName: $logoObjectName) {
      lender {
        id
        applyUrl
        contactInformation1
        contactInformation2
        friendlyId
        joinUrl
        raasEnabled
        logoObjectName
        logoUrl
        loanProducts {
          id
          name
        }
        name
      }
    }
  }
`;
