import logger from 'loglevel';

import {isDeployPreviewUrl} from './netlify';

logger.setDefaultLevel(logger.levels.WARN);
if (process.env.NODE_ENV === 'development' || isDeployPreviewUrl()) {
  logger.setLevel(logger.levels.DEBUG);
}

export default logger;
