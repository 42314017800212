import {gql} from '@apollo/client';

export default gql`
  mutation setGcddReviewComplexityLevel(
    $complexityLevel: ComplexityLevelEnum!
    $id: ID!
  ) {
    setGcddReviewComplexityLevel(complexityLevel: $complexityLevel, id: $id) {
      gcddReview {
        id
        complexityLevel
      }
    }
  }
`;
