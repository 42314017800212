import {gql} from '@apollo/client';

import {task} from '../../partials';

export default gql`
  mutation scheduleFinalReviewCall(
    $callEndsAt: DateTime!
    $callStartsAt: DateTime!
    $callEventId: String!
    $taskId: ID!
  ) {
    scheduleFinalReviewCall(
      callEndsAt: $callEndsAt
      callStartsAt: $callStartsAt
      callEventId: $callEventId
      taskId: $taskId
    ) {
      task {
        ${task}
      }
    }
  }
`;
