import styled from '@emotion/styled';

import Flex from '@renofi/components-internal/src/Flex';

export const Wrapper = styled(Flex)(
  {
    background: 'white',
    overflow: 'hidden',
  },
  ({
    duration = 500,
    fixed = true,
    sticky,
    width = 300,
    left = 0,
    top = 0,
    show,
  }) => ({
    transition: `all ${duration}ms ease`,
    width,
    height: `calc(100% - ${top}px)`,
    ...(fixed ? {position: 'fixed'} : {}),
    ...(sticky ? {position: 'sticky'} : {}),
    ...(fixed || sticky
      ? {
          height: `calc(100vh - ${top}px)`,
          marginRight: 8,
          left: show ? left : -1 * width - 8,
          top,
          zIndex: 1000,
          boxShadow: '4px 0 8px 0 rgba(0,0,0,0.1)',
          minWidth: show ? width : 0,
        }
      : {
          width: show ? width : 0,
          height: `calc(100% - ${top}px)`,
        }),
  }),
);

export const Content = styled(Flex)(
  {
    flexDirection: 'column',
    flexShrink: 0,
    flexGrow: 1,
  },
  ({width, fixed, sticky}) => ({
    width,
    overflow: fixed || sticky ? 'auto' : 'hidden',
  }),
);
