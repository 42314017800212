import * as React from 'react';

import PropTypes from 'prop-types';

const SvgDiningRoom = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M21.592 14.401v-1.748l.333-3.266.5-5.035-.853-.083a1.542 1.542 0 0 0-1.145.354c-.312.27-.52.645-.561 1.04l-.313 3.225c-.041.416.084.811.354 1.144.27.312.645.52 1.04.562h.021l-.145 1.56h-2.788l-.479-4.202h-2.184v-.354a3.419 3.419 0 0 0-2.268-3.204c.02-.083.02-.167.02-.25 0-.624-.52-1.144-1.144-1.144-.624 0-1.144.52-1.144 1.144 0 .083 0 .167.021.25C9.505 4.852 8.59 6.12 8.59 7.598v.354H6.405l-.479 4.202H3.18l-.146-1.56h.021c.416-.042.79-.25 1.04-.562.25-.312.396-.728.354-1.144l-.312-3.225a1.529 1.529 0 0 0-.562-1.04 1.515 1.515 0 0 0-1.144-.354l-.853.083.624 6.325.208 1.976v1.748l-.561 5.721.832.084.562-5.805h2.413l-.333 2.975.229.146c.062.041 1.04.603 2.892.978v1.685h.832v-1.54c.79.125 1.685.188 2.704.188 1.04 0 1.956-.084 2.747-.188v1.54h.832V18.5c.395-.084.749-.167 1.061-.25h.083v-.02c1.082-.313 1.665-.646 1.706-.687l.23-.146-.334-2.975h2.455l.583 5.784.832-.084-.583-5.721zM2.514 5.081c.188 0 .375.041.52.166.146.125.25.291.25.478l.312 3.225a.685.685 0 0 1-.166.52c-.125.146-.292.25-.479.25h-.02l-.437-4.619.02-.02zm18.517 4.68a.722.722 0 0 1-.479-.25.685.685 0 0 1-.166-.52l.312-3.224a.722.722 0 0 1 .25-.478.685.685 0 0 1 .52-.167h.02l-.416 4.203-.041.457v-.02zm-9.05-5.929a.32.32 0 0 1 .312.312.297.297 0 0 1-.104.23l-.209.207-.207-.208a.297.297 0 0 1-.104-.229.32.32 0 0 1 .311-.312zM9.42 7.598c0-1.248.895-2.31 2.123-2.518l.436-.062.437.062a2.548 2.548 0 0 1 2.123 2.518v.354H9.421v-.354zm-6.178 5.97v-.582h2.6l-.062.583H3.243zm13.398 3.766-.27-3.786-.833.063.291 3.952c-.977.23-2.247.417-3.849.417-1.518 0-2.767-.188-3.724-.396l.292-3.973-.832-.063-.27 3.828a8.43 8.43 0 0 1-1.228-.458l.936-8.134h9.653l.937 8.134c-.209.105-.583.25-1.103.416zm1.498-4.348h2.642v.583H18.2l-.062-.583z"
        fill={color}
        stroke={color}
        strokeWidth={0.1}
      />
      <path
        d="M16.121 9.574H7.758v.832h8.363v-.832z"
        fill={color}
        stroke={color}
        strokeWidth={0.1}
      />
    </svg>
  );
};

SvgDiningRoom.propTypes = {
  color: PropTypes.string,
};
export default SvgDiningRoom;
