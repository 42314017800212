import React from 'react';

import PropTypes from 'prop-types';

import TrashIcon from '@renofi/icons/src/Trash';
import {basic55} from '@renofi/theme';

import {Box, FileType, Flex, Loader, Text} from '../';

const FileCard = ({
  name,
  onDelete,
  small,
  viewOnly,
  width,
  loading,
  documentType = null,
}) => {
  return (
    <Flex key={name} alignItems="center" width={width}>
      <Box>
        <FileType
          css={{transform: `scale(${small ? 0.7 : 1})`}}
          fileName={name}
        />
      </Box>
      <Text fontSize={13} ml={small ? '8px' : 16} color={basic55}>
        {name}
      </Text>
      <Flex ml="auto" alignItems="center">
        <Box mr={24}>{documentType}</Box>
        {viewOnly ? null : loading ? (
          <Loader
            label={false}
            width={20}
            css={{marginLeft: -2, marginTop: -4}}
          />
        ) : (
          <TrashIcon css={{cursor: 'pointer'}} onClick={onDelete} />
        )}
      </Flex>
    </Flex>
  );
};

FileCard.propTypes = {
  name: PropTypes.string,
  onDelete: PropTypes.func,
  small: PropTypes.bool,
  viewOnly: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  documentType: PropTypes.node,
};

export default FileCard;
