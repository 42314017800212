import {propOr, pathOr} from 'ramda';

import transformObject, {
  __,
  toString,
  toNumber,
  toBoolean,
  toRoundedInt,
  toNullishString,
} from '@renofi/utilities/src/transformObject';
import {
  hasLoanApplicationRegularPermission,
  hasLoanApplicationAdvancedPermission,
} from '@renofi/utilities/src/permissions';

import {STATUSES, DISABLED_STATUSES_REGULAR} from './constants';

export const getIsClosedValuesPresent = ({
  appliedOn,
  approvedOn,
  closedOn,
  finalCreditScore,
  finalDtiRatio,
  finalLoanAmount,
  finalLoanRate,
  loanNumber,
  lenderProvidedIncome,
  lenderProvidedMonthlyDebt,
  lenderVerifiedReserves,
  loanProduct,
}) => {
  return Boolean(
    appliedOn &&
      approvedOn &&
      closedOn &&
      finalCreditScore &&
      finalDtiRatio &&
      finalLoanAmount &&
      finalLoanRate &&
      loanNumber &&
      lenderProvidedIncome &&
      lenderProvidedMonthlyDebt &&
      lenderVerifiedReserves &&
      loanProduct,
  );
};

export const getIsDeniedValuesPresent = ({
  deniedOn,
  isDeniedReasonCreditScore,
  finalCreditScore,
  isDeniedReasonDebt,
  finalDtiRatio,
  isDeniedReasonOther,
  deniedOtherExplanation,
}) => {
  return Boolean(
    deniedOn &&
      ((isDeniedReasonCreditScore && finalCreditScore) ||
        (isDeniedReasonDebt && finalDtiRatio) ||
        (isDeniedReasonOther && deniedOtherExplanation)),
  );
};

export const reduceStatus = (status) => {
  const statusMap = {
    in_progress: 'warning',
    approved: 'success',
    closed: 'info',
  };

  return propOr('info', status, statusMap);
};

export const getDecisions = (status) => {
  let decisions = {
    approved: 'Approved',
    not_accepted: 'Approved - not accepted',
    denied: 'Denied',
    withdrawn: 'Applicant withdrawn',
  };

  if (status === STATUSES.IN_PROGRESS) {
    decisions = {
      in_progress: 'Pending decision',
      ...decisions,
    };
  }

  if (status === STATUSES.APPROVED) {
    decisions = {
      ...decisions,
      closed: 'Closed',
    };
  }

  if (status === STATUSES.CLOSED) {
    decisions = {closed: 'Closed'};
  }

  return decisions;
};

export const getIsStatusChangeDisabled = ({mode, user, status}) => {
  const hasRegularPermission = hasLoanApplicationRegularPermission(user);
  const hasAdvancedPermission = hasLoanApplicationAdvancedPermission(user);

  const isDisabledForInternal =
    mode === 'internal' && !hasRegularPermission && !hasAdvancedPermission;

  return status === STATUSES.CLOSED || isDisabledForInternal;
};

export const getIsDisabledForInternalMode = ({mode, status, user}) => {
  const hasRegularPermission = hasLoanApplicationRegularPermission(user);
  const hasAdvancedPermission = hasLoanApplicationAdvancedPermission(user);

  if (mode !== 'internal' || hasAdvancedPermission) {
    return false;
  }

  const isDisabledForRegularPermission =
    DISABLED_STATUSES_REGULAR.includes(status);
  return hasRegularPermission ? isDisabledForRegularPermission : true;
};

export const transformLoanApplication = (loanApplication) => {
  return transformObject(
    {
      appliedOn: [__, toNullishString],
      approvedOn: [__, toNullishString],
      conditions: [__, toString],
      closedOn: [__, toNullishString],
      deniedOn: [__, toNullishString],
      deniedCreditScoreExplanation: [__, toString],
      deniedDebtExplanation: [__, toString],
      deniedOtherExplanation: [__, toString],
      estimatedClosingOn: [__, toNullishString],
      finalCreditScore: [__, toString],
      finalDtiRatio: [__, toString],
      finalLoanAmount: [__, toString],
      finalLoanRate: [__, toString],
      isDeniedReasonCreditScore: [__],
      isDeniedReasonDebt: [__],
      isDeniedReasonOther: [__],
      lenderProvidedIncome: [__, toString],
      lenderProvidedMonthlyDebt: [__, toString],
      lenderVerifiedReserves: [__, toString],
      loanNumber: [__, toString],
      loanProduct: [__],
      loanProducts: [pathOr([], ['lender', 'loanProducts'])],
      withdrawnOn: [__, toNullishString],
      withdrawnReason: [__, toString],
      withdrawnExplanation: [__, toString],
    },
    loanApplication,
  );
};

export const transformApprovedLoanApplication = (loanApplication) => {
  return transformObject(
    {
      appliedOn: [__, toNullishString],
      approvedOn: [__, toNullishString],
      conditions: [__, toNullishString],
      closedOn: [__, toNullishString],
      estimatedClosingOn: [__, toNullishString],
      finalCreditScore: [__, toString],
      finalDtiRatio: [__, toNumber],
      finalLoanAmount: [__, toRoundedInt],
      finalLoanRate: [__, toNumber],
      loanNumber: [__, toString],
      lenderProvidedIncome: [__, toRoundedInt],
      lenderProvidedMonthlyDebt: [__, toRoundedInt],
      lenderVerifiedReserves: [__, toRoundedInt],
      loanProductId: [pathOr(null, ['loanProduct', 'id'])],
    },
    loanApplication,
  );
};

export const transformClosedLoanApplication = (loanApplication) => {
  return transformObject(
    {
      appliedOn: [__, toNullishString],
      approvedOn: [__, toNullishString],
      conditions: [__, toString],
      closedOn: [__, toNullishString],
      estimatedClosingOn: [__, toNullishString],
      finalCreditScore: [__, toString],
      finalDtiRatio: [__, toNumber],
      finalLoanAmount: [__, toRoundedInt],
      finalLoanRate: [__, toNumber],
      loanNumber: [__, toString],
      lenderProvidedIncome: [__, toRoundedInt],
      lenderProvidedMonthlyDebt: [__, toRoundedInt],
      lenderVerifiedReserves: [__, toRoundedInt],
      loanProductId: [pathOr(null, ['loanProduct', 'id'])],
    },
    loanApplication,
  );
};

export const transformDeniedLoanApplication = (loanApplication) => {
  return transformObject(
    {
      deniedOn: [__, toNullishString],
      isDeniedReasonCreditScore: [__, toBoolean],
      isDeniedReasonDebt: [__, toBoolean],
      isDeniedReasonOther: [__, toBoolean],
      deniedCreditScoreExplanation: [__, toString],
      deniedDebtExplanation: [__, toString],
      deniedOtherExplanation: [__, toString],
      finalCreditScore: [__, toString],
      finalDtiRatio: [__, toNumber],
    },
    loanApplication,
  );
};

export const transformNotAcceptedAndWithdrawn = (loanApplication) => {
  return transformObject(
    {
      withdrawnOn: [__, toNullishString],
      withdrawnReason: [__, toString],
      withdrawnExplanation: [__, toString],
    },
    loanApplication,
  );
};
