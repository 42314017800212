import styled from '@emotion/styled';

export const Help = styled.div({
  marginBottom: 4,
});

export const Icon = styled.div(
  {
    width: 20,
    height: 20,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPosition: 'center center',
  },
  ({icon}) => ({
    backgroundImage: `url('${icon}')`,
  }),
);
