import * as React from 'react';

import PropTypes from 'prop-types';

const DoubleCheckSvg = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H15.8428V15.6H0V0Z"
        stroke="black"
        strokeOpacity="0.0117647"
        strokeWidth="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8822 4.55029L10.9514 3.63379L6.7663 7.75479L7.69706 8.67129L11.8822 4.55029ZM14.6811 3.63379L7.69706 10.5108L4.93778 7.80029L4.00701 8.71679L7.69706 12.3503L15.6185 4.55029L14.6811 3.63379ZM0.270752 8.71679L3.9608 12.3503L4.89157 11.4338L1.20812 7.80029L0.270752 8.71679Z"
        fill={color}
      />
    </svg>
  );
};

DoubleCheckSvg.propTypes = {
  color: PropTypes.string,
};

export default DoubleCheckSvg;
