import {gql} from '@apollo/client';

export default gql`
  query projectRenovationUpdatesV2($id: ID!) {
    projectRenovationUpdatesV2(id: $id) {
      id
      createdAt
      date
      details
      files
      kind
      noteToLender
      photos {
        original {
          objectName
          url
        }
        thumbnail {
          objectName
          url
        }
      }
      rejectedFiles
      shareWithLender
      sharedWithLenderAt
      source
      updatedAt
      userId
      user {
        id
        email
        firstName
        lastName
      }
    }
  }
`;
