import React, {Fragment, memo, useCallback} from 'react';

import PropTypes from 'prop-types';

import {sendEvent} from '@renofi/utilities/src/analytics';
import UnorderedListIcon from '@renofi/icons/src/UnorderedList';
import {white, basic20} from '@renofi/theme/src/colors';
import Tooltip from '@renofi/components-internal/src/Tooltip';

import Box from '../Box';
import Button from '../Button';

import {ToggleProgress, FooterContainer} from './styled';

const WizardFooter = ({
  disabled,
  loading,
  final,
  finalLabel,
  prevLabel,
  nextLabel,
  saveAndFinishLabel,
  start,
  startLabel,
  onNext,
  onPrev,
  handleSaveAndFinish,
  onStart,
  onClose,
  onFinish,
  withBackOnFinal,
  hidePrev,
  showNext,
  showSaveAndFinishButton,
  tooltipText,
  finalButtonVariant,
  forceProgress,
  setForceProgress,
}) => {
  const onClickToggleProgress = useCallback(() => {
    sendEvent('Wizard-Toggle-Progress-Menu');
    setForceProgress(!forceProgress);
  }, [forceProgress]);

  return (
    <FooterContainer>
      <ToggleProgress
        variant={forceProgress ? 'primary' : 'secondary'}
        onClick={onClickToggleProgress}>
        <UnorderedListIcon
          css={{zoom: 0.8, position: 'absolute', top: 6, left: 6}}
          color={forceProgress ? white : basic20}
        />
      </ToggleProgress>
      {start && (
        <Button
          className="wizardBtn"
          disabled={forceProgress}
          onClick={onStart}>
          {startLabel}
        </Button>
      )}
      {!start && final && (
        <>
          {withBackOnFinal && (
            <Box mr="16px">
              <Button
                className="wizardBtn"
                disabled={forceProgress}
                variant="secondary"
                onClick={onPrev}>
                {prevLabel}
              </Button>
            </Box>
          )}
          <Tooltip content={tooltipText} place="top" disable={!tooltipText}>
            <Button
              css={{cursor: 'pointer'}}
              className="wizardBtn"
              loading={loading}
              disabled={forceProgress || disabled}
              variant={finalButtonVariant}
              onClick={onFinish ?? onClose}>
              {finalLabel}
            </Button>
          </Tooltip>
        </>
      )}
      {!start && !final && (
        <Fragment>
          {!hidePrev && (
            <Box mr="8px">
              <Button
                className="wizardBtn"
                disabled={forceProgress}
                variant="secondary"
                onClick={onPrev}>
                {prevLabel}
              </Button>
            </Box>
          )}
          {showSaveAndFinishButton && (
            <Box>
              <Button
                className="wizardBtn"
                disabled={forceProgress}
                variant="secondary"
                loading={loading}
                onClick={handleSaveAndFinish}>
                {saveAndFinishLabel}
              </Button>
            </Box>
          )}
          {showNext && (
            <Box ml="8px">
              <Tooltip
                content={tooltipText}
                place="top"
                disable={!disabled || !tooltipText}>
                <Button
                  css={{cursor: 'pointer'}}
                  className="wizardBtn"
                  loading={loading}
                  disabled={forceProgress || disabled}
                  onClick={onNext}>
                  {nextLabel}
                </Button>
              </Tooltip>
            </Box>
          )}
        </Fragment>
      )}
    </FooterContainer>
  );
};

WizardFooter.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  final: PropTypes.bool.isRequired,
  finalLabel: PropTypes.string,
  nextLabel: PropTypes.string,
  saveAndFinishLabel: PropTypes.string,
  prevLabel: PropTypes.string,
  start: PropTypes.bool,
  startLabel: PropTypes.string,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  handleSaveAndFinish: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onFinish: PropTypes.func,
  onStart: PropTypes.func,
  withBackOnFinal: PropTypes.bool,
  hidePrev: PropTypes.bool,
  showNext: PropTypes.bool,
  showSaveAndFinishButton: PropTypes.bool,
  tooltipText: PropTypes.node,
  finalButtonVariant: PropTypes.string,

  forceProgress: PropTypes.bool,
  setForceProgress: PropTypes.func,
};

WizardFooter.defaultProps = {
  showSaveAndFinishButton: false,
  showNext: true,
};

export default memo(WizardFooter);
