import {gql} from '@apollo/client';

export default gql`
  mutation addFeasibilityStudyItem(
    $feasibilityReviewId: ID!
    $feasibilityStudyItem: FeasibilityStudyItemInputObject!
    $type: FeasibilityStudyItemType!
  ) {
    addFeasibilityStudyItem(
      feasibilityReviewId: $feasibilityReviewId
      feasibilityStudyItem: $feasibilityStudyItem
      type: $type
    ) {
      feasibilityStudyItem {
        id
        addedById
        addedBy {
          id
        }
        adjustedCost
        cost
        descriptionOfWork
        reviewerNotes
        reviewerRating
        source
        type
      }
    }
  }
`;
