import {SUBMIT_APPRAISAL_RECENT_TRANSACTIONS} from '../../../mutations';
import createMutationHook from '../../../utils/createMutationHook';

export default createMutationHook({
  name: 'submitAppraisalRecentTransactions',
  query: SUBMIT_APPRAISAL_RECENT_TRANSACTIONS,
  responsePath: [
    'submitAppraisalRecentTransactions',
    'appraisalRecentTransactions',
  ],
});
