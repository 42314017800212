import {gql} from '@apollo/client';

import {gcddDocument} from '../../partials';

export default gql`
  mutation rejectGcddDocuments(
    $documentIds: [ID!]!,
    $gcddReviewId: ID!,
    $rejectionDetails: String!,
    $rejectionReason: DocumentRejectionReasonTypeEnum!
  ) {
    rejectGcddDocuments(
      documentIds: $documentIds,
      gcddReviewId: $gcddReviewId,
      rejectionDetails: $rejectionDetails,
      rejectionReason: $rejectionReason
    ) {
      questionnaire {
        contractorGeneralLiabilityInsurance {
          ${gcddDocument}
          limitAggregate
          limitEachOccurrence
          limitWorkersCompensation
          workersCompDetailsAvailableInGeneralLiability
        }
        contractorLicense {
          ${gcddDocument}
          number
          state
        }
        contractorWorkersCompInsurance {
          ${gcddDocument}
          limitAggregate
          limitEachOccurrence
          limitWorkersCompensation
        }

      }
    }
  }
`;
