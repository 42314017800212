import {gql} from '@apollo/client';

import {realEstateOwned} from '../../partials';

export default gql`
  mutation removeRealEstateOwnedProperty($id: ID!) {
    removeRealEstateOwnedProperty(
      id: $id
    ) {
      success
      realEstateOwned {
        ${realEstateOwned}
      }
    }
  }
`;
