/* eslint-disable max-lines */
import {gql} from '@apollo/client';

import {
  applicant,
  borrowersAndPropertyInformation,
  payStatementInformation,
} from '../partials';

export default gql`
  query feasibilityReviewById($id: ID!) {
    feasibilityReviewById(id: $id) {
      id
      changeOrderProcedureApproved
      changeOrderProcedureApprovedSource
      changeOrderProcedureNotes
      clearDescriptionOfWorkApproved
      clearDescriptionOfWorkApprovedSource
      clearDescriptionOfWorkNotes
      clearGuaranteeApproved
      clearGuaranteeApprovedSource
      clearGuaranteeNotes
      clearGuaranteeRating
      clearGuaranteeRatingSource
      completedAt
      complexityGrading
      contactInfoApproved
      contactInfoApprovedSource
      contactInfoNotes
      contractTimelineApproved
      contractTimelineApprovedSource
      contractTimelineNotes
      contractTimelineRating
      contractTimelineRatingSource
      costEstimateSuppliedApproved
      costEstimateSuppliedApprovedSource
      costEstimateSuppliedNotes
      createdAt
      status
      documents {
        id
        checksum
        createdAt
        documentType
        fileName
        objectName
        pdfObjectName
        requestIpAddress
        requestIpLocation
        shareWithLenderAllowed
        shareable
        updatedAt
      }
      estimatedDuration
      estimatedDurationSource
      contractReviewStatus
      feasibilityStudyStatus
      feasibilityStudyItems {
        id
        addedById
        addedBy {
          id
        }
        adjustedCost
        cost
        descriptionOfWork
        reviewerNotes
        reviewerRating
        source
        type
      }
      generalCommentaryAndNotes
      homeownerResponsibilityCompleted
      inScopeAdjustedTotalRating
      inScopeTotalRating
      openedAt
      outOfScopeCompleted
      projectId
      project {
        id
        borrowersAndPropertyInformation {
          ${borrowersAndPropertyInformation}
        }
        borrower {
          id
          email
          firstName
          lastName
          phoneNumber
          salesforceContactId
        }
        primaryApplicant {
          ${applicant}
        }
        homeowners {
          id
          email
          firstName
          lastName
          phoneNumber
          salesforceContactId
        }
        primaryApplicantId
        city
        renofiId
        renovationCost
        salesforceOpportunityId
        streetAddressOne
        tasks {
          id
          additionalExplanation
          additionalRequestMessage
          allDocumentsAccepted
          anyDocumentRejected
          allDocumentsReviewed
          appraisalComparableSales {
            id
            createdAt
            updatedAt
            urls
          }
          appraisalPropertyDetails {
            id
            additionalInformation
            additionalInformationPresent
            basementSquareFootage
            basementState
            bedroomsInBasement
            bedroomsTotal
            city
            coolingTypes
            county
            createdAt
            fireplaceTypes
            fullBathroomsInBasement
            fullBathroomsTotal
            garagesAttached
            garagesDetached
            halfBathroomsInBasement
            halfBathroomsTotal
            heatingTypes
            lat
            lng
            mapPhotoUrl
            noteForAppraiser
            noteForAppraiserPresent
            previousRenovationsDescription
            previousRenovationsDescriptionPresent
            propertyType
            propertyTypeOther
            rentalIncome
            rentalIncomeCollectedOnPrimaryResidence
            state
            streetAddressOne
            streetAddressTwo
            streetViewPhotoUrl
            totalSquareFootage
            updatedAt
            yearBuilt
            yearPurchased
            zipCode
          }
          appraisalPropertyPhotos {
            id
            createdAt
            homeownerDescription
            objectName
            updatedAt
          }
          appraisalRecentTransactions {
            id
            createdAt
            date
            haveAppraisalFile
            occurredRecently
            updatedAt
            value
          }
          appraisalRenovationDetails {
            id
            additionalDetails
            additionalDetailsPresent
            createdAt
            renovationChanges {
              basement {
                changeTypes
                details
              }
              bathrooms {
                additional {
                  details
                  location
                  type
                }
                existing {
                  changeType
                  details
                  location
                  type
                }
              }
              bedrooms {
                additional {
                  details
                  location
                  type
                }
                existing {
                  changeType
                  details
                  location
                  type
                }
              }
              bonusRoom {
                changeTypes
                details
              }
              cooling {
                additional {
                  details
                  location
                  type
                }
                existing {
                  changeType
                  details
                  location
                  type
                }
              }
              custom {
                details
                name
              }
              deck {
                changeTypes
                details
              }
              diningRoom {
                changeTypes
                details
              }
              exterior {
                changeTypes
                details
              }
              fireplaces {
                additional {
                  details
                  location
                  type
                }
                existing {
                  changeType
                  details
                  location
                  type
                }
              }
              garages {
                additional {
                  details
                  location
                  type
                }
                existing {
                  changeType
                  details
                  location
                  type
                }
              }
              heating {
                additional {
                  details
                  location
                  type
                }
                existing {
                  changeType
                  details
                  location
                  type
                }
              }
              interior {
                changeTypes
                details
              }
              kitchen {
                changeTypes
                details
              }
              landscaping {
                changeTypes
                details
              }
              laundryRoom {
                changeTypes
                details
              }
              livingRoom {
                changeTypes
                details
              }
              mudRoom {
                changeTypes
                details
              }
              office {
                changeTypes
                details
              }
              outerBuildings {
                changeTypes
                details
              }
              painting {
                changeTypes
                details
              }
              patio {
                changeTypes
                details
              }
              pool {
                changeTypes
                details
              }
              porch {
                changeTypes
                details
              }
              roofing {
                changeTypes
                details
              }
              siding {
                changeTypes
                details
              }
              squareFootage {
                basement
                total
              }
              structuralChanges {
                changeTypes
                details
              }
              windows {
                changeTypes
                details
              }
            }
            updatedAt
          }
          canHaveDocument
          completedAt
          contractor {
            id
            businessName
            city
            contactName
            contactTitle
            email
            phoneNumber
            rejectionDetails
            rejectionReason
            secondaryAddress
            state
            status
            streetAddress
            website
            zipCode
          }
          details
          documents {
            id
            checksum
            createdAt
            documentType
            fileName
            objectName
            pdfObjectName
            rejectedAt
            rejectedById
            rejectedBy {
              id
              firstName
              lastName
            }
            rejectedBySystem
            rejectionDetails
            rejectionReason
            reviewedAt
            shareWithLenderAllowed
            shareable
            source
            status
          }
          facet
          informationKind
          payStatementInformations {
            ${payStatementInformation}
          }
          requirements
          status
          submittedAt
          taskType
          title
        }
        zipCode
      }
      propertyPhotos {
        id
        createdAt
        homeownerDescription
        objectName
        updatedAt
      }
      requestedAt
      returnedAt
      reviewerId
      reviewer {
        id
        firstName
        lastName
      }
      termsOfPaymentApproved
      termsOfPaymentApprovedSource
      termsOfPaymentNotes
      termsOfPayments {
        amount
        comment
        specialMaterialCosts
      }
      uncompletedDetails
      uncompletedReason
      updatedAt
      xactRemodelUrl
    }
  }
`;
