import React from 'react';

import PropTypes from 'prop-types';

import noop from '@renofi/utilities/src/noop';
import BackIcon from '@renofi/icons/src/BackIcon';
import {dangerRegular} from '@renofi/theme';

import {Container, StyledTitle, IconContainer} from './styled';

const PageTitle = ({text, backButton, onBackClick, ...props}) => {
  return (
    <Container {...props}>
      {backButton && (
        <IconContainer>
          <BackIcon
            color={dangerRegular}
            onClick={onBackClick}
            id="back-button"
          />
        </IconContainer>
      )}
      <StyledTitle>{text}</StyledTitle>
    </Container>
  );
};

PageTitle.propTypes = {
  text: PropTypes.string,
  backButton: PropTypes.bool,
  onBackClick: PropTypes.func,
};

PageTitle.defaultProps = {
  backButton: true,
  onBackClick: noop,
};

export default PageTitle;
