import * as React from 'react';

import PropTypes from 'prop-types';

import {successLight} from '@renofi/theme';

const SvgSuccess = ({color = successLight, width = 32}) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 16C0 7.168 7.168 0 16 0C24.832 0 32 7.168 32 16C32 24.832 24.832 32 16 32C7.168 32 0 24.832 0 16ZM12.8008 20.5635L23.3448 10.0195L25.6008 12.2915L12.8008 25.0915L4.80078 17.0915L7.05678 14.8355L12.8008 20.5635Z"
        fill={color}
      />
    </svg>
  );
};

SvgSuccess.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
};

export default SvgSuccess;
