import advisorFinalCallSchedule from './advisorFinalCallSchedule';
import certificateAcknowledgement from './certificateAcknowledgement';
import incomeSource from './incomeSource';
import assetDetails from './assetDetails';
import productDetailsConfirmation from './productDetailsConfirmation';

export default `
  id
  assetDetails {
    ${assetDetails}
  }
  additionalExplanation
  advisorFinalCallSchedule {
    ${advisorFinalCallSchedule}
  }
  allDocumentsAccepted
  anyDocumentRejected
  allDocumentsReviewed
  completedAt
  certificateAcknowledgement {
    ${certificateAcknowledgement}
  }
  contractor {
    businessName
    city
    contactName
    contactTitle
    email
    id
    phoneNumber
    rejectionDetails
    rejectionReason
    secondaryAddress
    state
    status
    streetAddress
    website
    zipCode
  }
  details
  documents {
    id
    borrowerRole
    checksum
    fileName
    objectName
    pdfObjectName
    rejectionDetails
    rejectionReason
    status
  }
  incomeSources {
    ${incomeSource}
  }
  informationKind
  productDetailsConfirmation {
    ${productDetailsConfirmation}
  }
  borrowerFinishedAt
  requirements
  status
  submittedAt
  title
`;
