import LogRocket from 'logrocket';

import logger from './logger';

const errorHandler = (error) => {
  LogRocket.captureException(error);
  logger.error('ERROR', error);
};

export default (func, {silent = false, handler = errorHandler} = {}) => {
  try {
    func();
  } catch (error) {
    try {
      if (silent) return;
      handler(error);
    } catch (error2) {
      logger.error('ERROR2', error2);
    }
  }
};
