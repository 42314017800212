import React from 'react';

import Context from './context';

export default (Component) => (props) =>
  (
    <Context.Consumer>
      {(storage) => <Component {...props} storage={storage} />}
    </Context.Consumer>
  );
