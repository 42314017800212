import React, {useRef, useCallback, useEffect, useState, memo} from 'react';

import PropTypes from 'prop-types';
import {createPortal} from 'react-dom';
import {format} from 'date-fns';

import noop from '@renofi/utilities/src/noop';
import {
  getDate,
  getUtcDate,
  DATE_FORMAT,
  validateDate,
} from '@renofi/utilities/src/dates';

import ActiveField from '../ActiveField';

import DateFieldPopup from './DateFieldPopup';

const DateField = ({
  small,
  canClear,
  clearValue,
  fixed,
  local,
  value,
  onChange,
  popupCss,
  disabledDays,
  modifiersStyles,
  position,
  inputClassName,
  onBlur,
  datePickerProps,
  onError,
  minYear,
  ...props
}) => {
  const ref = useRef();
  const [state, setState] = useState({
    open: props.open,
    inputTimeoutId: null,
    currentValue: value,
    isDateInvalid: false,
  });

  const [rect, setRect] = useState({
    x: 0,
    height: 0,
    top: 0,
    bottom: 0,
  });

  const isValidDate = (date) => {
    if (minYear && date.getFullYear() < minYear) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (local || !ref?.current) {
      return;
    }

    const inputRect = ref.current.getBoundingClientRect();
    setRect(inputRect);
  }, []);

  useEffect(() => {
    return () => clearTimeout(state?.inputTimeoutId);
  }, []);

  useEffect(() => {
    const isChanged =
      new Date(state.currentValue).getTime() !== new Date(value).getTime();

    if (isChanged) {
      setState((prevState) => ({
        ...prevState,
        currentValue: value,
      }));
    }
  }, [value]);

  const onClose = useCallback(() => {
    setState({...state, open: false});
    props.onClose();
  }, [state, props.onClose]);

  const onClear = (value) => {
    setState((prevState) => ({
      ...prevState,
      currentValue: value,
    }));
    props.onClear(value);
  };

  const onInputChange = useCallback(
    (date) => {
      if (date === null) {
        return;
      }

      const isValidInput = validateDate(date);
      const parsedDate = isValidInput ? getDate(date) : null;
      if (state.inputTimeoutId) {
        clearTimeout(state.inputTimeoutId);
      }

      const timeoutId = setTimeout(() => {
        if (
          parsedDate &&
          parsedDate?.toString() !== 'Invalid Date' &&
          isValidDate(parsedDate)
        ) {
          setState({
            ...state,
            currentValue: parsedDate,
            isDateInvalid: false,
            open: false,
          });
          onChange(getUtcDate(parsedDate));
          onError(false);
        } else {
          setState({
            ...state,
            isDateInvalid: true,
            open: false,
          });
          onError(true);
        }
      }, 500);

      setState({...state, inputTimeoutId: timeoutId});
    },
    [state],
  );

  const onSelect = useCallback(
    (day, {disabled}) => {
      if (disabled) {
        return;
      }

      const utcDate = getUtcDate(day);

      onChange(utcDate);
      setState({
        ...state,
        open: false,
        currentValue: new Date(utcDate),
        isDateInvalid: false,
      });
    },
    [state],
  );

  const onFocus = useCallback(
    (event) => {
      event.stopPropagation();
      props.onOpen();

      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          open: true,
        }));
      }, 0);
    },
    [props.onOpen],
  );

  const {currentValue, open, isDateInvalid} = state;

  const dateToFormat =
    typeof currentValue === 'string' ? getUtcDate(currentValue) : currentValue;
  const formattedValue = dateToFormat ? format(dateToFormat, DATE_FORMAT) : '';

  const popup = (
    <DateFieldPopup
      onSelect={onSelect}
      onClose={onClose}
      rect={rect}
      value={currentValue}
      position={position}
      popupCss={popupCss}
      disabledDays={disabledDays}
      modifiersStyles={modifiersStyles}
      datePickerProps={datePickerProps}
      local={local}
      fixed={fixed}
    />
  );

  return (
    <div ref={ref} css={{position: 'relative'}}>
      <ActiveField
        canClear={canClear}
        clearValue={clearValue}
        leftIcon={small ? undefined : 'calendar'}
        rightIcon={small ? 'calendar' : undefined}
        value={formattedValue}
        error={isDateInvalid ? 'Invalid date' : null}
        onChange={onInputChange}
        onClick={(event) => event.stopPropagation()}
        onFocus={onFocus}
        inputClassName={inputClassName}
        small={small}
        {...props}
        onClear={onClear}
      />
      {open && <>{local ? popup : createPortal(popup, document.body)}</>}
    </div>
  );
};

DateField.propTypes = {
  canClear: PropTypes.bool,
  clearValue: PropTypes.any,
  open: PropTypes.bool,
  fixed: PropTypes.bool,
  local: PropTypes.bool,
  position: PropTypes.shape({
    top: PropTypes.bool,
    bottom: PropTypes.bool,
  }),
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onClose: PropTypes.func,
  onClear: PropTypes.func,
  onChange: PropTypes.func,
  onOpen: PropTypes.func,
  onBlur: PropTypes.func,
  onError: PropTypes.func,
  showIconDivider: PropTypes.bool,
  popupCss: PropTypes.object,
  small: PropTypes.bool,
  disabledDays: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        after: PropTypes.instanceOf(Date),
        before: PropTypes.instanceOf(Date),
      }),
    ),
    PropTypes.shape({
      after: PropTypes.instanceOf(Date),
      before: PropTypes.instanceOf(Date),
    }),
  ]),
  minYear: PropTypes.number,
  inputClassName: PropTypes.string,
  modifiersStyles: PropTypes.shape({
    disabled: PropTypes.shape({
      color: PropTypes.string,
    }),
  }),
  datePickerProps: PropTypes.object,
};

DateField.defaultProps = {
  canClear: false,
  clearValue: null,
  onChange: noop,
  onClear: noop,
  onClose: noop,
  onOpen: noop,
  onCustomSelect: noop,
  onBlur: noop,
  local: true,
  open: false,
  position: {
    bottom: true,
  },
  popupCss: {},
  disabledDays: {},
  modifiersStyles: {
    disabled: {
      color: '#c7c7c8',
    },
  },
  onError: noop,
};

export default memo(DateField);
