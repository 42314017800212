import {gql} from '@apollo/client';

export default gql`
  mutation updateTask(
    $id: ID!
    $title: String!
    $details: String
    $requirements: String
  ) {
    updateTask(
      id: $id
      title: $title
      details: $details
      requirements: $requirements
    ) {
      task {
        id
        additionalExplanation
        allDocumentsAccepted
        anyDocumentRejected
        allDocumentsReviewed
        completedAt
        contractor {
          businessName
          city
          contactName
          contactTitle
          email
          id
          phoneNumber
          rejectionDetails
          rejectionReason
          secondaryAddress
          state
          status
          streetAddress
          website
          zipCode
        }
        details
        documents {
          id
          checksum
          fileName
          objectName
          rejectionDetails
          rejectionReason
          status
        }
        informationKind
        requirements
        status
        submittedAt
        title
      }
    }
  }
`;
