import {propEq} from 'ramda';

import {branch, compose, mapProps, withProps} from '@renofi/recompose/src';
import {
  withChangeRenovationUpdate,
  withCreateRenovationUpdate,
  withGetSignedUrls,
  withGetUploadProperties,
  withLenderProject,
  withProject,
  withRemoveRenovationUpdate,
  withRenovationState,
  withSetRenovationUpdateSharingSetting,
  withShareRenovationUpdate,
} from '@renofi/graphql/src/hoc';
import withNavigate from '@renofi/utilities/src/withNavigate';

import Component from './Component';
import {MODES} from './constants';

export default compose(
  withNavigate,
  mapProps(({match, ...props}) => ({
    ...match.params,
    ...props,
  })),
  branch(propEq('mode', MODES.LENDER), withLenderProject(), withProject()),
  withProps(({id, itemId, mode, project}) => ({
    id: itemId,
    projectId: project.id,
    ...(mode === MODES.LENDER ? {rrfId: id} : {}),
    mode,
  })),
  withRenovationState(),
  withGetSignedUrls(),
  withGetUploadProperties(),
  withCreateRenovationUpdate(),
  withChangeRenovationUpdate(),
  withRemoveRenovationUpdate(),
  withShareRenovationUpdate(),
  withSetRenovationUpdateSharingSetting(),
)(Component);
