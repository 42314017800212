import React, {forwardRef, memo, useMemo} from 'react';

import PropTypes from 'prop-types';
import {pick} from 'ramda';

import {useTheme} from '@renofi/theme/src';
import noop from '@renofi/utilities/src/noop';
import {dangerLight} from '@renofi/theme/src/colors';

import Icon from '../Icon';
import Label from '../Label';
import Flex from '../Flex';

import FrameSuffix from './components/FrameSuffix';
import {LAYOUT_KEYS} from './constants';
import {
  Container,
  ClearBox,
  Error,
  Field,
  IconBox,
  Info,
  Wrapper,
} from './styled';

const FieldFrame = (
  {
    canClear = false,
    name,
    help,
    info,
    label,
    error,
    errorBorder,
    required,
    large,
    valid,
    primary,
    children,
    disabled,
    onClear = noop,
    placeholder,
    value,
    inputValue,
    style,
    className,
    leftIcon,
    rightText,
    rightIcon,
    showIconDivider,
    customRightIcon,
    smallIcon,
    small,
    ...props
  },
  parentRef,
) => {
  const {styles} = useTheme('textField', {
    disabled,
    large,
    small,
    ...props,
  });
  const isClearEnabled = canClear && onClear;
  const showClear = isClearEnabled && !!inputValue;
  const isEmpty = required && !inputValue && inputValue !== 0;
  const errorMessage = isEmpty ? 'Required' : error;
  const left = Boolean(leftIcon);
  const right = Boolean(rightIcon);
  const iconProps = useMemo(
    () => ({
      showIconDivider: small ? false : showIconDivider,
      large,
      invalid: required,
      primary,
    }),
    [showIconDivider, large, required, primary, small],
  );

  if (errorBorder) {
    styles.border = `1px solid ${dangerLight}`;
    styles.borderColor = dangerLight;
  }

  return (
    <Wrapper className="renofiTextField" mb={24} {...pick(LAYOUT_KEYS, props)}>
      {(label || help) && (
        <Label labelFor={name} help={help} error={error} small={small}>
          {label}
        </Label>
      )}
      <Container
        disabled={disabled}
        large={large}
        small={small}
        style={style}
        css={styles}
        invalid={errorMessage}
        left={left}
        ref={parentRef}
        right={right}
        withLabel={label || help}
        className={className}>
        {leftIcon && (
          <IconBox small={smallIcon || small} left={left} {...iconProps}>
            <Icon size={small ? 16 : 20} name={leftIcon} primary={primary} />
          </IconBox>
        )}
        <Field>{value || children}</Field>

        {customRightIcon ? customRightIcon : null}

        <FrameSuffix
          icon={rightIcon}
          iconProps={iconProps}
          text={rightText}
          primary={primary}
          small={small}
        />
        {showClear && (
          <ClearBox onClick={onClear}>
            <Icon name="cross" size={small ? 16 : 20} />
          </ClearBox>
        )}
      </Container>
      {info && !error && <Info valid={valid}>{info}</Info>}
      {errorMessage && (
        <Error className="renofiTextFieldError" small={small}>
          {errorMessage}
        </Error>
      )}
    </Wrapper>
  );
};

FieldFrame.propTypes = {
  canClear: PropTypes.bool,
  valid: PropTypes.bool,
  required: PropTypes.bool,
  large: PropTypes.bool,
  small: PropTypes.bool,
  label: PropTypes.node,
  help: PropTypes.node,
  info: PropTypes.node,
  name: PropTypes.string,
  primary: PropTypes.bool,
  onClear: PropTypes.func,
  style: PropTypes.object,
  errorBorder: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inputValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  details: PropTypes.node,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  leftIcon: PropTypes.string,
  rightText: PropTypes.node,
  rightIcon: PropTypes.string,
  customRightIcon: PropTypes.node,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  forwardedRef: PropTypes.any,
  showIconDivider: PropTypes.bool,
  smallIcon: PropTypes.bool,
};

export default forwardRef(FieldFrame);
