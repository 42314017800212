import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';

import {
  actionExtraLight,
  actionRegular,
  basic20,
  basic80,
  border,
  white,
} from '@renofi/theme/src/colors';

import Flex from '../Flex';

import arrow from './arrow.svg';
import dots from './dots.svg';

export const Label = styled.div(
  {
    color: actionRegular,
    cursor: 'pointer',
  },
  ({pure}) => ({
    ...(!pure
      ? {
          paddingRight: 24,
          backgroundImage: `url('${arrow}')`,
          backgroundPosition: 'right center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '12px auto',
        }
      : {}),
  }),
);

export const Heading = styled.div({
  display: 'flex',
  width: '100%',
  minHeight: 42,
  whiteSpace: 'nowrap',
  padding: '10px 16px',
  marginBottom: 10,
  alignItems: 'center',
  color: 'rgba(0,0,0,0.55)',
  fontSize: 15,
  letterSpacing: 0.3,
  lineHeight: '18px',
  borderBottom: `1px solid ${border}`,
});

export const Items = styled('div', {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'local',
})(
  {
    display: 'flex',
    flexDirection: 'column',
    width: 'auto',
    minHeight: 43,
    padding: 0,
    borderRadius: 4,
    boxShadow: '0 1px 7px 0 rgba(0,0,0,0.3)',
    backgroundColor: white,
    overflow: 'hidden',
    zIndex: 1009,
    '& a': {
      display: 'flex',
      width: '100%',
      minHeight: 43,
      whiteSpace: 'nowrap',
      padding: '10px 16px',
      alignItems: 'center',
      fontSize: 16,
      lineHeight: '20px',
      color: basic80,
      '&[disabled]': {
        color: basic20,
      },
      ':hover': {
        color: basic80,
        textDecoration: 'none',
        backgroundColor: actionExtraLight,
      },
    },
  },
  ({cover, fixed, local, left, right, top, width, position}) => ({
    ...(cover && local ? {top: 0, right: 0} : {}),
    ...(!cover && local ? {top: 'calc(100% + 0.5rem)'} : {}),
    ...(!cover && !local ? {left, right, top} : {}),
    ...(local && position === 'left' ? {right: 0} : {}),
    position: !local && fixed ? 'fixed' : 'absolute',
    minWidth: local ? '100%' : width,
  }),
);

export const Wrapper = styled(Flex)({
  position: 'relative',
  overflow: 'visible',
});

export const Dots = styled.div(
  {
    width: 32,
    height: 32,
    backgroundImage: `url('${dots}')`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '24px',
    cursor: 'pointer',
  },
  ({active}) => ({
    backgroundColor: active ? 'rgba(51,51,51,0.2)' : 'transparent',
  }),
);
