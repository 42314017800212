import * as React from 'react';

import PropTypes from 'prop-types';

const SvgDesk = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M7.517 21.75H.18v-7.337h7.337v7.337zm-6.522-.815H6.7v-5.707H.995v5.707z"
        fill={color}
      />
      <path
        d="M7.517 15.228H.18v-4.076h7.337v4.076zm-6.522-.815H6.7v-2.445H.995v2.445z"
        fill={color}
      />
      <path
        d="M4.662 12.783h-1.63v.815h1.63v-.815zm1.225 4.483H5.07v1.63h.816v-1.63zm17.934 1.224h-7.337v-4.077h7.337v4.076zm-6.521-.816h5.706v-2.446H17.3v2.446z"
        fill={color}
      />
      <path
        d="M23.821 21.75h-7.337v-4.076h7.337v4.076zm-6.521-.815h5.706v-2.446H17.3v2.446zm3.666-4.891h-1.63v.815h1.63v-.816zm2.855-.816h-7.337v-4.076h7.337v4.076zm-6.521-.815h5.706v-2.445H17.3v2.445z"
        fill={color}
      />
      <path
        d="M20.966 12.783h-1.63v.815h1.63v-.815zm0 6.521h-1.63v.815h1.63v-.815zm2.446-8.152H.586v.816h22.826v-.816zm-8.151-1.63H8.74a1.63 1.63 0 0 1-1.63-1.63V4.63A1.63 1.63 0 0 1 8.74 3h6.521a1.63 1.63 0 0 1 1.631 1.63v3.261a1.63 1.63 0 0 1-1.63 1.63zM8.74 3.815a.815.815 0 0 0-.815.815v3.261a.815.815 0 0 0 .815.816h6.521a.815.815 0 0 0 .816-.816v-3.26a.815.815 0 0 0-.816-.816H8.74z"
        fill={color}
      />
      <path
        d="M13.706 11.967h-3.408l.542-3.26h2.323l.543 3.26zm-2.446-.815h1.483l-.273-1.63h-.937l-.273 1.63zM21.781 6.26H20.56v5.3h1.222v-5.3zm1.226 5.3h-.815l-.005-.408v-3.26h.816l.004.407v3.26zm-2.855-4.076H18.93v4.076h1.223V7.484zM4.663 11.967h-3.26V7.891h3.26v4.076zm-2.445-.815h1.63V8.706h-1.63v2.446z"
        fill={color}
      />
      <path
        d="M2.624 6.668h-.815v1.63h.815v-1.63zm1.222.408h-.815V8.3h.815V7.076zm13.455 6.522H6.703v-2.446h10.598v2.446zm-9.783-.815h8.968v-.815H7.518v.815zm4.192-8.03L9.672 6.791l.576.576 2.038-2.037-.576-.577zm2.035.405-2.038 2.037.576.577 2.038-2.038-.576-.576z"
        fill={color}
      />
    </svg>
  );
};

SvgDesk.propTypes = {
  color: PropTypes.string,
};
export default SvgDesk;
