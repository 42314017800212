import {gql} from '@apollo/client';

export default gql`
  query user {
    user {
      id
      email
      firstName
      lastName
      accountCategories
      lenderId
      registrationCompletedAt
      notificationPreferences {
        id
        active
        notificationType
      }
      permissions {
        id
        key
      }
      phoneNumber
      mfaEnabled
      currentAgreements {
        acceptedAt
        name
        version
        ipAddress
      }
    }
  }
`;
