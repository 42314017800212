/* eslint-disable no-dupe-keys */
import styled from '@emotion/styled';

import {Flex, Text} from '@renofi/components-internal';
import {DoubleCheck} from '@renofi/icons';
import {
  ELLIPSIS_OVERFLOW,
  actionRegular,
  basic55,
  basic80,
  dangerDark,
  successDark,
} from '@renofi/theme';
import {taskDocumentStatuses} from '@renofi/utilities/src/enums';

const getColorByStatus = (status) => {
  switch (status) {
    case taskDocumentStatuses.failed:
    case taskDocumentStatuses.rejected:
      return dangerDark;
    case taskDocumentStatuses.accepted:
      return successDark;
    default:
      return basic55;
  }
};

export const FileNameText = styled(Text)({
  position: 'absolute',
  width: '100%',
  // height: '100%',
  fontSize: 14,
  color: basic80,
  left: 0,
  top: 8,
  ...ELLIPSIS_OVERFLOW,
});

export const SubTitle = styled(Flex)({
  position: 'absolute',
  width: '100%',
  left: 0,
  bottom: 8,
  ...ELLIPSIS_OVERFLOW,
});

export const SubTitleText = styled.span(({status}) => {
  return {
    ...ELLIPSIS_OVERFLOW,
    color: getColorByStatus(status),
    fontSize: 12,
  };
});

export const DoubleCheckIcon = styled(DoubleCheck)({
  marginRight: 4,
});

export const Uploading = styled(SubTitleText)({
  color: actionRegular,
});
