export const HOURS_PER_DAY = 24;

export const MILLISECONDS = 1000;
export const MILLISECONDS_PER_MINUTE = MILLISECONDS * 60;
export const MILLISECONDS_PER_HOUR = MILLISECONDS_PER_MINUTE * 60;
export const MILLISECONDS_PER_DAY = MILLISECONDS_PER_HOUR * 24;
export const MILLISECONDS_PER_WEEK = MILLISECONDS_PER_DAY * 7;
export const MILLISECONDS_PER_MONTH = MILLISECONDS_PER_DAY * 30;
export const MILLISECONDS_PER_YEAR = MILLISECONDS_PER_DAY * 365;
export const DATE_FORMAT = 'MM/dd/yyyy';
