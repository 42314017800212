import React from 'react';

import PropTypes from 'prop-types';

import {useTheme} from '@renofi/theme/src';

import ClickOutside from '../../../ClickOutside';

import {Wrapper} from './styled';

const DateFieldWrapper = ({onClickOutside, children, popupCss, ...props}) => {
  const {styles} = useTheme('dateField');

  return (
    <ClickOutside onClickOutside={onClickOutside}>
      <Wrapper css={{...styles, ...popupCss}} {...props}>
        {children}
      </Wrapper>
    </ClickOutside>
  );
};

DateFieldWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  popupCss: PropTypes.object,
  onClickOutside: PropTypes.func,
};

DateFieldWrapper.defaultProps = {
  popupCss: {},
};

export default DateFieldWrapper;
