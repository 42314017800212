import styled from '@emotion/styled';

import {basic80} from '@renofi/theme/src/colors';

export const Input = styled.input({
  width: '100%',
  alignItems: 'center',
  border: 'none',
  outline: 'none',
  backgroundColor: 'transparent',
  color: basic80,
  fontSize: 16,
  padding: 0,
  margin: 0,
  '::placeholder': {
    opacity: 0.3,
  },
});
