import styled from '@emotion/styled';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.us';

export const Input = styled(Cleave)({
  display: 'block',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  border: 'none',
  outline: 'none',
  backgroundColor: 'transparent',
  '::placeholder': {
    opacity: 0.3,
  },
});
