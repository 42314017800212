import * as React from 'react';

import PropTypes from 'prop-types';

const SvgDoc = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <mask id="doc_svg__a" fill="#fff">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.56 0A3.56 3.56 0 0 0 8 3.56v24.88A3.56 3.56 0 0 0 11.56 32h16.88A3.56 3.56 0 0 0 32 28.44V3.539 8l-8-8H11.56z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.56 0A3.56 3.56 0 0 0 8 3.56v24.88A3.56 3.56 0 0 0 11.56 32h16.88A3.56 3.56 0 0 0 32 28.44V3.539 8l-8-8H11.56z"
        fill="#fff"
      />
      <path
        d="m32 3.539 1-.006-2 .006h1zM32 8l-.707.707L33 10.414V8h-1zm-8-8 .707-.707L24.414-1H24v1zM9 3.56A2.56 2.56 0 0 1 11.56 1v-2A4.56 4.56 0 0 0 7 3.56h2zm0 24.88V3.56H7v24.88h2zM11.56 31A2.56 2.56 0 0 1 9 28.44H7A4.56 4.56 0 0 0 11.56 33v-2zm16.88 0H11.56v2h16.88v-2zM31 28.44A2.56 2.56 0 0 1 28.44 31v2A4.56 4.56 0 0 0 33 28.44h-2zm0-24.88v24.88h2V3.56h-2zm0-.015v.015h2v-.027l-2 .012zm0-.006V8h2V3.539h-2zm1.707 3.754-8-8L23.293.707l8 8 1.414-1.414zM11.56 1H24v-2H11.56v2z"
        fill="#EBEBEB"
        mask="url(#doc_svg__a)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m24 0 8 8h-6a2 2 0 0 1-2-2V0z"
        fill="#A6ADBC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 8h5v5l-5-5z"
        fill="#D2D6DE"
      />
      <rect y={3} width={21} height={10} rx={2} fill="#2C377C" />
      <path
        d="M7.121 8.098c0 .541-.124 1.027-.371 1.457a2.71 2.71 0 0 1-.938.984 2.878 2.878 0 0 1-.933.36c-.339.067-.74.101-1.203.101H1.625V5.184h2.11c.473 0 .882.04 1.226.12.344.079.633.19.867.337.401.244.716.575.945.992.232.414.348.902.348 1.465zm-1.55-.012c0-.383-.071-.71-.212-.98a1.445 1.445 0 0 0-.66-.641 1.67 1.67 0 0 0-.472-.152 4.436 4.436 0 0 0-.723-.043h-.379v3.64h.379c.357 0 .618-.015.785-.047.167-.034.33-.093.488-.18.274-.156.474-.364.602-.624.128-.263.191-.588.191-.973zm8.35.008c0 .927-.265 1.664-.796 2.21-.531.545-1.266.817-2.203.817-.935 0-1.668-.272-2.2-.816-.53-.547-.796-1.284-.796-2.211 0-.935.265-1.673.797-2.215.53-.544 1.264-.816 2.199-.816.932 0 1.665.272 2.2.816.533.542.8 1.28.8 2.215zm-1.987 1.472c.145-.177.254-.385.324-.625a3.07 3.07 0 0 0 .105-.851c0-.35-.04-.646-.12-.89a1.728 1.728 0 0 0-.317-.595 1.181 1.181 0 0 0-.461-.34 1.438 1.438 0 0 0-1.078-.004 1.26 1.26 0 0 0-.461.337 1.66 1.66 0 0 0-.32.605 2.946 2.946 0 0 0-.122.89c0 .347.04.643.118.887.08.243.186.44.316.594.13.154.283.267.457.34a1.42 1.42 0 0 0 1.102 0c.174-.075.326-.191.457-.348zm5.765 1.547a3.63 3.63 0 0 1-1.199-.191 2.56 2.56 0 0 1-.941-.57 2.574 2.574 0 0 1-.614-.946 3.677 3.677 0 0 1-.215-1.308c0-.461.07-.88.207-1.254a2.68 2.68 0 0 1 .602-.965c.253-.258.565-.457.938-.598.375-.14.783-.21 1.226-.21.245 0 .465.014.66.042a3.553 3.553 0 0 1 1.02.273c.143.06.268.117.375.169v1.41h-.172a6.728 6.728 0 0 0-.277-.223 3.476 3.476 0 0 0-.375-.254 2.608 2.608 0 0 0-.465-.21 1.78 1.78 0 0 0-1.137.016 1.44 1.44 0 0 0-.527.327 1.64 1.64 0 0 0-.375.59c-.094.245-.14.542-.14.89 0 .365.05.67.151.915.105.244.235.437.391.578.159.143.336.246.531.308.196.06.388.09.578.09.183 0 .362-.027.54-.082a2.09 2.09 0 0 0 .496-.223c.127-.075.246-.156.355-.242.11-.086.2-.16.27-.222h.156v1.39a35.54 35.54 0 0 1-.418.184 3.444 3.444 0 0 1-.418.148c-.19.055-.369.097-.535.125a4.268 4.268 0 0 1-.688.043z"
        fill="#fff"
      />
      <rect x={13} y={16} width={14} height={2} rx={0.5} fill="#2C377C" />
      <rect x={13} y={20} width={14} height={2} rx={0.5} fill="#2C377C" />
      <rect x={13} y={24} width={14} height={2} rx={0.5} fill="#2C377C" />
    </svg>
  );
};

SvgDoc.propTypes = {
  color: PropTypes.string,
};
export default SvgDoc;
