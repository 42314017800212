import {isNil} from 'ramda';

export const openTab = (url) => !isNil(url) && window.open(url);

export const getDocument = () => window.document;

export const getLocation = () => window.location;

export const getChilipiper = () => window.ChiliPiper;

export const getHostname = () => window.location.hostname;

export const getDomain = () =>
  window.location.hostname.split('.').slice(-2).join('.');
