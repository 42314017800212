import {pathOr, propOr} from 'ramda';

import logoutWithRedirect from '@renofi/utilities/src/logoutWithRedirect';

const STATUS_CODE_PATH = ['extensions', 'exception', 'statusCode'];
const APP_NAME_PATH = [
  'extensions',
  'exception',
  'result',
  'errors',
  0,
  'app_name',
];
const APP_ERROR_PATH = [
  'extensions',
  'exception',
  'result',
  'errors',
  0,
  'message',
];

export default ({addNotification, error, storage}) => {
  const graphQLErrors = propOr([], 'graphQLErrors', error);
  const isAuthError = [401].includes(error?.networkError?.statusCode);
  const isSystemError = graphQLErrors.some(
    (err) => pathOr('', STATUS_CODE_PATH, err) === 500,
  );

  if (isSystemError) {
    const appNames = graphQLErrors.reduce(
      (arr, err) => arr.concat(pathOr([], APP_NAME_PATH, err)),
      [],
    );
    const messages = graphQLErrors.reduce(
      (arr, err) => arr.concat(pathOr([], APP_ERROR_PATH, err)),
      [],
    );

    addNotification({
      variant: 'danger',
      content: `A system error occured with this request. (apps: ${appNames.join(
        ',',
      )}) (errors: ${messages.join(',')})`,
    });
  }

  if (isAuthError) {
    storage.clear();
    return logoutWithRedirect();
  }
};
