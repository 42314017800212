import {gql} from '@apollo/client';

import {
  borrower,
  exceptionRequest,
  lender,
  note,
  renovationReadyFile,
  renovationUnderwritingRequest,
  smsConsentRenovationProgress,
  borrowersAndPropertyInformation,
  applicant,
} from '../../partials';

export default gql`
  query projectNoTasks($id: ID!) {
    projectById(id: $id) {
      id
      primaryApplicant {
        ${applicant}
      }
      coborrower {
        ${applicant}
      }
      actualCloseDate
      actualCreditScore
      actualDtiRatio
      appraisalCost
      appraisalManagementCompany
      appraiserAfterRenovationValue
      appraiserAsIsValue
      appraiserName
      appraiserReportSummary
      arvCltvRatio
      borrower {
        ${borrower}
      }
      borrowersAndPropertyInformation {
        ${borrowersAndPropertyInformation}
      }
      city
      createdAt
      mortgageStatementDate
      actualCurrentHomeValue
      statedCurrentHomeValue
      currentMortgageBalance
      currentMortgagePayment
      secondMortgageBalance
      documents {
        id
        checksum
        createdAt
        documentType
        fileName
        objectName
        pdfObjectName
        rejectedAt
        rejectedById
        rejectedBy {
          id
          firstName
          lastName
        }
        rejectedBySystem
        rejectionDetails
        rejectionReason
        reviewedAt
        shareWithLenderAllowed
        shareable
        source
        status
      }
      exceptionRequests {
        ${exceptionRequest}
      }
      estimatedCloseDate
      invitationSentAt
      lender {
        ${lender}
      }
      raas
      loanValue
      narrative
      peakCltvRatio
      positionedInterestRate
      actualRenovationHomeValue
      estimatedRenovationHomeValue
      renovationScope
      renofiId
      renofiProvidedDocuments {
        id
        checksum
        createdAt
        documentType
        fileName
        objectName
        pdfObjectName
        rejectedBySystem
        rejectionDetails
        rejectionReason
        shareWithLenderAllowed
        shareable
        status
      }
      renovationCost
      renovationCostToCurrentPropertyValueRatio
      renovationReadyFile {
        ${renovationReadyFile}
      }
      renovationReadyFiles {
        ${renovationReadyFile}
      }
      renovationUnderwritingRequestId
      renovationUnderwritingRequest {
        ${renovationUnderwritingRequest}
      }
      renovationUpdateSharingSettingValue
      productDetailsConfirmationStatus
      ruSummary
      salesforceOpportunityId
      selfEmployed
      selfReportedAssets
      selfReportedCreditScoreRange
      selfReportedDtiRatio
      selfReportedIncome
      selfReportedReserves
      stageName
      state
      status
      streetAddressOne
      streetAddressTwo
      notes {
        ${note}
      }
      smsConsentRenovationProgress {
        ${smsConsentRenovationProgress}
      }
      updatedAt
      yearsInHome
      zipCode
    }
  }
`;
