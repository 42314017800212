import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import {propOr} from 'ramda';

import {useSignedUrl} from '@renofi/graphql/src/hooks';

function SignedUrlImage({objectName, ...props}) {
  const {fetch: fetchSignedUrl, signedUrl} = useSignedUrl({lazy: true});
  const imageSrc = propOr(null, 'url', signedUrl);

  useEffect(() => {
    fetchSignedUrl({variables: {objectName}});
  }, [objectName]);
  if (!imageSrc) {
    return null;
  }

  return <img src={imageSrc} {...props} />;
}

SignedUrlImage.propTypes = {
  objectName: PropTypes.string,
};

export default SignedUrlImage;
