import {propOr} from 'ramda';

import useQuery from '../useQuery';
import {GET_LENDER_OWN_REPORT} from '../../queries';

const lastYear = new Date();
lastYear.setFullYear(lastYear.getFullYear() - 1);
const current = new Date();

export default function useLenderOwnReport({
  fetchPolicy = 'cache-first',
  from = lastYear,
  to = current,
} = {}) {
  const response = useQuery(GET_LENDER_OWN_REPORT, {
    fetchPolicy,
    variables: {
      from,
      to,
    },
  });

  const {data, error, loading, fetch} = response;
  const lenderOwnReport = propOr([], 'lenderOwnReport', data);

  return {error, fetch, lenderOwnReport, loading};
}
