import {gql} from '@apollo/client';

import {references} from '../../partials';

export default gql`
  mutation updateQuestionnaire($attributes: QuestionnaireInputObject!, $questionnaireId: ID!) {
    updateQuestionnaire(attributes: $attributes, questionnaireId: $questionnaireId) {
      questionnaire {
        avgNumberOfRenovationsPerYear
        avgPriceOfProjects
        bankruptcyDetails
        borrowerReminderSentAt
        businessName
        businessType
        businessTypeOtherDetails
        city
        contactFirstName
        contactLastName
        contactTitle
        contractorGeneralLiabilityInsurance {
          createdAt
          documentType
          fileSizeBytes
          id
          objectName
          source
        }
        contractorLicense {
          createdAt
          documentType
          fileSizeBytes
          id
          objectName
          source
        }
        contractorReminderSentAt
        contractorWorkersCompInsurance {
          createdAt
          documentType
          fileSizeBytes
          id
          objectName
          source
        }
        createdAt
        email
        firstOpenedAt
        hasBeenInvolvedInLawsuit
        hasEverFiledForBankruptcy
        hasLicense
        hasTaxLiens
        hasW2Employees
        hasWorkersCompInsurance
        id
        involvedInLawsuitDetails
        lastSentAt
        licenseNumber
        noInsuranceReasons
        noInsuranceReasonsOther
        noLicenseReasons
        noLicenseReasonsOther
        numberOfCompletedRenovations
        officePhoneNumber
        percentageBusinessWithCommercialInLast2y
        percentageBusinessWithResidentialInLast2y
        percentageCompletedRenovationsAmountRanges
        percentageLeadsNeedingExpertDesign
        percentageOfCompletedRenovationsIn30PercentAmountRange
        percentageSalesSpentOnMarketing
        phoneNumber
        propertyState
        references {
          ${references}
        }
        residentialHomeRemodelBlueprintingOption
        residentialHomeRemodelBlueprintingOptionOther
        residentialHomeRemodelRole
        residentialHomeRemodelRoleOther
        returnedAt
        secondaryAddress
        signatoryBusinessName
        signatoryName
        signatoryTitle
        signatoryType
        signatureDate
        state
        streetAddress
        submittedStep
        subsidaries
        taxLiensDetails
        teamSize
        typicalRenovationProjects
        typicalRenovationProjectsOtherDetails
        updatedAt
        website
        workersCompIncludedInGlid
        yearsAsAContractor
        zipCode
      }
    }
  }

`;
