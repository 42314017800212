import React from 'react';

import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import {basic15} from '@renofi/theme/src/colors';

const ANGLES = {
  up: 180,
  down: 0,
  left: 90,
  right: -90,
};

const Svg = styled('svg')(({direction}) => {
  const angle = ANGLES[direction] || 0;

  return {
    transform: `rotate(${angle}deg)`,
  };
});

function FilterArrow({direction, color, ...props}) {
  return (
    <Svg
      direction={direction}
      width="16"
      height="9"
      viewBox="0 0 16 9"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66667 8.66669H7.33333V7.33335H4.66667V8.66669ZM0 0.666687V2.00002H12V0.666687H0ZM2 5.33335H10V4.00002H2V5.33335Z"
        fill={color}
      />
    </Svg>
  );
}

FilterArrow.propTypes = {
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
  color: PropTypes.string,
  css: PropTypes.object,
};

FilterArrow.defaultProps = {
  direction: 'up',
  color: basic15,
};

export default FilterArrow;
