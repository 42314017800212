import * as React from 'react';

import PropTypes from 'prop-types';

const RenovationCompleted = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M3 19V9H0L11 0L22 9H19V10H17V7.475L11 2.575L5 7.475V17H7V19H3ZM13.2 21L9 16.8L10.4 15.4L13.2 18.2L19.1 12.3L20.5 13.7L13.2 21Z"
        fill={color}
      />
    </svg>
  );
};

RenovationCompleted.propTypes = {
  color: PropTypes.string,
};

export default RenovationCompleted;
