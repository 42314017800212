import styled from '@emotion/styled';
import {Input} from '@rebass/forms';

import {
  actionRegular,
  basic03,
  basic10,
  dangerLight,
  successLight,
} from '@renofi/theme/src/colors';

import Flex from '../Flex';

const getBorderColor = ({failure, success}) => {
  if (failure) {
    return dangerLight;
  }
  if (success) {
    return successLight;
  }
  return basic10;
};

export const Field = styled(Input)(
  {
    borderRadius: 10,
    fontSize: 36,
    lineHeight: '44px',
    width: 56,
    height: 96,
    margin: 0,
    padding: 0,
    fontWeight: 600,
    background: basic03,
    textAlign: 'center',
    color: 'black',
    borderStyle: 'solid',
    borderWidth: 1,
    ':focus': {
      outline: 'none',
      borderColor: actionRegular,
    },
  },
  (props) => ({
    borderColor: getBorderColor(props),
  }),
);

export const Wrapper = styled(Flex)(
  {
    height: 96,
    marginBottom: 24,
    justifyContent: 'space-between',
  },
  ({size}) => ({
    width: 56 * size + 24 * (size - 1),
  }),
);
