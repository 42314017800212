import {gql} from '@apollo/client';

export default gql`
  mutation submitAppraisalPropertyDetails(
    $taskId: ID!
    $propertyDetails: AppraisalPropertyDetailsInputObject!
  ) {
    submitAppraisalPropertyDetails(
      taskId: $taskId
      appraisalPropertyDetails: $propertyDetails
    ) {
      appraisalPropertyDetails {
        id
        additionalInformation
        additionalInformationPresent
        basementSquareFootage
        basementState
        bedroomsInBasement
        bedroomsTotal
        city
        coolingTypes
        county
        createdAt
        fireplaceTypes
        fullBathroomsInBasement
        fullBathroomsTotal
        garagesAttached
        garagesDetached
        halfBathroomsInBasement
        halfBathroomsTotal
        heatingTypes
        lat
        lng
        mapPhotoUrl
        noteForAppraiser
        noteForAppraiserPresent
        previousRenovationsDescription
        previousRenovationsDescriptionPresent
        propertyType
        propertyTypeOther
        rentalIncome
        rentalIncomeCollectedOnPrimaryResidence
        state
        streetAddressOne
        streetAddressTwo
        streetViewPhotoUrl
        totalSquareFootage
        updatedAt
        yearBuilt
        yearPurchased
        zipCode
      }
    }
  }
`;
