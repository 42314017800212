import {pipe, propEq, propOr, reject} from 'ramda';

import useToggledQuery from '../../useToggledQuery';
import {GET_ENUMS} from '../../../queries';

export default function useEnums({lazy = false, ...options} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_ENUMS,
    options,
  });
  const {data, error, loading, fetch} = response;

  const enums = propOr({}, 'enums', data);
  // Ignore unclassified as it's 100% internal and users shouldn't
  // be able or allowed to change a document-type to this one
  const documentTypes = pipe(
    propOr([], 'documentTypes'),
    reject(propEq('value', 'unclassified')),
  )(enums);
  const incomeTypes = propOr([], 'incomeTypes', enums);

  return {
    fetch,
    documentTypes,
    enums,
    incomeTypes,
    loading,
    error,
  };
}
