import {useContext, useMemo} from 'react';

import {mergeDeepRight, pathOr, propOr} from 'ramda';

import isFunction from '@renofi/utilities/src/isFunction';

import {ALL_THEMES} from './constants';
import ThemeContext from './context';
import {getVariantFromProps} from './utils';

/**
 * @description Below is a sample of potential props;
 * @param {Object} props
 * @param {String} props.defaultVariant
 * @param {Boolean} props.disabled
 * @param {Boolean} props.error
 * @param {Boolean} props.inverse
 * @param {Boolean} props.muted
 * @param {String} props.name
 * @param {Boolean} props.outline
 * @param {Boolean} props.primary
 * @param {Boolean} props.secondary
 * @param {String} props.variant
 * @param {Boolean} props.vibrant
 */
const useTheme = (name, props = {}) => {
  const context = useContext(ThemeContext);
  const {theme} = context;

  const styles = useMemo(() => {
    const variant = getVariantFromProps(props);
    const namedTheme = pathOr(null, [theme, name], ALL_THEMES);

    const executable = isFunction(namedTheme);
    const result = executable ? namedTheme(props) : namedTheme;

    return mergeDeepRight(
      propOr({}, 'common', result),
      propOr({}, variant, result),
    );
  }, [props, theme]);

  return {styles, theme};
};

export default useTheme;
