import {gql} from '@apollo/client';

export default gql`
  mutation updateReference($attributes: ReferenceInputObject!) {
    updateReference(attributes: $attributes) {
      reference {
        confirmationRequestedAt
        confirmationRequestedById
        confirmationRequestedMessage
        confirmedAt
        createdAt
        email
        id
        manualEntryDetails
        name
        phoneNumber
        questionnaireId
        score
        scoreSubmittedAt
        scoreSubmittedById
        status
        type
        updatedAt
      }
    }
  }
`;
