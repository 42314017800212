import {gql} from '@apollo/client';

import {gcddDocument} from '../../partials';

export default gql`
  query contractorInsurances($contractorId: ID!, $limit: Int!, $page: Int!) {
    contractorInsurances(contractorId: $contractorId, limit: $limit, page: $page) {
      collection {
        contractorId
        ${gcddDocument}
        limitAggregate
        limitEachOccurrence
        limitWorkersCompensation
        submittedById
        submittedBy {
          email
          firstName
          id
          lastName
          phoneNumber
        }
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
}
`;
