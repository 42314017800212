import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import logger from '@renofi/utilities/src/logger';

export function initLogRocket(logrocketId) {
  if (!logrocketId) {
    return logger.warn('🟧 No LogRocket ID given');
  }

  LogRocket.init(logrocketId, {
    rootHostname: 'renofi.com',
    release: process.env.REACT_APP_COMMIT_REF,
    browser: {
      // Sanitize tokens from URL
      urlSanitizer: (url) => {
        let sanitizedUrl = url;
        ['reset_password', 'signup', 'token_auth'].forEach((value) => {
          sanitizedUrl = redactUrl(sanitizedUrl, value);
        });
        return sanitizedUrl;
      },
    },
    network: {
      // Sanitize token from headers
      requestSanitizer: (request) => {
        request.headers['authorization'] = null;
        return request;
      },
    },
  });
  setupLogRocketReact(LogRocket);

  addSessionUrlToSegment();
}

export function addSessionUrlToSegment() {
  LogRocket.getSessionURL(function (sessionURL) {
    window.analytics?.track('LogRocket', {
      sessionURL: sessionURL,
    });
  });
}

function redactUrl(url, value) {
  return url.replace(
    new RegExp(`/${value}/([^/]*)`, 'i'),
    `/${value}/REDACTED`,
  );
}

export default LogRocket;
