import React from 'react';

import ErrorBoundary from './Component';

export default ({formatter} = {}) =>
  (Component) =>
  (props) =>
    (
      <ErrorBoundary props={props} formatter={formatter}>
        <Component {...props} />
      </ErrorBoundary>
    );
