import * as React from 'react';

import PropTypes from 'prop-types';

const SvgCamera = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M9 2 7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"
          fill={color}
        />
        <circle fill={color} cx={12} cy={12} r={3.2} />
      </g>
    </svg>
  );
};

SvgCamera.propTypes = {
  color: PropTypes.string,
};
export default SvgCamera;
