import React, {useState} from 'react';

import PropTypes from 'prop-types';

import Loader from '../Loader';

import {Cover, Table as StyledTable, Wrapper} from './styled';

const loader = <Loader label={false} />;

const Table = ({
  children,
  hover,
  loading,
  responsive,
  tableLayout = 'fixed',
  ...props
}) => {
  const [showSwipeIcon, setShowSwipeIcon] = useState(responsive);

  function onScroll() {
    setShowSwipeIcon(false);
  }
  return (
    <Wrapper onScroll={onScroll} responsive={responsive}>
      {loading && <Cover>{loader}</Cover>}
      <StyledTable
        hover={hover && !loading}
        responsive={responsive}
        showSwipeIcon={showSwipeIcon}
        tableLayout={tableLayout}
        {...props}>
        {children}
      </StyledTable>
    </Wrapper>
  );
};

Table.propTypes = {
  children: PropTypes.node,
  hover: PropTypes.bool,
  loading: PropTypes.bool,
  responsive: PropTypes.bool,
  tableLayout: PropTypes.string,
};

export default Table;
