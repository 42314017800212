export default `
accountNumber
accountHolderName
adjustedCashOrMarketValue
assetType
borrowerRole
cashOrMarketValue
description
financialInstitution
id
jointAccount
shareWithLender
taskId
`;
