const documents = [
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/x-vnd.oasis.opendocument.spreadsheet',
];

const images = [
  'image/apng',
  'image/heic',
  'image/heic-sequence',
  'image/heif',
  'image/heif-sequence',
  'image/jpeg',
  'image/pjpeg',
  'image/png',
  'image/webp',
];

export default () => [
  'application/octet-stream',
  'application/pdf',
  'text/plain',
  'text/xml',
  ...documents,
  ...images,
];
