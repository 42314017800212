import {gql} from '@apollo/client';

export default gql`
  mutation generateCombinedPdf(
    $urls: [StorageObjectUrl!]!
    $outputFileName: String
  ) {
    generateCombinedPdf(urls: $urls, outputFileName: $outputFileName) {
      url
    }
  }
`;
